import SingleCenterCard from "@components/single-center-card/single-center-card";
import { useAuth } from "@features/auth/state/hooks";
import { ROUTES } from "@features/routes";
import { useCallback, useEffect } from "react";
import { Route, useNavigate } from "react-router-dom";
import { SignUp } from "./signup";

export const SignUpRoutes = () => {
  return (
    <Route path={ROUTES.Signup}>
      <Route path={""} element={<SignUpPage />} />
    </Route>
  );
};

const SignUpPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const redirectToApp = useCallback(() => {
    navigate(
      decodeURIComponent(
        new URL((window as any).document.location).searchParams.get("r") || ""
      ) || ROUTES.Dashboard
    );
  }, [navigate]);

  useEffect(() => {
    if (user?.id) redirectToApp();
  }, [user?.id, redirectToApp]);

  return (
    <div className="h-full w-full absolute sm:bg-transparent">
      <SingleCenterCard insetLogo>
        <SignUp />
      </SingleCenterCard>
    </div>
  );
};

import { Button } from "@atoms/button/button";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import Select from "@atoms/select";
import { useAlertStates } from "@features/alerts/state/use-alert-states";
import { useAlert } from "@features/alerts/state/use-alerts";
import _ from "lodash";
import { atom, useRecoilState } from "recoil";

export const AlertStatusUpdateModalAtom = atom<{
  open: boolean;
  comment: string;
  status: number;
  alert_id: string;
}>({
  key: "AlertStatusUpdateModalAtom",
  default: {
    open: false,
    comment: "",
    status: 7,
    alert_id: "",
  },
});

export const AlertStatusUpdateModal = () => {
  const [modal, setModal] = useRecoilState(AlertStatusUpdateModalAtom);

  return (
    <Modal
      open={modal.open}
      onClose={() => setModal({ ...modal, open: false })}
    >
      <AlertStatusUpdateModalContent />
    </Modal>
  );
};

const AlertStatusUpdateModalContent = () => {
  const [modal, setModal] = useRecoilState(AlertStatusUpdateModalAtom);
  const { states } = useAlertStates();
  const { update, loading } = useAlert(modal.alert_id);

  return (
    <ModalContent title={"Update alert review"}>
      <InputLabel
        label="Alert status"
        input={
          <Select
            value={modal.status}
            onChange={(e) =>
              setModal({ ...modal, status: parseInt(e.target.value) })
            }
            shortcut={["s"]}
          >
            {Object.keys(states).map((state) => (
              <option
                value={state}
                disabled={parseInt(state) === 2}
                key={state}
              >
                {_.capitalize(states[state as any])}
              </option>
            ))}
          </Select>
        }
      />
      <InputLabel
        className="mt-2"
        label="Review"
        input={
          <Input
            value={modal.comment}
            onChange={(e) => setModal({ ...modal, comment: e.target.value })}
            multiline
            shortcut={["r"]}
          />
        }
      />
      <Button
        className="mt-4"
        disabled={loading}
        theme="default"
        onClick={() => setModal({ ...modal, open: false })}
        shortcut={["esc"]}
      >
        Cancel
      </Button>
      <Button
        className="mt-4 float-right"
        loading={loading}
        disabled={!modal.comment || !modal.status}
        onClick={async () => {
          await update(modal.status, modal.comment);
          setModal({ ...modal, comment: "", status: 7, open: false });
        }}
        shortcut={["enter"]}
      >
        Update alert status
      </Button>
    </ModalContent>
  );
};

import { useAuth } from "@features/auth/state/hooks";
import { delayRequest, useGlobalEffect } from "@features/utils";
import { LoadingAtom } from "@features/utils/loading";
import { websocketBus } from "@features/websockets";
import { useCallback } from "react";
import { useRecoilCallback, useRecoilState } from "recoil";
import { InboxApiClient } from "../api-client/api-client";
import {
  InboxThread,
  InboxThreadCreateInput,
  InboxThreadRequestOptions,
} from "../types";
import { ThreadsListAtom, ThreadAtom } from "./store";
import _ from "lodash";

export const useThreads = (customerId?: string) => {
  const [threads, setThreads] = useRecoilState(
    ThreadsListAtom(customerId || "all")
  );
  const [loading, setLoading] = useRecoilState(LoadingAtom("useThreads"));

  const setThread = useRecoilCallback(({ set }) => (thread: InboxThread) => {
    set(ThreadAtom(thread.id), thread);
  });

  const refresh = useCallback(
    async (options?: InboxThreadRequestOptions & { _silent?: boolean }) => {
      if (!options?._silent) setLoading(true);
      delayRequest("useThreadsRefresh", async () => {
        const threads = await InboxApiClient.getThreads({ ...options });
        setThreads(threads);
        for (const thread of threads.data) {
          setThread(thread);
        }
        if (!options?._silent) setLoading(false);
      });
    },
    [setThread, setThreads, setLoading]
  );

  const create = useCallback(async (input: InboxThreadCreateInput) => {
    return await InboxApiClient.createThread(input);
  }, []);

  return { threads, loading, refresh, create };
};

export const useRealtimeThreads = () => {
  const { clientId } = useAuth();

  const refreshThread = useRecoilCallback(
    ({ set }) =>
      async (id: string) => {
        _.debounce(async () => {
          return set(ThreadAtom(id), await InboxApiClient.getThread(id));
        }, 2000);
      },
    []
  );

  useGlobalEffect(
    `useRealtimeThreads`,
    () => {
      if (clientId) {
        const room = `client/${clientId}/threads`;
        websocketBus.emit("join", null, { room });
        websocketBus.on(room, (event: any) => {
          refreshThread(event.data.thread_id);
        });
      }
    },
    [clientId]
  );
};

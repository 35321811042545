import { atom, atomFamily } from "recoil";
import {
  SessionsRequestOptions,
  SessionListType,
  SessionStatusType,
  SessionType,
  SessionDecisionType,
} from "../types";

export const CustomerReviewSessionsAtom = atomFamily<
  {
    per_state: { [key: string]: SessionListType[] };
    total: number;
    loading: boolean;
  },
  string
>({
  key: "CustomerReviewSessionsAtom",
  default: () => ({
    per_state: {},
    total: 0,
    loading: true,
  }),
});

export const SessionsStatesAtom = atom<SessionStatusType[]>({
  key: "SessionsStatesAtom",
  default: [],
});

export const SessionsListAtom = atom<{
  data: SessionListType[];
  total: number;
}>({
  key: "SessionsListAtom",
  default: { data: [], total: 0 },
});

export const SessionDetailsAtom = atomFamily<SessionType | null, string>({
  key: "SessionDetailsAtom",
  default: null,
});

export const SessionDecisionsAtom = atomFamily<SessionDecisionType[], string>({
  key: "SessionDecisionsAtom",
  default: [],
});

export const CustomerSessionsAtom = atomFamily<SessionListType[], string>({
  key: "CustomerSessionssAtom",
  default: [],
});



export const SessionsFiltersAtom = atom<SessionsRequestOptions>({
  key: "SessionsFiltersAtom",
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newValue) => {
        localStorage.setItem(
          "saved.sessions_filters",
          JSON.stringify(newValue)
        );
      });
    },
  ],
  default: (() => {
    let saved = {};
    try {
      saved = JSON.parse(
        localStorage.getItem("saved.sessions_filters") || "{}"
      );
    } catch (e) {
      // No-op
    }

    return {
      external_id: null,
      search: null,
      language: null,
      date_from: null,
      date_to: null,
      status_id: null,
      include_unfinished: false,
      origin: ["vid", "pix"],
      score_min: null,
      score_max: null,
      scenario_ref: null,
      review_groups: null,
      assigned_to_member_id: null,
      on_page: 1,
      per_page: 10,
      order_by: "date",
      order_way: "desc",
      ...saved,
    } as unknown as SessionsRequestOptions;
  })(),
});

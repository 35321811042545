import { Button } from "@atoms/button/button";
import { RiskResourceType, RiskTreeNode } from "@features/risk-decisions/types";
import {
  ArrowDownOnSquareStackIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import { CSSProperties } from "react";
import { Handle, HandleProps, NodeProps, Position } from "reactflow";
import { NodeLayout } from "./components/_node_layout";

const handleStyle: CSSProperties = {
  height: 15,
  width: 15,
};

const fromProps: HandleProps = {
  type: "target",
  id: "from",
  position: Position.Left,
};
const output: HandleProps = {
  type: "source",
  position: Position.Right,
};

export default function OrNode(
  props: NodeProps<
    RiskTreeNode & {
      withRelations: boolean;
      editable: boolean;
      resource: RiskResourceType;
      onChange: (data: RiskTreeNode) => void;
    }
  >
) {
  const outputNodeIds = Object.keys(props.data.output_node_ids).filter((id) =>
    id.includes("or_output")
  );

  return (
    <NodeLayout
      selected={props.selected}
      className="dark:bg-slate-800 dark:text-white"
    >
      <div className="flex flex-col bg-blue-500 text-white space-y-2 rounded-md pt-2">
        <div className="flex flex-row">
          <div>
            <div className="relative">
              <small className="mx-4">from</small>
              <Handle {...fromProps} style={{ ...handleStyle, left: -4 }} />
            </div>
          </div>
          <div className="grow -rotate-90 opacity-50">
            <ArrowDownOnSquareStackIcon className="h-10 w-10" />
          </div>
          <div className="flex flex-col">
            {outputNodeIds.map((id, index) => {
              return (
                <div key={id} className="relative">
                  {index > 1 && (
                    <button
                      className="absolute top-0 -left-2 h-5 w-5 text-red-400"
                      onClick={() => {
                        const output_node_ids = {
                          ...props.data.output_node_ids,
                        };
                        delete output_node_ids[id];
                        props.data.onChange({
                          ...props.data,
                          output_node_ids,
                        });
                      }}
                    >
                      <TrashIcon className="h-5 w-5" />
                    </button>
                  )}
                  <small className="mx-4">output {index + 1}</small>
                  <Handle
                    onConnect={(params) => {
                      props.data.onChange({
                        ...props.data,
                        output_node_ids: {
                          ...props.data.output_node_ids,
                          [id]: params.target!,
                        },
                      });
                      return true;
                    }}
                    {...{ ...output, id: id }}
                    style={{ ...handleStyle, right: -8 }}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <Button
          className="w-full rounded-b-md"
          size="md"
          onClick={() => {
            const id = `or_output${outputNodeIds.length + 1}`;
            props.data.onChange({
              ...props.data,
              output_node_ids: {
                ...props.data.output_node_ids,
                [id]: "",
              },
            });
          }}
        >
          <PlusIcon className="h-6 w-6" />
          Add output
        </Button>
      </div>
    </NodeLayout>
  );
}

import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import { Checkbox } from "@atoms/input/input-checkbox";
import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Input } from "@atoms/input/input-text";
import { Page } from "@atoms/layout/page";
import Select from "@atoms/select";
import Tabs from "@atoms/tabs";
import { Info, Menu, Title } from "@atoms/text";
import { PageLoader } from "@components/page-loader";
import { useReviewGroups } from "@features/agents/state/use-review-groups";
import { useInboxSettings } from "@features/inbox-settings/state/use-inbox-settings";
import { InboxSettingsType } from "@features/inbox-settings/types";
import { validateInboxSettings } from "@features/inbox-settings/utils";
import { useMessagesLabels } from "@features/messages-labels/state/use-messages-labels";
import _ from "lodash";
import { useEffect, useState } from "react";
import { BlackListInput, RecipientsInput } from "./special-inputs";
import Link from "@atoms/link";

export default function InboxSettingsPage() {
  const { settings, save, loading, refresh } = useInboxSettings();
  const [configuration, setConfiguration] = useState<InboxSettingsType>();
  const { reviewGroups } = useReviewGroups();
  const { labels: messageTags } = useMessagesLabels();

  const [tabValue, setTabValue] = useState("sdk");
  const [mailboxId, setMailboxId] = useState<string | null>(null);

  useEffect(() => {
    if (settings) {
      setMailboxId(settings?.mailboxes?.[0]?.id);
      setConfiguration(settings);
    }
  }, [settings]);

  useEffect(() => {
    refresh();
  }, []);

  if (loading) return <PageLoader />;

  const selectedMailbox = (configuration?.mailboxes || []).find(
    (mailbox) => mailbox.id === mailboxId
  );

  const updateMailboxConfiguration = (
    update: Partial<InboxSettingsType["mailboxes"][0]>
  ) => {
    const key = Object.keys(update)[0];
    const value = Object.values(update)[0];
    setConfiguration({
      ...configuration!,
      mailboxes:
        configuration?.mailboxes.map((mailbox) =>
          mailbox.id === mailboxId ? { ...mailbox, [key]: value } : mailbox
        ) || [],
    });
  };

  return (
    <Page>
      <div className="flex flex-row justify-between">
        <Title>Inbox Settings</Title>
        <Button
          onClick={async () => {
            save(configuration!);
          }}
          disabled={!configuration || !validateInboxSettings(configuration)}
          theme="primary"
        >
          Save all tabs
        </Button>
      </div>

      <Tabs
        value={tabValue}
        onChange={(e) => setTabValue(e as string)}
        tabs={[
          {
            value: "sdk",
            label: "SDK integration",
          },
          {
            value: "smtp-imap",
            label: "SMTP & IMAP",
          },
          {
            value: "other",
            label: "Other",
          },
        ]}
      ></Tabs>

      {tabValue === "sdk" && (
        <>
          <Menu className="my-3 block">Go!Chat SDK</Menu>
          <>
            <InputLabel
              className="mt-2 max-w-xl"
              label="Style Override (CSS)"
              input={
                <Input
                  style={{ minHeight: 400 }}
                  multiline
                  placeholder=""
                  value={configuration?.style_override ?? ""}
                  onChange={(e) => {
                    setConfiguration({
                      ...configuration!,
                      style_override: e.target.value || "",
                    });
                  }}
                />
              }
            />
            <InputLabel
              className="mt-4 max-w-xl"
              label="Translations override (JSON)"
              input={
                <Input
                  style={{ minHeight: 400 }}
                  multiline
                  placeholder=""
                  value={configuration?.translations_override ?? ""}
                  onChange={(e) => {
                    setConfiguration({
                      ...configuration!,
                      translations_override: e.target.value || "",
                    });
                  }}
                />
              }
            />
          </>
        </>
      )}

      {tabValue === "other" && (
        <div className="max-w-xl">
          <br />
          <Menu className="mt-4 block mb-1">Incoming tickets</Menu>
          <Info className="block mb-3">
            This section defines how incoming tickets are handled. For instance,
            incoming tickets like the ones from the IMAP server should define
            how and if they should be ingested by Algoreg.
          </Info>
          <InputLabel
            className="mt-2 max-w-sm"
            label="Maximum customers matched in a incoming ticket"
            input={
              <Input
                placeholder="Number of customers matched"
                value={configuration?.max_thread_customers ?? ""}
                onChange={(e) => {
                  setConfiguration({
                    ...configuration!,
                    max_thread_customers: parseInt(e.target.value),
                  });
                }}
              />
            }
          />
        </div>
      )}

      {tabValue === "smtp-imap" && (
        <>
          <Menu className="my-3 block">Mailboxes</Menu>
          <div className="flex flex-row space-x-2">
            <Select
              className="!w-auto"
              value={mailboxId || ""}
              onChange={(e) => {
                if (e.target.value) {
                  setMailboxId(e.target.value);
                } else {
                  const newId = new Date().getTime().toString();
                  setMailboxId(newId);
                  setConfiguration({
                    ...configuration!,
                    mailboxes: [
                      ...(configuration?.mailboxes || []),
                      {
                        id: newId,
                        account_name:
                          "New mailbox " +
                          ((configuration?.mailboxes || []).length + 1),
                        default_reminder: null,
                        smtp_enabled: false,
                        smtp_address: "",
                        smtp_port: "",
                        smtp_username: "",
                        smtp_password: "",
                        smtp_dkim_domain: "",
                        smtp_dkim_selector: "",
                        smtp_dkim_key: "",
                        smtp_disable_start_tls: false,
                        imap_enabled: false,
                        imap_address: "",
                        imap_port: "",
                        imap_username: "",
                        imap_password: "",
                        imap_disable_start_tls: false,
                        imap_mark_as_read: false,
                        bounce_message_to: "",
                        allow_non_customer_emails: false,
                      } as any,
                    ],
                  });
                }
              }}
            >
              {configuration?.mailboxes?.map((mailbox) => (
                <option key={mailbox.id} value={mailbox.id}>
                  {mailbox.account_name}
                </option>
              ))}
              <option value={""}>Create new mailbox</option>
            </Select>
            <ButtonConfirm
              confirmMessage={
                "Are you sure you want to remove the mailbox " +
                selectedMailbox?.account_name +
                " ?"
              }
              onClick={() => {
                const newMailboxes = (configuration?.mailboxes || []).filter(
                  (mailbox) => mailbox.id !== mailboxId
                );
                setConfiguration({
                  ...configuration!,
                  mailboxes: newMailboxes,
                });
                setMailboxId(newMailboxes?.[0]?.id || null);
              }}
              disabled={(configuration?.mailboxes?.length || 0) <= 1}
              theme="danger"
            >
              Remove mailbox
            </ButtonConfirm>
          </div>

          {selectedMailbox && (
            <div className="p-4 border rounded mt-4">
              <>
                <Menu className="mb-3 block">Mailbox general parameters</Menu>
                <InputLabel
                  className="mt-2 max-w-sm"
                  label="Account Name"
                  input={
                    <Input
                      className="max-w-sm w-full"
                      placeholder="Web mailbox"
                      value={selectedMailbox.account_name}
                      onChange={(e) => {
                        updateMailboxConfiguration({
                          account_name: e.target.value,
                        });
                      }}
                    />
                  }
                />

                <InputLabel
                  className="mt-3 max-w-sm"
                  label="Review groups"
                  input={
                    <SelectMultiple
                      value={selectedMailbox.review_groups || []}
                      onChange={(selectedReviewGroups: string[]) => {
                        updateMailboxConfiguration({
                          review_groups: selectedReviewGroups,
                        });
                      }}
                      options={_.uniq([...(reviewGroups || [])]).map((rg) => ({
                        value: rg,
                        label: rg,
                      }))}
                    />
                  }
                />

                <InputLabel
                  className="mt-3 max-w-sm"
                  label="Assigned tags for incoming messages"
                  input={
                    <SelectMultiple
                      value={selectedMailbox.in_mail_tags || []}
                      onChange={(selectedReviewGroups: string[]) => {
                        updateMailboxConfiguration({
                          in_mail_tags: selectedReviewGroups,
                        });
                      }}
                      options={messageTags.map((rg) => ({
                        value: rg.code,
                        label: rg.name,
                      }))}
                    />
                  }
                />

                <InputLabel
                  className="mt-3 max-w-sm"
                  label="Additional recipients"
                  input={
                    <RecipientsInput
                      value={selectedMailbox.recipient_groups || []}
                      onChange={(values) => {
                        updateMailboxConfiguration({
                          recipient_groups: values,
                        });
                      }}
                    />
                  }
                />
                <Info>
                  Agents with not supervisor access level will be able to use
                  this recipients when sending messages in addition to the
                  customer's contacts.
                </Info>

                <br />
                <Menu className="block mt-3 mb-1">
                  Outgoing messages lifecycles
                </Menu>
                <Info className="block mb-3">
                  When a message is sent, its thread will immediately be changed
                  to either "reminder" or "pending". This state will update
                  itself every N days defined by the following "delay" option.
                  Each threads sends a maximum of N reminders before to pass in
                  the "pending" state and finally back to the "open" state.
                </Info>

                <Checkbox
                  label="Enable Thread Reminder"
                  value={selectedMailbox.default_reminder !== null}
                  onChange={(checked) => {
                    updateMailboxConfiguration({
                      default_reminder: checked
                        ? {
                            delay: 3,
                            number_left: 3,
                          }
                        : null,
                    });
                  }}
                />
                {selectedMailbox.default_reminder && (
                  <>
                    <InputLabel
                      className="mt-4 max-w-sm"
                      label="Delay (Days)"
                      input={
                        <Input
                          placeholder="Delay"
                          value={selectedMailbox.default_reminder.delay ?? ""}
                          onChange={(e) => {
                            updateMailboxConfiguration({
                              default_reminder: {
                                ...selectedMailbox?.default_reminder!,
                                delay: parseInt(e.target.value),
                              },
                            });
                          }}
                        />
                      }
                    />
                    <InputLabel
                      className="mt-2 max-w-sm"
                      label="Reminders (Times)"
                      input={
                        <Input
                          placeholder="Number of reminders"
                          value={
                            selectedMailbox.default_reminder.number_left ?? ""
                          }
                          onChange={(e) => {
                            updateMailboxConfiguration({
                              default_reminder: {
                                ...selectedMailbox?.default_reminder!,
                                number_left: parseInt(e.target.value),
                              },
                            });
                          }}
                        />
                      }
                    />
                  </>
                )}
              </>

              <br />
              <Menu className="my-3 block">SMTP Configuration</Menu>
              <>
                <Checkbox
                  label="Enable SMTP"
                  value={selectedMailbox.smtp_enabled}
                  onChange={(checked) => {
                    updateMailboxConfiguration({ smtp_enabled: checked });
                  }}
                />
                {selectedMailbox.smtp_enabled && (
                  <>
                    <InputLabel
                      className="mt-2 max-w-sm"
                      label="From Address"
                      input={
                        <Input
                          placeholder="notification@server.com"
                          value={selectedMailbox.from_email ?? ""}
                          onChange={(e) => {
                            updateMailboxConfiguration({
                              from_email: e.target.value,
                            });
                          }}
                        />
                      }
                    />
                    <InputLabel
                      className="mt-2 max-w-sm"
                      label="SMTP Server"
                      input={
                        <Input
                          required
                          label="SMTP Server*"
                          placeholder="smtp.serveraddress.com"
                          value={selectedMailbox.smtp_address ?? ""}
                          onChange={(e) => {
                            updateMailboxConfiguration({
                              smtp_address: e.target.value,
                            });
                          }}
                        />
                      }
                    />
                    <InputLabel
                      className="mt-2 max-w-sm"
                      label="SMTP Port"
                      input={
                        <Input
                          required
                          label="SMTP Port*"
                          placeholder="465"
                          value={selectedMailbox.smtp_port ?? ""}
                          onChange={(e) => {
                            updateMailboxConfiguration({
                              smtp_port: e.target.value,
                            });
                          }}
                        />
                      }
                    />
                    <InputLabel
                      className="mt-2 max-w-sm"
                      label="SMTP Username"
                      input={
                        <Input
                          required
                          label="SMTP Username*"
                          placeholder="smtp_username"
                          value={selectedMailbox.smtp_username ?? ""}
                          onChange={(e) => {
                            updateMailboxConfiguration({
                              smtp_username: e.target.value,
                            });
                          }}
                        />
                      }
                    />
                    <InputLabel
                      className="mt-2 max-w-sm"
                      label="SMTP Password"
                      input={
                        <Input
                          required
                          label="SMTP Password*"
                          placeholder="smtp_password"
                          type="password"
                          value={selectedMailbox.smtp_password ?? ""}
                          onChange={(e) => {
                            updateMailboxConfiguration({
                              smtp_password: e.target.value,
                            });
                          }}
                        />
                      }
                    />
                    <InputLabel
                      className="mt-2 mb-3 max-w-sm"
                      label="DKIM domain, selector and private key"
                      input={
                        <div className="flex flex-col">
                          <Input
                            className="focus:z-10 rounded-b-none"
                            placeholder="domain.com"
                            value={selectedMailbox.smtp_dkim_domain ?? ""}
                            onChange={(e) => {
                              updateMailboxConfiguration({
                                smtp_dkim_domain: e.target.value,
                              });
                            }}
                          />
                          <Input
                            className="focus:z-10 -mt-px rounded-none"
                            placeholder="s1"
                            value={selectedMailbox.smtp_dkim_selector ?? ""}
                            onChange={(e) => {
                              updateMailboxConfiguration({
                                smtp_dkim_selector: e.target.value,
                              });
                            }}
                          />
                          <Input
                            className="-mt-px rounded-t-none"
                            placeholder="Private Key"
                            value={selectedMailbox.smtp_dkim_key ?? ""}
                            onChange={(e) => {
                              updateMailboxConfiguration({
                                smtp_dkim_key: e.target.value,
                              });
                            }}
                          />
                        </div>
                      }
                    />
                    <Checkbox
                      label="SMTP Disable Start TLS"
                      value={selectedMailbox.smtp_disable_start_tls}
                      onChange={(checked) => {
                        updateMailboxConfiguration({
                          smtp_disable_start_tls: checked,
                        });
                      }}
                    />
                  </>
                )}
              </>

              <br />
              <Menu className="my-3 block">IMAP Configuration</Menu>
              <>
                <Checkbox
                  label="Enable IMAP"
                  value={selectedMailbox.imap_enabled}
                  onChange={(checked) => {
                    updateMailboxConfiguration({ imap_enabled: checked });
                  }}
                />
                {selectedMailbox.imap_enabled && (
                  <>
                    <InputLabel
                      className="mt-2 max-w-sm"
                      label="IMAP Server"
                      input={
                        <Input
                          required
                          label="IMAP Server*"
                          placeholder="imap.serveraddress.com"
                          value={selectedMailbox.imap_address ?? ""}
                          onChange={(e) => {
                            updateMailboxConfiguration({
                              imap_address: e.target.value,
                            });
                          }}
                        />
                      }
                    />
                    <InputLabel
                      className="mt-2 max-w-sm"
                      label="IMAP Port"
                      input={
                        <Input
                          required
                          label="IMAP Port*"
                          placeholder="993"
                          value={selectedMailbox.imap_port ?? ""}
                          onChange={(e) => {
                            updateMailboxConfiguration({
                              imap_port: e.target.value,
                            });
                          }}
                        />
                      }
                    />
                    <InputLabel
                      className="mt-2 max-w-sm"
                      label="IMAP Username"
                      input={
                        <Input
                          required
                          label="IMAP Username*"
                          placeholder="imap_username"
                          value={selectedMailbox.imap_username ?? ""}
                          onChange={(e) => {
                            updateMailboxConfiguration({
                              imap_username: e.target.value,
                            });
                          }}
                        />
                      }
                    />

                    <InputLabel
                      className="mt-2 mb-3 max-w-sm"
                      label="IMAP Password"
                      input={
                        <Input
                          required
                          label="IMAP Password*"
                          placeholder="imap_password"
                          type="password"
                          value={selectedMailbox.imap_password ?? ""}
                          onChange={(e) => {
                            updateMailboxConfiguration({
                              imap_password: e.target.value,
                            });
                          }}
                        />
                      }
                    />

                    <Checkbox
                      className="mt-2 mb-3"
                      label="IMAP Disable Start TLS"
                      value={selectedMailbox.imap_disable_start_tls}
                      onChange={(checked) => {
                        updateMailboxConfiguration({
                          imap_disable_start_tls: checked,
                        });
                      }}
                    />

                    <Checkbox
                      label="Mark ingested messages as read"
                      value={selectedMailbox.imap_mark_as_read}
                      onChange={(checked) => {
                        updateMailboxConfiguration({
                          imap_mark_as_read: checked,
                        });
                      }}
                    />

                    <InputLabel
                      className="mt-4 max-w-sm"
                      label="Blacklists"
                      input={
                        <BlackListInput
                          value={selectedMailbox.blacklist || []}
                          onChange={(values) => {
                            updateMailboxConfiguration({
                              blacklist: values,
                            });
                          }}
                        />
                      }
                    />
                    <Info>
                      Incoming messages matching one of this regexes will be
                      discarded. See the format of messages candidates{" "}
                      <Link
                        onClick={() => {
                          const popupWindow = window.open(
                            "",
                            "Popup",
                            "width=300,height=200"
                          );
                          popupWindow!.document.write(
                            "<p style='font-family: monospace; font-size: 12px'>From:Albert &lt;albert@mi5.co.uk&gt;<br/>Subject:About the missing oranges.<br/>Dear James,<br/>I noticed that my oranges are not in the fridge anymore.<br/>Do you know where they could be?<br/>Your friend Albert.</p>"
                          );
                        }}
                      >
                        here
                      </Link>
                      .
                    </Info>

                    <div className="mt-4">
                      <Checkbox
                        label="Accept messages from non-customers"
                        value={selectedMailbox.allow_non_customer_emails}
                        onChange={(checked) => {
                          updateMailboxConfiguration({
                            allow_non_customer_emails: checked,
                          });
                        }}
                      />
                    </div>

                    <>
                      <br />
                      <InputLabel
                        className="mt-4 max-w-sm"
                        label="Bounce refused emails"
                        input={
                          <Input
                            placeholder="* or email@server.com"
                            value={selectedMailbox.bounce_message_to ?? ""}
                            onChange={(e) => {
                              updateMailboxConfiguration({
                                bounce_message_to: e.target.value,
                              });
                            }}
                          />
                        }
                      />
                      <Info>
                        Bounce the incoming email back to the sender when using
                        "*", or to a specific recipient to get an alert.
                      </Info>
                    </>
                  </>
                )}
              </>
            </div>
          )}
        </>
      )}
    </Page>
  );
}

import { Tag } from "@atoms/badge/tag";
import {
  categoryToColor,
  categoryToDescription,
  categoryToName,
} from "@features/alerts/utils";

export const AlertCategories = (props: {
  isCategories?: { [key: string]: boolean };
  categories?: string[];
}) => {
  const activeCategories = getActiveCategories(props).filter(
    (a) => categoryToName[a]
  );

  return (
    <div className="-mx-1 -my-1">
      {activeCategories.map((a, i) => (
        <span data-tooltip={categoryToDescription[a] || a} key={i} tabIndex={0}>
          <Tag
            noColor
            className={
              "mx-1 my-1 text-white bg-" + (categoryToColor[a] || "slate-500")
            }
          >
            {categoryToName[a] || a}
          </Tag>
        </span>
      ))}
    </div>
  );
};

export const getActiveCategories = (props: {
  isCategories?: { [key: string]: boolean };
  categories?: string[];
}) => {
  return (props.categories || Object.keys(props.isCategories || {}))
    .filter((a) => (props.isCategories as any)[a] === true)
    .map((a) => a.toLocaleLowerCase().replace(/^is_/g, ""));
};

import { Button } from "@atoms/button/button";
import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Input } from "@atoms/input/input-text";
import { Page } from "@atoms/layout/page";
import { PageResponsiveColumns } from "@atoms/layout/page-block";
import Select from "@atoms/select";
import { Info, Section, SectionSmall, Title } from "@atoms/text";
import { PageLoader } from "@components/page-loader";
import { usePressSettings } from "@features/press/state/use-press-configuration";
import { PressSettings } from "@features/press/types";
import {
  CATEGORY_SEVERITY,
  REPORT_OVERALL_RISK_LEVEL,
  splitAndRemoveBlankPressSettingsUrls,
  splitPressSettingsUrls,
  validateDesiredReportCategorySetting,
  validatePressSettings,
} from "@features/press/utils";
import { ROUTES } from "@features/routes";
import {
  CpuChipIcon,
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { toUpper } from "lodash";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const PressSettingsPage = () => {
  const { settings, save, loading, refresh } = usePressSettings();
  const [settingsInput, setSettingsInput] = useState<PressSettings | null>();
  const [showPatternTooltip1, setShowPatternTooltip1] =
    useState<boolean>(false);
  const [showPatternTooltip2, setShowPatternTooltip2] =
    useState<boolean>(false);
  const [showPatternTooltip3, setShowPatternTooltip3] =
    useState<boolean>(false);

  useEffect(() => {
    if (settings) {
      setSettingsInput(settings);
    }
  }, [settings]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  if (loading || !settingsInput) return <PageLoader />;

  return (
    <>
      <Page>
        <Title>Press Settings</Title>

        <Section>Press report selector</Section>

        <Info>
          You can define which customer will get a report, by default no
          customer get a report.
        </Info>
        <div className="mt-2" />

        <Link to={ROUTES.PressDecisionTree}>
          <Button theme="primary" size="sm">
            <CpuChipIcon className="w-4 h-4 mr-2" />
            Open filter selector
          </Button>
        </Link>

        <div className="my-6 border-t" />

        <Button
          onClick={async () => {
            save.mutate(settingsInput);
          }}
          size="sm"
          disabled={!settingsInput || !validatePressSettings(settingsInput)}
          theme="primary"
          className="float-right"
          loading={save.isLoading}
        >
          Save settings
        </Button>
        <Section>Reports settings</Section>
        <Info>
          Settings used when searching web results about the customer and
          establishing the final bad press assessment.
        </Info>
        <div className="mt-4" />

        <PageResponsiveColumns>
          <div className="grow max-w-xl">
            <SectionSmall className="my-2">
              Risk categories desired in press reports
            </SectionSmall>
            <div className="mt-2 mb-1">
              <label className="block text-sm font-medium text-gray-700 dark:text-slate-400 whitespace-nowrap overflow-hidden text-ellipsis">
                Category label & Maximum applicable severity
              </label>
            </div>
            <div className="space-y-2">
              {settingsInput.desired_categories.map((drc, index) => {
                return (
                  <>
                    <div className="flex flex-row items-center">
                      {!validateDesiredReportCategorySetting(drc) && (
                        <ExclamationCircleIcon className="w-6 text-red-500 rounded-full m-auto mr-1" />
                      )}
                      <Input
                        placeholder=""
                        className="grow w-2/3 rounded-r-none"
                        value={drc.label}
                        onChange={(e) => {
                          setSettingsInput({
                            ...settingsInput,
                            desired_categories: [
                              ...settingsInput.desired_categories.slice(
                                0,
                                index
                              ),
                              {
                                ...drc,
                                label: e.target.value || "",
                              },
                              ...settingsInput.desired_categories.slice(
                                index + 1
                              ),
                            ],
                          });
                        }}
                      />
                      <Select
                        size="md"
                        className="shrink-0 w-max rounded-l-none -ml-px"
                        value={drc.maximum_severity}
                        onChange={(e) => {
                          setSettingsInput({
                            ...settingsInput,
                            desired_categories: [
                              ...settingsInput.desired_categories.slice(
                                0,
                                index
                              ),
                              {
                                ...drc,
                                maximum_severity: e.target
                                  .value as CATEGORY_SEVERITY,
                              },
                              ...settingsInput.desired_categories.slice(
                                index + 1
                              ),
                            ],
                          });
                        }}
                      >
                        {Object.values(CATEGORY_SEVERITY).map((cs, i) => (
                          <option key={"severity-option-" + i} value={cs}>
                            {toUpper(cs)}
                          </option>
                        ))}
                      </Select>
                      <XMarkIcon
                        onClick={() => {
                          setSettingsInput({
                            ...settingsInput,
                            desired_categories: [
                              ...settingsInput.desired_categories.slice(
                                0,
                                index
                              ),
                              ...settingsInput.desired_categories.slice(
                                index + 1
                              ),
                            ],
                          });
                        }}
                        className="w-4 cursor-pointer hover:opacity-50 bg-red-500 p-0.5 text-white rounded-full m-auto ml-1"
                      />
                    </div>
                  </>
                );
              })}
            </div>
            <Button
              onClick={() => {
                setSettingsInput({
                  ...settingsInput,
                  desired_categories: [
                    ...settingsInput.desired_categories,
                    { label: "", maximum_severity: CATEGORY_SEVERITY.HIGH },
                  ],
                });
              }}
              theme="outlined"
              className="mt-4"
            >
              Add Category
            </Button>
          </div>
          <div className="grow max-w-40" />
          <div className="grow max-w-xl">
            <SectionSmall className="my-2">
              Websites and domains filtering
            </SectionSmall>
            <InputLabel
              className="mt-2 max-w-xl"
              label={
                <>
                  <div className="flex flex-row justify-between">
                    <label className="flex">
                      Blacklisted websites and domains
                      <QuestionMarkCircleIcon
                        className="w-5 cursor-pointer text-slate-500 rounded-full mx-1"
                        onClick={() => {
                          showPatternTooltip1
                            ? setShowPatternTooltip1(false)
                            : setShowPatternTooltip1(true);
                        }}
                      />
                    </label>
                    <label>
                      Patterns detected:{" "}
                      {settingsInput.website_blacklisted.length}
                    </label>
                  </div>
                  <Info
                    className={
                      "block whitespace-normal text-wrap" +
                      (!showPatternTooltip1 ? " hidden" : "")
                    }
                  >
                    Follow these pattern examples to expand your list: <br />
                    - Exclude a single page: http://www.example.com/page.html or
                    *www.example.com/page.html <br />- Whole site:
                    http://www.mysite.com/* or *www.mysite.com/* <br />
                    - Parts of a site: *www.example.com/docs/* <br />
                    - Whole domain: *.example.com* <br />
                    Separate your patterns with a comma (',') or any whitespace
                    character
                  </Info>
                </>
              }
              input={
                <Input
                  style={{ minHeight: 200 }}
                  multiline
                  placeholder=""
                  value={settingsInput.website_blacklisted.join("\n")}
                  onChange={(e) => {
                    setSettingsInput({
                      ...settingsInput,
                      website_blacklisted: splitPressSettingsUrls(
                        e.target.value
                      ),
                    });
                  }}
                  onBlur={(e) => {
                    setSettingsInput({
                      ...settingsInput,
                      website_blacklisted: splitAndRemoveBlankPressSettingsUrls(
                        e.target.value
                      ),
                    });
                  }}
                />
              }
            />
            <InputLabel
              className="mt-2 max-w-xl"
              label={
                <>
                  <div className="flex flex-row justify-between">
                    <label className="flex">
                      Whitelisted websites and domains
                      <QuestionMarkCircleIcon
                        className="w-5 cursor-pointer text-slate-500 rounded-full mx-1"
                        onClick={() => {
                          showPatternTooltip2
                            ? setShowPatternTooltip2(false)
                            : setShowPatternTooltip2(true);
                        }}
                      />
                    </label>
                    <label>
                      Patterns detected:{" "}
                      {settingsInput.website_whitelisted.length}
                    </label>
                  </div>
                  <Info
                    className={
                      "block whitespace-normal text-wrap" +
                      (!showPatternTooltip2 ? " hidden" : "")
                    }
                  >
                    Follow these pattern examples to expand your list: <br />
                    - Exclude a single page: http://www.example.com/page.html or
                    *www.example.com/page.html <br />- Whole site:
                    http://www.mysite.com/* or *www.mysite.com/* <br />
                    - Parts of a site: *www.example.com/docs/* <br />
                    - Whole domain: *.example.com* <br />
                    Separate your patterns with a comma (',') or any whitespace
                    character
                  </Info>
                </>
              }
              input={
                <Input
                  style={{ minHeight: 200 }}
                  multiline
                  placeholder=""
                  value={settingsInput.website_whitelisted.join("\n")}
                  onChange={(e) => {
                    setSettingsInput({
                      ...settingsInput,
                      website_whitelisted: splitPressSettingsUrls(
                        e.target.value
                      ),
                    });
                  }}
                  onBlur={(e) => {
                    setSettingsInput({
                      ...settingsInput,
                      website_whitelisted: splitAndRemoveBlankPressSettingsUrls(
                        e.target.value
                      ),
                    });
                  }}
                />
              }
            />
            <InputLabel
              className="mt-2 max-w-xl"
              label={"Minimum age of domains (in months)"}
              input={
                <Input
                  placeholder="0"
                  value={settingsInput.website_minimum_month_old}
                  onChange={(e) => {
                    setSettingsInput({
                      ...settingsInput,
                      website_minimum_month_old: parseInt(e.target.value) || 0,
                    });
                  }}
                />
              }
            />
            <InputLabel
              className="mt-2 max-w-xl"
              label={
                <>
                  <div className="flex flex-row justify-between">
                    <label className="flex">
                      Sensible robot risk levels
                      <QuestionMarkCircleIcon
                        className="w-5 cursor-pointer text-slate-500 rounded-full mx-1"
                        onClick={() => {
                          showPatternTooltip3
                            ? setShowPatternTooltip3(false)
                            : setShowPatternTooltip3(true);
                        }}
                      />
                    </label>
                  </div>
                  <Info
                    className={
                      "block whitespace-normal text-wrap" +
                      (!showPatternTooltip3 ? " hidden" : "")
                    }
                  >
                    Determines the robot risk levels which lead to an "Action
                    required" EDD state
                    <br />
                    <br />
                    Details : When evaluating the "robot-assigned" overall risk
                    level of the press report, the algorithm also determines its
                    EDD state. When the algorithm assigns a "sensible" risk
                    level to the report OR when the previous revision's robot
                    risk level was a "sensible" one then an "Action required"
                    EDD state is attributed.
                  </Info>
                </>
              }
              input={
                <SelectMultiple
                  size="md"
                  className="shrink-0 w-max rounded-l-none -ml-px"
                  value={
                    settingsInput.sensible_report_overall_risk_levels || []
                  }
                  options={Object.values(REPORT_OVERALL_RISK_LEVEL).map(
                    (rorl) => ({
                      value: rorl,
                      label: toUpper(rorl),
                    })
                  )}
                  onChange={(e) => {
                    setSettingsInput({
                      ...settingsInput,
                      sensible_report_overall_risk_levels: e,
                    });
                  }}
                />
              }
            />
          </div>
        </PageResponsiveColumns>
      </Page>
    </>
  );
};

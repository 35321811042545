/** Describes applciation routes **/

export const ROUTES = {
  Login: "/login",
  Signup: "/signup",
  Setup2FA: "/login/2fa-setup",

  Dashboard: "/dashboard",
  PrivateList: "/private-list",
  InternalPrivateListView: "/internal/view/:id",
  ReleaseNote: "/release-note",

  Alerts: "/scan/alerts",
  AlertView: "/scan/alerts/view/:id",
  PostFiltering: "/scan/post-filtering",
  PostFilteringView: "/scan/post-filtering/:id",
  NameSearch: "/scan/name-search",
  ScanDecisionTree: "/scan/decision-tree",

  Customers: "/risk/customers",
  CustomerView: "/risk/customers/view/:id",
  CustomerViewAlert: "/risk/customers/view/:id/alerts",
  CustomerViewRisk: "/risk/customers/view/:id/risk",
  CustomerViewIdentity: "/risk/customers/view/:id/identity",
  CustomerViewDocument: "/risk/customers/view/:id/documents",
  Matrix: "/risk/matrix",
  CustomerRiskFactors: "/risk/factors",
  CustomerRiskFactorsDetails: "/risk/factors/:id",

  Scenarios: "/vid/scenarios",
  ScenarioCreate: "/vid/scenarios/create",
  ScenarioStatus: "/vid/scenarios/status",
  ScenarioView: "/vid/scenarios/view/:id",
  ScenarioIntegrationSettings: "/vid/integration/settings",
  Sessions: "/vid/sessions",
  SessionView: "/vid/sessions/view/:id",

  InboxHome: "/chat/inbox",
  Inbox: "/chat/inbox/:id",
  InboxTemplates: "/chat/templates",

  LiveTransactions: "/kyt/live-transactions",
  TransactionView: "/kyt/transactions/view/:id",
  Explore: "/kyt/explore",
  ExploreTransaction: "/kyt/explore/:id",
  SavedSearches: "/kyt/saved-searches",
  KytRules: "/kyt/rules",
  KytRuleDetails: "/kyt/rules/:id",

  PressReportsSearch: "/press/reports",
  PressReportView: "/press/reports/:customer_id",
  PressInstantReport: "/press/instantreport",
  PressDecisionTree: "/press/decisiontree",
  PressSettings: "/press/settings",

  Developers: "/settings/developers",
  Agents: "/settings/agents",
  Reporting: "/settings/reporting",
  Billing: "/settings/billing",
  Administration: "/settings/administration",
  CustomFields: "/settings/custom-fields",
  DeprecatedImportSetup: "/settings/import-setup",
  DocumentTagsSetup: "/settings/documents-tags",
  InboxMessageTagsSetup: "/settings/messages-tags",
  InboxMessageSetting: "/settings/messages-settings",
  SessionStatusSetup: "/settings/sessions-status",
  Settings: "/settings",
  ClientSettings: "/settings/client",
  CustomerManager: "/settings/customers",
  CustomerListManager: "/settings/customers/list/:id",
  CustomerManagerView: "/settings/customers/view/:id",
  CustomerManagerEdit: "/settings/customers/edit/:id",
  CustomerManagerAdd: "/settings/customers/add",
  AdminSettings: "/settings/admin",
};

import { Button } from "@atoms/button/button";
import { RiskResourceType } from "@features/risk-decisions/types";
import { useRiskFactors } from "@features/risk-decisions/use-risks-decisions";
import { getRiskRouteDetails } from "@features/risk-decisions/utils";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { RiskEditorHasUnsavedChangesAtom } from ".";
import { RiskElementEditModalAtom } from "./modals/metadata";

export const RisksHeader = ({
  editable,
  resource,
}: {
  editable: boolean;
  resource: RiskResourceType;
}) => {
  const { riskFactors } = useRiskFactors(resource);
  const { id } = useParams();
  const hasUnsavedChanges = useRecoilValue(
    RiskEditorHasUnsavedChangesAtom(resource)
  );
  const navigate = useNavigate();
  const openEdition = useSetRecoilState(RiskElementEditModalAtom(resource));
  useEffect(() => {
    if (riskFactors.length > 0 && !id) {
      navigate(
        getRiskRouteDetails(resource).replace(
          /:id/,
          riskFactors[0].id.toString() || ""
        )
      );
    }
  }, [riskFactors, id, navigate, resource]);

  if (riskFactors.length === 0) {
    return <div className="py-3"></div>;
  }

  return (
    <>
      {editable && (
        <>
          <Button
            className="rounded-r-none"
            size="sm"
            disabled={hasUnsavedChanges}
            theme="primary"
            onClick={() => openEdition({ open: true, id })}
          >
            Edit metadata
          </Button>
          <Button
            size="sm"
            className="-ml-px rounded-l-none"
            disabled={hasUnsavedChanges}
            theme="outlined"
            onClick={() => openEdition({ open: true, id, duplicate: true })}
          >
            Duplicate
          </Button>
        </>
      )}
    </>
  );
};

import { PageBlock, PageBlockHr } from "@atoms/layout/page-block";
import { BaseSmall, Info, SectionSmall } from "@atoms/text";
import {
  SessionAlertType,
  SessionControlResult,
} from "@features/sessions/types";
import { AlertTag } from "./alert-tag";
import { Control } from "./control";
import LocationMap from "./location-map";

export default function SessionLocationReview(props: {
  alert: SessionAlertType;
  anonymized: boolean;
  ip: string;
}) {
  return (
    <PageBlock>
      <div id="location" className="flex flex-row justify-between">
        <SectionSmall>Location</SectionSmall>
        <AlertTag score={props.alert.score} />
      </div>
      <Info>IP location verification</Info>
      <PageBlockHr />
      <SectionSmall>Controls</SectionSmall>
      {props.alert.location_controls && (
        <div className="flex flex-row space-x-2">
          <Control
            className="w-full"
            result={
              props.alert.location_controls.check.country_match === "success"
                ? SessionControlResult.Success
                : props.alert.location_controls.check.country_match ===
                  "warning"
                ? SessionControlResult.Warning
                : SessionControlResult.Error
            }
            title={
              props.alert.location_controls.check.country_match === "success"
                ? "IP location matches customer defined country."
                : "IP location does not match customer defined country."
            }
          />
          <Control
            className="w-full"
            result={
              props.alert.location_controls.check.precise_geolocation ===
              "success"
                ? SessionControlResult.Success
                : props.alert.location_controls.check.precise_geolocation ===
                  "warning"
                ? SessionControlResult.Warning
                : SessionControlResult.Undefined
            }
            title={
              props.alert.location_controls.check.precise_geolocation ===
              "success"
                ? "Precise geolocation was enabled."
                : "Precise geolocation was refused."
            }
          />
          {props.alert.location_controls.check.precise_geolocation ===
            "success" && (
            <Control
              className="w-full"
              result={
                props.alert.location_controls.check.max_distance_exceeded ===
                "success"
                  ? SessionControlResult.Success
                  : props.alert.location_controls.check
                      .max_distance_exceeded === "warning"
                  ? SessionControlResult.Warning
                  : SessionControlResult.Error
              }
              title={
                props.alert.location_controls.check.max_distance_exceeded ===
                "success"
                  ? "Geolocation and IP location match."
                  : "Geolocation and IP location doesn't match."
              }
            />
          )}
        </div>
      )}
      <div className="my-2 grid grid-cols-3 grid-rows-4 gap-2">
        <SectionSmall>Comparison</SectionSmall>
        <Info className="mt-1">Customer</Info>
        <Info className="mt-1">IP Location</Info>
        <Info>Country</Info>
        <BaseSmall>
          {props.alert.location_controls?.info?.customer.country || "-"}
        </BaseSmall>
        <BaseSmall>
          {props.alert.location_controls?.info?.system.location.country_code ||
            "-"}{" "}
          /
          {props.alert.location_controls?.info?.system.location.country_name ||
            "-"}
        </BaseSmall>
        <Info>City</Info>
        <BaseSmall>
          {props.alert.location_controls?.info?.customer.city || "-"}
        </BaseSmall>
        <BaseSmall>
          {props.alert.location_controls?.info?.system.location.city || "-"} /
          {props.alert.location_controls?.info?.system.location.region_name ||
            "-"}
        </BaseSmall>
        <Info>Zip</Info>
        <BaseSmall>
          {props.alert.location_controls?.info?.customer.zip || "-"}
        </BaseSmall>
        <BaseSmall>
          {props.alert.location_controls?.info?.system.location.zip || "-"}
        </BaseSmall>
      </div>
      <LocationMap
        ip={props.ip}
        location={{
          latitude:
            props.alert.location_controls?.info?.system.location.latitude,
          longitude:
            props.alert.location_controls?.info?.system.location.longitude,
        }}
        geo={{
          latitude:
            props.alert.location_controls?.info?.system.geocode.latitude,
          longitude:
            props.alert.location_controls?.info?.system.geocode.longitude,
        }}
      ></LocationMap>
      <Info>
        Blue marker is GPS Customer location, red marker is IP location.
        Customer IP was
        <b>{" " + props.ip ?? "unknown"}</b>
      </Info>
      {props.alert.location_controls?.check.precise_geolocation ===
        "success" && (
        <Info>
          GPS Distance from closest IP location is
          <b>
            {" " +
              props.alert.location_controls?.info?.system.location
                .geocode_distance_km +
              " km" ?? "unknown"}
          </b>
        </Info>
      )}
    </PageBlock>
  );
}

import { is2XX, useFetch } from "@features/utils";
import {
  SessionsRequestOptions,
  SessionListType,
  SessionStatusType,
  SessionType,
  CreateSessionInput,
  SessionSettings,
  SessionDecisionType,
} from "../types";
import Env from "@config/environment";

export class SessionsApiClient {
  static getSessions = async (options: Partial<SessionsRequestOptions>) => {
    const response = await useFetch(`/web/sessions`, {
      method: "POST",
      body: JSON.stringify({
        external_id: null,
        search: null,
        language: null,
        date_from: null,
        date_to: null,
        status_id: null,
        include_unfinished: false,
        origin: ["vid", "pix"],
        score_min: null,
        score_max: null,
        scenario_ref: null,
        only_last: false,
        on_page: 1,
        per_page: 10,
        order_by: "date",
        order_way: "desc",
        ...options,
      }),
    });
    const data = await response.json();
    return data as { data: SessionListType[]; total: number };
  };

  static getSession = async (id: string) => {
    const response = await useFetch(`/web/session/${id}`, {
      method: "GET",
    });
    const data = await response.json();
    return data as SessionType;
  };

  static getSessionDecisions = async (id: string) => {
    const response = await useFetch(`/web/session/${id}/decision`, {
      method: "GET",
    });
    const data = await response.json();
    return data as {
      total_number_of_results: number;
      decisions: SessionDecisionType[]
    };
  }

  static createSessionDecision = async (session_id: string, status: number, comment: string) => {
    const response = await useFetch(`/web/session/${session_id}/decision`, {
      method: "POST",
      formData: true,
      body: JSON.stringify({
        session_id: session_id,
        status_id: status,
        comment: comment,
        last: true,
      }),
    });
    if (!is2XX(response.status)) {
      throw new Error("Failed to create session decision");
    }
  }

  static getCustomerSessions = async (external_id: string) => {
    const response = await useFetch(`/web/session/other-sessions`, {
      method: "POST",
      body: JSON.stringify({
        external_id: external_id,
      })
    });
    const data = await response.json();
    return data as { data: SessionListType[]; total: number };
  };

  static getSessionsStates = async () => {
    const response = await useFetch(`/web/session/status`, {
      method: "GET",
    });
    const data = await response.json();
    return data as { status: SessionStatusType[] };
  };

  static createSessionAndGetHostURL = async (input: CreateSessionInput) => {
    const response = await useFetch(`/web/session/hosturl`, {
      method: "POST",
      body: JSON.stringify({
        ...input,
      }),
    });
    if (!(response.status)) {
      throw new Error("Failed to create session");
    }
    const responseBody = await response.json();
    if ("session_host_url" in responseBody) {
      const sessionUrl: string = responseBody["session_host_url"];
      return Env.vid.concat(sessionUrl);
    }
  };

  static getSessionsSettings = async () => {
    const response = await useFetch(`/web/session/settings`, {
      method: "GET",
    });
    const data = await response.json();
    return data as SessionSettings;
  }

  static updateSessionSettings = async (settings: SessionSettings) => {
    const response = await useFetch(`/web/session/settings`, {
      method: "POST",
      body: JSON.stringify(settings),
    });
    if (!is2XX(response.status)) {
      throw new Error("Unable to update sessions settings");
    }
  }
}

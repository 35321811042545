export default function LocationMap(props: {
  ip: string;
  location: {
    latitude: number;
    longitude: number;
  };
  geo: {
    latitude: number;
    longitude: number;
  };
}) {
  return (
    <iframe
      className="w-full h-96"
      src={`/assets/map.html?iploc=[${props.location.latitude},${props.location.longitude}]&gps=[${props.geo.latitude},${props.geo.longitude}]&ip=${props.ip}`}
    ></iframe>
  );
}

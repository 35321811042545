import _ from "lodash";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { SessionsApiClient } from "../api-client/api-client";
import { CustomerReviewSessionsAtom } from "./store";

export const useCustomerReviewSession = (externalId: string) => {
  const [review, setReview] = useRecoilState(
    CustomerReviewSessionsAtom(externalId)
  );

  const refresh = useCallback(async () => {
    if (!externalId) return;
    setReview({ ...review, loading: true });
    const sessions = await SessionsApiClient.getSessions({
      external_id: externalId,
      per_page: 500,
      order_by: "date",
      order_way: "DESC",
    });
    const stats = _.groupBy(sessions.data, (session) => session.scenario_ref);
    const total = sessions.total;
    setReview({ per_state: stats, total, loading: false });
  }, [externalId, review, setReview]);

  return { review, refresh };
};

import { useFetch } from "@features/utils";
import { InboxTemplateRequestType, InboxTemplateType } from "../types";

export class InboxTemplatesApiClient {
  static getInboxTemplates = async () => {
    const response = await useFetch(`/web/messages/templates`, {
      method: "GET",
    });
    const data = await response.json();
    try {
      return (data || []).map((a: InboxTemplateType) => {
        try {
          return {
            ...a,
            subject: JSON.parse(a.subject as unknown as string),
            content: JSON.parse(a.content as unknown as string),
          };
        } catch (e) {
          return {
            ...a,
            subject: {},
            content: {},
          };
        }
      }) as InboxTemplateType[];
    } catch (e) {
      return [];
    }
  };

  // create
  static createInboxTemplate = async (request: InboxTemplateRequestType) => {
    const response = await useFetch(`/web/messages/template`, {
      method: "POST",
      body: JSON.stringify({
        ...request,
        subject: JSON.stringify(request.subject ?? {}),
        content: JSON.stringify(request.content ?? {}),
      }),
    });
    return response;
  };

  // update
  static updateInboxTemplate = async (
    id: string,
    request: InboxTemplateRequestType
  ) => {
    const response = await useFetch(`/web/messages/template/${id}`, {
      method: "POST",
      body: JSON.stringify({
        ...request,
        subject: JSON.stringify(request.subject ?? {}),
        content: JSON.stringify(request.content ?? {}),
      }),
    });
    return response;
  };

  // delete
  static deleteInboxTemplate = async (id: string) => {
    try {
      await useFetch(`/web/messages/template/${id}`, {
        method: "DELETE",
      });
    } catch (error) {
      throw new Error("Error deleting inbox template");
    }
  };
}

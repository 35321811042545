import { useGlobalEffect } from "@features/utils";
import { LoadingAtom } from "@features/utils/loading";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import { CustomFieldsApiClient } from "../api-client/api-client";
import { CustomFieldType } from "../types";
import { CustomFieldsAtom } from "./store";

export const useCustomFields = () => {
  const [list, setList] = useRecoilState(CustomFieldsAtom);
  const [loading, setLoading] = useRecoilState(LoadingAtom("useCustomFields"));

  const refresh = useCallback(async () => {
    setLoading(true);
    setList(await CustomFieldsApiClient.get());
    setLoading(false);
  }, [setList, setLoading]);

  const save = useCallback(
    async (data: CustomFieldType[]) => {
      setLoading(true);
      try {
        await CustomFieldsApiClient.save(data);
        toast.success("Saved custom fields");
      } catch (e) {
        console.error(e);
        toast.error("Failed to save custom fields");
        setLoading(false);
        return false;
      }
      refresh();
      return true;
    },
    [refresh, setLoading]
  );

  useGlobalEffect(
    "useCustomFields",
    () => {
      refresh();
    },
    []
  );

  return {
    fields: list,
    save,
    loading,
    refresh,
  };
};

/** Describes application permissions **/
export const AppPermissions = {
  Root_ACO_Node: "root_aco_node",
  Resource_Alert_Node: "resource_alert_node",
  Resource_Alert_Read: "resource_alert_read",
  Action_CaseManagement: "action_casemanagement",
  Resource_Risk_Node: "resource_risk_node",
  Resource_Risk_Read: "resource_risk_read",
  Resource_Customers_Node: "resource_customers_node",
  Resource_Customers_Read: "resource_customers_read",
  Resource_Customers_Update: "resource_customers_update",
  Resource_Session_Node: "resource_session_node",
  Resource_Session_Read: "resource_session_read",
  Resource_Session_Create: "resource_session_create",
  Resource_Scenario_Node: "resource_scenario_node",
  Resource_Scenario_Read: "resource_scenario_read",
  Resource_Scenario_Create: "resource_scenario_create",
  Resource_Scenario_Update: "resource_scenario_update",
  Resource_Scenario_Delete: "resource_scenario_delete",
  Resource_Settings_Users_Node: "resource_settings_users_node",
  Resource_Settings_Users_Read: "resource_settings_users_read",
  Resource_Settings_Users_Write: "resource_settings_users_write",
  Resource_ImportSetup_Node: "resource_importsetup_node",
  Resource_ImportSetup_Create: "resource_importsetup_create",
  Resource_ImportSetup_Read: "resource_importsetup_read",
  Resource_PostfilteringSettings_Node: "resource_postfilteringsettings_node",
  Resource_PostfilteringSettings_Read: "resource_postfilteringsettings_read",
  Resource_PostfilteringSettings_Update:
    "resource_postfilteringsettings_update",
  Resource_PrefilteringSettings_Node: "resource_prefilteringsettings_node",
  Resource_PrefilteringSettings_Read: "resource_prefilteringsettings_read",
  Resource_PrefilteringSettings_Update: "resource_prefilteringsettings_update",
  Resource_Matrix_Node: "resource_matrix_node",
  Resource_Matrix_Create: "resource_matrix_create",
  Resource_Matrix_Read: "resource_matrix_read",
  Resource_Matrix_Update: "resource_matrix_update",
  Resource_Matrix_Delete: "resource_matrix_delete",
  Resource_SessionStatus_Node: "resource_sessionstatus_node",
  Resource_SessionStatus_Create: "resource_sessionstatus_create",
  Resource_SessionStatus_Read: "resource_sessionstatus_read",
  Resource_SessionStatus_Delete: "resource_sessionstatus_delete",
  Action_SessionStatusManagement: "action_sessionstatusmanagement",
  Resource_Privatelist_Node: "resource_privatelist_node",
  Resource_Privatelist_Create: "resource_privatelist_create",
  Resource_Privatelist_Read: "resource_privatelist_read",
  Resource_Privatelist_Delete: "resource_privatelist_delete",
  Resource_Privatelist_PostfilteringSettings_Node:
    "resource_privatelist_postfilteringsettings_node",
  Resource_Privatelist_PostfilteringSettings_Read:
    "resource_privatelist_postfilteringsettings_read",
  Resource_Privatelist_PostfilteringSettings_Update:
    "resource_privatelist_postfilteringsettings_update",
  Resource_Reporting_Alerts_Node: "resource_reporting_alerts_node",
  Resource_Reporting_Alerts_Read: "resource_reporting_alerts_read",
  Resource_Reporting_Alerts_Create: "resource_reporting_alerts_create",
  Resource_Reporting_Risk_Node: "resource_reporting_risk_node",
  Resource_Reporting_Risk_Read: "resource_reporting_risk_read",
  Resource_Reporting_Risk_Create: "resource_reporting_risk_create",
  Resource_Reporting_Vid_Node: "resource_reporting_vid_node",
  Resource_Reporting_Vid_Read: "resource_reporting_vid_read",
  Resource_Reporting_Vid_Create: "resource_reporting_vid_create",
  Resource_Settings_AdminPanel_Read: "resource_settings_adminpanel_read",
  Resource_Settings_AdminPanel_Write: "resource_settings_adminpanel_write",
  Resource_Settings_Scan_Write: "resource_settings_scan_write",
  Resource_Settings_Scan_Read: "resource_settings_scan_read",
  Resource_Settings_Risk_Read: "resource_settings_risk_read",
  Resource_Settings_Risk_Write: "resource_settings_risk_write",
  Resource_Settings_Sessions_Write: "resource_settings_sessions_write",
  Resource_Settings_Sessions_Read: "resource_settings_sessions_read",
  Resource_API_Chat_Read: "resource_api_chat_read",
  Resource_API_Chat_Write: "resource_api_chat_write",
  Resource_Chat_Read: "resource_chat_read",
  Resource_Chat_Write: "resource_chat_write",
  Resource_Chat_Manage: "resource_chat_manage",
  Resource_API_KYT_Read: "resource_api_kyt_read",
  Resource_API_KYT_Write: "resource_api_kyt_write",
  Resource_KYT_Read: "resource_kyt_read",
  Resource_KYT_Write: "resource_kyt_write",
  Resource_KYT_Manage: "resource_kyt_manage",
  Resource_API_Press_Read: "resource_api_press_read",
  Resource_API_Press_Write: "resource_api_press_write",
  Resource_Press_Read: "resource_press_read",
  Resource_Press_Write: "resource_press_write",
  Resource_Press_Manage: "resource_press_manage",
};

/** Defines the application feature access **/
export const AppFeatureAccess = {
  //
  // SIDENAV
  //
  SIDENAV_ALERT: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_Alert_Node,
    AppPermissions.Resource_Alert_Read,
  ],
  SIDENAV_ALERT_REPORTING: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_Reporting_Alerts_Node,
    AppPermissions.Resource_Reporting_Alerts_Read,
  ],
  SIDENAV_PRE_FILTERING: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_PrefilteringSettings_Node,
    AppPermissions.Resource_PrefilteringSettings_Read,
  ],
  SIDENAV_POST_FILTERING: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_PostfilteringSettings_Node,
    AppPermissions.Resource_PostfilteringSettings_Read,
  ],
  SIDENAV_CUSTOMER: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_Customers_Node,
    AppPermissions.Resource_Risk_Read,
  ],
  SIDENAV_RISK_REPORTING: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_Reporting_Risk_Node,
    AppPermissions.Resource_Reporting_Risk_Read,
  ],
  SIDENAV_MATRIX: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_Matrix_Node,
    AppPermissions.Resource_Matrix_Read,
  ],
  SIDENAV_SESSION: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_Session_Node,
    AppPermissions.Resource_Session_Read,
  ],

  CHAT: [AppPermissions.Resource_Chat_Read],
  CHAT_AGENT: [AppPermissions.Resource_Chat_Write],
  CHAT_MANAGE: [AppPermissions.Resource_Chat_Manage],

  KYT: [AppPermissions.Resource_KYT_Read],
  KYT_AGENT: [AppPermissions.Resource_KYT_Write],
  KYT_MANAGE: [AppPermissions.Resource_KYT_Manage],

  PRESS: [AppPermissions.Resource_Press_Read],
  PRESS_AGENT: [AppPermissions.Resource_Press_Write],
  PRESS_MANAGE: [AppPermissions.Resource_Press_Manage],

  SIDENAV_SESSION_REPORTING: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_Reporting_Vid_Node,
    AppPermissions.Resource_Reporting_Vid_Read,
  ],
  SIDENAV_SCENARIO: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_Scenario_Node,
    AppPermissions.Resource_Scenario_Read,
  ],
  SIDENAV_IMPORT_SETUP: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_ImportSetup_Node,
    AppPermissions.Resource_ImportSetup_Read,
  ],
  SIDENAV_IMPORT_SETUP_SAVE: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_ImportSetup_Node,
    AppPermissions.Resource_ImportSetup_Create,
  ],
  SIDENAV_CLIENT_SETTINGS: [AppPermissions.Root_ACO_Node],
  SIDENAV_CUSTOM_CUSTOMERS: [AppPermissions.Root_ACO_Node],
  SIDENAV_ADMIN_SETTINGS: [AppPermissions.Root_ACO_Node],
  SIDENAV_AGENTS: [AppPermissions.Resource_Settings_Users_Read],
  SIDENAV_PRIVATE_LIST: [AppPermissions.Resource_Privatelist_Read],

  AGENT_VIEW: [AppPermissions.Resource_Settings_Users_Read],
  AGENT_EDIT: [AppPermissions.Resource_Settings_Users_Write],

  //
  // DASHBOARD
  //
  DASHBOARD_ONBARDING: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_Session_Node,
    AppPermissions.Resource_Session_Read,
  ],
  DASHBOARD_RISK_SCORING: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_Customers_Node,
    AppPermissions.Resource_Customers_Read,
  ],
  DASHBOARD_SCANNING: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_Alert_Node,
    AppPermissions.Resource_Alert_Read,
  ],

  //
  // ALERT CASEMANAGEMENT
  //
  ALERT_CASEMANAGEMENT: [AppPermissions.Action_CaseManagement],

  //
  // SESSION STATUS
  //
  SESSION_STATUS_CREATE: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_SessionStatus_Node,
    AppPermissions.Resource_SessionStatus_Create,
  ],
  SESSION_STATUS_UPDATE: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_SessionStatus_Node,
    AppPermissions.Resource_SessionStatus_Create,
  ],
  SESSION_STATUS_DELETE: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_SessionStatus_Node,
    AppPermissions.Resource_SessionStatus_Delete,
  ],

  //
  // SESSION
  //
  SESSION_ADD_DECISION: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Action_SessionStatusManagement,
  ],

  //
  // SCENARIO
  //
  SCENARIO_CREATE: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_Scenario_Node,
    AppPermissions.Resource_Scenario_Create,
  ],
  SCENARIO_UPDATE: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_Scenario_Node,
    AppPermissions.Resource_Scenario_Update,
  ],
  SCENARIO_DELETE: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_Scenario_Node,
    AppPermissions.Resource_Scenario_Delete,
  ],

  //
  // MATRIX
  //
  MATRIX_CREATE: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_Matrix_Node,
    AppPermissions.Resource_Matrix_Create,
  ],
  MATRIX_UPDATE: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_Matrix_Node,
    AppPermissions.Resource_Matrix_Update,
  ],
  MATRIX_DELETE: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_Matrix_Node,
    AppPermissions.Resource_Matrix_Delete,
  ],

  //
  // CUSTOMER
  //
  CUSTOMER_READ: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_Customers_Node,
    AppPermissions.Resource_Customers_Read,
  ],
  CUSTOMER_UPDATE: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_Customers_Node,
    AppPermissions.Resource_Customers_Update,
  ],

  //
  // IMPORT_SETUP
  //
  IMPORT_SETUP_READ: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_ImportSetup_Node,
    AppPermissions.Resource_ImportSetup_Read,
  ],
  IMPORT_SETUP_CREATE: [
    AppPermissions.Root_ACO_Node,
    AppPermissions.Resource_ImportSetup_Node,
    AppPermissions.Resource_ImportSetup_Create,
  ],

  //
  // SETTINGS
  //
  ADMIN_SETTINGS: [
    AppPermissions.Resource_Settings_AdminPanel_Write,
    AppPermissions.Resource_Settings_AdminPanel_Read,
  ],
  COMMON_SETTINGS: [
    AppPermissions.Resource_Settings_Scan_Write,
    AppPermissions.Resource_Settings_Scan_Read,
    AppPermissions.Resource_Settings_Risk_Write,
    AppPermissions.Resource_Settings_Risk_Read,
    AppPermissions.Resource_Settings_Sessions_Write,
    AppPermissions.Resource_Settings_Sessions_Read,
  ],
  SCAN_SETTINGS: [
    AppPermissions.Resource_Settings_Scan_Write,
    AppPermissions.Resource_Settings_Scan_Read,
  ],
  RISK_SETTINGS: [
    AppPermissions.Resource_Settings_Risk_Write,
    AppPermissions.Resource_Settings_Risk_Read,
  ],
  RISK_SETTINGS_WRITE: [AppPermissions.Resource_Settings_Risk_Write],
  VID_SETTINGS: [
    AppPermissions.Resource_Settings_Sessions_Write,
    AppPermissions.Resource_Settings_Sessions_Read,
  ],

  //
  // POST FILTERING
  //
  POST_FILTERING_READ: [AppPermissions.Resource_PostfilteringSettings_Read],

  POST_FILTERING_UPDATE: [AppPermissions.Resource_PostfilteringSettings_Update],

  //
  // PRE FILTERING
  //
  PRE_FILTERING_READ: [AppPermissions.Resource_PrefilteringSettings_Read],

  PRE_FILTERING_UPDATE: [AppPermissions.Resource_PrefilteringSettings_Update],
};

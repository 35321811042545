import { Tag } from "@atoms/badge/tag";
import { PageBlock, PageBlockHr } from "@atoms/layout/page-block";
import Link from "@atoms/link";
import { BaseSmall, Info, InfoSmall, SectionSmall } from "@atoms/text";
import { AlertCategories } from "@components/alert-categories";
import { Table } from "@components/table";
import { WatchlistSources } from "@components/watchlist-sources";
import {
  DowJonesAssociate,
  DowJonesRecord,
  DowJonesRecordCategories,
} from "@features/alerts-namesearch/types";
import { AlertRevision } from "@features/alerts/types";
import {
  extractCategoriesFromRecord,
  extractDate,
  extractDates,
  getLastToDate,
} from "@features/alerts/utils";
import { useBilling } from "@features/billing/use-billing";
import {
  ChatBubbleBottomCenterTextIcon,
  ArrowTopRightOnSquareIcon,
  NewspaperIcon,
} from "@heroicons/react/24/outline";
import _ from "lodash";
import { useState } from "react";
import Linkify from "react-linkify";
import { useSetRecoilState } from "recoil";
import { ProfileModalAtom } from "./profile-modal";
import { ModalImage } from "@components/modal-image";

export const ListProfileDetails = ({
  associates,
  record,
  categories,
  alert,
  printable,
}: {
  categories?: Partial<DowJonesRecordCategories>;
  record: DowJonesRecord;
  associates: DowJonesAssociate[];
  alert?: AlertRevision;
  printable?: boolean;
}) => {
  const isEntity = record.type === "Entity";
  const [associatesPage, setAssociatesPage] = useState(0);
  const [associatesPerPage, setAssociatesPerPage] = useState(10);
  const setProfileModal = useSetRecoilState(ProfileModalAtom);

  const { plan } = useBilling();

  return (
    <>
      <PageBlock className="mt-2 w-full">
        <SectionSmall className="mt-1">General</SectionSmall>
        <div className="flex flex-row">
          <div className="w-1/4">
            <Info>Record type</Info>
            <br />
            {record.type}
          </div>
          {!isEntity && (
            <>
              <div className="w-1/4">
                <Info>Gender</Info>
                <br />
                {record.gender}
              </div>
              <div className="w-1/4">
                <Info>Deceased</Info>
                <br />
                {record.deceased ? "Yes" : "No"}
              </div>
            </>
          )}
          <div className="w-1/4">
            <Info>Status</Info>
            <br />
            {record.status}
            {record.status === "Inactive" && (
              <Info>
                {" "}
                since{" "}
                {extractDate(getLastToDate(record.sanctions || []), "to", "") ||
                  extractDate(
                    getLastToDate(
                      (record.roles || []).reduce(
                        (acc, cur) => [...acc, ...cur.occupation] as any,
                        []
                      )
                    ),
                    "to",
                    ""
                  ) ||
                  "Unknown"}
              </Info>
            )}
          </div>
        </div>

        <div className="-mx-1 mt-2 whitespace-nowrap overflow-auto">
          {(record.images || []).map((img, index) => {
            return (
              <div className="inline-block m-1" key={index}>
                <img
                  alt={img}
                  src={img}
                  onError={(e) => {
                    if (e.currentTarget.parentNode) {
                      (e.currentTarget.parentElement as any).style.margin = "0";
                      (e.currentTarget.parentNode as any).innerHTML = "";
                    }
                  }}
                  className="hidden"
                />
                <ModalImage
                  className="rounded h-32 w-full inline-block bg-slate-200 dark:bg-slate-600"
                  small={img}
                  large={img}
                  alt={""}
                />
              </div>
            );
          })}
        </div>

        <PageBlockHr />

        <SectionSmall>Names</SectionSmall>
        <div className="">
          {record.names.map((name, index) => (
            <div key={index} className="mb-2">
              <BaseSmall>{name.name_type}</BaseSmall>
              {index === 0 && (
                <div className="pl-4 flex flex-row">
                  {!isEntity && (
                    <>
                      <div className="w-1/3">
                        <Info>First names</Info>
                      </div>
                      <div className="w-1/3">
                        <Info>Last names</Info>
                      </div>
                      <div className="grow">
                        <Info>Middle names</Info>
                      </div>
                    </>
                  )}
                  {isEntity && (
                    <>
                      <div className="grow">
                        <Info>Name</Info>
                      </div>
                    </>
                  )}
                </div>
              )}
              {name.name_values.map((values, index2) => (
                <div className="pl-4 flex flex-row rounded" key={index2}>
                  {!isEntity && (
                    <>
                      <div className="w-1/3">
                        {(values.first_name || []).join(", ")}
                      </div>
                      <div className="w-1/3">
                        {[
                          ...(values.surname || []),
                          ...(values.maiden_name || []),
                        ].join(", ")}
                      </div>
                      <div className="grow">
                        {(values.middle_name || []).join(", ")}
                      </div>
                    </>
                  )}
                  {isEntity && (
                    <div className="grow">
                      {(values.entity_name || []).join(", ")}
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
        <PageBlockHr />

        <SectionSmall>Locations and nationality</SectionSmall>
        <div className="">
          {_.chunk(record.countries, 3).map((countries, index) => (
            <div key={index}>
              <div className="flex flex-row">
                {countries.map((country, index2) => (
                  <div className="w-1/3" key={index2}>
                    <Info>{country.country_type}</Info>
                    <br />
                    {country.names.join(", ")}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        {record.dates?.find((d) => d.date_type === "Date of Birth") && (
          <>
            <PageBlockHr />
            <SectionSmall>Date of birth</SectionSmall>
            <div className="flex flex-row">
              <div className="w-1/3">
                <Info>Date of Birth</Info>
                <br />
                {extractDates(record, "Date of Birth")}
              </div>
              {record.birth_place && (
                <div className="w-1/3">
                  <Info>Place of Birth</Info>
                  <br />
                  {record.birth_place.join(", ")}
                </div>
              )}
            </div>
          </>
        )}

        {record.company_details?.length && (
          <>
            <PageBlockHr />
            <SectionSmall>Entity information</SectionSmall>
            {record.company_details.map((company, index) => (
              <div key={index}>
                <div className="flex flex-row">
                  {(company.address_city ||
                    company.address_line ||
                    company.address_country) && (
                    <div className="w-1/2">
                      <Info>Address</Info>
                      <br />
                      {[
                        company.address_line,
                        company.address_city,
                        company.address_country,
                      ]
                        .filter((a) => a.trim())
                        .join(", ")}
                    </div>
                  )}
                  {company.url && (
                    <div className="grow">
                      <Info>URL</Info>
                      <br />
                      <Link href={company.url} target={"_BLANK"}>
                        {company.url}
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </>
        )}

        <div className="mt-2" />
      </PageBlock>

      <PageBlock className="mt-2 w-full">
        <div className="mt-2" />
        <SectionSmall>Lists</SectionSmall>

        <div className="my-2 flex flex-row">
          <div className="w-1/4">
            <Info>Source</Info>
            <br />
            <WatchlistSources sources={record.list_types} />
          </div>

          <div className="w-3/4">
            <Info>Categories</Info>
            <br />
            <AlertCategories
              isCategories={extractCategoriesFromRecord(record, categories)}
            />
          </div>
        </div>

        {!!record.descriptions.descriptions1?.length && (
          <div className="flex flex-row w-full mt-4">
            <div className="grow">
              <Info>Category 1</Info>
              <br />
              {record.descriptions.descriptions1.map((a) => a.text).join(", ")}
            </div>
          </div>
        )}

        {!!record.descriptions.descriptions2?.length && (
          <div className="flex flex-row w-full mt-2">
            <div className="grow">
              <Info>Category 2</Info>
              <br />
              {record.descriptions.descriptions2.map((a) => a.text).join(", ")}
            </div>
          </div>
        )}

        {!!record.descriptions.descriptions3?.length && (
          <div className="flex flex-row w-full mt-2">
            <div className="grow">
              <Info>Category 3</Info>
              <br />
              {record.descriptions.descriptions3.map((a) => a.text).join(", ")}
            </div>
          </div>
        )}

        {!!record.sanctions?.length && (
          <>
            <PageBlockHr />
            <SectionSmall>Sanctions</SectionSmall>
            <div className="flex flex-row">
              <div className="w-1/6">
                <Info>From</Info>
              </div>
              <div className="w-1/6">
                <Info>To</Info>
              </div>
              <div className="grow">
                <Info>Name</Info>
              </div>
            </div>
            {_.sortBy(
              record.sanctions,
              (san) => -new Date(extractDate(san, "to")).getTime()
            ).map((sanction, index) => (
              <div className="flex flex-row" key={index}>
                <div className="w-1/6">
                  <BaseSmall>{extractDate(sanction, "since")}</BaseSmall>
                </div>
                <div className="w-1/6">
                  <BaseSmall>{extractDate(sanction, "to")}</BaseSmall>
                </div>
                <div className="grow">
                  <Tag noColor className="text-white bg-red-500">
                    {sanction.reference}
                  </Tag>
                </div>
              </div>
            ))}
          </>
        )}

        <div className="mt-2" />
      </PageBlock>

      {!!associates?.length && (
        <PageBlock className="mt-2 w-full">
          <div className="mt-2" />
          <SectionSmall>Relatives and close associates</SectionSmall>

          <Table
            onClick={(row) => {
              setProfileModal({ open: true, id: row.list_id });
            }}
            columns={[
              {
                title: "ID",
                thClassName: "w-20",
                render: (row) => <Info>{row.list_id}</Info>,
              },
              {
                title: "Who",
                render: (row) => row.association_type_label,
              },
              {
                title: "Name",
                render: (row) =>
                  row.profile_display_name || <Info>Not available</Info>,
              },
            ]}
            data={
              printable
                ? associates
                : associates.slice(
                    associatesPage * associatesPerPage,
                    associatesPage * associatesPerPage + associatesPerPage
                  )
            }
            onRequestData={async ({ page, perPage }) => {
              setAssociatesPage(page - 1);
              setAssociatesPerPage(perPage);
            }}
            initialPagination={{
              page: 1,
              perPage: 10,
            }}
            total={associates.length}
            showPagination={!printable && associates.length > 10}
          />

          <div className="mt-2" />
        </PageBlock>
      )}
      <PageBlock className="mt-2 w-full">
        <div className="mt-2" />

        {record.roles?.length && (
          <>
            {record.roles.map((role, index) => (
              <div key={index} className="mb-2">
                <SectionSmall>{role.role_type}</SectionSmall>

                {index === 0 && (
                  <div className="pl-4 flex flex-row">
                    <div className="w-1/6">
                      <Info>From</Info>
                    </div>
                    <div className="w-1/6">
                      <Info>To</Info>
                    </div>
                    <div className="grow">
                      <Info>Name</Info>
                    </div>
                  </div>
                )}

                {_.sortBy(
                  role.occupation,
                  (occupation) =>
                    -new Date(extractDate(occupation, "to")).getTime()
                ).map((occupation, index2) => (
                  <div
                    className="pl-4 flex flex-row mt-2 overflow-hidden"
                    key={index2}
                  >
                    <div className="w-1/6 shrink-0">
                      <BaseSmall>{extractDate(occupation, "since")}</BaseSmall>
                    </div>
                    <div className="w-1/6 shrink-0">
                      <BaseSmall>{extractDate(occupation, "to")}</BaseSmall>
                    </div>
                    <div className="grow">
                      <Tag>{occupation.category}</Tag>
                      <br />
                      <BaseSmall>{occupation.title}</BaseSmall>
                    </div>
                  </div>
                ))}
              </div>
            ))}
            <PageBlockHr />
          </>
        )}

        <SectionSmall>Profile notes</SectionSmall>
        <BaseSmall className="whitespace-pre-wrap">
          <Linkify
            componentDecorator={(href, text, key) => (
              <Link href={href} target={"_BLANK"} key={key}>
                {text}
              </Link>
            )}
          >
            {record.profile_notes || "No additional notes."}
          </Linkify>
        </BaseSmall>
        <PageBlockHr />

        {record.sources?.length && (
          <>
            <SectionSmall>Sources</SectionSmall>
            <div className="">
              {_.sortBy(record.sources || [], (a) => a.includes("factiva")).map(
                (source: string, index: number) => {
                  const link = source.match(/https?:\/\/[^\s]+/g);
                  const disableLink =
                    source.includes("factiva") && !plan.scan_factiva_enabled;
                  return (
                    <div key={index} className="h-6 w-full relative">
                      {link?.[0] && !disableLink && (
                        <InfoSmall
                          noColor
                          className="absolute w-full h-full left-0 top-0 whitespace-nowrap overflow-hidden overflow-ellipsis"
                        >
                          <ArrowTopRightOnSquareIcon className="h-3 w-3 inline-block text-blue-600" />{" "}
                          <Link href={link?.[0]} target="_BLANK">
                            {source}
                          </Link>
                        </InfoSmall>
                      )}
                      {(!link?.[0] || disableLink) && (
                        <InfoSmall
                          noColor
                          className="absolute w-full h-full left-0 top-0 whitespace-nowrap overflow-hidden overflow-ellipsis"
                        >
                          {source.includes("factiva") && (
                            <NewspaperIcon className="h-3 w-3 inline-block" />
                          )}
                          {!source.includes("factiva") && (
                            <ChatBubbleBottomCenterTextIcon className="h-3 w-3 inline-block" />
                          )}{" "}
                          {source
                            .replace(link?.[0] || "", "")
                            .replace(/, on Factiva.com \(/gi, "")
                            .trim() || source}
                        </InfoSmall>
                      )}
                    </div>
                  );
                }
              )}
            </div>
            <PageBlockHr />
          </>
        )}

        <div className="flex flex-row">
          {alert?.customer_external_id && (
            <div className="w-1/4">
              <Info>Customer ID</Info>
              <br />
              {alert?.customer_external_id}
            </div>
          )}
          <div className="w-1/4">
            <Info>Record ID</Info>
            <br />
            {record.list_id}
          </div>
          <div className="w-1/4">
            <Info>Last update</Info>
            <br />
            {record.date}
          </div>
        </div>

        <div className="mt-2" />
      </PageBlock>
    </>
  );
};

import { useAuth } from "@features/auth/state/hooks";
import { useCustomersCache } from "@features/customers/state/use-customers-cache";
import { useTemplateContext } from "@features/inbox-templates/state/use-template-context";
import { useThreadMessages } from "@features/inbox/state/use-thread-messages";
import {
  InboxMessageCreateInput,
  ThreadRelatedCustomer,
} from "@features/inbox/types";
import { useControlledEffect } from "@features/utils";
import _ from "lodash";
import { useEffect, useState } from "react";
import { MessageAttachments } from "./message-attachments";
import { MessageForm } from "./message-form";
import MessageReminder from "./message-reminder";
import {
  MessageTemplates,
  TemplateWithPreviousType,
  applyTemplateAndSignature,
} from "./message-templates";
import { WarningReusingMessage } from "./warning-reusing-message";
import { useInboxMailboxes } from "@features/inbox-settings/state/use-inbox-mailboxes";
import { getBestLocaleFromList } from "@features/utils/strings";
import { inboxAvailableTemplateLanguages } from "@features/inbox-templates/enum";

export const MessageFormExtended = ({
  initialMessage,
  customers,
  threadId,
  onChange,
}: {
  initialMessage: Partial<InboxMessageCreateInput>;
  customers: string[];
  threadId?: string;
  onChange: (message: InboxMessageCreateInput) => void;
}) => {
  const [message, setMessage] =
    useState<Partial<InboxMessageCreateInput>>(initialMessage);
  const { customers: cachedCustomers } = useCustomersCache(customers);
  const { messages } = useThreadMessages(threadId || "");

  const { user } = useAuth();
  const [selectedTemplate, setSelectedTemplate] =
    useState<TemplateWithPreviousType>({});
  const [selectedSignature, setSelectedSignature] =
    useState<TemplateWithPreviousType>({});
  const [templateCustomers, setTemplateCustomers] = useState<
    ThreadRelatedCustomer[]
  >([]);
  const [selectedTemplateLanguage, setSelectedTemplateLanguage] = useState<
    string | undefined
  >(undefined);
  const { myMailboxes, loading: mbLoading } = useInboxMailboxes();
  const [loading, setLoading] = useState(false);

  useControlledEffect(() => {
    // Detect best language
    const languages = _.uniq(
      cachedCustomers
        .filter((a) => a.nationality_code || a.domicile_code)
        .map((a) =>
          getBestLocaleFromList(
            a.nationality_code || a.domicile_code,
            Object.keys(inboxAvailableTemplateLanguages)
          )
        )
    );

    setSelectedTemplateLanguage(languages[0] || "en");

    setTemplateCustomers(
      cachedCustomers.map((c) => ({
        ...c,
        id: c?.customer_id,
      }))
    );
  }, [cachedCustomers?.map((c) => c?.customer_id).join(",")]);

  useEffect(() => {
    onChange(message as InboxMessageCreateInput);
  }, [message, onChange]);

  const context = useTemplateContext({
    customers: templateCustomers,
    agent: user || undefined,
    lastMessage: _.find(messages?.data, (m) => m.delivery === 4),
  });

  useControlledEffect(() => {
    (async () => {
      setLoading(true);
      const { subject, content } = await applyTemplateAndSignature(
        message?.subject ?? "",
        message?.content ?? "",
        {
          template: selectedTemplate,
          signature: selectedSignature,
          context: context,
          language: selectedTemplateLanguage,
        }
      );
      setMessage({
        ...message,
        subject,
        content,
      });
      setLoading(false);
    })();
  }, [
    selectedTemplate,
    selectedSignature,
    !!templateCustomers?.length,
    selectedTemplateLanguage,
  ]);

  useEffect(() => {
    if (
      !message.mailbox_id ||
      !myMailboxes.find((m) => m.id === message.mailbox_id)
    ) {
      setMessage({ ...message, mailbox_id: myMailboxes?.[0]?.id });
    }
  }, [mbLoading, myMailboxes.length]);

  return (
    <>
      <div id="message-thread" className="flex flex-col space-y-3 mb-4">
        <div className="relative">
          <MessageForm
            customers={customers}
            value={message}
            onChange={(baseMessage, to) => {
              setMessage({ ...message, ...baseMessage });
              if (to[0]) setTemplateCustomers(to);
            }}
            disabled={loading}
          />

          <MessageTemplates
            autoSelectSignature={!initialMessage?.content}
            setSelectedSignature={(c) =>
              setSelectedSignature({
                current: c,
                previous: selectedSignature?.current,
              })
            }
            setSelectedTemplate={(c) =>
              setSelectedTemplate({
                current: c,
                previous: selectedTemplate?.current,
              })
            }
            setSelectedTemplateLanguage={setSelectedTemplateLanguage}
            language={selectedTemplateLanguage}
            disabled={loading}
          />
        </div>

        <MessageAttachments
          message={message}
          onChange={(msg) => {
            setMessage({ ...message, ...msg });
          }}
        />

        <MessageReminder
          message={message}
          onChange={(msg) => {
            setMessage({ ...message, ...msg });
          }}
        />

        {threadId && (
          <WarningReusingMessage message={message} threadId={threadId} />
        )}
      </div>
    </>
  );
};

import { Tag } from "@atoms/badge/tag";
import { Info } from "@atoms/text";
import { useBilling } from "@features/billing/use-billing";
import {
  BuildingLibraryIcon,
  NewspaperIcon,
  Bars4Icon,
} from "@heroicons/react/20/solid";

const sourceToDescription = {
  soc: "State-owned company or related individual",
  ame: "Adverse Media Entities",
  watchlist: "Default watchlist",
};

const sourceToName = {
  soc: "SO",
  ame: "AME",
  watchlist: "WL",
};

const sourceToColor = {
  soc: "purple-500",
  ame: "orange-500",
  watchlist: "slate-500",
};

export const WatchlistSources = (props: {
  sources: ("watchlist" | "soc" | "ame")[];
}) => {
  const { plan } = useBilling();

  const sources = (props.sources || []).filter((a) =>
    plan.scan_available_lists.includes(a)
  );

  if (
    plan.scan_available_lists.length === 1 &&
    plan.scan_available_lists[0] === "watchlist"
  )
    return (
      <span data-tooltip={sourceToDescription["watchlist"]} tabIndex={0}>
        <Info>Watchlist</Info>
      </span>
    );

  return (
    <div className="-mx-1 -my-1">
      {sources.map((a, i) => (
        <span data-tooltip={sourceToDescription[a] || a} key={i} tabIndex={0}>
          <Tag
            noColor
            className={
              "mx-1 my-1 inline-flex flex-row items-center text-white bg-" +
              (sourceToColor[a] || "slate-500")
            }
          >
            {a === "watchlist" && <Bars4Icon className="h-3 w-3 inline mr-1" />}
            {a === "ame" && <NewspaperIcon className="h-3 w-3 inline mr-1" />}
            {a === "soc" && (
              <BuildingLibraryIcon className="h-3 w-3 inline mr-1" />
            )}
            {sourceToName[a] || a}
          </Tag>
        </span>
      ))}
    </div>
  );
};

import { Shortcut, useShortcuts } from "@features/shortcuts";
import _ from "lodash";
import { AnchorHTMLAttributes, DetailedHTMLProps } from "react";
import { Link as L, LinkProps, useNavigate } from "react-router-dom";

export interface CustomLinkProps {
  href?: string;
  to?: string | false;
  onClick?: () => void;
  children: React.ReactNode;
  noColor?: boolean;
  shortcut?: Shortcut[];
  className?: string;
}

export default function Link(
  props: (
    | DetailedHTMLProps<
        AnchorHTMLAttributes<HTMLAnchorElement>,
        HTMLAnchorElement
      >
    | React.ForwardRefExoticComponent<
        LinkProps & React.RefAttributes<HTMLAnchorElement>
      >
  ) &
    CustomLinkProps
) {
  const navigate = useNavigate();

  useShortcuts(
    props.shortcut
      ? [
          ...props.shortcut,
          ...props.shortcut.map((s: string) => ("shift+" + s) as Shortcut),
        ]
      : [],
    (e) => {
      if (props.onClick) props.onClick();
      if (props.href || (props.to && e.shiftKey))
        window.open(
          props.href || props.to || undefined,
          e.shiftKey ? "_blank" : "_self"
        );
      if (props.to && !e.shiftKey) navigate(props.to);
    }
  );

  const colors = props.noColor
    ? ""
    : "hover:text-blue-700 active:text-blue-800 text-blue-600";

  if (
    (props.href || "")?.startsWith("http") ||
    (props.href || "")?.startsWith("mailto:")
  ) {
    return (
      <a
        href={props.href}
        className={colors + " " + (props.className || "")}
        {..._.omit(props, "children", "className", "noColor")}
      >
        {props.children}
      </a>
    );
  }

  if (props.onClick) {
    return (
      <span
        className={colors + " " + (props.className || "")}
        {..._.omit(props, "children", "className", "noColor", "to")}
      >
        {props.children}
      </span>
    );
  }

  return (
    <L
      to={(!props.onClick && (props.href || props.to)) || "#"}
      className={colors + " " + (props.className || "")}
      {..._.omit(props, "children", "className", "noColor", "to")}
    >
      {props.children}
    </L>
  );
}

import { atomFamily, selectorFamily } from "recoil";
import { AssignmentType } from "../types";

export const AssignmentAtom = atomFamily<AssignmentType | null, string>({
  key: "AssignmentAtom",
  default: () => null,
});

// abstraction
export const AssignmentsAtom = selectorFamily<AssignmentType[], string[]>({
  key: "AssignmentsAtom",
  get:
    (ids) =>
    ({ get }) => {
      return ids
        .map((id) => get(AssignmentAtom(id)))
        .filter((a) => a) as AssignmentType[];
    },
});

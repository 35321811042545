import { Input } from "@atoms/input/input-text";
import { useCustomFields } from "@features/custom-fields/state/use-custom-fields";
import {
  RiskNodeField,
  RiskResourceType,
  RiskTreeNode,
} from "@features/risk-decisions/types";

import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import Link from "@atoms/link";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Info, InfoSmall } from "@atoms/text";
import {
  COMPARAISON_OPERATORS,
  NODE_INVISIBLE_UNDEFINED,
} from "@features/risk-decisions/utils";
import { CalculatorIcon, PencilIcon } from "@heroicons/react/24/outline";
import { CSSProperties, useEffect, useState } from "react";
import { Handle, HandleProps, NodeProps, Position } from "reactflow";
import { NodeHeader } from "./components/_node_header";
import { NodeLayout } from "./components/_node_layout";
import FormulaEditor from "./components/formula_editor";

const handleStyle: CSSProperties = {
  height: 15,
  width: 15,
};

const fromProps: HandleProps = {
  type: "target",
  id: "from",
  position: Position.Left,
};
const noProps: HandleProps = {
  type: "source",
  id: "on_default_node_id",
  position: Position.Right,
};
const yesProps: HandleProps = {
  type: "source",
  id: "on_success_node_id",
  position: Position.Right,
};

const undefinedProps: HandleProps = {
  type: "source",
  id: "on_undefined_node_id",
  position: Position.Right,
};

const validateNode = (node: RiskTreeNode) => {
  if (!node.name) return "Name is missing";
  if (!node.formula?.formula?.expressions.length) return "Formula is missing";
  if (!node.formula?.formula?.aggregation_operator)
    return "Aggregation operator is missing";
  if (!node.formula?.comparison_operator)
    return "Comparison operator is missing";

  if (node.formula?.value === undefined) return "Value is missing";
  if (
    !Object.values(node.output_node_ids).some(
      (v) => v !== NODE_INVISIBLE_UNDEFINED.id
    )
  )
    return "At least one output is required";
  return "";
};

export default function FormulaNode(
  props: NodeProps<
    RiskTreeNode & {
      field: RiskNodeField;
      resource: RiskResourceType;
      withRelations: boolean;
      editable: boolean;
      onChange: (data: RiskTreeNode) => void;
    }
  >
) {
  const {
    fields,
    loading: loadingFields,
    refresh: refreshFields,
  } = useCustomFields();

  const [errorFormula, setErrorFormula] = useState<boolean>(false);
  const [expandFormula, setExpandFormula] = useState<boolean>(false);
  const [editorFormula, setEditorFormula] = useState<any>(null);

  useEffect(() => {
    if (fields.length === 0 && !loadingFields) {
      refreshFields();
    }
  }, [fields, loadingFields, refreshFields]);

  return (
    <>
      <Modal open={expandFormula} className="min-w-[50vw]" closable={false}>
        <ModalContent title="Formula Editor">
          <Info>
            Write a complex formula using all available fields and operators{" "}
            <Link target="_blank" href={""}>
              defined here
            </Link>
          </Info>
          <div className="flex flex-col space-y-4 mt-2 mb-4">
            {errorFormula && (
              <Tag noColor className="bg-red-600 text-white w-max inline-block">
                The current formula is invalid, changes can't be saved.
              </Tag>
            )}
            <div className="mt-2 border border-slate-200 rounded overflow-hidden py-2">
              <FormulaEditor
                type={props.data.resource}
                withRelations={props.data.withRelations}
                width="100%"
                height="300px"
                readonly={!props.data.editable}
                value={editorFormula || props.data.formula!.formula}
                onChange={(definition) => {
                  if (definition) {
                    setEditorFormula(definition);
                    setErrorFormula(false);
                  } else {
                    setErrorFormula(true);
                  }
                }}
              />
            </div>
          </div>

          <Button
            className="float-right"
            disabled={errorFormula}
            onClick={() => {
              props.data.onChange({
                ...props.data,
                formula: {
                  ...props.data.formula!,
                  formula: editorFormula?.formula,
                },
              });
              setExpandFormula(false);
              setEditorFormula(null);
            }}
          >
            Save
          </Button>
          <Button
            theme="outlined"
            onClick={() => {
              setExpandFormula(false);
              setEditorFormula(null);
            }}
          >
            Cancel
          </Button>
        </ModalContent>
      </Modal>
      <NodeLayout
        selected={props.selected}
        invalidMessage={validateNode(props.data)}
      >
        <NodeHeader
          disabled={!props.data.editable}
          icon={(p) => <CalculatorIcon {...p} />}
          type="Formula Component"
          value={props.data.name || ""}
          onChange={(value) =>
            props.data.onChange({
              ...props.data,
              name: value,
            })
          }
        />

        <div
          className="p-0 border-t flex flex-col cursor-pointer"
          onClick={() => {
            setExpandFormula(true);
          }}
        >
          <div className="m-2 flex flex-row justify-between">
            <InfoSmall className="">Formula Expression:</InfoSmall>
            <div className="flex flex-row">
              <PencilIcon className="h-4 w-4" />
              {errorFormula && (
                <InfoSmall>
                  <span className="text-red-600">Invalid formula</span>
                </InfoSmall>
              )}
            </div>
          </div>
          <FormulaEditor
            type={props.data.resource}
            withRelations={props.data.withRelations}
            readonly={true}
            height="80px"
            value={props.data.formula!.formula}
            onChange={(definition) => {
              if (definition) {
                props.data.onChange({
                  ...props.data,
                  formula: {
                    ...props.data.formula!,
                    ...definition,
                  },
                });
                setErrorFormula(false);
              } else {
                setErrorFormula(true);
              }
            }}
          />
        </div>

        <div className="flex flex-row nodrag text-center h-9 text-sm   border-y border-gray-200 dark:border-slate-400">
          {COMPARAISON_OPERATORS.map((operator) => {
            return (
              <button
                disabled={!props.data.editable}
                data-tooltip={operator.tooltip}
                key={"comparaison_formula_" + operator.value}
                onClick={() => {
                  props.data.onChange({
                    ...props.data,
                    formula: {
                      ...props.data.formula!,
                      comparison_operator: operator.value,
                    },
                  });
                }}
                className={`disabled:opacity-50 grow m-auto h-full border-r ${
                  operator.value === props.data.formula!.comparison_operator
                    ? "bg-blue-600 text-white font-bold"
                    : ""
                }`}
              >
                {operator.label}
              </button>
            );
          })}
        </div>
        <Input
          disabled={!props.data.editable}
          className="nowheel nodrag rounded-none text-sm border-gray-200 border-t-0 border-l-0 border-r-0"
          placeholder="Value"
          type="number"
          value={props.data.formula?.value}
          onChange={(e) => {
            props.data.onChange({
              ...props.data,
              formula: {
                ...props.data.formula!,
                value: isNaN(parseFloat(e.target.value))
                  ? undefined
                  : parseFloat(e.target.value),
              },
            });
          }}
        />

        <div className="h-2"></div>

        <div className="relative">
          <small className="mx-4">from</small>
          <Handle {...fromProps} style={{ ...handleStyle, left: -8 }} />
        </div>
        <div className="relative text-right">
          <small className="mx-4">no</small>
          <Handle
            onConnect={(params) => {
              console.log("handle onConnect", params);
              props.data.onChange({
                ...props.data,
                output_node_ids: {
                  ...props.data.output_node_ids,
                  [noProps.id!]: params.target!,
                },
              });
              return true;
            }}
            {...noProps}
            style={{ ...handleStyle, right: -8 }}
          />
        </div>
        <div className="relative text-right">
          <small className="mx-4">yes</small>
          <Handle
            onConnect={(params) => {
              props.data.onChange({
                ...props.data,
                output_node_ids: {
                  ...props.data.output_node_ids,
                  [yesProps.id!]: params.target!,
                },
              });
              return true;
            }}
            {...yesProps}
            style={{ ...handleStyle, right: -8 }}
          />
        </div>
        <div className="relative text-right">
          <small className="mx-4 opacity-30">undefined</small>
          <Handle
            onConnect={(params) => {
              props.data.onChange({
                ...props.data,
                output_node_ids: {
                  ...props.data.output_node_ids,
                  [undefinedProps.id!]: params.target!,
                },
              });
              return true;
            }}
            {...undefinedProps}
            style={{ ...handleStyle, right: -8 }}
          />
        </div>
        <div className="h-4"></div>

        <div className="h-4"></div>
      </NodeLayout>
    </>
  );
}

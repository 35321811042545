import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import { GetStartedCard } from "@atoms/card/get-started";
import InputCopiable from "@atoms/input/input-copiable";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Section } from "@atoms/text";
import { Role } from "@components/roles-manager";
import { Table } from "@components/table";
import { useAgents } from "@features/agents/state/use-agents";
import { AgentType } from "@features/agents/types";
import { useAuth } from "@features/auth/state/hooks";
import { useHasAccess } from "@features/auth/state/use-access";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { DeveloperEditionModalAtom } from "./edition-modal";

export default function DeveloperApiKeys() {
  const { developers: data, loading, refresh, resetPassword } = useAgents();
  const [secretModal, openSecretModal] = useState<{
    email: string;
    password: string;
  } | null>(null);
  const setEditionModalState = useSetRecoilState(DeveloperEditionModalAtom);
  const { clientId } = useAuth();

  const hasAccess = useHasAccess();

  return (
    <>
      <div className="flex flex-row items-center justify-between">
        <Section>API keys</Section>

        {hasAccess("AGENT_EDIT") && (
          <Button
            onClick={() => {
              setEditionModalState({ open: true, id: null });
            }}
            theme="primary"
          >
            New API key
          </Button>
        )}
      </div>
      <Modal open={!!secretModal} onClose={() => openSecretModal(null)}>
        <ModalContent title="New API secret">
          <InputLabel
            label="Identifier"
            input={<InputCopiable value={secretModal?.email || ""} />}
          />
          <InputLabel
            className="mt-2"
            label="Secret"
            input={<InputCopiable value={secretModal?.password || ""} />}
          />
        </ModalContent>
      </Modal>

      <div className="my-4">
        <GetStartedCard
          show={data.length === 0 && !loading}
          title={<>Create your first API key</>}
          text={
            <>
              You can interact with Algoreg from your existing workflow using
              our API.
            </>
          }
        />
      </div>
      <Table
        showPagination={false}
        data={data}
        columns={[
          {
            title: "Key name",
            thClassName: "w-40",
            render: (item) => (
              <div className="flex items-center">{item.name}</div>
            ),
          },
          {
            title: "Identifier",
            render: (item) => item.email_login,
          },
          ...(hasAccess("AGENT_EDIT")
            ? [
                {
                  title: "Secret",
                  thClassName: "w-64",
                  render: (item: AgentType) => (
                    <>
                      ••••••••
                      <ButtonConfirm
                        theme="outlined"
                        size="sm"
                        className="ml-2"
                        onClick={async () => {
                          const { password } = await resetPassword(item.id);
                          openSecretModal({
                            email: item.email_login,
                            password,
                          });
                        }}
                      >
                        Regenerate
                      </ButtonConfirm>
                    </>
                  ),
                },
              ]
            : []),
          {
            title: "Access",
            render: (item) => (
              <div>
                {item?.clients
                  ?.find((c) => c.id === clientId)
                  ?.roles.map((role) => (
                    <Role code={role.code} key={role.code} />
                  ))}
              </div>
            ),
          },
          ...(hasAccess("AGENT_EDIT")
            ? [
                {
                  title: "Actions",
                  headClassName: "justify-end",
                  className: "text-right",
                  thClassName: "w-24",
                  render: (item: AgentType) => (
                    <>
                      <Button
                        theme="outlined"
                        size="sm"
                        onClick={() => {
                          setEditionModalState({
                            open: true,
                            id:
                              item.clients.find((c) => c.id === clientId)
                                ?.agent_id || 0,
                          });
                        }}
                      >
                        Modify
                      </Button>
                    </>
                  ),
                },
              ]
            : []),
        ]}
        onRequestData={async () => {
          refresh();
        }}
      />
    </>
  );
}

import { useState } from "react";
import Link, { CustomLinkProps } from "./index";
import _ from "lodash";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Button } from "@atoms/button/button";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

interface ConfirmLinkProps extends CustomLinkProps {
  confirmTitle?: string;
  confirmMessage?: string;
  confirmIcon?: React.ReactNode;
  confirmButtonTheme?: "primary" | "secondary" | "danger" | "default";
  confirmButtonText?: string;
  cancelButtonText?: string;
}

export const LinkConfirm = (props: ConfirmLinkProps) => {
  const [inConfirm, setInConfirm] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <Link
        {..._.omit(
          props,
          "onClick",
          "confirmButtonTheme",
          "confirmButtonText",
          "cancelButtonText",
          "confirmTitle",
          "confirmMessage",
          "confirmIcon",
          "href" //Not passing the href to prevent default behaviour (open link)
        )}
        onClick={() => {
          setInConfirm(true);
        }}
      />
      <Modal
        open={inConfirm}
        onClose={() => {
          setInConfirm(false);
        }}
      >
        <ModalContent
          title={props.confirmTitle || "Confirm open this link ?"}
          text={
            props.confirmMessage ||
            "Confirm you want to follow this link by clicking Confirm."
          }
          icon={props.confirmIcon || ExclamationCircleIcon}
          buttons={
            <>
              <Button
                theme={props.confirmButtonTheme || "primary"}
                onClick={() => {
                  setInConfirm(false);
                  setTimeout(() => {
                    if (props.href) {
                      window.open(props.href);
                    } else if (props.onClick) {
                      props.onClick();
                    } else if (props.to) {
                      navigate(props.to);
                    }
                  }, 500);
                }}
                className="mr-4 my-2"
              >
                {props.confirmButtonText || "Confirm"}
              </Button>
              <Button
                onClick={() => {
                  setInConfirm(false);
                }}
                theme="default"
                className={"mr-4 my-2 shadow-none"}
              >
                {props.cancelButtonText || "Cancel"}
              </Button>
            </>
          }
        />
      </Modal>
    </>
  );
};

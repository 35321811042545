import { Tag } from "@atoms/badge/tag";
import { Checkbox } from "@atoms/input/input-checkbox";
import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Input } from "@atoms/input/input-text";
import { PageBlock, PageResponsiveColumns } from "@atoms/layout/page-block";
import Select from "@atoms/select";
import { Base, Info, InfoSmall, Menu } from "@atoms/text";
import { ScenarioType } from "@features/scenarios/types";
import {
  SCENARIOS_SCORING_FACE_CONTROLS,
  SCENARIOS_SCORING_ID_CONTROLS,
  SCENARIOS_SCORING_SEVERITY,
} from "@features/scenarios/utils";
import _ from "lodash";
import { Doughnut } from "react-chartjs-2";

export default function ScenarioScoringTab(props: {
  scenario: ScenarioType;
  onChange: (scenario: ScenarioType) => void;
}) {
  return (
    <PageResponsiveColumns>
      <div className="grow md:max-w-lg w-full flex-col space-y-8">
        <div className="flex flex-col space-y-2">
          <Menu className="mb-2">General Scoring</Menu>
          <div className="flex flex-row space-x-2">
            <InputLabel
              className="grow"
              label="Fail threshold"
              input={
                <Input
                  value={
                    props.scenario.scoring_preferences.threshold.fail * 100
                  }
                  min={0}
                  max={100}
                  type="number"
                  onChange={(e) => {
                    props.onChange({
                      ...props.scenario,
                      scoring_preferences: {
                        ...props.scenario.scoring_preferences,
                        threshold: {
                          ...props.scenario.scoring_preferences.threshold,
                          fail: parseInt(e.target.value) / 100,
                        },
                      },
                    });
                  }}
                  placeholder="Threshold 0-100"
                />
              }
            />
            <InputLabel
              className="grow"
              label="Success threshold"
              input={
                <Input
                  value={
                    props.scenario.scoring_preferences.threshold.success * 100
                  }
                  min={0}
                  max={100}
                  type="number"
                  onChange={(e) => {
                    props.onChange({
                      ...props.scenario,
                      scoring_preferences: {
                        ...props.scenario.scoring_preferences,
                        threshold: {
                          ...props.scenario.scoring_preferences.threshold,
                          success: parseInt(e.target.value) / 100,
                        },
                      },
                    });
                  }}
                  placeholder="Threshold 0-100"
                />
              }
            />
          </div>
          <PageBlock>
            {/* <Info>Scenario Scoring</Info> */}
            <Doughnut
              data={{
                labels: ["Fail", "Warning", "Success"],
                datasets: [
                  {
                    data: [
                      props.scenario.scoring_preferences.threshold.fail,
                      Math.max(
                        0,
                        props.scenario.scoring_preferences.threshold.success -
                          props.scenario.scoring_preferences.threshold.fail
                      ),
                      100 -
                        Math.max(
                          props.scenario.scoring_preferences.threshold.success,
                          props.scenario.scoring_preferences.threshold.fail
                        ),
                    ],
                    borderRadius: 20,
                    circumference: 180,
                    rotation: 270,
                    backgroundColor: ["#ef4444", "#eab308", "#22c55e"],
                  },
                ],
              }}
              options={{
                responsive: true,
                cutout: "80%",
                aspectRatio: 2,
                plugins: {
                  legend: {
                    position: "top",
                  },
                  title: {
                    display: false,
                  },
                },
              }}
            />
          </PageBlock>
        </div>
      </div>
      <div className="grow w-full md:pl-10">
        <div className="flex flex-col space-y-5">
          <Menu>Scoring Details</Menu>

          <PageBlock className="flex flex-col space-y-4">
            <div className="flex flex-row justify-between items-center">
              <Info className="font-bold">Face</Info>
              <InfoSmall> Face control scoring</InfoSmall>
            </div>
            <Base>
              Sets the face control scoring to zero if the following conditions
              are met:
            </Base>
            <div className="flex flex-row space-x-2">
              <Tag noColor className="bg-red-500 text-white">
                {" "}
              </Tag>
              <InputLabel
                className="grow"
                label="Controls in state Error"
                input={
                  <SelectMultiple
                    value={(
                      props.scenario.scoring_preferences.face
                        .set_to_zero_triggers.error ?? []
                    ).map((status) => status.toString())}
                    options={[
                      ..._.sortBy(
                        Object.keys(SCENARIOS_SCORING_FACE_CONTROLS).map(
                          (control) => ({
                            value: control,
                            label: SCENARIOS_SCORING_FACE_CONTROLS[control],
                          })
                        ),
                        "label"
                      ),
                    ]}
                    onChange={(e) => {
                      props.onChange({
                        ...props.scenario,
                        scoring_preferences: {
                          ...props.scenario.scoring_preferences,
                          face: {
                            ...props.scenario.scoring_preferences.face,
                            set_to_zero_triggers: {
                              ...props.scenario.scoring_preferences.face
                                .set_to_zero_triggers,
                              error: e,
                            },
                          },
                        },
                      });
                    }}
                  />
                }
              />
            </div>
            <div className="flex flex-row space-x-2">
              <Tag noColor className="bg-yellow-500 text-white">
                {" "}
              </Tag>
              <InputLabel
                className="grow"
                label="Controls in state Warning"
                input={
                  <SelectMultiple
                    value={(
                      props.scenario.scoring_preferences.face
                        .set_to_zero_triggers.warning ?? []
                    ).map((status) => status.toString())}
                    options={[
                      ..._.sortBy(
                        Object.keys(SCENARIOS_SCORING_FACE_CONTROLS).map(
                          (control) => ({
                            value: control,
                            label: SCENARIOS_SCORING_FACE_CONTROLS[control],
                          })
                        ),
                        "label"
                      ),
                    ]}
                    onChange={(e) => {
                      props.onChange({
                        ...props.scenario,
                        scoring_preferences: {
                          ...props.scenario.scoring_preferences,
                          face: {
                            ...props.scenario.scoring_preferences.face,
                            set_to_zero_triggers: {
                              ...props.scenario.scoring_preferences.face
                                .set_to_zero_triggers,
                              warning: e,
                            },
                          },
                        },
                      });
                    }}
                  />
                }
              />
            </div>
            <div className="flex flex-row space-x-2">
              <Tag noColor className="bg-slate-500 text-white">
                {" "}
              </Tag>
              <InputLabel
                className="grow"
                label="Controls in state Undefined"
                input={
                  <SelectMultiple
                    value={(
                      props.scenario.scoring_preferences.face
                        .set_to_zero_triggers.undefined ?? []
                    ).map((status) => status.toString())}
                    options={[
                      ..._.sortBy(
                        Object.keys(SCENARIOS_SCORING_FACE_CONTROLS).map(
                          (control) => ({
                            value: control,
                            label: SCENARIOS_SCORING_FACE_CONTROLS[control],
                          })
                        ),
                        "label"
                      ),
                    ]}
                    onChange={(e) => {
                      props.onChange({
                        ...props.scenario,
                        scoring_preferences: {
                          ...props.scenario.scoring_preferences,
                          face: {
                            ...props.scenario.scoring_preferences.face,
                            set_to_zero_triggers: {
                              ...props.scenario.scoring_preferences.face
                                .set_to_zero_triggers,
                              undefined: e,
                            },
                          },
                        },
                      });
                    }}
                  />
                }
              />
            </div>
          </PageBlock>

          <PageBlock className="flex flex-col space-y-4">
            <div className="flex flex-row justify-between items-center">
              <Info className="font-bold">Identity</Info>
              <InfoSmall>ID Controls scoring</InfoSmall>
            </div>

            <div className="flex flex-col space-y-2">
              <Base>Information matching coefficients for the identity</Base>
              <div className="flex flex-row space-x-2">
                <InputLabel
                  className="grow"
                  label="First name"
                  input={
                    <Input
                      value={
                        props.scenario.scoring_preferences.id
                          .first_name_coefficient
                      }
                      type="number"
                      onChange={(e) => {
                        props.onChange({
                          ...props.scenario,
                          scoring_preferences: {
                            ...props.scenario.scoring_preferences,
                            id: {
                              ...props.scenario.scoring_preferences.id,
                              first_name_coefficient: parseInt(e.target.value),
                            },
                          },
                        });
                      }}
                      placeholder="Coefficient"
                    />
                  }
                />
                <InputLabel
                  className="grow"
                  label="Last name"
                  input={
                    <Input
                      required
                      value={
                        props.scenario.scoring_preferences.id
                          .last_name_coefficient
                      }
                      type="number"
                      onChange={(e) => {
                        props.onChange({
                          ...props.scenario,
                          scoring_preferences: {
                            ...props.scenario.scoring_preferences,
                            id: {
                              ...props.scenario.scoring_preferences.id,
                              last_name_coefficient: parseInt(e.target.value),
                            },
                          },
                        });
                      }}
                      placeholder="Coefficient"
                    />
                  }
                />

                <InputLabel
                  className="grow"
                  label="Birth date"
                  input={
                    <Input
                      required
                      value={
                        props.scenario.scoring_preferences.id
                          .birth_date_coefficient
                      }
                      type="number"
                      onChange={(e) => {
                        props.onChange({
                          ...props.scenario,
                          scoring_preferences: {
                            ...props.scenario.scoring_preferences,
                            id: {
                              ...props.scenario.scoring_preferences.id,
                              birth_date_coefficient: parseInt(e.target.value),
                            },
                          },
                        });
                      }}
                      placeholder="Coefficient"
                    />
                  }
                />
              </div>
            </div>

            <div className="flex flex-col space-y-2">
              <Base>
                Sets the ID control scoring to zero if the following conditions
                are met:
              </Base>
              <div className="flex flex-row space-x-2">
                <Tag noColor className="bg-red-500 text-white">
                  {" "}
                </Tag>
                <InputLabel
                  className="grow"
                  label="Controls in state Error"
                  input={
                    <SelectMultiple
                      value={(
                        props.scenario.scoring_preferences.id
                          .set_to_zero_triggers.error ?? []
                      ).map((status) => status.toString())}
                      options={[
                        ..._.sortBy(
                          Object.keys(SCENARIOS_SCORING_ID_CONTROLS).map(
                            (control) => ({
                              value: control,
                              label: SCENARIOS_SCORING_ID_CONTROLS[control],
                            })
                          ),
                          "label"
                        ),
                      ]}
                      onChange={(e) => {
                        props.onChange({
                          ...props.scenario,
                          scoring_preferences: {
                            ...props.scenario.scoring_preferences,
                            id: {
                              ...props.scenario.scoring_preferences.id,
                              set_to_zero_triggers: {
                                ...props.scenario.scoring_preferences.id
                                  .set_to_zero_triggers,
                                error: e,
                              },
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </div>
              <div className="flex flex-row space-x-2">
                <Tag noColor className="bg-yellow-500 text-white">
                  {" "}
                </Tag>
                <InputLabel
                  className="grow"
                  label="Controls in state Warning"
                  input={
                    <SelectMultiple
                      value={(
                        props.scenario.scoring_preferences.id
                          .set_to_zero_triggers.warning ?? []
                      ).map((status) => status.toString())}
                      options={[
                        ..._.sortBy(
                          Object.keys(SCENARIOS_SCORING_ID_CONTROLS).map(
                            (control) => ({
                              value: control,
                              label: SCENARIOS_SCORING_ID_CONTROLS[control],
                            })
                          ),
                          "label"
                        ),
                      ]}
                      onChange={(e) => {
                        props.onChange({
                          ...props.scenario,
                          scoring_preferences: {
                            ...props.scenario.scoring_preferences,
                            id: {
                              ...props.scenario.scoring_preferences.id,
                              set_to_zero_triggers: {
                                ...props.scenario.scoring_preferences.id
                                  .set_to_zero_triggers,
                                warning: e,
                              },
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </div>
              <div className="flex flex-row space-x-2">
                <Tag noColor className="bg-slate-500 text-white">
                  {" "}
                </Tag>
                <InputLabel
                  className="grow"
                  label="Controls in state Undefined"
                  input={
                    <SelectMultiple
                      value={(
                        props.scenario.scoring_preferences.id
                          .set_to_zero_triggers.undefined ?? []
                      ).map((status) => status.toString())}
                      options={[
                        ..._.sortBy(
                          Object.keys(SCENARIOS_SCORING_ID_CONTROLS).map(
                            (control) => ({
                              value: control,
                              label: SCENARIOS_SCORING_ID_CONTROLS[control],
                            })
                          ),
                          "label"
                        ),
                      ]}
                      onChange={(e) => {
                        props.onChange({
                          ...props.scenario,
                          scoring_preferences: {
                            ...props.scenario.scoring_preferences,
                            id: {
                              ...props.scenario.scoring_preferences.id,
                              set_to_zero_triggers: {
                                ...props.scenario.scoring_preferences.id
                                  .set_to_zero_triggers,
                                undefined: e,
                              },
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </div>
            </div>
          </PageBlock>

          <PageBlock className="flex flex-col space-y-4">
            <div className="flex flex-row justify-between items-center mb-2">
              <Info className="font-bold">Watchlist</Info>
              <InfoSmall> Watchlist scoring</InfoSmall>
            </div>
            <Checkbox
              label="Service enabled"
              value={props.scenario.scoring_preferences.watchlist.enabled}
              onChange={(s) => {
                props.onChange({
                  ...props.scenario,
                  scoring_preferences: {
                    ...props.scenario.scoring_preferences,
                    watchlist: {
                      ...props.scenario.scoring_preferences.watchlist,
                      enabled: s,
                    },
                  },
                });
              }}
            />
            {props.scenario.scoring_preferences.watchlist.enabled && (
              <div className="flex flex-row space-x-2 px-4 py-2 border-blue-300 bg-blue-50 border rounded-md">
                <InputLabel
                  className="grow"
                  label="Precise match"
                  input={
                    <Select
                      onChange={(e) => {
                        props.onChange({
                          ...props.scenario,
                          scoring_preferences: {
                            ...props.scenario.scoring_preferences,
                            watchlist: {
                              ...props.scenario.scoring_preferences.watchlist,
                              precise_match: e.target.value,
                            },
                          },
                        });
                      }}
                      value={
                        props.scenario.scoring_preferences.watchlist
                          .precise_match
                      }
                      placeholder="Select Severity"
                    >
                      <option disabled value="">
                        Select Severity
                      </option>

                      {Object.keys(SCENARIOS_SCORING_SEVERITY).map(
                        (severity) => (
                          <option key={"severity" + severity} value={severity}>
                            {_.capitalize(SCENARIOS_SCORING_SEVERITY[severity])}
                          </option>
                        )
                      )}
                    </Select>
                  }
                />
                <InputLabel
                  className="grow"
                  label="Fuzzy match"
                  input={
                    <Select
                      onChange={(e) => {
                        props.onChange({
                          ...props.scenario,
                          scoring_preferences: {
                            ...props.scenario.scoring_preferences,
                            watchlist: {
                              ...props.scenario.scoring_preferences.watchlist,
                              fuzzy_match: e.target.value,
                            },
                          },
                        });
                      }}
                      value={
                        props.scenario.scoring_preferences.watchlist.fuzzy_match
                      }
                      placeholder="Select Severity"
                    >
                      <option disabled value="">
                        Select Severity
                      </option>

                      {Object.keys(SCENARIOS_SCORING_SEVERITY).map(
                        (severity) => (
                          <option key={"severity" + severity} value={severity}>
                            {_.capitalize(SCENARIOS_SCORING_SEVERITY[severity])}
                          </option>
                        )
                      )}
                    </Select>
                  }
                />
              </div>
            )}
          </PageBlock>

          <PageBlock className="flex flex-col space-y-4">
            <div className="flex flex-row justify-between items-center">
              <Info className="font-bold">Geolocalisation</Info>
              <InfoSmall> Geolocalisation scoring</InfoSmall>
            </div>
            <Checkbox
              label="Service enabled"
              value={props.scenario.scoring_preferences.geolocation.enabled}
              onChange={(s) => {
                props.onChange({
                  ...props.scenario,
                  scoring_preferences: {
                    ...props.scenario.scoring_preferences,
                    geolocation: {
                      ...props.scenario.scoring_preferences.geolocation,
                      enabled: s,
                    },
                  },
                });
              }}
            />
            {props.scenario.scoring_preferences.geolocation.enabled && (
              <div className="flex flex-col space-y-2 px-4 py-2 border-blue-300 bg-blue-50 border rounded-md">
                <InputLabel
                  className="grow"
                  label="Precise geolocation was disabled"
                  input={
                    <Select
                      onChange={(e) => {
                        props.onChange({
                          ...props.scenario,
                          scoring_preferences: {
                            ...props.scenario.scoring_preferences,
                            geolocation: {
                              ...props.scenario.scoring_preferences.geolocation,
                              precise_geolocation_undefined: e.target.value,
                            },
                          },
                        });
                      }}
                      value={
                        props.scenario.scoring_preferences.geolocation
                          .precise_geolocation_undefined
                      }
                      placeholder="Select Severity"
                    >
                      <option disabled value="">
                        Select Severity
                      </option>

                      {Object.keys(SCENARIOS_SCORING_SEVERITY).map(
                        (severity) => (
                          <option key={"severity" + severity} value={severity}>
                            {_.capitalize(SCENARIOS_SCORING_SEVERITY[severity])}
                          </option>
                        )
                      )}
                    </Select>
                  }
                />
                <InputLabel
                  className="grow"
                  label="Match IP country with customer country"
                  input={
                    <Select
                      onChange={(e) => {
                        props.onChange({
                          ...props.scenario,
                          scoring_preferences: {
                            ...props.scenario.scoring_preferences,
                            geolocation: {
                              ...props.scenario.scoring_preferences.geolocation,
                              country_different: e.target.value,
                            },
                          },
                        });
                      }}
                      value={
                        props.scenario.scoring_preferences.geolocation
                          .country_different
                      }
                      placeholder="Select Severity"
                    >
                      <option disabled value="">
                        Select Severity
                      </option>

                      {Object.keys(SCENARIOS_SCORING_SEVERITY).map(
                        (severity) => (
                          <option key={"severity" + severity} value={severity}>
                            {_.capitalize(SCENARIOS_SCORING_SEVERITY[severity])}
                          </option>
                        )
                      )}
                    </Select>
                  }
                />
                <div className="flex flex-row space-x-2">
                  <InputLabel
                    className="grow"
                    label="Geolocation IP distance exceeded"
                    input={
                      <Select
                        onChange={(e) => {
                          props.onChange({
                            ...props.scenario,
                            scoring_preferences: {
                              ...props.scenario.scoring_preferences,
                              geolocation: {
                                ...props.scenario.scoring_preferences
                                  .geolocation,
                                ip_to_geolocation_max_distance_exceeded:
                                  e.target.value,
                              },
                            },
                          });
                        }}
                        value={
                          props.scenario.scoring_preferences.geolocation
                            .ip_to_geolocation_max_distance_exceeded
                        }
                        placeholder="Select Severity"
                      >
                        <option disabled value="">
                          Select Severity
                        </option>

                        {Object.keys(SCENARIOS_SCORING_SEVERITY).map(
                          (severity) => (
                            <option
                              key={"severity" + severity}
                              value={severity}
                            >
                              {_.capitalize(
                                SCENARIOS_SCORING_SEVERITY[severity]
                              )}
                            </option>
                          )
                        )}
                      </Select>
                    }
                  />
                  <InputLabel
                    label="Max distance (km)"
                    input={
                      <Input
                        value={
                          props.scenario.scoring_preferences.geolocation
                            .ip_to_geolocation_max_distance
                        }
                        type="number"
                        onChange={(e) => {
                          props.onChange({
                            ...props.scenario,
                            scoring_preferences: {
                              ...props.scenario.scoring_preferences,
                              geolocation: {
                                ...props.scenario.scoring_preferences
                                  .geolocation,
                                ip_to_geolocation_max_distance: parseInt(
                                  e.target.value
                                ),
                              },
                            },
                          });
                        }}
                        placeholder="Max distance"
                      />
                    }
                  />
                </div>
              </div>
            )}
          </PageBlock>

          <PageBlock className="flex flex-col space-y-4">
            <div className="flex flex-row justify-between items-center mb-2">
              <Info className="font-bold">Bot Detection</Info>
              <InfoSmall>ReCaptcha Scoring</InfoSmall>
            </div>
            <Checkbox
              label="Service enabled"
              value={props.scenario.scoring_preferences.captcha.enabled}
              onChange={(s) => {
                props.onChange({
                  ...props.scenario,
                  scoring_preferences: {
                    ...props.scenario.scoring_preferences,
                    captcha: {
                      ...props.scenario.scoring_preferences.captcha,
                      enabled: s,
                    },
                  },
                });
              }}
            />
            {props.scenario.scoring_preferences.captcha.enabled && (
              <div className="flex flex-row space-x-2 px-4 py-2 border-blue-300 bg-blue-50 border rounded-md">
                <InputLabel
                  label="Threshold (%)"
                  input={
                    <Input
                      required
                      value={
                        props.scenario.scoring_preferences.captcha.threshold *
                        100
                      }
                      max={100}
                      min={0}
                      type="number"
                      onChange={(e) => {
                        props.onChange({
                          ...props.scenario,
                          scoring_preferences: {
                            ...props.scenario.scoring_preferences,
                            captcha: {
                              ...props.scenario.scoring_preferences.captcha,
                              threshold: parseInt(e.target.value) / 100,
                            },
                          },
                        });
                      }}
                      placeholder="Threshold"
                    />
                  }
                />
                <InputLabel
                  className="grow"
                  label="Precise match"
                  input={
                    <Select
                      onChange={(e) => {
                        props.onChange({
                          ...props.scenario,
                          scoring_preferences: {
                            ...props.scenario.scoring_preferences,
                            captcha: {
                              ...props.scenario.scoring_preferences.captcha,
                              behavior: e.target.value,
                            },
                          },
                        });
                      }}
                      value={
                        props.scenario.scoring_preferences.captcha.behavior
                      }
                      placeholder="Select Severity"
                    >
                      <option disabled value="">
                        Select Severity
                      </option>

                      {Object.keys(SCENARIOS_SCORING_SEVERITY).map(
                        (severity) => (
                          <option key={"severity" + severity} value={severity}>
                            {_.capitalize(SCENARIOS_SCORING_SEVERITY[severity])}
                          </option>
                        )
                      )}
                    </Select>
                  }
                />
              </div>
            )}
          </PageBlock>
        </div>
      </div>
    </PageResponsiveColumns>
  );
}

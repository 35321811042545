import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import { GetStartedCard } from "@atoms/card/get-started";
import { Page } from "@atoms/layout/page";
import { Title } from "@atoms/text";
import { Table } from "@components/table";
import { Column } from "@components/table/table";
import { useHasAccess } from "@features/auth/state/use-access";
import { useInboxTemplates } from "@features/inbox-templates/state/use-inbox-templates";
import { InboxTemplateType } from "@features/inbox-templates/types";
import { atom, useSetRecoilState } from "recoil";
import { InboxTemplateModal } from "./modal";

export const InboxTemplateModalAtom = atom<
  Partial<InboxTemplateType> & { open: boolean; type: "ADD" | "EDIT" }
>({
  key: "InboxTemplateModalAtom",
  default: { open: false, type: "ADD" },
});

export default function InboxTemplatesPage() {
  const { templates, loading, deleteInboxTemplate } = useInboxTemplates();
  const setModal = useSetRecoilState(InboxTemplateModalAtom);
  const hasAccess = useHasAccess();

  const columns: Column<InboxTemplateType>[] = [
    {
      title: "Label",
      render: (row) => <>{row.label}</>,
    },
    {
      title: "Languages",
      render: (row) => <>{Object.keys(row.content).join(", ")}</>,
    },
    {
      title: "Actions",
      headClassName: "justify-end",
      className: "justify-end h-12",
      thClassName: "w-24",
      render: (row) => (
        <div className="flex flex-row space-x-2">
          <Button
            theme="default"
            size="sm"
            onClick={() => setModal({ open: true, ...row, type: "EDIT" })}
          >
            Edit
          </Button>

          <ButtonConfirm
            confirmTitle="Delete Message Template"
            confirmMessage="This will be applied now and this template will not be usable anymore"
            theme="danger"
            size="sm"
            loading={loading}
            onClick={async () => {
              await deleteInboxTemplate(row.id);
            }}
          >
            Remove
          </ButtonConfirm>
        </div>
      ),
    },
  ];

  return (
    <Page>
      {hasAccess("CHAT_MANAGE") && ( // TODO: change this to maybe TEMPLATE_CREATE
        <Button
          className="float-right"
          size="sm"
          onClick={() =>
            setModal({
              open: true,
              type: "ADD",
            })
          }
        >
          Create template
        </Button>
      )}
      <Title>Inbox Templates</Title>

      {hasAccess("CHAT_MANAGE") && templates.length === 0 && !loading && (
        <GetStartedCard
          className="mt-4"
          text={
            <>
              You can use inbox template to store some pre-defined messages
              ready to be sent to your customers.
            </>
          }
          title="Inbox templates"
        />
      )}

      <br />

      <Table
        columns={columns}
        data={templates}
        loading={loading}
        showPagination={false}
      />

      <InboxTemplateModal />
    </Page>
  );
}

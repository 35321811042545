import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import InputDate from "@atoms/input/input-date";
import { InputDecorationIcon } from "@atoms/input/input-decoration-icon";
import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { BaseSmall } from "@atoms/text";
import { Table } from "@components/table";
import { useHasAccess } from "@features/auth/state/use-access";
import { CustomerAllDetailType } from "@features/customers/types";
import { useDocumentsLabels } from "@features/documents-labels/state/use-documents-labels";
import { useCustomerDocuments } from "@features/documents/state/user-customer-documents";
import { stringToColor } from "@features/utils";
import {
  ArchiveBoxIcon,
  ArrowTopRightOnSquareIcon,
  DocumentIcon,
  MagnifyingGlassIcon,
  PhotoIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import _, { uniqBy } from "lodash";
import { useRef, useState } from "react";

export const getFileType = (document: {
  file_type: string;
  file_source?: string;
}) => {
  let type = "other";
  if (document.file_source && document.file_source !== "internal") {
    type = "link";
  } else if (document.file_type === "image") {
    type = "image";
  } else if (document.file_type === "video") {
    type = "video";
  } else if (["zip", "rar", "tar", "gz"].includes(document.file_type)) {
    type = "archive";
  }

  const color = {
    link: "blue-500",
    image: "emerald-500",
    video: "purple-500",
    archive: "yellow-500",
    other: "slate-500",
  }[type];

  return {
    type,
    color,
    icon: ({ className }: { className?: string }) => (
      <>
        {type === "link" && <ArrowTopRightOnSquareIcon className={className} />}
        {type === "image" && <PhotoIcon className={className} />}
        {type === "video" && <VideoCameraIcon className={className} />}
        {type === "archive" && <ArchiveBoxIcon className={className} />}
        {type === "other" && <DocumentIcon className={className} />}
      </>
    ),
  };
};

export const DocumentManager = ({
  customer,
}: {
  customer: CustomerAllDetailType;
}) => {
  const { labels } = useDocumentsLabels();
  const {
    documents,
    total,
    update,
    loading,
    filters,
    setFilters,
    uploadFile,
    download,
    deleteDocument,
  } = useCustomerDocuments(customer.details.customer.external_id);
  const hasAccess = useHasAccess();

  const fileRef = useRef<HTMLInputElement>(null);
  const [fileUpdateModal, openFileUpdateModal] = useState<{
    id: string;
    name: string;
    tag_codes: string[];
    file?: File;
  } | null>(null);

  return (
    <div>
      <input
        type="file"
        onChange={async (e) => {
          const file = e.target.files?.[0];
          if (file) {
            openFileUpdateModal({
              id: "",
              name: file.name,
              tag_codes: [],
              file,
            });
          }
        }}
        className="hidden"
        ref={fileRef}
      />

      <Modal open={!!fileUpdateModal} onClose={() => openFileUpdateModal(null)}>
        <ModalContent title="Change document properties">
          <InputLabel
            label="Categories"
            className="w-full z-20"
            input={
              <SelectMultiple
                className="z-20"
                options={uniqBy(
                  (labels || []).map((a) => ({
                    label: a.name,
                    value: a.code,
                  })),
                  "value"
                )}
                value={fileUpdateModal?.tag_codes || []}
                onChange={(e) => {
                  openFileUpdateModal({
                    ...fileUpdateModal!,
                    tag_codes: _.uniq(e),
                  });
                }}
              />
            }
          />
          <InputLabel
            className="w-full mt-4"
            label="File name"
            input={
              <div className="flex flex-row">
                <Input
                  className="rounded-r-none z-10"
                  placeholder="File name"
                  value={
                    fileUpdateModal?.name.split(".").slice(0, -1).join(".") ||
                    ""
                  }
                  onChange={(e) => {
                    const name =
                      e.target.value +
                      "." +
                      fileUpdateModal?.name.split(".").pop();
                    openFileUpdateModal({
                      ...fileUpdateModal!,
                      name,
                    });
                  }}
                />
                <Input
                  className="w-16 rounded-l-none -ml-px"
                  disabled
                  value={"." + fileUpdateModal?.name.split(".").pop()}
                />
              </div>
            }
          />
          <Button
            className="mt-6"
            loading={loading}
            onClick={() => {
              if (fileUpdateModal?.file) {
                uploadFile(
                  fileUpdateModal.file,
                  fileUpdateModal.tag_codes,
                  fileUpdateModal.name
                );
              } else if (fileUpdateModal?.id) {
                update(fileUpdateModal?.id, {
                  name: fileUpdateModal?.name,
                  tag_codes: fileUpdateModal?.tag_codes,
                });
              }
              openFileUpdateModal(null);
            }}
          >
            Save
          </Button>
        </ModalContent>
      </Modal>

      <div className="grow">
        <div className="flex flex-row space-x-3 mb-3">
          <InputLabel
            className="grow"
            label="Search"
            input={
              <InputDecorationIcon
                className="grow"
                prefix={(p) => <MagnifyingGlassIcon {...p} />}
                input={({ className }) => (
                  <Input
                    className={className}
                    placeholder="Name"
                    value={filters.display_name}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        on_page: 1,
                        display_name: e.target.value,
                      })
                    }
                  />
                )}
              />
            }
          />
          <InputLabel
            label="Categories"
            className="w-64"
            input={
              <SelectMultiple
                options={uniqBy(
                  (labels || []).map((a) => ({
                    label: a.name,
                    value: a.code,
                  })),
                  "value"
                )}
                value={filters.tags || []}
                onChange={(tags) => {
                  setFilters({
                    ...filters,
                    on_page: 1,
                    tags: _.uniq(tags),
                  });
                }}
              />
            }
          />
          <InputLabel
            label="Created After"
            className="w-32"
            input={
              <InputDate
                value={
                  filters.min_created_at ? new Date(filters.min_created_at) : ""
                }
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    on_page: 1,
                    min_created_at: e?.getTime(),
                  })
                }
              />
            }
          />
          <InputLabel
            label="Created Before"
            className="w-32"
            input={
              <InputDate
                value={
                  filters.max_created_at ? new Date(filters.max_created_at) : ""
                }
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    on_page: 1,
                    max_created_at: e?.getTime(),
                  })
                }
              />
            }
          />
          {hasAccess("CUSTOMER_UPDATE") && (
            <div className="pt-6">
              <Button
                onClick={() => {
                  fileRef.current?.click();
                }}
              >
                Upload
              </Button>
            </div>
          )}
        </div>
        <Table
          loading={loading}
          columns={[
            {
              title: "Name",
              className: "w-full",
              thClassName: "w-full",
              headClassName: "w-full",
              render: (document) => {
                const { color: typeColor, icon } = getFileType(document);

                return (
                  <div className="w-full flex flex-row items-center space-x-2">
                    <div className="grow flex items-center">
                      <Tag
                        noColor
                        className={
                          "inline-flex items-center text-white bg-" +
                          typeColor +
                          " h-5 w-5 !p-1 mr-2"
                        }
                      >
                        {icon({ className: "h-5 h-5" })}
                      </Tag>
                      {(document.tags || [])
                        .map((id) => labels.find((l) => l.code === id))
                        .filter((a) => a)
                        .map((row) => {
                          return (
                            <Tag
                              key={row!.id}
                              noColor
                              className="capitalize mr-2"
                              style={{
                                backgroundColor: stringToColor(row!.code),
                              }}
                            >
                              {row!.name}
                            </Tag>
                          );
                        })}
                      <BaseSmall>{document.display_name}</BaseSmall>
                    </div>
                    <div className="whitespace-nowrap">
                      <Button
                        theme="outlined"
                        size="sm"
                        onClick={() =>
                          download(
                            document,
                            document.file_source === "internal" ? true : false
                          )
                        }
                      >
                        {document.file_source === "internal"
                          ? "Download"
                          : "Open link"}
                      </Button>
                      {hasAccess("CUSTOMER_UPDATE") && (
                        <>
                          <Button
                            theme="default"
                            className="ml-2"
                            size="sm"
                            onClick={() =>
                              openFileUpdateModal({
                                id: document.id,
                                name: document.display_name,
                                tag_codes: labels
                                  .filter((l) =>
                                    (document.tags || []).includes(l.code!)
                                  )
                                  .map((a) => a.code),
                              })
                            }
                          >
                            Edit
                          </Button>
                          <ButtonConfirm
                            confirmTitle="Remove document"
                            className="ml-2"
                            size="sm"
                            theme="danger"
                            loading={loading}
                            onClick={() => {
                              deleteDocument(document.id);
                            }}
                          >
                            Delete
                          </ButtonConfirm>
                        </>
                      )}
                    </div>
                  </div>
                );
              },
            },
          ]}
          data={documents}
          total={total}
          onRequestData={async (pagination) => {
            setFilters({
              ...filters,
              on_page: pagination.page,
              per_page: pagination.perPage,
            });
          }}
        />
      </div>
    </div>
  );
};

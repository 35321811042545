import { ButtonConfirm } from "@atoms/button/confirm";
import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { usePostHocPatterns } from "@features/kyt/state/use-post-hoc-patterns";
import { KytPostHocPattern } from "@features/kyt/types";
import toast from "react-hot-toast";
import { atom, useRecoilState } from "recoil";

export const PostHocPatternModalAtom = atom<{
  open: boolean;
  pattern: Partial<KytPostHocPattern> | null;
}>({
  key: "PostHocPatternModalAtom",
  default: {
    open: false,
    pattern: null,
  },
});

export const PostHocPatternModal = () => {
  const [modal, setModal] = useRecoilState(PostHocPatternModalAtom);
  return (
    <Modal
      open={modal.open}
      onClose={() => setModal({ open: false, pattern: null })}
    >
      {modal.open && <PostHocPatternModalContent />}
    </Modal>
  );
};

export const PostHocPatternModalContent = () => {
  const [modal, setModal] = useRecoilState(PostHocPatternModalAtom);
  const { patterns, create, update, deletePattern } = usePostHocPatterns();
  return (
    <ModalContent title="Add/Edit Post-Hoc Pattern">
      <InputLabel
        label="Name"
        input={
          <Input
            placeholder="Pattern name"
            value={modal.pattern?.name ?? ""}
            onChange={(e) =>
              setModal({
                ...modal,
                pattern: {
                  ...modal.pattern,
                  name: e.target.value,
                },
              })
            }
          />
        }
      />

      <InputLabel
        className="mt-4"
        label="Evaluation Frequency"
        input={
          <SelectMultiple
            value={
              modal.pattern?.evaluation_frequency
                ? [modal.pattern?.evaluation_frequency]
                : []
            }
            selectionLimit={1}
            placeholder="Select evaluation frequency"
            onChange={(e) => {
              setModal({
                ...modal,
                pattern: {
                  ...modal.pattern,
                  evaluation_frequency:
                    e.length > 0
                      ? (e[0] as "daily" | "weekly" | "monthly")
                      : undefined,
                },
              });
            }}
            options={["Daily", "Weekly", "Monthly"].map((type) => ({
              value: type.toLowerCase(),
              label: type,
            }))}
          />
        }
      />
      <InputLabel
        className="mt-4"
        label="Query (Cypher)"
        input={
          <Input
            className="focus:z-10 rounded-r-none  min-h-[10rem]  rounded-t-none"
            placeholder="MATCH (a:Node)-[e:TRANSACTION_ALL]->(b:Node) RETURN a, e, b;"
            multiline
            size="sm"
            disabled={modal.pattern?.id !== undefined}
            value={modal.pattern?.query ?? ""}
            onChange={(e) => {
              setModal({
                ...modal,
                pattern: {
                  ...modal.pattern,
                  query: e.target.value,
                },
              });
            }}
          />
        }
      />

      <ButtonConfirm
        confirmTitle="Save Post-Hoc Pattern"
        className="mt-6 float-right"
        theme="primary"
        disabled={
          !modal.pattern?.name ||
          !modal.pattern?.query ||
          !modal.pattern?.evaluation_frequency
        }
        onClick={async () => {
          if (!modal.pattern?.id) {
            const created = await create({
              evaluation_frequency: modal.pattern?.evaluation_frequency,
              name: modal.pattern?.name,
              query: modal.pattern?.query,
            });
            if (created) {
              setModal({ ...modal, open: false });
            }
          } else {
            const updated = await update(modal.pattern?.id, {
              evaluation_frequency: modal.pattern?.evaluation_frequency,
              name: modal.pattern?.name,
              parameters: "",
              // query: modal.pattern?.query,
            });
            if (updated) {
              setModal({ ...modal, open: false });
            }
          }
        }}
      >
        Save
      </ButtonConfirm>

      {patterns.find((pattern) => pattern.id === modal.pattern?.id) && (
        <ButtonConfirm
          confirmTitle="Delete Pattern"
          confirmMessage="This will be applied now and alerts will not be triggered using this pattern anymore"
          className="mt-6"
          theme="danger"
          onClick={async () => {
            try {
              await deletePattern(modal.pattern?.id!);
              setModal({ ...modal, open: false });
              toast.success("Post-Hoc Pattern deleted successfully");
            } catch (error) {
              toast.error("Error deleting Post-Hoc Pattern");
            }
          }}
        >
          Remove
        </ButtonConfirm>
      )}
    </ModalContent>
  );
};

import { useRecoilState } from "recoil";
import { TransactionAtom } from "./store";
import { LoadingAtom } from "@features/utils/loading";
import { useCallback } from "react";
import { delayRequest } from "@features/utils";
import { KytApiClient } from "../api-client/api-client";
import { useCustomerBy } from "@features/customers/state/use-customer-by";

export const useTransaction = (id: string) => {
  const [fullTransaction, setFullTransaction] = useRecoilState(
    TransactionAtom(id)
  );
  const [loading, setLoading] = useRecoilState(
    LoadingAtom(`useTransaction${id}`)
  );
  const { customer: from } = useCustomerBy({ external_ids: fullTransaction?.transaction.from.external_id || "" })
  const { customer: to } = useCustomerBy({ external_ids: fullTransaction?.transaction.to.external_id || "" })

  const refresh = useCallback(async () => {
    setLoading(true);
    delayRequest(`useTransaction${id}Refresh`, async () => {
      setFullTransaction(await KytApiClient.getTransactionFullHistory(id));
      setLoading(false);
    });
  }, [setFullTransaction, setLoading, id]);

  const update = useCallback(
    async (state: number, comment: string) => {
      if (id && comment) {
        setLoading(true);
        delayRequest(`useTransaction${id}Update`, async () => {
          await KytApiClient.updateTransaction(id, state, comment);
          await refresh();
          setLoading(false);
        });
      }
    },
    [refresh, setLoading, id]
  );

  return {
    transaction: fullTransaction?.transaction,
    revisions: fullTransaction?.history_items,
    from,
    to,
    update,
    refresh,
    loading,
  };
};

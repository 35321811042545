import { useGlobalEffect } from "@features/utils";
import { useRecoilState } from "recoil";
import { AlertsApiClient } from "../api-client/api-client";
import { AlertsCategoriesAtom } from "./store";

export const useAlertCategories = () => {
  const [categories, setCategories] = useRecoilState(AlertsCategoriesAtom);

  const refresh = async () => {
    setCategories(await AlertsApiClient.getAlertsCategories());
  };

  useGlobalEffect(
    "useAlertCategories",
    () => {
      refresh();
    },
    []
  );

  return { categories, refresh };
};

import { useRiskElement } from "@features/risk-decisions/user-risk-element";
import {
  NODE_INVISIBLE_UNDEFINED,
  convertFlowToRiskTree,
  convertRiskElementToNodes,
  getRiskRouteDetails,
} from "@features/risk-decisions/utils";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ReactFlow, {
  Background,
  Connection,
  Controls,
  Edge,
  EdgeRemoveChange,
  EdgeChange,
  FitViewOptions,
  MiniMap,
  Node,
  NodeChange,
  NodeTypes,
  Panel,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  useReactFlow,
} from "reactflow";
import { useRecoilState } from "recoil";
import { RiskEditorHasUnsavedChangesAtom } from ".";
import ComparisonNode from "./nodes/comparison";
import FormulaNode from "./nodes/formula";
import LeafNode from "./nodes/leaf";
import MatrixNode from "./nodes/matrix";
import RelationsNode from "./nodes/relations";
import StartNode from "./nodes/start";
import RiskFlowComponentPanel from "./panels/risk-flow-component-panel";
import RisksChangePanel from "./panels/risk-view-change-detected";
import { RiskResourceType, RiskTreeNode } from "@features/risk-decisions/types";
import OrNode from "./nodes/or_node";

const minimapStyle = {
  height: 120,
};
const fitViewOptions: FitViewOptions = {
  minZoom: 0.75,
  maxZoom: 0.75,
  duration: 200,
};

const nodeTypes: NodeTypes = {
  leaf: LeafNode,
  or: OrNode,
  start: StartNode,
  comparison: ComparisonNode,
  matrix: MatrixNode,
  formula: FormulaNode,
  relations: RelationsNode,
};

export default function RiskFlow({
  type,
  id,
  editable,
}: {
  readonly type: RiskResourceType;
  readonly editable?: boolean;
  readonly id: string;
}) {
  const { element, update } = useRiskElement(type, id);
  const [nodes, setNodes] = useState<Node<RiskTreeNode>[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [changed, setChanged] = useRecoilState(
    RiskEditorHasUnsavedChangesAtom(type)
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { project, fitView, viewportInitialized } = useReactFlow();

  const types = useMemo(() => nodeTypes, []);

  const resetNodes = useCallback(() => {
    const { nodes, edges } = convertRiskElementToNodes(element!);
    setNodes(nodes);
    setEdges(edges);
    setChanged(false);
  }, [setNodes, setEdges, setChanged, element]);

  useEffect(() => {
    if (viewportInitialized) {
      setTimeout(() => {
        fitView({ maxZoom: 0.75, minZoom: 0.75, duration: 200 });
      }, 200);
    }
  }, [viewportInitialized, fitView]);

  useEffect(() => {
    if (element?.id) resetNodes();
  }, [element?.id, resetNodes]);

  const onNodesChange = useCallback(
    (changes: NodeChange[]) => {
      const newNodes = applyNodeChanges(changes, nodes);
      setNodes(newNodes);
      if (
        !_.isEqual(
          _.pick(nodes, "id", "data", "type"),
          _.pick(newNodes, "id", "data", "type")
        )
      )
        setChanged(true);
    },
    [nodes, setNodes, setChanged]
  );

  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => {
      const newEdges = applyEdgeChanges(changes, edges);
      if (changes.length === 1 && changes[0].type === "remove") {
        // attach to undefinied node
        const removed = edges.find(
          (e) => e.id === (changes[0] as EdgeRemoveChange).id
        );
        if (removed) {
          setNodes(
            nodes.map((n) =>
              n.id === removed.source
                ? {
                    ...n,
                    data: {
                      ...n.data,
                      output_node_ids: {
                        ...n.data.output_node_ids,
                        [removed.sourceHandle!]: NODE_INVISIBLE_UNDEFINED.id,
                      },
                    },
                  }
                : n
            )
          );
        }
      }
      setEdges(newEdges);
      if (
        !_.isEqual(
          edges.map((e) => e.id),
          newEdges.map((e) => e.id)
        )
      )
        setChanged(true);
    },
    [edges, setChanged, nodes]
  );

  const onConnect = useCallback(
    (connection: Connection) => {
      // RULE 1: Start node can only have one child
      const sameSource = edges.find(
        (edge) =>
          edge.source === connection.source &&
          edge.sourceHandle === connection.sourceHandle
      );
      if (
        connection.source === "root" &&
        edges.find((edge) => edge.source === "root")
      ) {
        toast.error("Start node can only have one child");
        // RULE 2: a node cannot have more than one parent (source)
      } else if (sameSource) {
        toast.error("An output cannot have more than one node output");
      } else {
        const newEdges = addEdge(connection, edges);
        setEdges(newEdges);
        if (!_.isEqual(edges, newEdges)) setChanged(true);
      }
    },
    [edges, setEdges, setChanged]
  );

  return (
    <div className="w-full h-full select-none">
      <ReactFlow
        nodes={nodes.map((node) => ({
          ...node,
          data: {
            ...node.data,
            editable,
            resource: type,
            withRelations: element?.type === "customer_relation",
            withTransactions: type === "kyt",
            onChange: (data: any) => {
              if (!editable) return;
              setNodes(
                nodes.map((n) => (n.id === node.id ? { ...n, data } : n))
              );
              setChanged(true);
            },
          },
        }))}
        edges={edges}
        nodesDraggable={editable}
        nodesConnectable={editable}
        onNodesChange={editable ? onNodesChange : undefined}
        onEdgesChange={editable ? onEdgesChange : undefined}
        onConnect={editable ? onConnect : undefined}
        nodeTypes={types}
        fitViewOptions={fitViewOptions}
      >
        <Panel
          hidden={!editable}
          position="bottom-center"
          className="bg-white dark:bg-slate-900 border rounded p-4 shadow-sm z-10"
        >
          <RiskFlowComponentPanel
            type={type}
            enableOr={true}
            addNode={(node) => {
              const vp = project({ x: 0, y: 0 });
              node.position = { x: vp.x + 100, y: vp.y + 100 };
              setNodes([...nodes, node]);
            }}
          />
        </Panel>

        {changed && (
          <Panel
            hidden={!editable}
            position="top-center"
            className="bg-white dark:bg-slate-900 border rounded p-4 shadow-sm z-10"
          >
            <RisksChangePanel
              loading={loading}
              onDiscard={() => resetNodes()}
              onSave={async () => {
                setLoading(true);
                const { error, riskFactor: newElement } = convertFlowToRiskTree(
                  nodes,
                  edges,
                  type
                );

                if (error) {
                  toast.error(error?.message);
                } else if (newElement) {
                  newElement.id = element!.id;
                  newElement.label = element!.label;
                  newElement.use_weight = element!.use_weight;
                  newElement.weight = element!.weight;
                  if (element!.code) newElement.code = element!.code;
                  const newVersion = await update(newElement);
                  setChanged(false);
                  setLoading(false);
                  if (newVersion) {
                    navigate(
                      getRiskRouteDetails(type).replace(
                        /:id/,
                        newVersion.id.toString()
                      )
                    );
                  }
                }
                setLoading(false);
              }}
            />
          </Panel>
        )}
        {editable && (
          <div className="w-15">
            <MiniMap
              style={minimapStyle}
              className="dark:bg-slate-500"
              zoomable
              pannable
            />
          </div>
        )}
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
}

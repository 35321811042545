import { GetStartedCard } from "@atoms/card/get-started";
import { Page } from "@atoms/layout/page";
import { Title } from "@atoms/text";
import { useHasAccess } from "@features/auth/state/use-access";
import { useCustomFields } from "@features/custom-fields/state/use-custom-fields";
import { CustomFieldsTables } from "./custom-fields";

export const CustomFieldsPage = () => {
  const { fields } = useCustomFields();
  const hasAccess = useHasAccess();

  return (
    <Page>
      <Title>Custom fields</Title>

      {hasAccess("SIDENAV_IMPORT_SETUP_SAVE") &&
        (fields || []).filter(
          (f) => f.field_source === 3 || f.field_source === 6
        ).length !== 0 && (
          <GetStartedCard
            className="mt-4"
            text={
              <>
                You can use custom data fields to store any information you
                want. Use them to store additional information about your
                customers, and the relations between your customers.
              </>
            }
            title="Custom fields"
          />
        )}

      <br />

      <CustomFieldsTables />
    </Page>
  );
};

import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import { GetStartedCard } from "@atoms/card/get-started";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import { Page } from "@atoms/layout/page";
import { Modal, ModalContent } from "@atoms/modal/modal";
import Select from "@atoms/select";
import { Base, Info, Section, Title } from "@atoms/text";
import { Table } from "@components/table";
import { Column } from "@components/table/table";
import { useHasAccess } from "@features/auth/state/use-access";
import { ROUTES } from "@features/routes";
import { useScenarios } from "@features/scenarios/state/use-scenarios";
import { ScenarioListType } from "@features/scenarios/types";
import { SCENARIOS_LANGUAGES } from "@features/scenarios/utils";
import _ from "lodash";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

export default function ScenariosList() {
  const { scenarios, refresh, create, loading } = useScenarios();
  const [modal, setModal] = useState<
    Partial<ScenarioListType> & { open: boolean }
  >({ open: false });
  const hasAccess = useHasAccess();

  useEffect(() => {
    refresh();
  }, [refresh]);

  const columns: Column<ScenarioListType>[] = [
    {
      title: "Category",
      render: (row) => (
        <Tag data-tooltip="Value used in the customer's summary and risk scoring.">
          {row.result_label || row.code}
        </Tag>
      ),
    },
    {
      title: "Name",
      render: (row) => (
        <Base className="flex items-center mr-2">{row.label}</Base>
      ),
    },
    {
      title: "Code",
      render: (row) => (
        <Base className="flex items-center mr-2">{row.code}</Base>
      ),
    },
    {
      title: "Default Language",
      render: (row) => (
        <Info className="flex items-center mr-2">
          {SCENARIOS_LANGUAGES[row.language]}
        </Info>
      ),
    },
    {
      title: "Enabled",
      render: (row) => (
        <>
          <Tag
            noColor
            className={
              "text-white " + (row.enabled ? "bg-green-500" : "bg-slate-500")
            }
          >
            {row.enabled ? "YES" : "NO"}
          </Tag>
        </>
      ),
    },
    {
      title: "Actions",
      headClassName: "justify-end",
      className: "justify-end h-12",
      thClassName: "w-24",
      render: (row) =>
        hasAccess("SCENARIO_UPDATE") && (
          <div className="flex flex-row space-x-2">
            <Link to={ROUTES.ScenarioView.replace(":id", row.id.toString())}>
              <Button theme="outlined" size="sm">
                Edit
              </Button>
            </Link>
          </div>
        ),
    },
  ];

  return (
    <Page>
      {hasAccess("SCENARIO_UPDATE") && (
        <div>
          <Button
            className="float-right"
            size="sm"
            onClick={() =>
              setModal({
                open: true,
              })
            }
          >
            Create Scenario
          </Button>
        </div>
      )}
      <Title>Scenarios</Title>

      <div className="my-4">
        <GetStartedCard
          show={(scenarios?.data || [])?.length === 1}
          title={<>Customize your verification processes</>}
          text={
            <>
              Scenarios are used to define the flow of the onboarding process.{" "}
              <br />
              Each scenario can have multiple steps, each step can have multiple
              interactions. Each interaction can have multiple options.
            </>
          }
        />
      </div>

      <Section>Active</Section>

      <Table
        data={_.sortBy(
          (scenarios?.data || []).filter((s) => s.enabled),
          ["result_label", "code"]
        )}
        columns={columns}
        showPagination={false}
        loading={loading}
      />

      {(scenarios?.data || []).filter((s) => !s.enabled).length > 0 && (
        <>
          <br />
          <br />
          <Section>Draft</Section>

          <Table
            data={_.sortBy(
              (scenarios?.data || []).filter((s) => !s.enabled),
              ["result_label", "code"]
            )}
            columns={columns}
            showPagination={false}
            loading={loading}
          />
        </>
      )}

      <Modal open={modal.open} onClose={() => setModal({ open: false })}>
        <ModalContent title="New On Boarding Scenario">
          <InputLabel
            label="Scenario Code (Identifier)"
            input={
              <>
                <Info>
                  This is the code that will be used to identify the scenario in
                  the API
                </Info>
                <Input
                  placeholder="Scenario code"
                  value={modal.code || ""}
                  onChange={(e) =>
                    setModal({
                      ...modal,
                      code: e.target.value,
                    })
                  }
                />
              </>
            }
          />
          <InputLabel
            className="mt-4"
            label="Label"
            input={
              <Input
                placeholder="Scenario label"
                value={modal.label || ""}
                onChange={(e) =>
                  setModal({
                    ...modal,
                    label: e.target.value,
                  })
                }
              />
            }
          />

          <InputLabel
            className="mt-4"
            label="Type"
            input={
              <>
                <Select
                  value={modal.language || ""}
                  placeholder="Select language"
                  onChange={(e) =>
                    setModal({ ...modal, language: e.target.value as any })
                  }
                >
                  <option disabled value="">
                    Select language
                  </option>

                  {Object.keys(SCENARIOS_LANGUAGES).map((languageCode) => (
                    <option key={"lang_" + languageCode} value={languageCode}>
                      {_.capitalize(SCENARIOS_LANGUAGES[languageCode])}
                    </option>
                  ))}
                </Select>
              </>
            }
          />

          <ButtonConfirm
            confirmTitle="Save scenario"
            className="mt-6 float-right"
            theme="primary"
            loading={loading}
            disabled={!modal.label || !modal.code || !modal.language}
            onClick={async () => {
              try {
                await create(modal);
                setModal({ ...modal, open: false });
                refresh();
              } catch (error) {
                toast.error("Error creating scenario");
              }
            }}
          >
            Create
          </ButtonConfirm>
        </ModalContent>
      </Modal>
    </Page>
  );
}

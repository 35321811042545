import { useGlobalEffect } from "@features/utils";
import { LoadingAtom } from "@features/utils/loading";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import { SessionsLabelsApiClient } from "../api-client/api-client";
import { SessionLabelType } from "../types";
import { SessionsLabelsAtom } from "./store";

export const useSessionsLabels = () => {
  const [list, setList] = useRecoilState(SessionsLabelsAtom);
  const [loading, setLoading] = useRecoilState(
    LoadingAtom("useSessionsLabels")
  );

  const refresh = useCallback(async () => {
    setLoading(true);
    setList(await SessionsLabelsApiClient.get());
    setLoading(false);
  }, [setList, setLoading]);

  const save = useCallback(
    async (
      id: number | undefined,
      data: Pick<SessionLabelType, "label" | "type" | "is_auto">
    ) => {
      setLoading(true);
      try {
        await SessionsLabelsApiClient.save(id, data);
        toast.success("Saved sessions labels");
      } catch (e) {
        console.error(e);
        toast.error("Failed to save session labels");
        setLoading(false);
        return false;
      }
      refresh();
      return true;
    },
    [refresh, setLoading]
  );

  const remove = useCallback(
    async (id: number) => {
      setLoading(true);
      try {
        await SessionsLabelsApiClient.delete(id);
        toast.success("Removed sessions labels");
      } catch (e) {
        console.error(e);
        toast.error("Failed to remove session labels");
        setLoading(false);
        return false;
      }
      refresh();
      return true;
    },
    [refresh, setLoading]
  );

  useGlobalEffect(
    "useSessionsLabels",
    () => {
      refresh();
    },
    []
  );

  return {
    labels: list,
    save,
    remove,
    loading,
    refresh,
  };
};

import { useFetch } from "@features/utils";
import { ScheduledAccess, ScheduledAccessData } from "../types";

export class ScheduledAccessApiClient {
    static getScheduledAccess = async (
    ): Promise<ScheduledAccess> => {
        const response = await useFetch(`/web/schedule-access`, {
            method: "GET",
        });
        if (response.status >= 300) {
            throw new Error("Unable to get scheduledAccess");
        }
        const data = await response.json();
        return data as ScheduledAccess;
    };

    static enableScheduledAccess = async (enabled: boolean): Promise<ScheduledAccess> => {
        const response = await useFetch(`/web/schedule-access`, {
            method: "PUT",
            body: JSON.stringify({ enabled })
        });
        if (response.status >= 300) {
            throw new Error("Unable to edit scheduled access");
        }
        const data = await response.json();
        return data as ScheduledAccess;
    };

    static createAccess = async (access: ScheduledAccessData): Promise<ScheduledAccess> => {
        const response = await useFetch(`/web/schedule-access/access`, {
            method: "POST",
            body: JSON.stringify({ ...access })
        });
        if (response.status >= 300) {
            throw new Error("Unable to create scheduled access");
        }
        const data = await response.json();
        return data as ScheduledAccess;
    };

    static editAccess = async (access: ScheduledAccessData): Promise<ScheduledAccess> => {
        const response = await useFetch(`/web/schedule-access/access/${access.id || ""}`, {
            method: "PUT",
            body: JSON.stringify({ ...access })
        });
        if (response.status >= 300) {
            throw new Error("Unable to create scheduled access");
        }
        const data = await response.json();
        return data as ScheduledAccess;
    };

    static deleteAccess = async (access: ScheduledAccessData): Promise<ScheduledAccess> => {
        const response = await useFetch(`/web/schedule-access/access/${access.id || ""}`, {
            method: "DELETE"
        });
        if (response.status >= 300) {
            throw new Error("Unable to create scheduled access");
        }
        const data = await response.json();
        return data as ScheduledAccess;
    };
}

import { delayRequest, useGlobalEffect } from "@features/utils";
import { LoadingAtom } from "@features/utils/loading";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import { DocumentsApiClient } from "../api-client/api-client";
import { CustomerDocumentsAtom, CustomerDocumentsFilterAtom } from "./store";
import { DocumentType } from "../types";
import { fileToBase64 } from "@features/utils/compress";

export const useCustomerDocuments = (externalId: string) => {
  const [documents, setDocuments] = useRecoilState(
    CustomerDocumentsAtom(externalId)
  );
  const [loading, setLoading] = useRecoilState(
    LoadingAtom("useCustomerDocuments-" + externalId)
  );
  const [filters, setFilters] = useRecoilState(CustomerDocumentsFilterAtom);

  const refresh = useCallback(async () => {
    setLoading(true);
    const files = await DocumentsApiClient.search({
      ...(filters || {}),
      customer_external_id: externalId,
    });
    if (!(files.data?.length >= 0)) {
      toast.error("Error loading documents");
      return;
    }
    setDocuments(files);
    setLoading(false);
  }, [filters, externalId, setLoading, setDocuments]);

  useGlobalEffect(
    "useCustomerDocuments",
    () => {
      delayRequest("useCustomerDocumentsDelayRequest", () => refresh(), {
        doInitialCall: false,
        timeout: 1000,
      });
    },
    [filters, externalId]
  );

  useGlobalEffect(
    "useCustomerDocumentsResetFilters",
    () => {
      setFilters({
        per_page: 10,
        on_page: 1,
      });
    },
    [externalId]
  );

  const download = async (
    document: DocumentType,
    avoidPrevisualizationTab: boolean
  ) => {
    const url = await DocumentsApiClient.getDownloadRoute(document);
    if (avoidPrevisualizationTab) {
      if (url.indexOf("?") === -1) {
        window.open(url + "?download=1", "_blank");
      } else {
        window.open(url + "&download=1", "_blank");
      }
    } else {
      window.open(url, "_blank");
    }
  };

  const update = useCallback(
    async (id: string, options: { name: string; tag_codes: string[] }) => {
      setLoading(true);
      const response = await DocumentsApiClient.update(id, options);
      if (!response.ok) {
        toast.error("Error updating document");
        return;
      }
      await refresh();
      setLoading(false);
    },
    [refresh, setLoading]
  );

  const upload = useCallback(
    async (
      document: {
        customer_external_id: string;
        name: string;
        tag_codes: string[];
      },
      file: {
        file_size: number; //For external files only
        file_url: string; //For external files only
        file_name: string;
        file_base_64: string;
      }
    ) => {
      setLoading(true);
      const response = await DocumentsApiClient.upload(document, file);
      if (!response.ok) {
        toast.error("Error uploading document");
        return;
      }
      setTimeout(() => {
        // no op
      }, 2000); //Indexing delay
      await refresh();
      setLoading(false);
    },
    [refresh, setLoading]
  );

  const uploadFile = useCallback(
    async (file: File, tag_codes: string[], name?: string) => {
      setLoading(true);
      try {
        const res = upload(
          {
            customer_external_id: externalId,
            name: name || file.name,
            tag_codes: tag_codes || [],
          },
          {
            file_size: file.size,
            file_url: "",
            file_name: file.name,
            file_base_64: (await fileToBase64(file)).split(",")[1],
          }
        );
        setLoading(false);
        return res;
      } catch (e) {
        toast.error("Error preparing document for upload.");
      }
      setLoading(false);
    },
    [externalId, upload, setLoading]
  );

  const deleteDocument = useCallback(
    async (id: string) => {
      setLoading(true);
      const response = await DocumentsApiClient.delete(id);
      if (!response.ok) {
        toast.error("Error while removing document");
        return;
      }
      await refresh();
      setLoading(false);
    },
    [refresh, setLoading]
  );

  return {
    loading,
    documents: documents.data,
    total: documents.total,
    refresh,
    filters,
    setFilters,
    download,
    update,
    upload,
    uploadFile,
    deleteDocument,
  };
};

import { useRecoilState } from "recoil";
import { InstantReportAtom } from "./store";
import { LoadingAtom } from "@features/utils/loading";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { PressApiClient } from "../api-client/api-client";
import { InstantPressRequestOptions } from "../types";

export const useInstantReport = () => {
  const [instantReport, setInstantReport] = useRecoilState(InstantReportAtom);
  const [loading, setLoading] = useRecoilState(LoadingAtom("useInstantReport"));

  const requestNewInstantReport = useCallback(
    async (options: Partial<InstantPressRequestOptions>) => {
      setLoading(true);
      try {
        const report = await PressApiClient.generateInstantReportAbout(options);
        setInstantReport(report);
      } catch (error) {
        toast.error("Error while trying to generate an instant report");
        setInstantReport(null);
      }
      setLoading(false);
    },
    [setLoading]
  );

  const requestExistingInstantReport = useCallback(
    async (reportBodyid: string) => {
      setLoading(true);
      try {
        const report = await PressApiClient.getExistingInstantReport(
          reportBodyid
        );
        setInstantReport(report);
      } catch (error) {
        toast.error("Error while trying to retrieve existing instant report");
        setInstantReport(null);
      }
      setLoading(false);
    },
    [setLoading]
  );

  return {
    instantReport,
    loading,
    requestNewInstantReport,
    requestExistingInstantReport,
  };
};

import { Button } from "@atoms/button/button";
import { GetStartedCard } from "@atoms/card/get-started";
import { Page } from "@atoms/layout/page";
import { Title } from "@atoms/text";
import { useHasAccess } from "@features/auth/state/use-access";
import { useCustomMatrix } from "@features/custom-matrix/state/use-custom-matrix";
import { useSetRecoilState } from "recoil";
import {
  CustomMatricesModalAtom,
  CustomMatricesTable,
} from "./custom-matrices";

export const CustomMatricesPage = () => {
  const { matrices, loading } = useCustomMatrix();
  const hasAccess = useHasAccess();

  const setModal = useSetRecoilState(CustomMatricesModalAtom);

  return (
    <Page>
      {hasAccess("MATRIX_CREATE") && (
        <Button
          className="float-right"
          size="sm"
          onClick={() =>
            setModal({
              open: true,
            })
          }
        >
          Add custom matrix
        </Button>
      )}

      <Title>Custom matrices</Title>

      {hasAccess("MATRIX_CREATE") && matrices.length === 0 && !loading && (
        <GetStartedCard
          className="mt-4"
          text={
            <>
              You can use custom matrices to store some pre-scoring informations
              about your customers based on their parameters.
            </>
          }
          title="Custom matrices"
        />
      )}

      <br />

      <CustomMatricesTable />
    </Page>
  );
};

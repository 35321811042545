import { useGlobalEffect } from "@features/utils";
import { LoadingAtom } from "@features/utils/loading";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import { DocumentsLabelsApiClient } from "../api-client/api-client";
import { DocumentLabelType } from "../types";
import { DocumentsLabelsAtom } from "./store";

export const useDocumentsLabels = () => {
  const [list, setList] = useRecoilState(DocumentsLabelsAtom);
  const [loading, setLoading] = useRecoilState(
    LoadingAtom("useDocumentsLabels")
  );

  const refresh = useCallback(async () => {
    setLoading(true);
    setList(await DocumentsLabelsApiClient.get());
    setLoading(false);
  }, [setList, setLoading]);

  const save = useCallback(
    async (data: DocumentLabelType[]) => {
      setLoading(true);
      try {
        await DocumentsLabelsApiClient.save(data);
        toast.success("Saved documents labels");
      } catch (e) {
        console.error(e);
        toast.error("Failed to save document labels");
        setLoading(false);
        return false;
      }
      refresh();
      return true;
    },
    [refresh, setLoading]
  );

  useGlobalEffect(
    "useDocumentsLabels",
    () => {
      refresh();
    },
    []
  );

  return {
    labels: list,
    save,
    loading,
    refresh,
  };
};

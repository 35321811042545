import { defaultAlertStates } from "@features/alerts/utils";
import _ from "lodash";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { atom, useRecoilState } from "recoil";
import { ButtonConfirm } from "@atoms/button/confirm";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import Select from "@atoms/select";
import { Base } from "@atoms/text";
import { AlertsApiClient } from "@features/alerts/api-client/api-client";
import { useAlertStates } from "@features/alerts/state/use-alert-states";
import { AlertListType } from "@features/alerts/types";

/**
 * Update multiple alerts statuses for a customer or by ids
 */

export const UpdateMultipleAlertsModalAtom = atom<{
  open: boolean;
  customer_id?: string;
  alerts?: AlertListType[];
  onFinish?: () => void;
}>({
  key: "UpdateMultipleAlertsModalAtom",
  default: {
    open: false,
  },
});

export const UpdateMultipleAlertsModal = () => {
  const [open, setOpen] = useRecoilState(UpdateMultipleAlertsModalAtom);

  return (
    <Modal
      open={!!open.open}
      onClose={() =>
        setOpen({
          open: false,
        })
      }
    >
      <UpdateMultipleAlertsModalContent />
    </Modal>
  );
};

export const UpdateMultipleAlertsModalContent = () => {
  const [open, setOpen] = useRecoilState(UpdateMultipleAlertsModalAtom);

  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const [status, setStatus] = useState(0);
  const [onlyStatus, setOnlyStatus] = useState(0);

  const { states } = useAlertStates();

  const [alerts, setAlerts] = useState<AlertListType[]>([]);

  useEffect(() => {
    if (states) {
      setStatus(defaultAlertStates.pending);
    }
  }, [states]);

  useEffect(() => {
    if (open.open && states) {
      (async () => {
        setLoading(true);
        const alerts =
          open.alerts ||
          (
            await AlertsApiClient.getAlerts({
              id: open.customer_id,
              on_page: 1,
              per_page: 500,
              states: onlyStatus <= 0 ? undefined : [onlyStatus],
            })
          )?.data;
        setLoading(false);
        if (!alerts) {
          setOpen({
            open: false,
          });
        } else {
          setAlerts(alerts);
        }
      })();
    }
  }, [open, onlyStatus, setOpen, states]);

  return (
    <ModalContent title={"Bulk alert update"}>
      {open.customer_id && (
        <Base>
          Set status of all alerts for this customer in one click. This is an
          unusual operation that should not be done out of very specific cases.
        </Base>
      )}
      {!open.customer_id && (
        <Base>Update status of {alerts.length} selected alerts.</Base>
      )}

      <br />
      <br />

      <InputLabel
        label="Alerts new status"
        input={
          <Select
            value={status}
            onChange={(e) => setStatus(parseInt(e.target.value))}
          >
            {Object.keys(states).map((state) => (
              <option value={state} key={state}>
                {_.capitalize(states[state as any])}
              </option>
            ))}
          </Select>
        }
      />
      <InputLabel
        className="mt-2"
        label="Review"
        input={
          <Input
            value={content}
            onChange={(e) => setContent(e.target.value)}
            multiline
          />
        }
      />

      {!!open.customer_id && (
        <>
          <br />
          <InputLabel
            label="Only update alerts with status"
            input={
              <Select
                value={onlyStatus}
                onChange={(e) => setOnlyStatus(parseInt(e.target.value))}
              >
                <option value={0}>Update all</option>
                {Object.keys(states).map((state) => (
                  <option value={state} key={state}>
                    {_.capitalize(states[state as any])}
                  </option>
                ))}
              </Select>
            }
          />
        </>
      )}

      <ButtonConfirm
        confirmMessage={
          "Are you sure you want to update " + alerts.length + " alert(s)?"
        }
        className="mt-4 float-right"
        loading={loading}
        disabled={!content || !status || alerts.length === 0}
        onClick={async () => {
          setLoading(true);
          for (const alert of alerts) {
            await AlertsApiClient.updateAlertStatus(
              alert.alert_id,
              status || 0,
              content + " (bulk action)"
            );
          }
          toast.success("Alerts updated");
          setOpen({
            open: false,
          });
          setContent("");
          setStatus(0);
          setLoading(false);
          if (open.onFinish) open.onFinish();
        }}
      >
        Update {alerts.length} alert(s) status(es)
      </ButtonConfirm>
    </ModalContent>
  );
};

import { Button } from "@atoms/button/button";
import { Section } from "@atoms/text";
import { useWebAuthn } from "@features/webauthn/state/useWebAuthn";

import toast from "react-hot-toast";
import { AuthenticationDevices } from "./authentication-devices";
import { useAuth } from "@features/auth/state/hooks";
import { useCallback } from "react";

export function Security() {
  const { useRegisterMutation, useDeleteDeviceMutation, getDevicesQuery } =
    useWebAuthn();

  const { user } = useAuth();
  const { mutateAsync: register, isLoading: isRegistering } =
    useRegisterMutation();
  const { mutateAsync: deleteDevice, isLoading: isDeletingDevice } =
    useDeleteDeviceMutation();
  const { data: devices, refetch, isFetching } = getDevicesQuery();

  const onWebAuthnRegister = async () => {
    try {
      await register();
      await refetch();
    } catch (e: any) {
      toast.error(
        "An error has occurred while registering your device, please try again later"
      );
    }
  };

  const onDelete = useCallback(
    async (credentialId: number) => {
      if (
        devices &&
        devices.length <= 1 &&
        user?.clients.some((client) => client.two_fa_required)
      ) {
        toast.error(
          "You cannot delete this device because at least one of your clients needs to have a 2FA authenticator. Please add another one before deleting this one.",
          { duration: 6000 }
        );
        return;
      }
      try {
        await deleteDevice(credentialId);
        await refetch();
      } catch (e: any) {
        toast.error(
          "An error has occurred while deleting your device, please try again later"
        );
      }
    },
    [user, devices, refetch, deleteDevice]
  );

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center justify-between mt-2">
        <Section className="my-auto">WebAuthn</Section>
        <Button
          onClick={onWebAuthnRegister}
          loading={isRegistering}
          theme="primary"
        >
          New Device
        </Button>
      </div>
      <AuthenticationDevices
        loading={isFetching || isDeletingDevice || isRegistering}
        data={devices || []}
        onDelete={onDelete}
      />
    </div>
  );
}

import { InboxSettingsType } from "./types";

// validate method for InboxSettingsType
export const validateInboxSettings: (
  inboxSettings: InboxSettingsType
) => boolean = (inboxSettings: InboxSettingsType) => {
  if (!inboxSettings.mailboxes?.length) return false;
  return inboxSettings.mailboxes.every((mailbox) => {
    if (!mailbox.account_name || !mailbox.id) return false;

    if (
      mailbox.smtp_enabled &&
      (!mailbox.smtp_address ||
        !mailbox.smtp_port ||
        !mailbox.smtp_username ||
        !mailbox.smtp_password)
    )
      return false;
    if (
      mailbox.imap_enabled &&
      (!mailbox.imap_address ||
        !mailbox.imap_port ||
        !mailbox.imap_username ||
        !mailbox.imap_password)
    )
      return false;
    return true;
  });
};

import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { TransactionsListAtom } from "./store";
import { LoadingAtom } from "@features/utils/loading";
import { KytTransactionsRequestOptions } from "../types";
import { delayRequest } from "@features/utils";
import { KytApiClient } from "../api-client/api-client";
import { sortBy } from "lodash";

export const useTransactions = () => {
  const [transactions, setTransactions] = useRecoilState(TransactionsListAtom);
  const [loading, setLoading] = useRecoilState(LoadingAtom("useTransactions"));

  const refresh = useCallback(
    async (options?: KytTransactionsRequestOptions) => {
      setLoading(true);
      delayRequest("useTransactionsRefresh", async () => {
        const unordered = await KytApiClient.getTransactions({ ...options });
        const ordered = sortBy(unordered.data, (item) =>
          new Date(item.date).getTime()
        );
        setTransactions({ ...unordered, data: ordered.reverse() });
        setLoading(false);
      });
    },
    [setTransactions, setLoading]
  );

  return { transactions, refresh, loading };
};

import { is2XX, useFetch } from "@features/utils";
import { InboxSettingsType, InboxSettingsMailboxesOnlyType } from "../types";

export class InboxSettingsApiClient {
  static getMailboxes = async () => {
    const response = await useFetch(`/web/messages/configuration_restricted`, {
      method: "GET",
    });
    const data = await response.json();
    return (data as InboxSettingsMailboxesOnlyType)?.mailboxes || [];
  };

  static get = async () => {
    const response = await useFetch(`/web/messages/configuration`, {
      method: "GET",
    });
    const data = await response.json();
    return data as InboxSettingsType;
  };

  static save = async (settings: InboxSettingsType) => {
    const response = await useFetch(`/web/messages/configuration`, {
      method: "POST",
      body: JSON.stringify(settings),
    });
    if (!is2XX(response.status)) {
      throw new Error("Failed to save inbox settings");
    }
  };
}

import { Button } from "@atoms/button/button";
import { GetStartedCard } from "@atoms/card/get-started";
import { Checkbox } from "@atoms/input/input-checkbox";
import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Input } from "@atoms/input/input-text";
import { PageResponsiveColumns } from "@atoms/layout/page-block";
import Select from "@atoms/select";
import { Menu } from "@atoms/text";
import { ROUTES } from "@features/routes";
import { useScenarios } from "@features/scenarios/state/use-scenarios";
import { QuestionType, ScenarioType } from "@features/scenarios/types";
import { SCENARIOS_LANGUAGES } from "@features/scenarios/utils";
import _ from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ScenarioQuestionForm from "./question-form";
import { useSessionsLabels } from "@features/sessions-labels/state/use-sessions-labels";

export default function ScenarioTab(props: {
  scenario: ScenarioType;
  onChange: (scenario: ScenarioType) => void;
}) {
  const { deleteScenario } = useScenarios();
  const [questionsExpanded, setQuestionExpanded] = useState<number[]>([]);
  const navigate = useNavigate();
  const { labels } = useSessionsLabels();

  return (
    <PageResponsiveColumns>
      <div className="grow md:max-w-md w-full flex-col space-y-8">
        <div className="flex flex-col space-y-2">
          <Menu className="mb-2">General Configuration</Menu>
          <Checkbox
            label="Scenario enabled"
            value={props.scenario.enabled}
            onChange={(s) => {
              props.onChange({ ...props.scenario, enabled: s });
            }}
          />
          <InputLabel
            label="Scenario label"
            input={
              <Input
                value={props.scenario.label}
                onChange={(e) => {
                  props.onChange({ ...props.scenario, label: e.target.value });
                }}
                placeholder="Scenario Label"
              />
            }
          />
          <InputLabel
            label="Scenario Code"
            input={
              <Input
                disabled
                value={props.scenario.code}
                onChange={(e) => {
                  props.onChange({ ...props.scenario, code: e.target.value });
                }}
                placeholder="code"
              />
            }
          />
          <InputLabel
            label="Category"
            input={
              <Input
                value={props.scenario.result_label}
                onChange={(e) => {
                  props.onChange({
                    ...props.scenario,
                    result_label: e.target.value,
                  });
                }}
                placeholder="Category"
              />
            }
          />
          <InputLabel
            label="Review Status"
            input={
              <SelectMultiple
                value={(props.scenario.status ?? []).map((status) =>
                  status.toString()
                )}
                options={[
                  ..._.sortBy(
                    labels.map((label) => ({
                      value: `${label.id}`,
                      label: label.label,
                    })),
                    "label"
                  ),
                ]}
                onChange={(e) => {
                  props.onChange({
                    ...props.scenario,
                    status: e.map((status) => parseInt(status)),
                  });
                }}
              />
            }
          />
        </div>
        <div className="flex flex-col space-y-2">
          <Menu className="mb-2">Languages</Menu>
          <InputLabel
            label="Available Languages"
            input={
              <SelectMultiple
                options={[
                  ..._.sortBy(
                    Object.keys(SCENARIOS_LANGUAGES).map((code) => ({
                      value: code,
                      label: SCENARIOS_LANGUAGES[code],
                    })),
                    "label"
                  ),
                ]}
                onChange={(languages) => {
                  const questions = (props.scenario.questions ?? []).map(
                    (question) => {
                      const localized = { ...question.description_localized };
                      Object.keys(localized).forEach((lang) => {
                        if (!languages.includes(lang)) {
                          delete localized[lang];
                        } else {
                          localized[lang] = localized[lang] ?? "";
                        }
                      });
                      return {
                        ...question,
                        description_localized: localized,
                      };
                    }
                  );
                  props.onChange({
                    ...props.scenario,
                    questions: questions,
                    languages: languages,
                  });
                }}
                value={props.scenario.languages}
                placeholder="Select languages"
              />
            }
          />
          <InputLabel
            label="Default Language"
            input={
              <Select
                value={props.scenario.language}
                placeholder="Select language"
                onChange={(e) => {
                  const lang = e.target.value;

                  props.onChange({
                    ...props.scenario,
                    language: lang,
                  });
                }}
              >
                <option disabled value="">
                  Select Default language
                </option>

                {Object.keys(SCENARIOS_LANGUAGES)
                  .filter((lang) => props.scenario.languages.includes(lang))
                  .map((languageCode) => (
                    <option key={"lang_" + languageCode} value={languageCode}>
                      {_.capitalize(SCENARIOS_LANGUAGES[languageCode])}
                    </option>
                  ))}
              </Select>
            }
          />
        </div>
        <Button
          size="sm"
          className="self-end"
          theme="danger"
          onClick={async () => {
            await deleteScenario(props.scenario.id);
            navigate(ROUTES.Scenarios);
          }}
        >
          Delete Scenario
        </Button>
      </div>
      <div className="grow w-full pl-10">
        <div className="flex flex-row justify-between items-center mb-6">
          <Menu>Scenario Questions</Menu>
          <Button
            size="sm"
            onClick={() => {
              const description_localized = props.scenario.languages.reduce(
                (acc, lang) => ({
                  ...acc,
                  [lang]: "",
                }),
                {}
              );

              props.onChange({
                ...props.scenario,
                questions: [
                  ...(props.scenario.questions ?? []),
                  {
                    id: Math.random(),
                    position: (props.scenario.questions ?? []).length,
                    label: "New Question",
                    type: QuestionType.Face,
                    description_localized: description_localized,
                    eliminatory: false,
                    coefficient: 0,
                    auto_start_recognition: false,
                    possible_answers: null,
                    should_not_contain: null,
                  },
                ],
              });
            }}
          >
            Add Question
          </Button>
        </div>
        <GetStartedCard
          title="No Question set yet !"
          text={"You can start adding questions to your scenario"}
          show={(props.scenario.questions ?? []).length === 0}
        />
        {props.scenario.questions?.length > 0 && (
          <div className="flex flex-col space-y-5">
            {_.sortBy(props.scenario.questions, "position").map((question) => {
              return (
                <ScenarioQuestionForm
                  scenario={props.scenario}
                  key={question.id}
                  question={question}
                  expanded={questionsExpanded.includes(question.id)}
                  last={
                    question.position === props.scenario.questions.length - 1
                  }
                  onChange={(questionChanged) => {
                    if (!questionChanged) {
                      props.onChange({
                        ...props.scenario,
                        questions: props.scenario.questions
                          .filter((q) => q.id !== question.id)
                          .map((q, index) => ({
                            ...q,
                            position: index,
                          })),
                      });
                      return;
                    }
                    if (questionChanged.position !== question.position) {
                      // re-order questions positions (mainly up or down)
                      // well check if up or down then identify the two questions to swap
                      const swapQuestion = props.scenario.questions.find(
                        (q) => q.position === questionChanged.position
                      );
                      if (!swapQuestion) return;
                      props.onChange({
                        ...props.scenario,
                        questions: props.scenario.questions.map((q) => {
                          if (q.id === question.id) {
                            return questionChanged;
                          }
                          if (q.id === swapQuestion.id) {
                            return {
                              ...swapQuestion,
                              position: question.position,
                            };
                          }
                          return q;
                        }),
                      });
                      return;
                    }
                    props.onChange({
                      ...props.scenario,
                      questions: props.scenario.questions.map((q) =>
                        q.id === question.id ? questionChanged : q
                      ),
                    });
                  }}
                  onClick={() => {
                    if (questionsExpanded.includes(question.id)) {
                      setQuestionExpanded(
                        questionsExpanded.filter((id) => question.id !== id)
                      );
                    } else {
                      setQuestionExpanded([...questionsExpanded, question.id]);
                    }
                  }}
                />
              );
            })}
          </div>
        )}
      </div>
    </PageResponsiveColumns>
  );
}

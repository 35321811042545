import { is2XX, useFetch } from "@features/utils";
import { MessageLabelType } from "../types";

export class MessagesLabelsApiClient {
  static get = async () => {
    const response = await useFetch(`/web/messages/tags`, {
      method: "GET",
    });
    const data = await response.json();
    return (data as MessageLabelType[]) || [];
  };

  static save = async (labels: MessageLabelType[]) => {
    const response = await useFetch(`/web/messages/tags`, {
      method: "POST",
      body: JSON.stringify({
        tags: labels,
      }),
    });
    if (!is2XX(response.status)) {
      throw new Error("Failed to save custom labels");
    }
  };
}

import countries from "@assets/countries.json";
import { Button } from "@atoms/button/button";
import InputDate from "@atoms/input/input-date";
import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Input } from "@atoms/input/input-text";
import { PageResponsiveColumns } from "@atoms/layout/page-block";
import Select from "@atoms/select";
import { InstantPressRequestOptions } from "@features/press/types";
import { useState } from "react";

export const InstantPressFilters = ({
  value,
  onChange,
  onSubmit,
  loading,
}: {
  value: Partial<InstantPressRequestOptions>;
  onChange: (params: Partial<InstantPressRequestOptions>) => void;
  onSubmit: (params: Partial<InstantPressRequestOptions>) => void;
  loading?: boolean;
}) => {
  const [filters, setFilters] =
    useState<Partial<InstantPressRequestOptions>>(value);

  return (
    <>
      <PageResponsiveColumns>
        <InputLabel
          className="w-full md:max-w-max"
          label="Type"
          input={
            <Select
              value={filters.entity_type || 0}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  entity_type: parseInt(e.target.value) || 0,
                })
              }
            >
              <option value={1}>Person</option>
              <option value={2}>Company</option>
              <option value={0}>Unknown</option>
            </Select>
          }
        />
        {filters.entity_type === 1 && (
          <>
            <InputLabel
              className="w-full"
              label="Full name"
              input={
                <Input
                  value={filters.name || ""}
                  placeholder={"Barack Obama"}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      name: e.target.value,
                    })
                  }
                />
              }
            />
          </>
        )}
        {filters.entity_type === 0 && (
          <InputLabel
            className="w-full"
            label="Query"
            input={
              <Input
                value={filters.name || ""}
                placeholder={"Search query"}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    name: e.target.value,
                  })
                }
              />
            }
          />
        )}
        {filters.entity_type === 2 && (
          <InputLabel
            className="w-full"
            label="Company name"
            input={
              <Input
                value={filters.name || ""}
                placeholder={"Microsoft"}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    name: e.target.value,
                  })
                }
              />
            }
          />
        )}
        <InputLabel
          className="w-full md:w-40 shrink-0"
          label="Countries"
          input={
            <SelectMultiple
              value={
                ((filters.country_codes || []).filter((a) => a) ||
                  []) as string[]
              }
              onChange={(e) => {
                setFilters({
                  ...filters,
                  country_codes: e,
                });
              }}
              options={countries.map((c) => ({
                label: c.name,
                value: c["alpha-3"],
              }))}
            />
          }
        />
        {filters.entity_type === 1 && (
          <InputLabel
            className="w-full md:max-w-max"
            label="Date of birth"
            input={
              <InputDate
                value={filters.date_of_birth || ""}
                placeholder={"1961-08-04"}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    date_of_birth: e
                      ? new Date(
                          new Date(e).getTime() -
                            new Date(e).getTimezoneOffset() * 60000
                        ) //We don't care about timezone here
                          .toISOString()
                          .split("T")[0]
                      : null,
                  });
                }}
              />
            }
          />
        )}
        <div className="grow" />
        <div className="pt-6">
          <Button
            loading={loading}
            disabled={!filters.name || loading}
            onClick={() => {
              onChange(filters);
              onSubmit(filters);
            }}
          >
            Search
          </Button>
        </div>
      </PageResponsiveColumns>
    </>
  );
};

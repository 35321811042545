import { Button } from "@atoms/button/button";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import Select from "@atoms/select";
import { useTransaction } from "@features/kyt/state/use-transaction";
import { KYT_TRANSACTION_STATES } from "@features/kyt/utils";
import _ from "lodash";
import { atom, useRecoilState } from "recoil";

export const TransactionStatusUpdateModalAtom = atom<{
  open: boolean;
  comment: string;
  status: number;
  transaction_id: string;
}>({
  key: "TransactionStatusUpdateModalAtom",
  default: {
    open: false,
    comment: "",
    status: 0,
    transaction_id: "",
  },
});

export const TransactionStatusUpdateModal = () => {
  const [modal, setModal] = useRecoilState(TransactionStatusUpdateModalAtom);

  return (
    <Modal
      open={modal.open}
      onClose={() => setModal({ ...modal, open: false })}
    >
      <TransactionStatusUpdateModalContent />
    </Modal>
  );
};

const TransactionStatusUpdateModalContent = () => {
  const [modal, setModal] = useRecoilState(TransactionStatusUpdateModalAtom);
  const { update, loading } = useTransaction(modal.transaction_id);

  return (
    <ModalContent title={"Update transaction review"}>
      <InputLabel
        label="Transaction status"
        input={
          <Select
            value={modal.status}
            onChange={(e) =>
              setModal({ ...modal, status: parseInt(e.target.value) })
            }
          >
            {Object.keys(KYT_TRANSACTION_STATES).map((state) => (
              <option value={state} key={state}>
                {_.capitalize(KYT_TRANSACTION_STATES[state as any])}
              </option>
            ))}
          </Select>
        }
      />
      <InputLabel
        className="mt-2"
        label="Review"
        input={
          <Input
            value={modal.comment}
            onChange={(e) => setModal({ ...modal, comment: e.target.value })}
            multiline
          />
        }
      />
      <Button
        className="mt-4"
        disabled={loading}
        theme="default"
        onClick={() => setModal({ ...modal, open: false })}
      >
        Cancel
      </Button>
      <Button
        className="mt-4 float-right"
        loading={loading}
        disabled={!modal.comment || !modal.status}
        onClick={async () => {
          await update(modal.status, modal.comment);
          setModal({ ...modal, comment: "", status: 0, open: false });
        }}
      >
        Update transaction status
      </Button>
    </ModalContent>
  );
};

import { useRecoilState } from "recoil";
import { ReportingSettingsAtom } from "./store";
import { LoadingAtom } from "@features/utils/loading";
import { ReportingApiClient } from "../api-client/api-client";
import { useCallback } from "react";
import { ReportingSettingsType } from "../types";
import toast from "react-hot-toast";
import { useGlobalEffect } from "@features/utils";

// Currently gets and sets reportingSettings that belong to the client (identifies client associated to the agent/token Bearer when the api calls the backend)
// Maybe think about modifying this hook to receive the current client ID as a parameter (would also require a change on the backend api)
export const useReportingSettings = () => {
  const [reportingSettings, setReportingSettings] = useRecoilState(
    ReportingSettingsAtom
  );
  const [loading, setLoading] = useRecoilState(
    LoadingAtom("useReportingSettings")
  );

  const refresh = useCallback(async () => {
    setLoading(true);
    try {
      setReportingSettings(await ReportingApiClient.getReportingSettings());
    } catch (e) {
      setLoading(false);
    }
    setLoading(false);
  }, [setReportingSettings, setLoading]);

  const save = useCallback(
    async (reportingSettings: ReportingSettingsType) => {
      setLoading(true);
      try {
        await ReportingApiClient.setReportingSettings(reportingSettings);
        toast.success("Saved Reporting settings");
      } catch (e) {
        console.error(e);
        toast.error("Failed to save Reporting settings");
        setLoading(false);
        return false;
      }
      refresh();
      return true;
    },
    [refresh, setLoading]
  );

  const sendTestReport = useCallback(
    async (index: number) => {
      setLoading(true);
      try {
        await ReportingApiClient.testReportingSettings(index);
        toast.success("Test report sent");
      } catch (e) {
        console.error(e);
        toast.error("Failed to send test report");
        setLoading(false);
      }
      setLoading(false);
    },
    [setLoading]
  );

  useGlobalEffect(
    "useReportingSettings",
    () => {
      refresh();
    },
    []
  );

  return {
    reportingSettings,
    save,
    sendTestReport,
    loading,
    refresh,
  };
};

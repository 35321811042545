import { DropdownButton } from "@atoms/dropdown";
import { Checkbox } from "@atoms/input/input-checkbox";
import { Loader } from "@atoms/loader";
import { Info } from "@atoms/text";
import {
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
} from "@heroicons/react/24/outline";
import _ from "lodash";
import { ReactNode, useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import { twMerge } from "tailwind-merge";
import { TablePagination } from "./pagination";

export type Column<T> = {
  title?: string | ReactNode;
  className?: string;
  thClassName?: string;
  headClassName?: string;
  orderable?: boolean;
  render: (item: T) => string | ReactNode;
};

export type Pagination = {
  total: number;
  page: number;
  perPage: number;
  orderBy?: number;
  order?: "ASC" | "DESC";
};

type PropsType<T> = {
  columns: Column<T>[];
  data: T[];
  rowIndex?: string;
  cellClassName?: (row: T) => string;
  tableClassName?: string;
  className?: string;
  showPagination?: boolean;
  pagination?: Pagination;
  scrollable?: boolean;
  loading?: boolean;
  onSelect?:
    | {
        icon?: (args: { className?: string }) => ReactNode;
        label: string | ReactNode;
        callback: (items: T[]) => void;
      }[]
    | ((items: T[]) => void);
  onClick?: (item: T, e: MouseEvent) => void;
  onChangeOrder?: (columnIndex: number, direction: "ASC" | "DESC") => void;
  onChangePage?: (page: number) => void;
  onChangePageSize?: (size: number) => void;
};

export function RenderedTable<T>({
  columns,
  data,
  rowIndex,
  pagination,
  showPagination = true,
  loading,
  scrollable,
  onSelect,
  onClick,
  onChangeOrder,
  onChangePage,
  onChangePageSize,
  cellClassName,
  tableClassName,
  className,
}: PropsType<T>) {
  const [selected, setSelected] = useState<T[]>([]);

  useEffect(() => {
    setSelected([]);
  }, [data.length, pagination?.page, pagination?.perPage]);

  useEffect(() => {
    if (onSelect && typeof onSelect === "function") onSelect(selected);
  }, [selected, onSelect]);

  return (
    <div
      className={
        "z-0 not-prose text-left border-slate-200 dark:border-slate-700 relative overflow-hidden " +
        (className || "")
      }
    >
      <SimpleBar
        className={
          "relative overflow-auto " + (scrollable ? "h-full " : "rounded")
        }
      >
        {loading && (
          <div className="absolute m-auto left-0 top-0 right-0 bottom-0 w-6 h-6 text-center z-10">
            <Loader color="text-blue-500" />
          </div>
        )}

        <table
          className={
            "border-collapse table-fixed w-auto min-w-full " +
            (loading ? " opacity-75 animate-pulse " : "") +
            (scrollable ? " scrollable h-full " : "") +
            " " +
            (tableClassName || "")
          }
        >
          {columns.map((c) => c.title || "").join("") && (
            <thead>
              <tr>
                {onSelect && (
                  <th
                    className={
                      "w-8 shrink-0 relative " +
                      (scrollable
                        ? " sticky top-0 bg-slate-50 dark:bg-slate-800 "
                        : "")
                    }
                  >
                    <div
                      className="absolute z-10 mt-1 top-0 left-0 "
                      style={{
                        boxShadow: "40px 0 20px #F8FAFC",
                      }}
                    >
                      {selected.length > 0 &&
                        typeof onSelect !== "function" && (
                          <DropdownButton
                            options={onSelect.map((a) => ({
                              onClick: () => a.callback(selected),
                              icon: a.icon,
                              label: a.label,
                            }))}
                            theme="primary"
                            size="sm"
                          >
                            {selected.length || 0} item(s)
                          </DropdownButton>
                        )}
                    </div>
                  </th>
                )}
                {columns.map((column, i) => (
                  <th
                    key={i}
                    className={twMerge(
                      "table-hover-sort-container font-medium p-4 py-3",
                      column.orderable && "pr-0",
                      scrollable &&
                        " sticky top-0 bg-slate-50 dark:bg-slate-800 z-10 ",
                      column.thClassName || ""
                    )}
                  >
                    <div
                      className={twMerge(
                        "items-center flex text-blue-500 table-hover-sort-container",
                        column.headClassName || ""
                      )}
                    >
                      <Info
                        onClick={() => {
                          if (column.orderable) {
                            onChangeOrder &&
                              onChangeOrder(
                                i,
                                pagination?.order === "ASC" ? "DESC" : "ASC"
                              );
                          }
                        }}
                        className={
                          "uppercase " +
                          (column.orderable
                            ? "cursor-pointer hover:opacity-75 "
                            : "")
                        }
                        noColor={pagination?.orderBy === i}
                      >
                        {column.title}
                      </Info>
                      {column.orderable && (
                        <div className="w-4 flex items-center -mr-1">
                          {pagination?.orderBy === i &&
                            pagination.order === "DESC" && (
                              <ArrowSmallUpIcon className="h-4 w-4 text-blue-500 inline" />
                            )}
                          {pagination?.orderBy === i &&
                            pagination.order !== "DESC" && (
                              <ArrowSmallDownIcon className="h-4 w-4 text-blue-500 inline" />
                            )}
                          {pagination?.orderBy !== i && column.orderable && (
                            <ArrowSmallDownIcon className="table-hover-sort h-4 w-4 text-slate-500 opacity-50 inline" />
                          )}
                        </div>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
          )}
          <tbody className="overflow-hidden ">
            {data.length === 0 && (
              <tr>
                <td colSpan={columns.length + (onSelect ? 1 : 0)}>
                  <div
                    className={
                      " p-4 text-center" +
                      (scrollable
                        ? ""
                        : "bg-white dark:bg-slate-700 border rounded border-slate-200 dark:border-slate-600")
                    }
                  >
                    <Info>No data</Info>
                  </div>
                </td>
              </tr>
            )}
            {data.map((row, i) => {
              if (onSelect && !rowIndex)
                throw new Error(
                  "rowIndex is required when onSelect is defined"
                );
              const isSelected = selected
                .map((a) => (a as any)[rowIndex || "id"])
                .includes((row as any)[rowIndex || "id"]);
              return (
                <tr
                  key={i}
                  onClick={(e) => onClick && onClick(row, e as any)}
                  className={onClick ? "cursor-pointer hover:opacity-75" : ""}
                >
                  {onSelect && (
                    <td>
                      <Checkbox
                        className="mr-2"
                        value={isSelected}
                        onChange={(a, e) => {
                          //Code to manage shift click range
                          if (
                            (e.shiftKey || e.ctrlKey) &&
                            selected.length > 0
                          ) {
                            const anchor = selected[selected.length - 1];
                            let start = false;
                            const newSelection: T[] = [];
                            for (const d of data) {
                              if (
                                (d as any)[rowIndex || "id"] ===
                                  (anchor as any)[rowIndex || "id"] ||
                                (d as any)[rowIndex || "id"] ===
                                  (row as any)[rowIndex || "id"]
                              ) {
                                if (start) {
                                  newSelection.push(d);
                                  break;
                                }
                                if (!start) start = true;
                              }
                              if (start) {
                                newSelection.push(d);
                              }
                            }
                            setSelected(
                              _.uniqBy(
                                [
                                  ...selected.filter(
                                    (s) => !newSelection.includes(s)
                                  ),
                                  ...(a ? newSelection : []),
                                  anchor,
                                ],
                                (s) => (s as any)[rowIndex || "id"]
                              )
                            );
                          } else {
                            if (a) {
                              setSelected(
                                _.uniqBy(
                                  [...selected, row],
                                  (s) => (s as any)[rowIndex || "id"]
                                )
                              );
                            } else {
                              setSelected(selected.filter((s) => s !== row));
                            }
                          }
                        }}
                      />
                    </td>
                  )}
                  {columns.map((cell, j) => {
                    const jFirst = j === 0;
                    const jLast = j === columns.length - 1;
                    const iFirst = i === 0;
                    const iLast = i === data.length - 1;
                    return (
                      <td
                        key={j}
                        className={
                          "m-0 p-0 height-table-hack overflow-hidden " +
                          cell.thClassName
                        }
                      >
                        <div
                          className={twMerge(
                            "h-full w-full flex items-center border-t border-slate-200 dark:border-slate-600 py-1 px-3",
                            (i % 2
                              ? isSelected
                                ? "dark:bg-opacity-90 bg-opacity-90 "
                                : "dark:bg-opacity-25 bg-opacity-25 "
                              : "") +
                              ((!scrollable && jFirst && " border-l ") || "") +
                              ((!scrollable && jLast && " border-r ") || "") +
                              ((!scrollable && iLast && " border-b ") || "") +
                              ((!scrollable &&
                                iFirst &&
                                jFirst &&
                                " rounded-tl ") ||
                                "") +
                              ((!scrollable &&
                                iFirst &&
                                jLast &&
                                " rounded-tr ") ||
                                "") +
                              ((!scrollable &&
                                iLast &&
                                jFirst &&
                                " rounded-bl ") ||
                                "") +
                              ((!scrollable &&
                                iLast &&
                                jLast &&
                                " rounded-br ") ||
                                "") +
                              (isSelected
                                ? " bg-blue-200 dark:bg-blue-800 "
                                : " bg-white dark:bg-slate-700 "),
                            cell.className,
                            cellClassName && cellClassName(row)
                          )}
                        >
                          {cell.render(row)}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {showPagination && !!pagination && (
            <tfoot>
              <tr>
                <td
                  colSpan={columns.length + (onSelect ? 1 : 0)}
                  className={
                    "items-center " +
                    (scrollable
                      ? " sticky bottom-0 bg-slate-50 dark:bg-slate-800 z-10 "
                      : "")
                  }
                >
                  <div
                    className={
                      "w-full pl-2 py-2 text-slate-500 dark:text-slate-400 " +
                      (scrollable
                        ? "pr-2 border-t border-slate-200 dark:border-slate-600"
                        : "pr-0")
                    }
                  >
                    <TablePagination
                      pagination={pagination}
                      dataLength={data.length}
                      onChangePage={onChangePage}
                      onChangePageSize={
                        scrollable ? undefined : onChangePageSize
                      }
                      loading={loading}
                    />
                  </div>
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </SimpleBar>
    </div>
  );
}

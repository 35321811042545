import { useGlobalEffect } from "@features/utils";
import { LoadingAtom } from "@features/utils/loading";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { InboxTemplatesApiClient } from "../api-client/api-client";
import { InboxTemplateRequestType } from "../types";
import { InboxTemplatesAtom } from "./store";

export const useInboxTemplates = () => {
  const [inboxTemplates, setInboxTemplates] =
    useRecoilState(InboxTemplatesAtom);
  const [loading, setLoading] = useRecoilState(
    LoadingAtom("useInboxTemplates")
  );

  const refresh = useCallback(async () => {
    setLoading(true);
    setInboxTemplates(
      (await InboxTemplatesApiClient.getInboxTemplates()) ?? []
    );
    setLoading(false);
  }, [setInboxTemplates, setLoading]);

  const createInboxTemplate = async (request: InboxTemplateRequestType) => {
    try {
      await InboxTemplatesApiClient.createInboxTemplate(request);
      refresh();
    } catch (error) {
      console.log("error creating inbox template", error);
    }
  };

  const editInboxTemplate = async (
    id: string,
    request: InboxTemplateRequestType
  ) => {
    try {
      await InboxTemplatesApiClient.updateInboxTemplate(id, request);
      refresh();
    } catch (error) {
      console.log("error editing inbox template", error);
    }
  };

  const deleteInboxTemplate = async (id: string) => {
    try {
      await InboxTemplatesApiClient.deleteInboxTemplate(id);
      refresh();
    } catch (error) {
      console.log("error deleting inbox template", error);
    }
  };

  useGlobalEffect(
    "useInboxTemplates",
    () => {
      refresh();
    },
    []
  );

  return {
    templates: inboxTemplates,
    loading,
    refresh,
    createInboxTemplate,
    editInboxTemplate,
    deleteInboxTemplate,
  };
};

import Avatar from "@atoms/avatar/avatar";
import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { Base, InfoSmall, Section } from "@atoms/text";
import { useHasAccess } from "@features/auth/state/use-access";
import { usePressReport } from "@features/press/state/use-press-report";
import { PressReport } from "@features/press/types";
import {
  REPORT_EDD_STATUS_COLORS,
  REPORT_EDD_STATUS_FROM_UINT_BACKEND,
  REPORT_EDD_STATUS_TO_UINT_BACKEND,
  REPORT_RISK_LEVEL_COLORS,
} from "@features/press/utils";
import { formatTime } from "@features/utils/dates";
import { CodeBracketIcon, NewspaperIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import { useRecoilState } from "recoil";
import { ReportStatusAndRiskUpdateModalAtom } from "./status-risk-update-modal";

export const ReportTimeline = ({
  disabled,
  customer_id,
  revisions,
}: {
  disabled?: boolean;
  customer_id: string;
  revisions: PressReport[];
}) => {
  const [modal, setModal] = useRecoilState(ReportStatusAndRiskUpdateModalAtom);
  const hasAccess = useHasAccess();
  const { focusedReportBody, focusReportBody } = usePressReport(
    customer_id || ""
  );

  return (
    <>
      <Section className="mt-4 mb-4">
        Report history
        <div className="float-right">
          {hasAccess("PRESS_AGENT") && (
            <Button
              onClick={() =>
                setModal({
                  ...modal,
                  open: true,
                  customer_id: revisions[0].customer_id,
                  status:
                    REPORT_EDD_STATUS_FROM_UINT_BACKEND[revisions[0].edd_state],
                  active_risk_level: revisions[0].active_overall_risk_level,
                })
              }
              className="ml-4"
              theme="outlined"
              size="sm"
              disabled={disabled}
              shortcut={["u"]}
            >
              Update report
            </Button>
          )}
        </div>
      </Section>
      <ol className="relative border-l border-gray-200 dark:border-gray-700 ml-3">
        {_.sortBy(revisions, (d) => -new Date(d.created_at).getTime()).map(
          (revision, index) => (
            <li
              key={index}
              className={
                "mb-2 ml-6 transition-opacity " +
                (focusedReportBody?.report_body_id === revision.report_body_id
                  ? ""
                  : " opacity-75 hover:opacity-100")
              }
            >
              <span className="flex absolute -left-3 justify-center items-center w-6 h-6 bg-slate-500 rounded-full">
                {!revision.created_by_agent_name && (
                  <CodeBracketIcon className="h-4 w-4 text-white" />
                )}
                {!!revision.created_by_agent_name && (
                  <Avatar size={6} fallback={revision.created_by_agent_name} />
                )}
              </span>
              <div className="grid grid-cols-6 gap-0">
                <div className="col-span-3">
                  {revision.edd_state !==
                    REPORT_EDD_STATUS_TO_UINT_BACKEND["No_status"] && (
                    <Tag
                      noColor
                      className={
                        " text-white capitalize block bg-opacity-50 bg-" +
                        REPORT_EDD_STATUS_COLORS[
                          REPORT_EDD_STATUS_FROM_UINT_BACKEND[
                            revision.edd_state
                          ]
                        ]
                      }
                    >
                      {REPORT_EDD_STATUS_FROM_UINT_BACKEND[
                        revision.edd_state
                      ] || "Unknown"}
                    </Tag>
                  )}
                  <Tag
                    noColor
                    className={
                      " text-white capitalize block mx-1 bg-" +
                      REPORT_RISK_LEVEL_COLORS[
                        revision.active_overall_risk_level
                      ]
                    }
                  >
                    {revision.active_overall_risk_level || "Unknown"}
                  </Tag>
                  <Base className="whitespace-pre-wrap block">
                    {revision.comment}
                  </Base>
                  <InfoSmall className="block mt-1">
                    {revision.created_by_agent_name || "System"} •{" "}
                    {formatTime(revision.created_at)}
                  </InfoSmall>
                </div>
                <div className="col-span-3 flex justify-end items-start pt-0.5">
                  {(index === 0 ||
                    (index > 0 &&
                      revision.report_body_id !==
                        revisions[index - 1].report_body_id)) && (
                    <>
                      <Tag
                        noColor
                        className={
                          "text-white capitalize mr-2 bg-" +
                          REPORT_RISK_LEVEL_COLORS[
                            revision.robot_overall_risk_level
                          ]
                        }
                      >
                        {"Robot: " + revision.robot_overall_risk_level ||
                          "Unknown"}
                      </Tag>

                      <div
                        className={
                          "flex w-8 h-8 justify-center items-center rounded-full -mt-1" +
                          (focusedReportBody?.report_body_id ===
                          revision.report_body_id
                            ? " bg-blue-500 text-white"
                            : " border border-blue-500 text-blue-500 cursor-pointer")
                        }
                        onClick={() => {
                          focusReportBody(revision.report_body_id);
                        }}
                        data-tooltip="View press report revision"
                      >
                        <NewspaperIcon className="h-5 w-5"></NewspaperIcon>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </li>
          )
        )}
      </ol>
    </>
  );
};

const host = document.location.host.match(/[^.]{3,}.[^.]{2,5}$/)?.[0] || "algoreg.com";
const isNewScheme = !document.location.origin.includes(".backoffice.");
const context = (host === document.location.host || "backoffice."+host === document.location.host) ? "" : ((!isNewScheme ?
    ((document.location.origin || "").split(".")[0] || "").split("//")[1] :
  ((document.location.origin || "").split("." + host)[0] || "").split("//")[1].split(".").pop() ||
  "demo") + ".");

const Env = {
  server: isNewScheme ? ("https://api." + context + host) : ("https://" + context + "api." + host),
  vid: isNewScheme ? ("https://vid." + context + host) : ("https://" + context + "vid." + host),
  angular: document.location.origin,
  version: "1.0.0",
};

export default Env;

import { Checkbox } from "@atoms/input/input-checkbox";
import { InboxMessageCreateInput } from "@features/inbox/types";

export default function MessageReminder({
  message,
  onChange,
}: {
  message: Partial<InboxMessageCreateInput>;
  onChange: (message: Partial<InboxMessageCreateInput>) => void;
}) {
  return (
    <div>
      <Checkbox
        label="Keep thread open after sending message"
        value={
          message?.set_reminder !== null && message?.set_reminder !== undefined
        }
        onChange={(checked) => {
          onChange({
            ...message,
            set_reminder: checked
              ? {
                  delay: 0,
                  number_left: 0,
                }
              : null,
          });
        }}
      />
    </div>
  );
}

import { InboxTemplateType } from "@features/inbox-templates/types";
import { getBestLocaleFromList } from "@features/utils/strings";
import Twig from "twig";

export const INBOX_MESSAGE_REMINDER_DELAY = 1; // 1 days
export const INBOX_MESSAGE_REMINDER_NUMBER_LEFT = 3; // 2 reminders

export const INBOX_STATUS_LABELS = {
  1: "Open",
  2: "Closed",
  3: "Reminder",
  4: "Waiting",
};
export const INBOX_STATUS_COLORS = {
  1: "green-500",
  2: "red-500",
  3: "yellow-500",
  4: "yellow-500",
};

export const MESSAGE_DELIVERY_STATUS_LABELS = {
  1: "Draft",
  2: "Sent (outbox)",
  3: "Sent",
  4: "Inbox",
};
export const MESSAGE_DELIVERY_STATUS_COLORS = {
  1: "gray-500",
  2: "green-500",
  3: "green-500",
  4: "gray-500",
};

export const generateContent = async (
  context: any,
  template: InboxTemplateType,
  language?: string
) => {
  language = getBestLocaleFromList(
    language || "",
    Object.keys(template.subject),
    "en"
  );

  let content = template.content[language ?? "en"] ?? "";
  // transform @[variable](variable) to {{ variable }} with regex,
  const regex = /@\[(.*?)\]\((.*?)\)/g;
  content = content.replace(regex, "$1");

  // transform {{scenario.*}} to {{scenario.*|default("Unable to generate session")}} in case client doesn't have govid auth
  const regexScenario = /{{scenario(.*?)}}/g;
  content = content.replace(
    regexScenario,
    `{{scenario$1|default('{{Unable to generate session}}')}}`
  );

  const renderedTemplate = await Twig.twig({
    data: content,
  }).render(context, undefined, true);

  return renderedTemplate;
};

export const generateSubject = (
  context: any,
  template: InboxTemplateType,
  language?: string
) => {
  language = getBestLocaleFromList(
    language || "",
    Object.keys(template.subject),
    "en"
  );

  let subject = template.subject[language ?? "en"] ?? "";
  // transform @[variable](variable) to {{ variable }} with regex,
  const regex = /@\[(.*?)\]\((.*?)\)/g;
  subject = subject.replace(regex, "$1");
  const renderedTemplate = Twig.twig({
    data: subject,
  }).render(context);

  return renderedTemplate;
};

import { useFetch } from "@features/utils";
import { AlertsRequestOptions, AlertListType, AlertType } from "../types";

export class AlertsApiClient {
  static getAlerts = async (options: Partial<AlertsRequestOptions>) => {
    const response = await useFetch(`/web/alerts`, {
      method: "POST",
      body: JSON.stringify({
        categories: [],
        creation_date_from: null,
        creation_date_to: null,
        external_id: null,
        id: null,
        last_update_from: null,
        last_update_to: null,
        score_from: 0,
        score_to: 100,
        name: null,
        on_page: 1,
        order_by: "STATUS",
        order_way: "ASC",
        per_page: 10,
        states: [],
        ...options,
      }),
    });
    const data = await response.json();
    return data as { data: AlertListType[]; total: number };
  };

  static updateAlertStatus = async (
    id: string,
    status: number,
    comment: string
  ) => {
    await useFetch(`/web/casemanagement/modify-alert`, {
      method: "POST",
      body: JSON.stringify({
        alert_id: id,
        comment: comment,
        alert_state: status,
      }),
      formData: true,
    });
  };

  static getAlert = async (id: string) => {
    const response = await useFetch(`/web/alerts/${id}`, {
      method: "GET",
    });
    const data = await response.json();
    return data as AlertType;
  };

  static getAlertsCategories = async () => {
    const response = await useFetch(`/web/alert/categories`, {
      method: "GET",
    });
    const data = await response.json();
    return data as { [key: string]: string };
  };

  static getAlertsState = async () => {
    const response = await useFetch(`/web/alert/states`, {
      method: "GET",
    });
    const data = await response.json();
    return data as { [key: number]: string };
  };
}

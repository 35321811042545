import { Modal, ModalContent } from "@atoms/modal/modal";
import { Security } from "./security";

type ModalSecurityProps = {
  open: boolean;
  onClose: () => void;
};

export function ModalSecurity({ open, onClose }: ModalSecurityProps) {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalContent title="Security">
        <Security />
      </ModalContent>
    </Modal>
  );
}

import { Info, SectionSmall } from "@atoms/text";

import { Tag } from "@atoms/badge/tag";
import {
  extractCustomerFullName,
  RELATIONSHIP,
} from "@features/customers/utils";
import { BuildingOfficeIcon, UserIcon } from "@heroicons/react/24/outline";
import { useRecoilState } from "recoil";
import { CustomerGraphSelectionAtom } from ".";

export const RelationDetail = () => {
  const [selection] = useRecoilState(CustomerGraphSelectionAtom);

  if (selection.selectedRelations.length === 0) {
    return <></>;
  }

  const name = extractCustomerFullName(selection?.selectedCustomer as any);
  const isFirm = selection?.selectedCustomer?.account_type === 3;

  const row = selection.selectedRelations;
  const relNames = row.map((r) => {
    const name = RELATIONSHIP[r.relation_type] || "Unknown";
    const suffix =
      r.parent.customer?.id === selection.selectedCustomer?.id ? "" : " of";
    return name + suffix;
  });
  const relCustomer =
    row[0].child.customer?.id === selection?.selectedCustomer?.id
      ? row[0].parent.customer
      : row[0].child.customer;
  const relName = extractCustomerFullName(relCustomer);
  const relIsFirm = relCustomer?.account_type === 3;

  return (
    <div className="p-4">
      <SectionSmall className="flex flex-row items-center">
        <div
          className={
            "inline bg-" +
            (isFirm ? "blue" : "slate") +
            "-500 rounded-" +
            (isFirm ? "sm" : "full") +
            " w-5 h-5 mr-1 flex items-center justify-center text-white"
          }
        >
          {!isFirm && <UserIcon className="inline-block w-3 h-3" />}
          {isFirm && <BuildingOfficeIcon className="inline-block w-3 h-3" />}
        </div>
        {name}
        {" ↔️ "}
        <div
          className={
            "ml-1 inline bg-" +
            (relIsFirm ? "blue" : "slate") +
            "-500 rounded-" +
            (relIsFirm ? "sm" : "full") +
            " w-5 h-5 mr-1 flex items-center justify-center text-white"
          }
        >
          {!relIsFirm && <UserIcon className="inline-block w-3 h-3" />}
          {relIsFirm && <BuildingOfficeIcon className="inline-block w-3 h-3" />}
        </div>
        {relNames.map((relName, j) => (
          <Tag key={j} noColor className="bg-blue-200 dark:gb-blue-800 mr-1">
            {relName}
          </Tag>
        ))}
        {relName}
      </SectionSmall>
      <Info>No more details for this relation.</Info>
      {/* TODO retrieve details from the API when available */}
    </div>
  );
};

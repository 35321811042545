import { useFetch } from "@features/utils";
import { AssignmentType } from "../types";

export class AssignmentsApiClient {
  static getObjectAssignments = async (
    type:
      | "customer"
      | "alert"
      | "session"
      | "thread"
      | "transaction"
      | "pressreport",
    objectsIds: string[]
  ) => {
    if (!type || objectsIds?.length === 0) return [];
    const response = await useFetch(
      `/web/${
        type === "alert"
          ? "alerts"
          : type === "customer"
          ? "customers"
          : type === "session"
          ? "sessions"
          : type === "thread"
          ? "threads"
          : type === "transaction"
          ? "kyt/transactions"
          : type === "pressreport"
          ? "press"
          : type
      }/assignments?ids=${objectsIds.join(",")}`,
      {
        method: "GET",
      }
    );
    if (response.status === 200 || response.status === 204) {
      if (response.status === 204) return null; // no assignment
      const data = await response.json();
      return data as AssignmentType[];
    }

    console.error(
      `Unable to get ${type} assignment, response ${response.status}`
    );

    return [];
  };

  static getObjectAssignment = async (
    type:
      | "customer"
      | "alert"
      | "session"
      | "thread"
      | "transaction"
      | "pressreport",
    objectId: string
  ) => {
    if (!type || !objectId) return null;
    const response = await useFetch(
      `/web/${
        type === "alert"
          ? "alerts"
          : type === "transaction"
          ? "kyt/transaction"
          : type === "pressreport"
          ? "press"
          : type
      }/${objectId}/assignment`,
      {
        method: "GET",
      }
    );
    if (response.status === 200 || response.status === 204) {
      if (response.status === 204) return null; // no assignment
      const data = await response.json();
      return data as AssignmentType;
    }

    throw new Error(
      `Unable to get ${type} assignment, response ${response.status}`
    );
  };

  static setObjectAssignment = async (
    type:
      | "customer"
      | "alert"
      | "session"
      | "thread"
      | "transaction"
      | "pressreport",
    objectId: string,
    membersId: number[]
  ) => {
    if (!type) return null;

    const response = await useFetch(
      `/web/${
        type === "alert"
          ? "alerts"
          : type === "transaction"
          ? "kyt/transaction"
          : type === "pressreport"
          ? "press"
          : type
      }/${objectId}/assignment`,
      {
        method: "POST",
        body: JSON.stringify({
          member_ids: membersId,
        }),
      }
    );

    if (response.status === 200 || response.status === 204) {
      // good
      return;
    }

    throw new Error(
      `Unable to set ${type} assignment, response ${response.status}`
    );
  };
}

import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { BaseSmall, InfoSmall } from "@atoms/text";
import { Table } from "@components/table";
import { Column } from "@components/table/table";
import { GraphQueryAtom } from "@features/kyt/state/store";
import useExploreGraph from "@features/kyt/state/use-explore-graph";
import { KytTransaction } from "@features/kyt/types";
import {
  KYT_TRANSACTION_STATES_COLORS,
  KYT_TRANSACTION_STATES_LABELS,
} from "@features/kyt/utils";
import { ROUTES } from "@features/routes";
import { formatTime } from "@features/utils/dates";
import { formatAmount } from "@features/utils/strings";
import { BuildingOfficeIcon, UserIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { useSetRecoilState } from "recoil";

export default function ExploreTransactions() {
  const { transactionsSQL, loadingSQL: loading } = useExploreGraph();
  const setGraphQuery = useSetRecoilState(GraphQueryAtom);

  function _getColumns(): Column<KytTransaction>[] {
    return [
      // {
      //   title: "#",
      //   className: "overflow-hidden text-ellipsis",
      //   render: (row) => <Info>{row.external_id}</Info>,
      // },
      {
        title: "Status",
        render: (row) => (
          <div className="w-16 flex items-center flex-row capitalize whitespace-nowrap">
            <Tag
              noColor
              className={
                "text-xs  my-1 text-white bg-" +
                (KYT_TRANSACTION_STATES_COLORS[row.state ? 0 : row.state] ||
                  "slate-500")
              }
            >
              {KYT_TRANSACTION_STATES_LABELS[row.state ? 0 : row.state]}
            </Tag>
          </div>
        ),
      },
      {
        title: "Date",
        render: (row) => (
          <InfoSmall className="w-16">
            {formatTime(row.date, undefined, {
              keepDate: true,
              keepTime: true,
            })}
          </InfoSmall>
        ),
      },
      {
        title: "Amount",
        thClassName: "w-40",
        render: (row) => (
          <div className="flex flex-col items-end w-full font-mono">
            <BaseSmall>
              {formatAmount(row.amount, row.currency)}{" "}
              <InfoSmall>{row.currency}</InfoSmall>
            </BaseSmall>
            {row.converted_currency !== row.currency && (
              <InfoSmall>
                ~ {formatAmount(row.converted_amount, row.converted_currency)}
                <InfoSmall className="ml-2">{row.converted_currency}</InfoSmall>
              </InfoSmall>
            )}
          </div>
        ),
      },
      {
        title: "From",
        render: (row) => (
          <div className="w-36 flex items-center">
            {row.from.account_type === "F" ? (
              <BuildingOfficeIcon className="h-4 w-4 inline mr-2 shrink-0" />
            ) : (
              <UserIcon className="h-4 w-4 inline mr-2 shrink-0" />
            )}
            <BaseSmall>{row.from.full_name}</BaseSmall>
          </div>
        ),
      },
      {
        title: "To",
        render: (row) => (
          <div className="w-36 flex items-center">
            {row.to.account_type === "F" ? (
              <BuildingOfficeIcon className="h-4 w-4 inline mr-2 shrink-0" />
            ) : (
              <UserIcon className="h-4 w-4 inline mr-2 shrink-0" />
            )}
            <BaseSmall>{row.to.full_name}</BaseSmall>
          </div>
        ),
      },

      {
        title: "Actions",
        className: "text-right",
        headClassName: "justify-end",
        thClassName: "w-24",
        render: (row) => (
          <div className="flex flex-row space-x-2">
            <Link to={ROUTES.TransactionView.replace(":id", row.id)}>
              <Button size="sm" className="text-xs" theme="outlined">
                View
              </Button>
            </Link>
            <Button
              size="sm"
              className="text-xs"
              theme="secondary"
              onClick={() => {
                const cypherTransactionQuery = `MATCH (a)-[e:TRANSACTION_ALL]->(b) WHERE a.node_id = "${row.from.external_id}" AND b.node_id = "${row.to.external_id}" RETURN a, b ,e`;
                setGraphQuery(cypherTransactionQuery);
              }}
            >
              Graph
            </Button>
          </div>
        ),
      },
    ];
  }

  return (
    <Table
      className="w-full h-full max-h-full overflow-y-auto"
      rowIndex="external_id"
      total={transactionsSQL?.total ?? 0}
      data={transactionsSQL?.transactions ?? []}
      loading={loading}
      scrollable
      columns={_getColumns()}
    />
  );
}

import { FIELD_TYPES } from "@features/custom-fields/enum";
import { useCustomFields } from "@features/custom-fields/state/use-custom-fields";
import { LoadingAtom } from "@features/utils/loading";
import { useMonaco } from "@monaco-editor/react";
import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import { RiskResourceType } from "./types";
import _ from "lodash";
import { getTransactionFields } from "@components/risks/nodes/components/field-selector";

export type MonacoEditor = typeof import("monaco-editor");

export const relationsAggregate = [
  "none",
  "and",
  "or",
  "sum",
  "average",
  "max",
  "min",
];

let id = 0;

export const useFormulaEditor = (
  type: RiskResourceType,
  withRelations: boolean
) => {
  const monaco = useMonaco();
  const [loading, setLoading] = useRecoilState(LoadingAtom("useFormulaEditor"));
  const { fields, loading: loadingFields } = useCustomFields();

  const customerFields = fields.filter(
    (f) =>
      f.field_type === FIELD_TYPES.NUMBER && ![5, 6].includes(f.field_source)
  );

  const customerRelationsFields = fields.filter(
    (f) =>
      f.field_type === FIELD_TYPES.NUMBER && [5, 6].includes(f.field_source)
  );

  const initEditorConfig = useCallback(() => {
    id++;

    // Monaco Custom Language Configuration
    monaco!.languages.register({ id: "algoreg" + type + id });
    _configureMonacoLanguage(
      monaco!,
      type,
      id,
      withRelations,
      customerFields.map((f) => f.label),
      customerRelationsFields.map((f) => f.label),
      relationsAggregate
    );

    // Monaco Custom Language Theme;
    _configureMonacoTheme(monaco!, type, id);
    monaco!.editor.setTheme("algoregtheme" + type + id);

    // Monaco Custom Language AutoCompletion
    _configureMonacoAutocompletion(
      monaco!,
      type,
      id,
      withRelations,
      customerFields.map((f) => f.label),
      customerRelationsFields.map((f) => f.label),
      relationsAggregate
    );
  }, [monaco]);

  useEffect(() => {
    setLoading(true);
    if (monaco && !loadingFields) {
      initEditorConfig();
      setLoading(false);
    }
  }, [monaco, fields?.length]);

  return { loading, monaco, id };
};

function _configureMonacoLanguage(
  monaco: MonacoEditor,
  type: RiskResourceType,
  id: number,
  withRelations: boolean,
  customerFields: string[],
  customerRelationsFields: string[],
  relationsAggregate: string[]
) {
  monaco.languages.setLanguageConfiguration("algoreg" + type + id, {
    wordPattern: /[a-z_$.][.\w$]*/,
    autoClosingPairs: [{ open: "(", close: ")" }],
  });

  // configure custom algoreg language
  monaco.languages.setMonarchTokensProvider("algoreg" + type + id, {
    keywords: getKeywords(
      type,
      withRelations,
      customerFields,
      customerRelationsFields,
      relationsAggregate
    ),
    tokenizer: {
      root: [
        // order matters
        // aggregation_operators_simple
        [/[-+*/]/, "operators"],
        // aggregation_operators_methods
        [new RegExp("\\b(" + getFunctions().join("|") + ")\\b"), "functions"],
        // constants
        [/\b\d+\b/, "constants"],
        // entities
        // identifiers and keywords
        [
          /[a-z_$.][.\w$]*/,
          {
            cases: {
              "@keywords": "keyword",
              "@default": "invalid",
            },
          },
        ],
      ],
    },
  });
}

function _configureMonacoTheme(
  monaco: MonacoEditor,
  type: RiskResourceType,
  id: number
) {
  monaco.editor.defineTheme("algoregtheme" + type + id, {
    base: "vs",
    inherit: true,
    rules: [
      { token: "operators", foreground: "#0DB94F" },
      {
        token: "functions",
        foreground: "#F783B9",
        fontStyle: "italic",
      },
      { token: "constants", foreground: "#0000FF" },
      { token: "keyword", foreground: "#2F6AB8", fontStyle: "bold" },
      {
        token: "invalid",
        foreground: "#FF0000",
      },
    ],
    colors: {},
  });
}

function _configureMonacoAutocompletion(
  monaco: MonacoEditor,
  type: RiskResourceType,
  id: number,
  withRelations: boolean,
  customerFields: string[],
  customerRelationsFields: string[],
  relationsAggregate: string[]
) {
  // configure code completion
  return monaco.languages.registerCompletionItemProvider(
    "algoreg" + type + id,
    {
      provideCompletionItems: function (model: any, position: any) {
        const word = model.getWordUntilPosition(position);
        const range = {
          startLineNumber: position.lineNumber,
          endLineNumber: position.lineNumber,
          startColumn: word.startColumn,
          endColumn: word.endColumn,
        };

        const keywords = getKeywords(
          type,
          withRelations,
          customerFields,
          customerRelationsFields,
          relationsAggregate
        );

        return {
          suggestions: keywords.map((keyword) => ({
            label: keyword,
            kind: monaco.languages.CompletionItemKind.Keyword,
            documentation: `${keyword}`,
            insertText: keyword,
            range: range,
          })),
        };
      },
    }
  );
}

const getFunctions = () => ["average", "max", "min"];

// Generate all entities callable in the formula editor
// rolling / monthly / yearly
// from / to / from_institution / to_institution // customer
// customer_relations
// transaction
const getKeywords = (
  type: RiskResourceType,
  withRelations: boolean,
  customerFields: string[],
  customerRelationsFields: string[],
  relationsAggregate: string[]
) => {
  return [
    // Functions
    ...getFunctions(),
    // Entities
    ...(type === "kyt"
      ? [
          ...Object.keys(getTransactionFields())
            .filter((a) => (getTransactionFields() as any)[a] !== "text")
            .map((a) => "transaction." + a),
        ]
      : []),
    ..._.flatten(
      (type === "kyt"
        ? ["from", "to", "from_institution", "to_institution"]
        : ["customer"]
      ).map((source) => [
        ...customerFields.map((a) => source + "." + a),
        ...getKytKeys().map((a) => source + "." + a),
      ])
    ),
    ...(withRelations
      ? [
          ...customerRelationsFields.map((a) => "customer_relations." + a),
          ...customerRelationsFields
            .map((a) => [
              ...relationsAggregate.map(
                (b) => "customer_relations." + a + "." + b
              ),
            ])
            .reduce((a, b) => a.concat(b), []),
        ]
      : []),
  ];
};

const getKytKeys = () =>
  _.flattenDeep(
    ["kyt.rolling", "kyt.month", "kyt.year"].map((a) =>
      (a === "kyt.rolling"
        ? Array.from(Array(18)).map((_, i) => a + "." + (i + 1) + "m")
        : a === "kyt.month"
        ? Array.from(Array(18)).map((_, i) => a + "." + i + "")
        : [a + ".0"]
      ).map((a) =>
        ["all"] //, "card", "bank", "crypto", "exchange"]
          .map((b) => a + "." + b)
          .map((a) =>
            ["all", "in", "out"]
              .map((b) => a + "." + b)
              .map((a) =>
                ["all"] //, "accepted", "rejected", "pending"]
                  .map((b) => a + "." + b)
                  .map((a) =>
                    ["sum", "max", "min", "count"].map((b) => a + "." + b)
                  )
              )
          )
      )
    )
  );

export const AuthJWT: {
  token: string | null;
  refresh: string | null;
  clientId: number | null;
  empty: () => void;
} = {
  token: null,
  refresh: null,
  clientId: null,
  empty: () => {
    AuthJWT.token = null;
    AuthJWT.refresh = null;
    AuthJWT.clientId = null;
  },
};

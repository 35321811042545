interface CronParts {
  second: string;
  minute: string;
  hour: string;
  dayOfMonth: string;
  month: string;
  dayOfWeek: string;
}

export const parseCronExpression = (
  cronExpression: string
): CronParts | null => {
  const cronParts: CronParts = {
    second: "",
    minute: "",
    hour: "",
    dayOfMonth: "",
    month: "",
    dayOfWeek: "",
  };

  const cronSegments = cronExpression.split(" ");

  if (cronSegments.length !== 6) {
    console.error("Invalid cron expression format");
    return null;
  }

  cronParts.second = cronSegments[0];
  cronParts.minute = cronSegments[1];
  cronParts.hour = cronSegments[2];
  cronParts.dayOfMonth = cronSegments[3];
  cronParts.month = cronSegments[4];
  cronParts.dayOfWeek = cronSegments[5];

  return cronParts;
};

import Avatar from "@atoms/avatar/avatar";
import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import InputCopiable from "@atoms/input/input-copiable";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { SideModal } from "@atoms/modal/side-modal";
import { Info, Section } from "@atoms/text";
import { RolesManagement } from "@components/roles-manager";
import { ApiAgentsRolesCodes } from "@features/agents/enum";
import { useAgent, useAgents } from "@features/agents/state/use-agents";
import { useAuth } from "@features/auth/state/hooks";
import { validateEmail } from "@features/utils/strings";
import _ from "lodash";
import { useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";

export const DeveloperEditionModalAtom = atom<{
  open: boolean;
  id: number | null;
}>({
  key: "DeveloperEditionModalAtom",
  default: {
    open: false,
    id: null,
  },
});

export const DeveloperEditionModal = () => {
  const [state, setModalState] = useRecoilState(DeveloperEditionModalAtom);
  const [secretModal, setSecretModal] = useState("");
  const [loading, setLoading] = useState(false);
  const { create, setRoles, refresh } = useAgents();
  const { agent, disable, resetPassword } = useAgent(state.id || 0);
  const { user, clientId } = useAuth();

  const [form, setForm] = useState<{
    name: string;
    identifier: string;
    roles: string[];
  }>({
    name: agent?.name || "",
    identifier: agent?.email_login || "",
    roles:
      agent?.clients
        ?.find((c) => c.id === clientId)
        ?.roles.map((a) => a.code) || [],
  });

  const name = form.name || agent?.name || "";
  const identifier = form.identifier || agent?.email_login || "";
  const roles =
    (form.roles.length && form.roles) ||
    agent?.clients?.find((c) => c.id === clientId)?.roles.map((a) => a.code) ||
    [];

  //Reset everything when closing the modal
  useEffect(() => {
    if (!state.open) {
      setForm({
        name: "",
        identifier: "",
        roles: [],
      });
    }
  }, [state.open]);

  return (
    <>
      <Modal
        open={!!secretModal && state.open}
        onClose={() => {
          setSecretModal("");
          refresh();
          if (!agent?.id) {
            setModalState({ id: null, open: false });
          }
        }}
      >
        <ModalContent title="API Secret">
          <div className="mb-2">
            <InputCopiable value={secretModal} readOnly />
          </div>
          <Info>
            We generated a new secret. Please copy it and save it, this is the
            last time we will display it.
          </Info>
        </ModalContent>
      </Modal>
      <SideModal
        open={state.open}
        onClose={() => setModalState({ ...state, open: false })}
      >
        <ModalContent
          title={
            !state.id ? (
              "Create user"
            ) : (
              <div className="flex items-center">
                <div className="inline-block w-8 mr-2">
                  <Avatar
                    fallback={agent?.name}
                    avatar={agent?.avatar}
                    size={8}
                    className="h-8 w-8"
                  />
                </div>{" "}
                {agent?.name}
              </div>
            )
          }
        >
          <div className="max-w-3xl w-screen" />
          <Section>Key name and identifier</Section>
          <InputLabel
            label="Key name"
            input={
              <Input
                autoFocus
                disabled={!!agent?.id}
                placeholder="API Prod"
                value={name}
                onChange={(e) => setForm({ ...form, name: e.target.value })}
              />
            }
          />
          <InputLabel
            className="mt-3"
            label="Email format identifier"
            feedback={
              form?.identifier && !validateEmail(identifier)
                ? "Invalid identifier"
                : ""
            }
            hasError={!validateEmail(identifier)}
            input={
              <Input
                disabled={!!agent?.id}
                placeholder="api_prod@mi5.gov.uk"
                value={identifier}
                onChange={(e) =>
                  setForm({ ...form, identifier: e.target.value })
                }
              />
            }
          />
          <br />
          <Section>Access management</Section>

          <RolesManagement
            availableRoles={ApiAgentsRolesCodes}
            roles={roles}
            onChange={async (roles: string[]) => {
              if (roles.length > 0) {
                setForm({ ...form, roles: roles });
              }
            }}
            readOnly={agent?.id === user?.id}
          />

          <br />

          <Button
            disabled={
              !validateEmail(identifier) ||
              !name ||
              roles?.length === 0 ||
              _.isEqual(
                roles,
                agent?.clients
                  ?.find((c) => c.id === clientId)
                  ?.roles.map((a) => a.code)
              )
            }
            loading={loading}
            className="my-1 block"
            theme="primary"
            onClick={async () => {
              setLoading(true);
              try {
                let id = agent?.clients?.find(
                  (c) => c.id === clientId
                )?.agent_id;
                if (!id) {
                  const { password, agent_id } = await create(identifier, name);
                  id = agent_id;
                  if (password) setSecretModal(password);
                }
                await setRoles(id!, roles);
              } catch (e) {
                console.log(e);
              }
              setLoading(false);
            }}
          >
            {!agent?.id ? "Create API key" : "Save changes"}
          </Button>

          <br />

          {!!agent?.id && (
            <>
              <br />
              <Section>Operations</Section>

              <ButtonConfirm
                className="my-1 block"
                theme="default"
                onClick={async () => {
                  const { password } = await resetPassword();
                  if (secretModal) setSecretModal(password);
                }}
              >
                Regenerate secret
              </ButtonConfirm>
              <br />

              {agent?.id !== user?.id && (
                <ButtonConfirm
                  className="my-1 block"
                  theme="danger"
                  onClick={async () => {
                    await disable();
                    setModalState({ ...state, open: false });
                  }}
                >
                  Delete key
                </ButtonConfirm>
              )}
            </>
          )}
        </ModalContent>
      </SideModal>
    </>
  );
};

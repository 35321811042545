import { Tag } from "@atoms/badge/tag";
import { Loader } from "@atoms/loader";
import { Info } from "@atoms/text";
import { CustomerAllDetailType } from "@features/customers/types";
import { usePressReport } from "@features/press/state/use-press-report";
import { REPORT_RISK_LEVEL_COLORS } from "@features/press/utils";
import { useControlledEffect } from "@features/utils";
import { formatTime } from "@features/utils/dates";

export const PressReviewContent = ({
  customer,
}: {
  customer: CustomerAllDetailType;
}) => {
  const { report, loading, refresh, focusedReportBody, focusReportBody } =
    usePressReport(customer.details.customer.id);

  useControlledEffect(() => {
    refresh();
  }, []);

  useControlledEffect(() => {
    if (report?.latest_revision?.report_body_id) {
      focusReportBody(report.latest_revision.report_body_id);
    }
  }, [report]);

  return (
    <>
      {loading && <Loader />}
      {!loading && !report?.latest_revision?.report_body_id && (
        <>
          <Tag
            noColor
            className={
              "text-white capitalize block !py-1 !px-3 bg-" +
              REPORT_RISK_LEVEL_COLORS["none"]
            }
          >
            Never checked
          </Tag>
          <Info className="block mt-2">
            No press report found for this customer.
          </Info>
        </>
      )}
      {!loading && report?.latest_revision?.report_body_id && (
        <>
          {report?.latest_revision?.active_overall_risk_level && (
            <Tag
              noColor
              className={
                " text-white capitalize block mr-1 !py-1 !px-3 bg-" +
                REPORT_RISK_LEVEL_COLORS[
                  report?.latest_revision?.active_overall_risk_level || "none"
                ]
              }
            >
              {report?.latest_revision?.active_overall_risk_level || "Unknown"}
            </Tag>
          )}

          {!report?.latest_revision?.active_overall_risk_level && (
            <Tag
              noColor
              className={
                " text-white capitalize block mr-1 bg-" +
                REPORT_RISK_LEVEL_COLORS[
                  report?.latest_revision?.robot_overall_risk_level || "none"
                ]
              }
            >
              {report?.latest_revision?.robot_overall_risk_level || "Unknown"}{" "}
              to be confirmed by an agent
            </Tag>
          )}

          <Info className="block mt-2 space-x-1">
            {focusedReportBody?.report_categories
              ?.filter((a) => !a.reverted)
              ?.map((a) => (
                <Tag
                  key={a.category}
                  noColor
                  className={
                    " text-white capitalize block mr-1 bg-" +
                    REPORT_RISK_LEVEL_COLORS[a.severity || "low"]
                  }
                >
                  {a.category}
                </Tag>
              ))}
          </Info>

          <Info className="block mt-2">
            {report?.latest_revision.active_overall_risk_level && (
              <>
                Press risk level confirmed by{" "}
                {report?.latest_revision.created_by_agent_name},{" "}
                {formatTime(report?.latest_revision.created_at)}.
              </>
            )}
          </Info>
        </>
      )}
    </>
  );
};

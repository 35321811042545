import { SessionControlType } from "@features/sessions/types";
import { Control } from "./control";
import { getFirstNodes } from "@features/sessions/utils";

type ControlGroup = {
  key: string;
  title: string;
};

type ControlsProps = {
  controls: SessionControlType[];
  groups?: ControlGroup[];
};

export function Controls({ controls, groups }: ControlsProps) {
  let firstNodes: ControlGroup[] = [];
  if (groups) {
    firstNodes = groups;
  } else {
    const nodes = getFirstNodes(controls);
    firstNodes = nodes.map((node) => ({
      key: node.identifier,
      title: node.titleMsg,
    }));
  }

  return (
    <div className="flex flex-wrap gap-4">
      {firstNodes
        .filter(
          (group) =>
            controls.filter((control) => control.identifier === group.key)
              .length > 0
        )
        .map((group) => (
          <Control
            key={group.key}
            result={
              controls.find((control) => control.identifier === group.key)!
                .result
            }
            title={group.title}
            controls={controls.filter(
              (control) =>
                control.identifier.startsWith(group.key) &&
                control.identifier !== group.key
            )}
          />
        ))}
    </div>
  );
}

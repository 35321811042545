import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Info } from "@atoms/text";
import { useMessagesLabels } from "@features/messages-labels/state/use-messages-labels";
import { useControlledEffect } from "@features/utils";
import { uniqBy } from "lodash";
import { useState } from "react";
import CustomerSearchInput from "../../../customer-search-input";

export const ThreadInit = ({
  customers,
  tags,
  onChange,
}: {
  customers?: string[];
  tags?: string[];
  onChange: (o: { tags: string[]; customers: string[] }) => void;
}) => {
  const { labels: allTags } = useMessagesLabels();
  const [newThreadTags, setNewThreadTags] = useState<string[]>(tags ?? []);
  const [newThreadCustomers, setNewThreadCustomers] = useState<string[]>(
    customers ?? []
  );

  useControlledEffect(() => {
    onChange({
      tags: newThreadTags,
      customers: newThreadCustomers,
    });
  }, [newThreadTags, newThreadCustomers]);

  return (
    <>
      <InputLabel
        label="Customers in this thread"
        input={
          <CustomerSearchInput
            placeholder="Search customers by name or id..."
            value={newThreadCustomers}
            onCustomersChange={(customers) => {
              setNewThreadCustomers(customers);
            }}
          />
        }
      />
      <div>
        <Info>
          Select at least one customer you want to include in this discussion.
        </Info>
      </div>

      <InputLabel
        className="mt-4"
        label="Thread tags"
        input={
          <SelectMultiple
            options={uniqBy(
              (allTags ?? []).map((a) => ({
                label: a.name,
                value: a.code,
              })),
              "value"
            )}
            value={newThreadTags ?? []}
            onChange={(tags) => {
              setNewThreadTags(tags);
            }}
          />
        }
      />
    </>
  );
};

import { useAuth } from "@features/auth/state/hooks";
import { delayRequest, useGlobalEffect } from "@features/utils";
import { LoadingAtom } from "@features/utils/loading";
import { websocketBus } from "@features/websockets";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { InboxApiClient } from "../api-client/api-client";
import {
  InboxThread,
  InboxThreadCreateInput,
  InboxThreadStatus,
} from "../types";
import { ThreadAtom } from "./store";
import { useThreadMessages } from "./use-thread-messages";
import _ from "lodash";

export const useThread = (id: string) => {
  const { refresh: refreshHistory } = useThreadMessages(id);
  const [thread, setThread] = useRecoilState(ThreadAtom(id));
  const [loading, setLoading] = useRecoilState(LoadingAtom("useThread+" + id));

  const refresh = useCallback(
    async (thread?: InboxThread) => {
      if (!id) return;
      setLoading(true);
      delayRequest("useThreadsRefresh", async () => {
        setThread(thread || (await InboxApiClient.getThread(id)));
        await refreshHistory();
        setLoading(false);
      });
    },
    [setThread, setLoading, id, refreshHistory]
  );

  const update = useCallback(
    async (threadId: string, input: Partial<InboxThreadCreateInput>) => {
      if (!id) return;
      const result = await InboxApiClient.editThread(
        threadId,
        input as InboxThreadCreateInput
      );
      await refresh(result);
    },
    [refresh, id]
  );

  const close = useCallback(
    async (thread: InboxThread) => {
      await update(thread.id, { status: InboxThreadStatus.CLOSED });
    },
    [update]
  );

  const open = useCallback(
    async (thread: InboxThread) => {
      await update(thread.id, { status: InboxThreadStatus.OPEN });
    },
    [update]
  );

  const comment = useCallback(
    async (thread: InboxThread, comment: string) => {
      await update(thread.id, { comment });
    },
    [update]
  );

  useGlobalEffect(
    "useThread+" + id,
    () => {
      if (!thread) {
        refresh();
      }
    },
    []
  );

  return { thread, loading, refresh, update, close, open, comment };
};

export const useRealtimeThread = (id: string) => {
  const { clientId } = useAuth();
  const { refresh } = useThreadMessages(id);

  const debounceRefresh = useCallback(() => {
    _.debounce(() => {
      refresh();
    }, 2000)();
  }, [refresh]);

  useGlobalEffect(
    `useRealtimeThread+${id}`,
    () => {
      if (clientId) {
        const room = `client/${clientId}/threads`;
        websocketBus.on(room, (event: any) => {
          if (event.data.thread_id === id) debounceRefresh();
        });
        const room2 = `client/${clientId}/assignment`;
        websocketBus.on(room2, (event: any) => {
          if (event.data.id === id) debounceRefresh();
        });
        const room3 = `client/${clientId}/threads/${id}/messages`;
        websocketBus.on(room3, (event: any) => {
          if (event.data.thread_id === id) debounceRefresh();
        });
      }
    },
    [clientId]
  );
};

// We can use numbers for that: 1: open, 2: closed, 3: reminder, 4: waiting
export enum InboxThreadStatus {
  OPEN = 1,
  CLOSED,
  REMINDER,
  WAITING,
}

export enum MessageDeliveryStatus {
  DRAFT = 1,
  READY_TO_BE_SENT,
  SENT,
  RECEIVED,
}

export enum MessageOrigin {
  APIWEB = "apiweb",
  APIPUBLIC = "apipublic",
  APIENDUSER = "apienduser",
  IMAP = "imap",
}

export type InboxThread = {
  id: string;
  client_id: string;
  created_at: string;
  updated_at: string;

  external_messages_ids: string[]; //List of messages ids we saw in this thread (to attach message to thread automatically)
  customers: ThreadRelatedCustomer[]; //List of customers in the json format "id-1;id-2"
  tags: string[]; //Format "tag-code-1;tag-code-2" etc it is a custom list of tags like for documents
  status: InboxThreadStatus;

  contacts: string[]; //Computed automatically, separated by "," and in the format "AAA BBB <some.email@server.com>, ..."
  review_groups: string[] | null;

  reminder: {
    delay: number | null;
    next_date: string | null;
    number_left: number | null;
  } | null;

  attachments: InboxMessageAttachment[];
  message_count: number;
  last_message: InboxMessage | null;
};

export type InboxThreadRevision = {
  id: string;
  thread_id: string;
  revision_number: string;
  created_at: string;

  agent_id: string;

  customers: []; //List of customers in the json format "id-1;id-2"
  tags: []; //Format "tag-code-1;tag-code-2" etc it is a custom list of tags like for documents

  comment: string;
  status: InboxThreadStatus;
  reminder: string | null; //We will use this format: "delay;number_left;next_date" with:
  // - delay in days
  // - Number of reminder still to go, if set to 0 status go to "waiting" and then after the delay go to "open"
  // - Next sending message date
};

// InboxThread List Request Options
export type InboxThreadRequestOptions = {
  content: string | null;

  id: string | null;
  customer_id: string | null;
  contact: string | null;
  review_groups: string[] | null;
  assigned_to_member_id: number | null;
  tag_codes: string[];

  only_without_assignment: boolean;
  only_without_review_groups: boolean;

  max_created_at: string | null;
  max_updated_at: string | null;
  min_created_at: string | null;
  min_updated_at: string | null;

  status: InboxThreadStatus;

  on_page: number;
  per_page: number | null;
};

export type InboxThreadCreateInput = {
  comment?: string;
  customer_ids: string[];
  reminder: {
    delay?: number | null;
    next_date?: string | null;
    number_left?: number | null;
  } | null;
  status: InboxThreadStatus;
  tag_codes: string[];
};

export type InboxMessage = {
  id: string;
  created_at: string;
  agent_id: number | null;
  read_by_agents: number[]; //List of agents ids who read this message
  mailbox_id: string;

  external_message_id: string; //An id generated by the API or the mail sender / receiver, it looks like <33AD0CE9-1AD9-41EA-B8FC-38AF891C7054@algoreg.com>
  reply_to_external_message_id: string | null; //An id generated by the API or the mail, it looks like <33AD0CE9-1AD9-41EA-B8FC-38AF891C7054@algoreg.com>
  to: string; //Separated by "," and in the format "AAA BBB <some.email@server.com>, ..."
  from: string; //Separated by "," and in the format "AAA BBB <some.email@server.com>, ..."
  cc: string; //Separated by "," and in the format "AAA BBB <some.email@server.com>, ..."
  bcc: string; //Separated by "," and in the format "AAA BBB <some.email@server.com>, ..."
  subject: string;
  priority: number;
  content: string; //This content is plain text

  raw_message: string; //Contains the original message in raw format (could be json, or raw mail)

  delivery: MessageDeliveryStatus; //1: draft, 2: ready to be sent, 3: sent
  //Only this field can be updated by an agent from 1 to 2

  attachments: InboxMessageAttachment[];
  origin: MessageOrigin;
};

export type ThreadHistoryItemType = {
  agent_id: number | null;
  comment: string | null;
  customers: ThreadRelatedCustomer[];
  reminder: {
    delay: number | null;
    next_date: string | null;
    number_left: number | null;
  } | null;
  revision_number: number;
  status: InboxThreadStatus;
  tags: string[] | null;
  created_at: string;
};

export type InboxMessageCreateInput = {
  mailbox_id: string;
  thread_id: string;
  external_msg_id: string;
  reply_to_external_msg_id: string;
  delivery: MessageDeliveryStatus;
  subject: string;
  priority: number;
  from: string;
  to: string;
  cc: string;
  bcc: string;
  content: string;
  existing_attachments?: string[];
  new_attachments?: InboxMessageAttachmentCreate[];
  attachments?: InboxMessageAttachmentCreate[];
  set_reminder: {
    delay: number;
    number_left: number;
  } | null;
  read?: boolean;
};

export type InboxMessageAttachmentCreate = {
  id?: string;
  file: string;
  name: string;
};

export type InboxMessageAttachment = {
  id: string;
  file_not_dangerous: boolean;
  file_mime: string;
  file_type: string;
  file_name: string;
  file_size: number;
  attachment_movements?: InboxMessageAttachmentMovements;
};

export type InboxMessageAttachmentMovements = {
  movements?: InboxAttachmentMovedTo[];
  total: number;
};

export type InboxAttachmentMovedTo = {
  customer_id: string;
  file_id: string;
  agent_id: number | null;
};

export type InboxMessageTemplate = {
  id: string;
  client_id: string;
  agent_id: string;
  enabled: boolean;
  use_as_signature: boolean;
  name: string;
  content: string;
};

export type InboxMessageTag = {
  id: string;
  client_id: string;
  code: string;
  name: string;
  disabled: boolean;
  is_default: boolean;
};

export type ThreadCreateModalType = {
  new_thread: InboxThreadCreateInput;
  first_message: InboxMessageCreateInput;
};

export type ThreadRelatedCustomer = {
  id: string;
  external_id: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  language: string;
  nationality_code: string;
  domicile_code: string;
  company_name: string;
  trading_name: string;
  account_type: number;
  review_groups: string[] | null;
};

export type InboxUploadAttachmentToCustomersDocumentInput = {
  attachment_id: string;
  customer_ids: string[];
};

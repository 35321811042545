import _ from "lodash";
import { useState } from "react";
import { useQuery } from "react-query";
import { KytApiClient } from "../api-client/api-client";
import { KytGraph, KytGraphRelation } from "../types";

export const useRelationsQuery = () => {
  const [nodeId, setNodeId] = useState<string>();

  const fetchGraphRelationElements = async () => {
    if (!nodeId) return [];
    const cypherRelationsQuery = `MATCH (a)-[e:TRANSACTION_ALL]->(b) WHERE a.node_id = "${nodeId}" OR b.node_id = "${nodeId}" RETURN a, b ,e`;
    const queryGraph = await KytApiClient.getTransactionsCypherQuery(
      cypherRelationsQuery
    );
    queryGraph.nodes = [..._.uniqBy(queryGraph.nodes, "id")];
    queryGraph.edges = [
      ..._.uniqWith(
        queryGraph.edges,
        (a, b) => a.start_id === b.start_id && a.end_id === b.end_id
      ),
    ];
    return _transformGraphToRelations(queryGraph);
  };

  const { data, isLoading, refetch, error } = useQuery<KytGraphRelation[]>(
    ["cypherRelationsQuery", nodeId],
    fetchGraphRelationElements,
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const _transformGraphToRelations = (graph: KytGraph) => {
    return graph.edges.map((link) => {
      const type = link.start_id === nodeId ? "out" : "in";
      const relationNode = graph.nodes.find(
        (n) => n.id === (type === "out" ? link.end_id : link.start_id)
      );
      return {
        link,
        node: {
          ...relationNode!,
        },
        type: type,
      } as KytGraphRelation;
    });
  };

  return {
    cypherData: data,
    cypherLoading: isLoading,
    refetch,
    cypherError: error,
    setQueryNode: setNodeId,
  };
};

import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { CustomersApiClient } from "../api-client/api-client";
import { CustomerSearchType } from "../types";
import { CustomersQueryInputAtom } from "./store";

export const useCustomers = () => {
  const [query, setQuery] = useRecoilState(CustomersQueryInputAtom);

  const schema = useQuery({
    queryKey: ["customers", "schema"],
    queryFn: CustomersApiClient.getCustomersSearchSchema,
  });

  const customers = useQuery<{ data: CustomerSearchType[]; total: number }>({
    queryKey: ["customers", query.query, query.options],
    queryFn: async () => {
      return await CustomersApiClient.getCustomersAdvanced(
        query.query,
        query.options
      );
    },
    cacheTime: 2000,
  });

  return {
    customers: customers.data || { data: [], total: 0 },
    loading: customers.isLoading || schema.isLoading,
    search: setQuery,
    schema: (schema.data || []).map((field) => {
      if (field.external_key === "assignees") {
        field.value_type = "string"; // Number ids can't work with this field
      }
      return field;
    }),
  };
};

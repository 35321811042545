import { Button } from "@atoms/button/button";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import Select from "@atoms/select";
import { usePressReport } from "@features/press/state/use-press-report";
import { UpdatePressReportOptions } from "@features/press/types";
import {
  REPORT_EDD_STATE,
  REPORT_OVERALL_RISK_LEVEL,
} from "@features/press/utils";
import _ from "lodash";
import { atom, useRecoilState } from "recoil";

export const ReportStatusAndRiskUpdateModalAtom = atom<{
  open: boolean;
  comment: string;
  status: REPORT_EDD_STATE;
  active_risk_level: REPORT_OVERALL_RISK_LEVEL;
  customer_id: string;
}>({
  key: "ReportStatusAndRiskUpdateModalAtom",
  default: {
    open: false,
    comment: "",
    status: REPORT_EDD_STATE.NO_STATUS,
    active_risk_level: REPORT_OVERALL_RISK_LEVEL.NONE,
    customer_id: "",
  },
});

export const ReportStatusAndRiskUpdateModal = () => {
  const [modal, setModal] = useRecoilState(ReportStatusAndRiskUpdateModalAtom);

  return (
    <Modal
      open={modal.open}
      onClose={() => setModal({ ...modal, comment: "", open: false })}
    >
      <ReportStatusAndRiskUpdateModalContent />
    </Modal>
  );
};

const ReportStatusAndRiskUpdateModalContent = () => {
  const [modal, setModal] = useRecoilState(ReportStatusAndRiskUpdateModalAtom);
  const { update, loading } = usePressReport(modal.customer_id);

  return (
    <ModalContent title={"Update press report"}>
      <InputLabel
        label="Press report status"
        input={
          <Select
            value={modal.status}
            onChange={(e) =>
              setModal({
                ...modal,
                status: e.target.value as REPORT_EDD_STATE,
              })
            }
          >
            {Object.keys(REPORT_EDD_STATE).map((state) => (
              <option
                value={REPORT_EDD_STATE[state as keyof typeof REPORT_EDD_STATE]}
                key={REPORT_EDD_STATE[state as keyof typeof REPORT_EDD_STATE]}
              >
                {_.capitalize(
                  REPORT_EDD_STATE[
                    state as keyof typeof REPORT_EDD_STATE
                  ].replaceAll("_", " ")
                )}
              </option>
            ))}
          </Select>
        }
      />
      <InputLabel
        label="Press report active risk level"
        input={
          <Select
            value={modal.active_risk_level}
            onChange={(e) =>
              setModal({
                ...modal,
                active_risk_level: e.target.value as REPORT_OVERALL_RISK_LEVEL,
              })
            }
          >
            {Object.keys(REPORT_OVERALL_RISK_LEVEL).map((risk) => (
              <option
                value={
                  REPORT_OVERALL_RISK_LEVEL[
                    risk as keyof typeof REPORT_OVERALL_RISK_LEVEL
                  ]
                }
                key={
                  REPORT_OVERALL_RISK_LEVEL[
                    risk as keyof typeof REPORT_OVERALL_RISK_LEVEL
                  ]
                }
              >
                {_.capitalize(
                  REPORT_OVERALL_RISK_LEVEL[
                    risk as keyof typeof REPORT_OVERALL_RISK_LEVEL
                  ].replaceAll("_", " ")
                )}
              </option>
            ))}
          </Select>
        }
      />
      <InputLabel
        className="mt-2"
        label="Review"
        input={
          <Input
            value={modal.comment}
            onChange={(e) => setModal({ ...modal, comment: e.target.value })}
            multiline
          />
        }
      />
      <Button
        className="mt-4"
        disabled={loading}
        theme="default"
        onClick={() => setModal({ ...modal, comment: "", open: false })}
      >
        Cancel
      </Button>
      <Button
        className="mt-4 float-right"
        loading={loading}
        disabled={!modal.comment || !modal.status || !modal.active_risk_level}
        onClick={async () => {
          await update({
            new_edd_state: modal.status,
            new_active_risk_level: modal.active_risk_level,
            comment: modal.comment,
          } as UpdatePressReportOptions);

          setModal({ ...modal, comment: "", open: false });
        }}
      >
        Update report
      </Button>
    </ModalContent>
  );
};

import { Button } from "@atoms/button/button";
import { GetStartedCard } from "@atoms/card/get-started";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import { Page } from "@atoms/layout/page";
import { PageResponsiveColumns } from "@atoms/layout/page-block";
import Link from "@atoms/link";
import { Info, Menu, Title } from "@atoms/text";
import { PageLoader } from "@components/page-loader";
import { useSessionsSettings } from "@features/sessions/state/use-sessions-settings";
import { SessionSettings } from "@features/sessions/types";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";

export default function ScenarioIntegrationSettings() {
  const { sessionsSettings, loading, refresh, save } = useSessionsSettings();

  const [settings, setSettings] = useState<SessionSettings | null>(null);

  useEffect(() => {
    if (sessionsSettings) {
      setSettings(sessionsSettings);
    }
  }, [sessionsSettings]);

  const hasChanges = useCallback(() => {
    return !_.isEqual(settings, sessionsSettings);
  }, [settings, sessionsSettings]);

  if (!sessionsSettings && loading) {
    return <PageLoader />;
  }

  return (
    <Page>
      <div className="flex flex-row justify-between">
        <Title>Scenario Integration Settings</Title>
        {hasChanges() && (
          <Button
            theme="primary"
            size="sm"
            onClick={async () => {
              try {
                await save(settings!);
                toast.success("Sessions Settings saved successfully");
                refresh();
              } catch (e) {
                console.error(e);
                toast.error("Failed to save Sessions Settings");
              }
            }}
          >
            <PencilSquareIcon className="h-5 w-5 mr-2" />
            Save Changes
          </Button>
        )}
      </div>
      <div className="flex flex-col w-full space-y-4 my-4">
        <GetStartedCard
          show={true}
          title="Integration settings"
          text={
            <div>
              Configure the integration settings for the Go!Vid scenario, You
              can find the integration documentation at the following link: {""}
              <Link
                href="https://docs.algoreg.com/docs/govid/get-started#integration-example"
                target="BLANK"
              >
                Go!Vid Integration Documentation
              </Link>
            </div>
          }
        />
        <PageResponsiveColumns>
          <div className="grow md:max-w-md w-full flex flex-col space-y-2">
            <Menu>Return URL</Menu>
            <Info>
              Redirect users to this URL after they complete the onboarding
              process
            </Info>
            <InputLabel
              label=" "
              input={
                <Input
                  placeholder="https://yourwebsite.com/onboarding-complete"
                  value={settings?.return_url}
                  onChange={(e) => {
                    setSettings({
                      ...settings!,
                      return_url: e.target.value,
                    });
                  }}
                />
              }
            />
          </div>
          <div className="grow md:pl-5 pl-0  w-full flex flex-col space-y-4">
            <div className="flex flex-col space-y-2">
              <Menu>Translation override</Menu>
              <Info>Upload your own translation to override defaults</Info>
              <InputLabel
                className="w-full"
                label=" "
                input={
                  <Input
                    style={{ minHeight: 200 }}
                    multiline
                    placeholder="// Write your translation here in JSON format"
                    value={settings?.translation_override_json}
                    onChange={(e) => {
                      setSettings({
                        ...settings!,
                        translation_override_json: e.target.value,
                      });
                    }}
                  />
                }
              />
            </div>
            <div className="flex flex-col space-y-2">
              <Menu>Style override</Menu>
              <Info>Change onboarding style using your own stylesheets</Info>
              <InputLabel
                className="w-full"
                label=" "
                input={
                  <Input
                    style={{ minHeight: 200 }}
                    multiline
                    placeholder="// Write your CSS rules here"
                    value={settings?.style_override_css}
                    onChange={(e) => {
                      setSettings({
                        ...settings!,
                        style_override_css: e.target.value,
                      });
                    }}
                  />
                }
              />
            </div>
          </div>
        </PageResponsiveColumns>
      </div>
    </Page>
  );
}

import { Button } from "@atoms/button/button";
import { Checkbox } from "@atoms/input/input-checkbox";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import { Loader } from "@atoms/loader";
import { Base, Info, Menu, Section } from "@atoms/text";
import { useHasAccess } from "@features/auth/state/use-access";
import { useWebhookSettings } from "@features/webhook/state/use-webhook-settings";
import { WebhookSettingsType } from "@features/webhook/types";
import {
  WEBHOOK_EVENTS_LABELS,
  webhookEventsToArray,
  webhookEventsToString,
} from "@features/webhook/utils";
import {
  ChatBubbleBottomCenterIcon,
  CurrencyEuroIcon,
  ShieldCheckIcon,
  ShieldExclamationIcon,
  UserIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

export default function DeveloperWebhook() {
  const { settings, loading, refresh, update } = useWebhookSettings();
  const hasAccess = useHasAccess();
  const [configuration, setConfiguration] = useState<WebhookSettingsType>({
    url: "",
    authorization: "",
    events: "",
  });

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    setConfiguration(settings);
  }, [loading, settings]);

  const eventsList = webhookEventsToArray(configuration?.events ?? "");

  return (
    <>
      <div className="flex flex-row justify-between items-center">
        <Section>Webhook Settings</Section>
        {hasAccess("AGENT_EDIT") &&
          (configuration?.url !== settings?.url ||
            configuration?.authorization !== settings?.authorization ||
            configuration?.events?.length !== settings?.events?.length) && (
            <Button
              onClick={async () => {
                try {
                  await update(configuration);
                  toast.success("Webhook Settings updated");
                } catch (error) {
                  toast.error("Error updating Webhook Settings");
                }
              }}
              theme="primary"
            >
              Save Settings
            </Button>
          )}
        {loading && <Loader />}
      </div>

      <Info>
        Algoreg will send event to your application in real-time for you to
        update your customers access and information.
      </Info>

      <div className="mt-4 max-w-sm">
        <InputLabel
          label="Webhook callback URL"
          input={
            <Input
              required
              label="Webhook URL*"
              placeholder="https://example.com/webhook"
              value={configuration?.url ?? ""}
              onChange={(e) => {
                setConfiguration({
                  ...configuration!,
                  url: e.target.value,
                });
              }}
            />
          }
        />
        <InputLabel
          className="mt-4"
          label="Authorization Token (Optional)"
          input={
            <Input
              label="Authorization Token"
              placeholder="Bearer ********"
              value={configuration?.authorization}
              onChange={(e) => {
                setConfiguration({
                  ...configuration!,
                  authorization: e.target.value,
                });
              }}
            />
          }
        />
      </div>
      <div className="mt-8">
        <div>
          <Menu>Events types</Menu> <br />
          <Info>Listen to changes based on the event types you select</Info>
        </div>
        <div className="">
          {Object.keys(WEBHOOK_EVENTS_LABELS).map((event) => {
            return (
              <div
                key={event}
                className="flex flex-row mt-3 justify-between items-center"
              >
                <Checkbox
                  value={eventsList.includes(event)}
                  onChange={(checked) => {
                    if (checked) {
                      setConfiguration({
                        ...configuration!,
                        events: webhookEventsToString(eventsList.concat(event)),
                      });
                    } else {
                      setConfiguration({
                        ...configuration!,
                        events: webhookEventsToString(
                          eventsList.filter((e) => e !== event)
                        ),
                      });
                    }
                  }}
                />
                {event.startsWith("alert") && (
                  <ShieldCheckIcon className="w-4 h-4 inline-block ml-4 text-black" />
                )}
                {event.startsWith("customer") && (
                  <UserIcon className="w-4 h-4 inline-block ml-4 text-black" />
                )}
                {event.startsWith("thread") && (
                  <ChatBubbleBottomCenterIcon className="w-4 h-4 inline-block ml-4 text-black" />
                )}
                {event.startsWith("transaction") && (
                  <CurrencyEuroIcon className="w-4 h-4 inline-block ml-4 text-black" />
                )}
                {event.startsWith("edd") && (
                  <ShieldExclamationIcon className="w-4 h-4 inline-block ml-4 text-black" />
                )}
                {event.startsWith("session") && (
                  <VideoCameraIcon className="w-4 h-4 inline-block ml-4 text-black" />
                )}
                <Base className="ml-2 grow">
                  {WEBHOOK_EVENTS_LABELS[event]}
                </Base>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

import { is2XX, useFetch } from "@features/utils";
import _ from "lodash";
import { PostFilteringListType, PostFilteringType } from "../types";

export class AlertsFiltersApiClient {
  static get = async () => {
    const response = await useFetch(`/web/postfiltering`, {
      method: "GET",
    });
    const data = await response.json();
    return data as PostFilteringListType;
  };

  static save = async (name: string, settings: PostFilteringType) => {
    const response = await useFetch(`/web/postfiltering`, {
      method: "PUT",
      body: JSON.stringify({
        name,
        settings,
      }),
    });
    if (!is2XX(response.status)) {
      throw new Error("Unable to save filter");
    }
  };

  static delete = async (name: string, settings: PostFilteringType) => {
    settings = _.cloneDeep(settings);
    for (const filter in settings) {
      settings[filter as keyof PostFilteringType].deleted = true;
    }
    await this.save(name, settings);
  };
}

import { Input } from "@atoms/input/input-text";
import { PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";

export const NodeHeader = (props: {
  disabled?: boolean;
  icon: (...args: any[]) => any;
  value: string;
  onChange: (e: string) => void;
  type: string;
}) => {
  const [editingName, setEditingName] = useState(false);

  return (
    <div className="p-4 flex flex-row space-x-4">
      <div className="rounded bg-blue-400 p-2 w-12 h-12 flex items-center justify-center">
        <props.icon className="h-full w-8 text-white" />
      </div>
      <div>
        <div className="text-sm text-blue-400 font-semibold">
          <small>{props.type}</small>
        </div>
        <div className="font-bold flex flex-row space-x-2 justify-between items-center">
          {!editingName && !props.disabled && (
            <>
              <div>{props.value}</div>
              <span
                data-tooltip={"Edit node name"}
                className="flex items-center"
                tabIndex={0}
              >
                <button onClick={() => setEditingName(true)}>
                  <PencilIcon className="h-4 w-4" />
                </button>
              </span>
            </>
          )}
          {editingName && (
            <>
              <Input
                value={props.value || ""}
                placeholder="Component name"
                onChange={(e) => props.onChange(e.target.value)}
              />
              <button onClick={() => setEditingName(false)}>
                <XMarkIcon className="h-5 w-5" />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

import { useDashboardTimeseries } from "@features/dashboard/state/hooks";
import { Loader } from "../../../atoms/loader";
import Select from "../../../atoms/select";
import { formatTime } from "../../../features/utils/dates";
import { TrashIcon } from "@heroicons/react/24/outline";
import { isEmbed } from "../../../features/utils/use-embed-utils";

export const TimeseriesSelector = () => {
  const {
    period: timeseriesPeriod,
    setPeriod: setTimeseriesPeriod,
    loading: loadingTimeseries,
  } = useDashboardTimeseries();

  if (isEmbed()) return <></>;

  return (
    <div className="float-right flex w-auto">
      {timeseriesPeriod[0] === 0 && (
        <div className="items-center whitespace-nowrap px-4 flex">
          <div className="h-3 w-3 rounded-full bg-green-500 animate-pulse inline-block mr-2" />{" "}
          Live
        </div>
      )}
      {timeseriesPeriod[0] !== 0 && (
        <>
          <div className="items-center whitespace-nowrap px-4 flex">
            {loadingTimeseries && <Loader />}
            {!loadingTimeseries && (
              <TrashIcon
                onClick={() => setTimeseriesPeriod([0, undefined])}
                className="w-4 h-4 text-red-500 cursor-pointer"
                data-tooltip="Go back to live data"
              />
            )}
            <div className="mr-2" />
            {formatTime(timeseriesPeriod[0], "", {
              keepTime: false,
              keepSeconds: false,
              keepDate: true,
            })}
            {" -> "}
            {!timeseriesPeriod[1]
              ? "now"
              : formatTime(timeseriesPeriod[1], "", {
                  keepTime: false,
                  keepSeconds: false,
                  keepDate: true,
                })}
          </div>
        </>
      )}
      <Select
        data-tooltip="Explore past data"
        size="sm"
        onChange={(e) => {
          if (e.target.value === "custom") {
            // TODO
          } else if (e.target.value === "live") {
            setTimeseriesPeriod([0, undefined]);
          } else {
            setTimeseriesPeriod([
              Date.now() - parseInt(e.target.value) * 1000 * 60 * 60 * 24,
              undefined,
            ]);
          }
          e.target.selectedIndex = 0;
        }}
      >
        <option disabled selected>
          Change
        </option>
        <option value="live">Live data</option>
        <option value="7">7 days</option>
        <option value="30">30 days</option>
        <option value="90">90 days</option>
        <option value="182">6 month</option>
        <option value="365">1 years</option>
        {/*<option value="custom">Custom period</option>*/}
      </Select>
    </div>
  );
};

import Avatar from "@atoms/avatar/avatar";
import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import InputCopiable from "@atoms/input/input-copiable";
import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { SideModal } from "@atoms/modal/side-modal";
import { Info, Section } from "@atoms/text";
import { RolesManagement } from "@components/roles-manager";
import { AgentsApiClient } from "@features/agents/api-client/api-client";
import { useAgent, useAgents } from "@features/agents/state/use-agents";
import { useReviewGroups } from "@features/agents/state/use-review-groups";
import { useAuth } from "@features/auth/state/hooks";
import { validateEmail } from "@features/utils/strings";
import _ from "lodash";
import { useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";

export const AgentEditionModalAtom = atom<{
  open: boolean;
  id: number | null;
}>({
  key: "AgentEditionModalAtom",
  default: {
    open: false,
    id: null,
  },
});

export const AgentEditionModal = () => {
  const [state, setModalState] = useRecoilState(AgentEditionModalAtom);
  const [passwordModal, setPasswordModal] = useState("");
  const [loading, setLoading] = useState(false);
  const { create, setRoles, refresh } = useAgents();
  const { agent, disable, resetPassword } = useAgent(state.id || 0);
  const { user, clientId } = useAuth();
  const { reviewGroups: existingReviewGroups } = useReviewGroups();
  const [reviewGroupModalName, setReviewGroupModalName] = useState("");
  const [reviewGroupModalOpen, setReviewGroupModalOpen] = useState(false);

  const [form, setForm] = useState<{
    name: string;
    email: string;
    roles: string[];
    review_groups?: string[];
  }>({
    name: "",
    email: "",
    roles: [],
    review_groups: ["NOACCESS"],
  });

  const name = form.name || agent?.name || "";
  const email = form.email || agent?.email_login || "";
  const roles =
    (form.roles.length && form.roles) ||
    agent?.clients?.find((c) => c.id === clientId)?.roles.map((a) => a.code) ||
    [];
  const reviewGroups =
    form.review_groups ||
    agent?.clients?.find((c) => c.id === clientId)?.review_groups;

  //Reset everything when closing the modal
  useEffect(() => {
    if (!state.open) {
      setForm({
        name: "",
        email: "",
        roles: [],
        review_groups: undefined,
      });
    }
  }, [state.open]);

  return (
    <>
      <Modal
        open={!!passwordModal && state.open}
        onClose={() => {
          setPasswordModal("");
          refresh();
          if (!agent?.id) {
            setModalState({ id: null, open: false });
          }
        }}
      >
        <ModalContent title="Agent password">
          <div className="mb-2">
            <InputCopiable value={passwordModal} readOnly />
          </div>
          <Info>
            We generated a new password for this agent. Please copy it and save
            it, this is the last time we will display it.
          </Info>
        </ModalContent>
      </Modal>
      <SideModal
        open={state.open}
        onClose={() => setModalState({ ...state, open: false })}
      >
        <ModalContent
          title={
            !state.id ? (
              "Create user"
            ) : (
              <div className="flex items-center">
                <div className="inline-block w-8 mr-2">
                  <Avatar
                    fallback={agent?.name || loading}
                    avatar={agent?.avatar}
                    size={8}
                    className="h-8 w-8"
                  />
                </div>{" "}
                {agent?.name}
              </div>
            )
          }
        >
          <div className="max-w-3xl w-screen" />
          <Section>Identity</Section>
          <InputLabel
            label="Name"
            input={
              <Input
                autoFocus
                disabled={!!agent?.id || loading}
                placeholder="Judi Dench (M)"
                value={name}
                onChange={(e) => setForm({ ...form, name: e.target.value })}
              />
            }
          />
          <InputLabel
            className="mt-3"
            label="Email"
            feedback={
              form?.email && !validateEmail(email) ? "Invalid email" : ""
            }
            hasError={!validateEmail(email)}
            input={
              <Input
                disabled={!!agent?.id}
                placeholder="judi.dench@mi5.gov.uk"
                value={email}
                onChange={(e) => setForm({ ...form, email: e.target.value })}
              />
            }
          />
          <div>
            <Info className="block mt-2 max-w-lg">
              If this email is already in our systems, the user will either be
              re-enabled (if previously disabled) or invited from another
              client's space.
            </Info>
          </div>
          <br />
          <Section>Review groups</Section>
          <div className="w-full flex flex-row items-center">
            <Modal
              open={reviewGroupModalOpen}
              onClose={() => setReviewGroupModalOpen(false)}
            >
              <ModalContent title="Create review group">
                <InputLabel
                  label="Name"
                  input={
                    <Input
                      autoFocus
                      placeholder="GROUP-A"
                      value={reviewGroupModalName}
                      onChange={(e) =>
                        setReviewGroupModalName(
                          e.target.value
                            ?.replace(/[^a-zA-Z0-9-_]/g, "")
                            .toLocaleUpperCase()
                        )
                      }
                    />
                  }
                />
                <Button
                  className="mt-3"
                  onClick={() => {
                    setReviewGroupModalOpen(false);
                    setReviewGroupModalName("");
                    setForm({
                      ...form,
                      review_groups: [
                        ...(reviewGroups || []),
                        reviewGroupModalName,
                      ],
                    });
                  }}
                >
                  Create
                </Button>
              </ModalContent>
            </Modal>
            <div className="grow">
              <SelectMultiple
                value={reviewGroups || []}
                onChange={(reviewGroups: string[]) => {
                  setForm({
                    ...form,
                    review_groups: _.uniq(reviewGroups || []),
                  });
                }}
                options={_.uniq([
                  ...existingReviewGroups,
                  ...(reviewGroups || []),
                ]).map((a) => ({
                  value: a,
                  label: a,
                }))}
              />
            </div>
            <Button
              className="ml-2"
              theme="outlined"
              onClick={() => {
                //Open group creation modal
                setReviewGroupModalOpen(true);
              }}
            >
              Create review group
            </Button>
          </div>
          <div>
            {reviewGroups?.includes("NOACCESS") && (
              <Info className="block mt-2 max-w-lg">
                By default new agents have access to no customers with the
                review groups restriction "NOACCESS". Remove it to start giving
                access to this user.
              </Info>
            )}
          </div>
          <br />
          <Section>Roles management</Section>
          <RolesManagement
            roles={roles}
            onChange={async (roles: string[]) => {
              if (roles.length > 0) {
                setForm({ ...form, roles: roles });
              }
            }}
            readOnly={agent?.id === user?.id || loading}
          />
          <div>
            <Info className="block mt-2 max-w-lg">
              Customers <b>wont have access to Algoreg</b> if they don't have
              the minimal access Go!Risk Read Only.
            </Info>
          </div>
          <br />
          <Button
            disabled={
              !validateEmail(email) ||
              !name ||
              roles?.length === 0 ||
              (_.isEqual(
                roles,
                agent?.clients
                  ?.find((c) => c.id === clientId)
                  ?.roles.map((a) => a.code)
              ) &&
                _.isEqual(
                  reviewGroups,
                  agent?.clients?.find((c) => c.id === clientId)?.review_groups
                ))
            }
            loading={loading}
            className="my-1 block"
            theme="primary"
            onClick={async () => {
              setLoading(true);
              try {
                let id = agent?.clients.find(
                  (c) => c.id === clientId
                )?.agent_id;
                if (!id) {
                  const { password, agent_id } = await create(email, name);
                  if (password) setPasswordModal(password);
                  id = agent_id;
                }
                await AgentsApiClient.updateAgent(id!, reviewGroups || []);
                await setRoles(id!, roles);
              } catch (e) {
                console.error(e);
              }
              setLoading(false);
            }}
          >
            {!agent?.id ? "Create user" : "Save changes"}
          </Button>
          <br />
          {!!agent?.id && (
            <>
              <br />
              <Section>Operations</Section>

              <ButtonConfirm
                className="my-1 block"
                theme="default"
                confirmTitle="Reset user password ?"
                confirmMessage="You will reset the user's password. If the user has 2FA activated, it will remove all credentials linked to the account. Do you want to continue?"
                onClick={async () => {
                  const { password } = await resetPassword();
                  if (password) setPasswordModal(password);
                }}
              >
                Reset password and 2FA
              </ButtonConfirm>
              <br />

              {agent?.id !== user?.id && (
                <ButtonConfirm
                  className="my-1 block"
                  theme="danger"
                  onClick={async () => {
                    await disable();
                    setModalState({ ...state, open: false });
                  }}
                >
                  Delete agent
                </ButtonConfirm>
              )}
            </>
          )}
        </ModalContent>
      </SideModal>
    </>
  );
};

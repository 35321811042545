import { PageBlock } from "@atoms/layout/page-block";
import { BaseSmall } from "@atoms/text";
import { AlertType } from "@features/alerts/types";
import { useCustomer } from "@features/customers/state/use-customer";
import { useControlledEffect } from "@features/utils";
import { Identity } from "@views/client/customers/customer-details/identity";

export const AlertCustomerDetails = ({ alert }: { alert: AlertType }) => {
  const { customer, refresh } = useCustomer(alert.customer_id || "");

  useControlledEffect(() => {
    if (!customer) refresh();
  }, [refresh]);

  if (!customer) {
    return (
      <>
        <PageBlock className="mb-2">
          <div className="flex flex-row justify-between">
            <BaseSmall>Loading...</BaseSmall>
          </div>
        </PageBlock>
      </>
    );
  }

  return (
    <>
      <PageBlock className="mb-2">
        <Identity customer={customer} />
      </PageBlock>
    </>
  );
};

import { useFetch } from "@features/utils";
import {
  RiskMonitoringType,
  OnboardingMonitoringType,
  ScanMonitoringType,
  ChatMonitoringType,
  KytMonitoringType,
  MonitoringMetricHistoryOutput,
  MonitoringMetricsByDate,
  PressMonitoringType,
} from "../types";

export class DashboardApiClient {
  static getOnboarding = async () => {
    const response = await useFetch(`/web/monitoring/onboarding`, {
      method: "GET",
    });
    const data = await response.json();
    return data as OnboardingMonitoringType;
  };

  static getRisk = async () => {
    const response = await useFetch(`/web/monitoring/risk`, {
      method: "GET",
    });
    const data = await response.json();
    return data as RiskMonitoringType;
  };

  static getScan = async () => {
    const response = await useFetch(`/web/monitoring/scan`, {
      method: "GET",
    });
    const data = await response.json();
    return data as ScanMonitoringType;
  };

  static getChat = async () => {
    const response = await useFetch(`/web/monitoring/message`, {
      method: "GET",
    });
    const data = await response.json();
    return data as ChatMonitoringType;
  };

  static getKyt = async () => {
    const response = await useFetch(`/web/monitoring/kyt`, {
      method: "GET",
    });
    const data = await response.json();
    return data as KytMonitoringType;
  };

  static getPress = async () => {
    const response = await useFetch(`/web/monitoring/press`, {
      method: "GET",
    });
    const data = await response.json();

    // TODO: fixme it must be done in the backend monitoring/press endpoint
    const response2 = await useFetch(`/web/pressreports`, {
      method: "POST",
      body: JSON.stringify({
        query: [
          {
            key: "edd_state",
            operations: [
              {
                op: "equals",
                value: {
                  string: "Action_required",
                },
              },
            ],
          },
        ],
        options: {
          limit: 10,
          offset: 0,
          order_way: "ASC",
        },
      }),
    });
    const data2 = await response2.json();
    const reportsByStatus = {
      reports_by_status: {
        action_required: data2?.total,
        pending: 0,
        no_status: 0,
      },
    };

    return { ...data, ...reportsByStatus } as PressMonitoringType;
  };

  static getTimeseries = async (from: number, to?: number) => {
    const response = await useFetch(
      `/web/monitoring/metrics-history?` +
        `fromDate=${new Date(from).toISOString()}` +
        `&toDate=${new Date(to || new Date()).toISOString()}`,
      {
        method: "GET",
      }
    );
    const data = (await response.json()) as MonitoringMetricHistoryOutput[];
    return convertMetricsToByDate(data);
  };
}

function convertMetricsToByDate(
  metrics: MonitoringMetricHistoryOutput[]
): MonitoringMetricsByDate {
  if (metrics.length === 0) return [];

  // Finding the lowest and highest date
  const dates = metrics.map((metric) =>
    new Date(metric.date.split("T")[0]).getTime()
  );
  const minDate = new Date(Math.min(...dates));
  const maxDate = new Date(Math.max(...dates));

  const result: MonitoringMetricsByDate = [];

  for (let d = minDate; d <= maxDate; d.setDate(d.getDate() + 1)) {
    const dateString = d.toISOString().split("T")[0];
    const dailyMetrics = metrics.filter(
      (metric) => metric.date.split("T")[0] === dateString
    );

    if (dailyMetrics.length > 0) {
      result.push({
        date: d.getTime(),
        monitoring_metrics: dailyMetrics,
      });
    } else {
      // Fill the missing values with zeroes
      result.push({
        date: d.getTime(),
        monitoring_metrics: metrics.map((metric) => ({
          ...metric,
          value: 0,
          date: `${dateString}T00:00:00`,
          last_updated: new Date().toISOString(),
        })),
      });
    }
  }

  return result;
}

import { useEffect } from "react";

export const isEmbed = () => {
  return new URLSearchParams(window.location.search).get("embed");
};

/*
function inlineStyles(document: any) {
  if (!isEmbed()) return;

  // Function to get all styles in a string
  function getElementStyles(element: any) {
    // If no id and no classnames, continue
    if (!element.id && !element.className) {
      //If width/height set it
      if (element.width) {
        return `width: ${element.width}px; height: ${element.height}px;`;
      }
      return "";
    }

    const style = window.getComputedStyle(element);
    let styleString = "";
    for (let i = 0; i < style.length; i++) {
      const prop = style[i];
      const value = style.getPropertyValue(prop);
      if (prop.match(/^-/)) continue;
      if (prop.match(/^table/)) continue;
      if (prop.match(/^user/)) continue;
      if (prop.match(/^transition/)) continue;
      if (prop.match(/^animation/)) continue;
      if (prop.match(/^font-family/)) continue;
      if (prop.match(/^clip/)) continue;
      if (prop.match(/^container/)) continue;
      if (prop.match(/^hyphenate/)) continue;
      if (prop.match(/^inset/)) continue;
      if (prop.match(/^(lighting|flood|inline|list-)/)) continue;
      if (prop.match(/^(letter|tab|ruby|rx|ry|shape|touch|hyphens|caret)/))
        continue;
      if (
        prop.match(
          /^(stroke|mask|isolation|font-synthesis|font-stretch|caption|break|dominant|empty|accent)/
        )
      )
        continue;
      if (
        prop.match(
          /^(view|overscroll|outline|offset|image-|border-block|block-|baseline|backface|background-(attachment|clip|origin|position|repeat|size))/
        )
      )
        continue;
      if (prop.match(/^border-inline/)) continue;
      if (prop.match(/^border-image/)) continue;
      if (prop.match(/^border-(collapse)/)) continue;
      if (prop.match(/^font-(kerning|optical)/)) continue;
      if (
        prop.match(
          /^(zoom|stop|scroll|start|will|writing|text|buffered|fill|color-)/
        ) &&
        prop !== "text-align"
      )
        continue;

      if (
        value === "0px" ||
        value === "none" ||
        value === "0" ||
        value === "normal"
      )
        continue;
      styleString += `${prop}: ${value}; `;
    }
    return styleString;
  }

  // Iterate over all elements
  Array.from(document.body.getElementsByTagName("*")).forEach(function (
    element
  ) {
    // Get computed styles as string
    const inlineStyle = getElementStyles(element);

    // Set inline style
    (element as any).setAttribute("style", inlineStyle);

    // Remove classnames
    (element as any).removeAttribute("class");
  });

  // Remove all stylesheets
  Array.from(document.getElementsByTagName("style")).forEach(function (
    element
  ) {
    (element as any).innerText =
      "*{background: transparent;font-family: Helvetica;}";
  });
}
*/

function applyInlineCSS(cssLink: string) {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const style = document.createElement("style");
      style.innerHTML = xhr.responseText;
      document.head.appendChild(style);
      resolve(true);
    };
    xhr.open("GET", cssLink);
    xhr.send();
  });
}

const makeInline = async () => {
  if (!isEmbed()) return;

  // Hack for the email format, not super clean
  for (const item of Array.from(
    document.getElementsByClassName("embed-apply-post-css")
  )) {
    item?.classList.remove("overflow-auto");
    item?.classList.remove("bg-slate-50");
    item?.classList.remove("dark:bg-slate-900");
    item?.classList.remove("p-6");
  }

  // Replace all meta tags with external CSS by inline CSS
  const metaTags = document.getElementsByTagName("link");
  let i;
  for (i = 0; i < metaTags.length; i++) {
    if (metaTags[i].getAttribute("rel") === "stylesheet") {
      await applyInlineCSS(metaTags[i].getAttribute("href") || "");
      metaTags[i]?.parentNode?.removeChild(metaTags[i]);
      i--; // Adjust the counter as the NodeList is live
    }
  }

  // Remove all script and noscript tags
  const scriptTags = document.getElementsByTagName("script");
  for (i = 0; i < scriptTags.length; i++) {
    scriptTags[i].parentNode?.removeChild(scriptTags[i]);
    i--; // Adjust the counter as the NodeList is live
  }

  // Remove all noscript tags
  const noscriptTags = document.getElementsByTagName("noscript");
  for (i = 0; i < noscriptTags.length; i++) {
    noscriptTags[i].parentNode?.removeChild(noscriptTags[i]);
    i--; // Adjust the counter as the NodeList is live
  }

  //Merge all style tags into one
  const styleTags = document.getElementsByTagName("style");
  const style = document.createElement("style");
  for (i = 0; i < styleTags.length; i++) {
    style.innerHTML += styleTags[i].innerHTML;
    styleTags[i].parentNode?.removeChild(styleTags[i]);
    i--; // Adjust the counter as the NodeList is live
  }
  // Remove comments from the style
  style.innerHTML = style.innerHTML.replace(
    /\/\*[\s\S]*?\*\/|([^\\:]|^)\/\/.*$/gm,
    ""
  );
  // Remove line breaks
  style.innerHTML = style.innerHTML.replace(/(\r\n|\n|\r)/gm, "");
  style.innerHTML = `
  * { font-family: Arial; }
  hr { opacity: 0.25; }
  @media (max-width: 600px) {
    .embed-flex-auto-layout { flex-direction: column !important; }
    .embed-flex-auto-layout > *:not(:last-child) { margin-bottom: 1rem; }
  }
  @media (min-width: 600px) {
    .embed-flex-auto-layout { flex-direction: row !important; }
    .embed-flex-auto-layout > *:not(:last-child) { margin-right: 1rem; }
  }
    `;
  document.head.appendChild(style);

  const tailwindClassMap: any = {
    "sm\\:w-1\\/5": { width: "20%" },
    flex: { display: "flex" },
    "flex-row": { flexDirection: "row" },
    "flex-col": { flexDirection: "column" },
    grow: { flex: "1" },
    "font-bold": { fontWeight: 700 },
    "font-semibold": { fontWeight: 600 },
    "font-normal": { fontWeight: 400 },
    "text-lg": { fontSize: "1.125rem", lineHeight: "1.75rem" },
    "text-base": { fontSize: "1rem", lineHeight: "1.5rem" },
    "text-xs": { fontSize: "0.75rem", lineHeight: "1rem" },
    "text-sm": { fontSize: "0.875rem", lineHeight: "1.25rem" },
    "text-5xl": { fontSize: "3rem" },
    "text-center": { textAlign: "center" },
    block: { display: "block" },
    "inline-block": { display: "inline-block" },
    "w-full": { width: "100%" },
    "px-4": { paddingLeft: "1rem", paddingRight: "1rem" },
    "py-2": { paddingTop: "0.5rem", paddingBottom: "0.5rem" },
    "p-4": { padding: "1rem" },
    "mx-2": { marginLeft: "0.5rem", marginRight: "0.5rem" },
    "m-1": { margin: "0.25rem" },
    "-m-1": { margin: "-0.25rem" },
    "-mx-2": { marginLeft: "-0.5rem", marginRight: "-0.5rem" },
    "mt-2": { marginTop: "0.5rem" },
    "mt-4": { marginTop: "1rem" },
    "mb-2": { marginBottom: "0.5rem" },
    "mb-4": { marginBottom: "1rem" },
    "mr-2": { marginRight: "0.5rem" },
    "mr-3": { marginRight: "0.875rem" },
    "mr-6": { marginRight: "1.5rem" },
    "my-6": { marginTop: "1.5rem", marginBottom: "1.5rem" },
    border: { border: "1px solid rgba(128,128,128,0.3)" },
    rounded: { borderRadius: "5px" },
    "rounded-full": { borderRadius: "50%" },
    "justify-center": { justifyContent: "center" },
    "items-center": { alignItems: "center" },
    "bg-white": { background: "rgb(255, 255, 255)" },
    "bg-red-500": { background: "rgb(239, 68, 68)" },
    "bg-yellow-500": { background: "rgb(234, 179, 8)" },
    "bg-green-500": { background: "rgb(34, 197, 94)" },
    "text-slate-400": { color: "rgb(148, 163, 184)" },
    "text-white": { color: "#FFF" },
    "h-16": { height: "4rem" },
    "w-40": { width: "10rem" },
    "h-40": { height: "10rem" },
    "w-3": { width: "0.875rem" },
    "h-3": { height: "0.875rem" },
  };

  const apply = () => {
    Object.keys(tailwindClassMap).forEach((k) => {
      document.querySelectorAll("." + k).forEach((n) => {
        Object.keys(tailwindClassMap[k]).forEach((k2) => {
          (n as any).style[k2] = tailwindClassMap[k][k2];
        });
        (n as any).classList.remove(k);
      });
    });

    document.querySelectorAll("img").forEach((n) => {
      n.style.width = "auto";
      n.style.height = "100%";
    });
  };

  setInterval(() => {
    apply();
  }, 500);
  apply();
};

const convertToStaticImagesOnEmbed = () => {
  if (!isEmbed()) return;

  const canvases = document.getElementsByTagName("canvas");

  for (let i = 0; i < canvases.length; i++) {
    const canvas = canvases[i];

    // Create a new image
    const img = new Image();
    img.src = canvas.toDataURL();

    // Copy the style and position of the canvas to the image
    img.style.cssText = window.getComputedStyle(canvas).cssText;
    img.width = canvas.width;
    img.height = canvas.height;

    // Insert the image after the canvas
    canvas.parentNode?.insertBefore(img, canvas.nextSibling);

    // Destroy the canvas
    canvas.remove();
  }

  if (canvases.length > 0 || true) {
    setTimeout(() => {
      convertToStaticImagesOnEmbed();
    }, 500);
  }
};

export const useEmbedUtils = () => {
  useEffect(() => {
    makeInline();
    setTimeout(() => {
      convertToStaticImagesOnEmbed();
    }, 1000);
    //inlineStyles(document);
  }, []);

  // Get ?products=goscan,govid,gochat,gorisk from URL
  const urlParams = new URLSearchParams(window.location.search);
  const products = urlParams.get("products")?.split(",");
  const displayProduct = (product: string) => {
    return products
      ? products?.includes(product) ||
          products?.includes(product.replace(/gorisk/, "go!score")) ||
          products?.includes(product.replace(/gorisk/, "goscore")) ||
          products?.includes(product.replace(/^go/, "go!").toLocaleLowerCase())
      : true;
  };

  return { displayProduct, embed: isEmbed() };
};

import { Button } from "@atoms/button/button";
import { GetStartedCard } from "@atoms/card/get-started";
import { Page } from "@atoms/layout/page";
import { Title } from "@atoms/text";
import FuzzySet from "fuzzyset.js";
import PapaParse from "papaparse";
import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { useSetRecoilState } from "recoil";
import {
  ColumnsConfirmModalAtom,
  ImportColumnModal,
} from "./columns-confirm-modal";
import { useAllFields } from "./utils";

export const CustomerImportPage = () => {
  const { customerFields, relationsFields } = useAllFields();
  const [loading, setLoading] = useState(false);
  const openFileUpdateModal = useSetRecoilState(ColumnsConfirmModalAtom);

  const generateTemplateFile = (relations?: boolean) => {
    const title = relations ? "relations.csv" : "customers.csv";
    let content = relations
      ? [...relationsFields].map((f) => f.label).join(";")
      : customerFields.map((f) => f.label).join(";");

    content += "\n";
    content += (relations ? relationsFields : customerFields)
      .map((f) => {
        if (f.field_type === 2)
          return 1 + Math.random().toString(10).substring(2, 4);
        if (f.field_type === 3) return Math.random() > 0.5 ? 1 : 0;
        if (f.field_type === 4) {
          if (f.label === "external_id" || f.label === "relation_external_id")
            return "uniq_entity_id";
          if (f.label === "account_number") return "equals_to_external_id";
          if (f.label === "account_type") return "F (firm) or N (individual)";
          if (f.label === "nationality_code" || f.label === "domicile_code")
            return "FRA";
          if (f.label === "date_of_birth") return "YYYY-MM-DD";
        }
        return "";
      })
      .join(";");

    const link = document.createElement("a");
    const file = new Blob([content], { type: "text/plain" });
    link.href = URL.createObjectURL(file);
    link.download = title;
    link.click();
    URL.revokeObjectURL(link.href);
  };

  const uploadedFile = (file: File) => {
    fileRef.current!.value = "";
    setLoading(true);
    PapaParse.parse(file, {
      header: true,
      worker: true,
      complete: (results) => {
        if (results.errors.length > 0) {
          toast.error("There was an error while parsing the file");
        } else {
          let customersFitScore = 0;
          const mapping: { [key: string]: string } = {};
          for (const field of customerFields) {
            const fuzzySet = FuzzySet(results.meta.fields);
            const fuzzyResult = fuzzySet.get(field.label);
            customersFitScore += fuzzyResult ? fuzzyResult[0][0] : 0;
            if (fuzzyResult && fuzzyResult[0][0] > 0.8) {
              mapping[field.label] = fuzzyResult[0][1];
            }
          }
          let relationsFitScore = 0;
          for (const field of relationsFields) {
            const fuzzySet = FuzzySet(results.meta.fields);
            const fuzzyResult = fuzzySet.get(field.label);
            relationsFitScore += fuzzyResult ? fuzzyResult[0][0] : 0;
            if (fuzzyResult && fuzzyResult[0][0] > 0.8) {
              mapping[field.label] = fuzzyResult[0][1];
            }
          }

          openFileUpdateModal({
            data: results.data,
            fields: results.meta.fields,
            mode:
              customersFitScore > relationsFitScore ? "customers" : "relations",
            mapping,
          });
        }
        setLoading(false);
      },
    });
  };

  const fileRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Page>
        <ImportColumnModal />
        <input
          type="file"
          onChange={async (e) => {
            const file = e.target.files?.[0];
            if (file) {
              uploadedFile(file);
            }
          }}
          className="hidden"
          ref={fileRef}
        />

        <Title>Import customers</Title>
        <div className="my-4">
          <GetStartedCard
            show={true}
            title={<>Import or update your customer base</>}
            text={
              <>
                You can import your customers and relations using CSV files.
                Please use the templates bellow to prepare your document in the
                right format. You can use excel or any spreadsheet software and
                then export it back to CSV for Algoreg.
                <br />
                We can support up to 500 000 customers in a single import.
                Please split your document if you plan to add more customers at
                once.
                <br />
                Algoreg supports custom fields, go to the custom fields page to
                manage your customers and relations fields.
              </>
            }
          />
        </div>
        <div className="">
          <Button onClick={() => fileRef.current!.click()} loading={loading}>
            Select file to import
          </Button>
        </div>

        <div className="mt-4">
          Download template file for
          <br />
          <Button
            size="sm"
            theme="outlined"
            className="mr-2"
            onClick={() => generateTemplateFile()}
          >
            customers.csv
          </Button>
          <Button
            size="sm"
            theme="outlined"
            onClick={() => generateTemplateFile(true)}
          >
            relations.csv
          </Button>
        </div>
      </Page>
    </>
  );
};

import {
  CheckIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { MinusIcon } from "@heroicons/react/20/solid";

export default function SessionScore({
  text,
  status = "slate",
}: {
  text?: string;
  status?: "green" | "red" | "yellow" | "slate";
}) {
  return (
    <div
      className={`h-6 flex items-center text-white rounded-full whitespace-nowrap bg-${
        status || "slate"
      }-400 `}
    >
      <div
        className={`bg-${
          status || "slate"
        }-500 h-6 w-6 flex items-center justify-center rounded-full`}
      >
        {status === "green" && <CheckIcon className="h-4 w-4" />}
        {status === "red" && <XMarkIcon className="h-4 w-4" />}
        {status === "yellow" && <ExclamationTriangleIcon className="h-4 w-4" />}
        {status === "slate" && <MinusIcon className="h-4 w-4" />}
      </div>
      {text && <div className="pl-1.5 pr-2 text-sm">{text}</div>}
    </div>
  );
}

import { useRef, useState } from "react";
import { ChartProps, Doughnut } from "react-chartjs-2";

export const DoughnutChart = (
  props: Omit<ChartProps<any, any, any>, "type">
) => {
  const [legend, setLegend] = useState<any>([]);
  const toggleLabel = useRef<(index: number) => void>(() => {
    // No-op
  });
  const legendRef = useRef<any[]>([]);

  const htmlLegendPlugin = {
    id: "htmlLegend",
    afterUpdate(chart: any) {
      const newLegend =
        chart.options.plugins.legend.labels.generateLabels(chart);
      if (
        newLegend.length &&
        newLegend.filter((a: any) => !a.hidden).length !==
          legendRef.current.filter((a) => !a.hidden).length
      ) {
        legendRef.current = newLegend;
        setLegend(newLegend);
      }
      toggleLabel.current = (index) => {
        chart.toggleDataVisibility(index);
        chart.update();
      };
    },
  };

  return (
    <div className="flex flex-row items-center">
      <div className="w-40 h-40 mr-6">
        <Doughnut
          options={{
            ...props.options,
            plugins: {
              ...props.options?.plugins,
              htmlLegend: {},
              legend: {
                display: false,
              },
            },
          }}
          plugins={[htmlLegendPlugin]}
          data={props.data}
        />
      </div>
      <div className="grow">
        {legend.map(
          (entry: {
            fillStyle: string;
            hidden: boolean;
            index: number;
            text: string;
          }) => (
            <div
              key={entry.index}
              onClick={() => toggleLabel.current(entry.index)}
              className={
                "flex items-center cursor-pointer " +
                (entry.hidden
                  ? "opacity-50 hover:opacity-25"
                  : "hover:opacity-75")
              }
            >
              <div
                className="w-3 h-3 mr-2 rounded-full"
                style={{ backgroundColor: entry.fillStyle }}
              />
              <span
                className={"text-xs " + (entry.hidden ? "line-through" : "")}
              >
                {entry.text}
              </span>
            </div>
          )
        )}
      </div>
    </div>
  );
};

import { is2XX, useFetch } from "@features/utils";
import { SessionLabelType } from "../types";

export class SessionsLabelsApiClient {
  static get = async () => {
    const response = await useFetch(`/web/session/status`, {
      method: "GET",
    });
    const data = await response.json();
    return (data?.status as SessionLabelType[]).filter((a) => a.active);
  };

  static save = async (
    id: number | undefined,
    label: Pick<SessionLabelType, "label" | "type" | "is_auto">
  ) => {
    const response = await useFetch(
      `/web/session/status${id ? `/${id}` : ""}`,
      {
        method: id ? "PUT" : "POST",
        formData: true,
        body: JSON.stringify({
          ...label,
          auto: label.is_auto,
        }),
      }
    );
    if (!is2XX(response.status)) {
      throw new Error("Failed to save session labels");
    }
  };

  static delete = async (id: number) => {
    const response = await useFetch(`/web/session/status/${id}`, {
      method: "DELETE",
    });
    if (!is2XX(response.status)) {
      throw new Error("Failed to delete session labels");
    }
  };
}

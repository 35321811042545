import Select from "@atoms/select";
import { Base } from "@atoms/text";
import { FIELD_TYPES } from "@features/custom-fields/enum";
import { useCustomFields } from "@features/custom-fields/state/use-custom-fields";
import { CustomFieldType } from "@features/custom-fields/types";
import {
  RiskNodeField,
  RiskResourceType,
} from "@features/risk-decisions/types";
import { useEffect, useState } from "react";

export const FieldSelector = (props: {
  type: RiskResourceType;
  withRelations?: boolean;
  editable?: boolean;
  value: {
    id?: number;
    string_identifier?: string;
    entity: RiskNodeField["entity"];
  };
  onChange: (value: {
    string_identifier?: string;
    id?: number;
    entity: RiskNodeField["entity"];
  }) => void;
  fieldTypes?: ("text" | "number" | "boolean")[];
}) => {
  const { fields, loading, refresh } = useCustomFields();
  const [useKytCustomValue, setUseKytCustomValue] = useState(false);

  useEffect(() => {
    if (fields.length === 0) {
      refresh();
    }
  }, [fields.length, refresh]);

  const allowRelationFields = props.withRelations;
  const fieldTypesNumber = (props.fieldTypes || []).map(
    (f) => ({ number: 2, boolean: 3, text: 4 }[f])
  );

  const onChange = (value: {
    string_identifier?: string;
    id?: number;
    entity: RiskNodeField["entity"];
  }) => {
    if (value.id || value.entity === "transaction") setUseKytCustomValue(false);
    props.onChange(ensureValidity(props.type, fields, value));
  };

  useEffect(() => {
    if (props.value.string_identifier?.indexOf("kyt") === 0) {
      setUseKytCustomValue(true);
    }
  }, [props.value.string_identifier]);

  const sid = props.value.string_identifier;

  return (
    <>
      {props.type === "kyt" && (
        <div className="flex relative focus:z-10">
          <Select
            disabled={!props.editable}
            className="disabled:opacity-50 nowheel nodrag w-full rounded-none grow"
            name="entity"
            value={props.value?.entity}
            onChange={(e) => {
              onChange({
                ...props.value,
                entity: e.target.value as RiskNodeField["entity"],
              });
            }}
          >
            <option value="transaction">Transaction</option>
            <option value="from">From customer</option>
            <option value="to">To customer</option>
            <option value="to_institution">To institution</option>
            <option value="from_institution">From institution</option>
          </Select>
        </div>
      )}
      <div className="flex relative focus:z-10">
        {props.value?.entity !== "transaction" && (
          <Select
            disabled={!props.editable}
            className="disabled:opacity-50 nowheel nodrag w-full rounded-none grow"
            placeholder="Select a field"
            value={useKytCustomValue ? "kyt" : props.value.id}
            onChange={(e) => {
              if (e.target.value === "kyt") {
                setUseKytCustomValue(true);
                onChange({
                  ...props.value,
                  string_identifier: "kyt.rolling.1m.all.all.all.sum",
                  id: undefined,
                });
              } else {
                setUseKytCustomValue(false);
                onChange({
                  ...props.value,
                  string_identifier: undefined,
                  id: parseInt(e.target.value),
                });
              }
            }}
            name="field"
          >
            <option value="" className="text-gray-200" disabled selected>
              Select a field
            </option>
            {!loading &&
              fields
                .filter(
                  (f) => allowRelationFields || ![5, 6].includes(f.field_source)
                )
                .filter(
                  (f) =>
                    !fieldTypesNumber.length ||
                    fieldTypesNumber.includes(f.field_type)
                )
                .map((field) => (
                  <option key={"field" + field.field_id} value={field.field_id}>
                    {field.label}
                  </option>
                ))}
            <option disabled></option>
            <option value="kyt">KYT custom value</option>
          </Select>
        )}
        {props.value?.entity === "transaction" && (
          <Select
            disabled={!props.editable}
            className="disabled:opacity-50 nowheel nodrag w-full rounded-none grow"
            placeholder="Select a field"
            value={sid || ""}
            onChange={(e) => {
              onChange({
                ...props.value,
                id: undefined,
                string_identifier: e.target.value,
              });
            }}
            name="field"
          >
            <option value="" className="text-gray-200" disabled selected>
              Select a field
            </option>
            {Object.keys(getTransactionFields()).map((key) => (
              <option key={"field" + key} value={key}>
                {key}
              </option>
            ))}
          </Select>
        )}
      </div>
      {useKytCustomValue && (
        <div className="flex relative focus:z-10 w-full grow">
          <div className="w-full grow m-2 rounded-md bg-blue-500 overflow-hidden">
            <div className="p-2 space-y-2">
              <Select
                value={sid?.split(".")[1] || "rolling"}
                onChange={(e) => {
                  onChange({
                    ...props.value,
                    string_identifier:
                      sid
                        ?.split(".")
                        .map((_, i) => (i === 1 ? e.target.value : _))
                        .map((_, i) =>
                          i === 2
                            ? e.target.value === "rolling"
                              ? "1m"
                              : 0
                            : _
                        )
                        .join(".") || "kyt.rolling.1m.all.all.all.sum",
                  });
                }}
              >
                <option value="rolling">Rolling</option>
                <option value="month">Month</option>
                <option value="year">Year</option>
              </Select>
              <Select
                value={sid?.split(".")[2] || "1m"}
                onChange={(e) => {
                  onChange({
                    ...props.value,
                    string_identifier:
                      sid
                        ?.split(".")
                        .map((_, i) => (i === 2 ? e.target.value : _))
                        .join(".") || "kyt.rolling.1m.all.all.all.sum",
                  });
                }}
              >
                {sid?.split(".")[1] === "rolling" &&
                  Array.from(Array(18)).map((_, i) => (
                    <option key={i} value={i + 1 + "m"}>
                      {i + 1}m
                    </option>
                  ))}
                {sid?.split(".")[1] === "month" &&
                  Array.from(Array(18)).map((_, i) => (
                    <option key={i} value={i}>
                      {i > 0 && `${i}m ago`}
                      {i === 0 && `Month to date`}
                    </option>
                  ))}
                {sid?.split(".")[1] === "year" && (
                  <option value={0}>Year to date</option>
                )}
              </Select>
              <Select
                value={sid?.split(".")[3] || "1m"}
                onChange={(e) => {
                  onChange({
                    ...props.value,
                    string_identifier:
                      sid
                        ?.split(".")
                        .map((_, i) => (i === 3 ? e.target.value : _))
                        .join(".") || "kyt.rolling.1m.all.all.all.sum",
                  });
                }}
              >
                <option value={"all"}>All methods</option>
                <option value={"card"}>Card</option>
                <option value={"bank"}>Bank</option>
                <option value={"crypto"}>Crypto</option>
                <option value={"exchange"}>Exchange</option>
              </Select>
              <Select
                value={sid?.split(".")[4] || "1m"}
                onChange={(e) => {
                  onChange({
                    ...props.value,
                    string_identifier:
                      sid
                        ?.split(".")
                        .map((_, i) => (i === 4 ? e.target.value : _))
                        .join(".") || "kyt.rolling.1m.all.all.all.sum",
                  });
                }}
              >
                <option value={"all"}>All directions</option>
                <option value={"in"}>In</option>
                <option value={"out"}>Out</option>
              </Select>
              <Select
                value={sid?.split(".")[5] || "1m"}
                onChange={(e) => {
                  onChange({
                    ...props.value,
                    string_identifier:
                      sid
                        ?.split(".")
                        .map((_, i) => (i === 5 ? e.target.value : _))
                        .join(".") || "kyt.rolling.1m.all.all.all.sum",
                  });
                }}
              >
                <option value={"all"}>All statuses</option>
                <option value={"accepted"}>Accepted</option>
                <option value={"rejected"}>Rejected</option>
              </Select>
              <Select
                value={sid?.split(".")[6] || "1m"}
                onChange={(e) => {
                  onChange({
                    ...props.value,
                    string_identifier:
                      sid
                        ?.split(".")
                        .map((_, i) => (i === 6 ? e.target.value : _))
                        .join(".") || "kyt.rolling.1m.all.all.all.sum",
                  });
                }}
              >
                <option value={"sum"}>Volume (sum)</option>
                <option value={"count"}>Count</option>
                <option value={"max"}>Max</option>
                <option value={"min"}>Min</option>
              </Select>
            </div>
            <Base
              className="font-mono text-sm text-center w-full block py-2 px-2 text-white bg-blue-600 break-all	"
              noColor
            >
              {sid}
            </Base>
          </div>
        </div>
      )}
    </>
  );
};

export const getTransactionFields = () => {
  return {
    "transaction.converted_amount": "number",
    "transaction.external_id": "text",
    "transaction.amount": "number",
    "transaction.currency": "text",
    "transaction.converted_currency": "text",
    "transaction.blockchain": "text",
    "transaction.tx_hash": "text",
    "transaction.full_name": "text",
    "transaction.account_type": "text",
    "transaction.domicile_code": "text",
    "transaction.device.fingerprint": "text",
    "transaction.device.user_agent": "text",
    "transaction.device.language": "text",
    "transaction.device.geolocation": "text",
    "transaction.device.country": "text",
    "transaction.device.ip": "text",
    "transaction.device.session_id": "text",
    "transaction.device.session_duration": "number",
    "transaction.device.3ds": "boolean",
    "transaction.device.2fa": "boolean",
    "transaction.payment_institution.code": "text",
    "transaction.payment_institution.name": "text",
    "transaction.payment_institution.country": "text",
    "transaction.payment_method.type": "text",
    "transaction.payment_method.code": "text",
    "transaction.payment_method.country": "text",
  };
};

export const useGetFieldType = () => {
  const { fields, refresh } = useCustomFields();

  useEffect(() => {
    if (fields.length === 0) {
      refresh();
    }
  }, [fields.length, refresh]);

  return (field: RiskNodeField) => {
    if (field?.entity === "transaction") {
      return (
        (getTransactionFields() as any)[field.string_identifier || ""] || "text"
      );
    }

    const selectedField = fields.find((f) => f.field_id === field?.id);

    const isBooleanField = selectedField?.field_type === FIELD_TYPES.BOOLEAN;
    const isStringField = selectedField?.field_type === FIELD_TYPES.TEXT;

    return isBooleanField ? "boolean" : isStringField ? "text" : "number";
  };
};

const ensureValidity = (
  type: RiskResourceType,
  fields: CustomFieldType[],
  value: {
    id?: number;
    string_identifier?: string;
    entity: RiskNodeField["entity"];
  }
) => {
  if (!value.entity) {
    if (type === "kyt") {
      value = {
        ...value,
        entity: "transaction",
      };
    } else {
      value = {
        ...value,
        entity: "customer",
      };
    }
  }

  if (value.entity === "transaction") {
    if (!value.string_identifier) {
      console.log("change identifier");
      value = {
        ...value,
        id: undefined,
        string_identifier: "transaction.converted_amount",
      };
    }
  } else {
    if (!value.id && value.string_identifier?.indexOf("kyt") !== 0) {
      value = {
        ...value,
        id: fields.find((a) => a.label === "external_id")?.field_id || 1,
        string_identifier: undefined,
      };
    }
  }

  return value;
};

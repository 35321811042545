import { is2XX, useFetch } from "@features/utils";
import { ClientSettings } from "../types";

export class ClientApiClient {
  static getSettings = async () => {
    const response = await useFetch(`/web/client-settings`, {
      method: "GET",
    });
    const data = await response.json();
    return data as ClientSettings;
  };

  static saveSettings = async (settings: ClientSettings) => {
    const toUpdate = {
      twofa_required: settings.twofa_required
    }
    const response = await useFetch(`/web/client-settings`, {
      method: "PUT",
      body: JSON.stringify(toUpdate)
    });
    if (is2XX(response.status)) {
      return true;
    }
    return false;
  };
}

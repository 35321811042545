import { Checkbox } from "@atoms/input/input-checkbox";
import InputDate from "@atoms/input/input-date";
import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Input } from "@atoms/input/input-text";
import Select from "@atoms/select";
import { useAgents } from "@features/agents/state/use-agents";
import { useReviewGroups } from "@features/agents/state/use-review-groups";
import { AuthJWT } from "@features/auth/jwt";
import { useScenarios } from "@features/scenarios/state/use-scenarios";
import { useSessionStates } from "@features/sessions/state/use-sessions-states";
import { SessionsRequestOptions } from "@features/sessions/types";
import { SESSIONS_LANGUAGES } from "@features/sessions/utils";
import { useControlledEffect } from "@features/utils";
import _ from "lodash";
import { useState } from "react";

export default function SessionsListFilters(props: {
  value: Partial<SessionsRequestOptions>;
  onChange: (params: Partial<SessionsRequestOptions>) => void;
}) {
  const { states, refresh } = useSessionStates();
  const { scenarios, refresh: refreshScenarios } = useScenarios();
  const { reviewGroups } = useReviewGroups();
  const { members } = useAgents();

  const [filters, setFilters] = useState<Partial<SessionsRequestOptions>>(
    props.value
  );

  useControlledEffect(() => {
    props.onChange({
      ...filters,
    });
  }, [filters]);

  useControlledEffect(() => {
    if (states.length === 0) refresh();
    if (scenarios?.data.length === 0) refreshScenarios();
  }, [states.length, scenarios?.data.length]);

  // Need agents actives
  const agentsActives = members.filter(
    (agent) =>
      agent.active &&
      agent.clients.filter(
        (client) => client.active && client.id === AuthJWT.clientId
      ).length > 0
  );

  const scenariosCategories =
    scenarios?.data.map((scenario) => scenario.result_label) ?? [];

  return (
    <div className="flex flex-row w-full">
      <div className="grow mr-2">
        <InputLabel
          label="Search"
          input={
            <Input
              highlight
              value={filters.search || ""}
              onChange={(e) =>
                setFilters({ ...filters, search: e.target.value || null })
              }
              placeholder="Search session"
            />
          }
        />
        <InputLabel
          label="Customer ID"
          className="mt-2"
          input={
            <Input
              highlight
              value={filters.external_id || ""}
              onChange={(e) =>
                setFilters({ ...filters, external_id: e.target.value || null })
              }
              placeholder="Search customer external ID"
            />
          }
        />
      </div>
      <div className="mr-2 w-1/6">
        <InputLabel
          label="Score range"
          input={
            <div className="flex">
              <Input
                highlight
                className="rounded-r-none"
                value={filters.score_min || ""}
                type="number"
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    score_min: parseInt(e.target.value) || null,
                  })
                }
                placeholder="0"
              />
              <Input
                highlight
                className="rounded-l-none -ml-px"
                value={filters.score_max || ""}
                type="number"
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    score_max: parseInt(e.target.value) || null,
                  })
                }
                placeholder="100"
              />
            </div>
          }
        />
        <InputLabel
          className="mt-2"
          label="Review groups"
          input={
            <SelectMultiple
              highlight
              value={
                filters.only_without_review_groups
                  ? ["none"]
                  : filters.review_groups || []
              }
              options={[
                { value: "none", label: "(None)" },
                ..._.sortBy(
                  reviewGroups.map((c) => ({
                    value: c,
                    label: c,
                  })),
                  "label"
                ),
              ]}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  only_without_review_groups: e.includes("none"),
                  review_groups: (!e.includes("none") && e) || [],
                })
              }
            />
          }
        />
      </div>
      <div className="w-1/6 mr-2">
        <InputLabel
          label="Scenario"
          input={
            <Select
              highlight
              value={filters.scenario_ref || ""}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  scenario_ref: e.target.value || null,
                })
              }
            >
              <option value="">All</option>
              {(scenarios?.data || []).map((scenario) => (
                <option key={"review_" + scenario.id} value={scenario.code}>
                  {scenario.code}
                </option>
              ))}
            </Select>
          }
        />
        <InputLabel
          label="Language"
          className="mt-2"
          input={
            <Select
              highlight
              value={filters.language || ""}
              onChange={(e) =>
                setFilters({ ...filters, language: e.target.value || null })
              }
            >
              <option value="">All</option>
              {Object.keys(SESSIONS_LANGUAGES).map((languageCode) => (
                <option key={"lang_" + languageCode} value={languageCode}>
                  {_.capitalize(SESSIONS_LANGUAGES[languageCode])}
                </option>
              ))}
            </Select>
          }
        />
        <InputLabel
          label="Category"
          className="mt-2"
          input={
            <Select
              highlight
              value={filters.result_label || ""}
              onChange={(e) =>
                setFilters({ ...filters, result_label: e.target.value || null })
              }
            >
              <option value="">All</option>
              {scenariosCategories
                .filter((value) => !!value)
                .map((category) => (
                  <option key={"category_" + category} value={category}>
                    {_.capitalize(category)}
                  </option>
                ))}
            </Select>
          }
        />
      </div>
      <div className="mr-2 w-1/6">
        <InputLabel
          label="Session from"
          input={
            <InputDate
              highlight
              value={filters.date_from || null}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  date_from: e?.toISOString() || null,
                })
              }
            />
          }
        />
        <InputLabel
          className="mt-2"
          label="Session to"
          input={
            <InputDate
              highlight
              value={filters.date_to || null}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  date_to: e?.toISOString() || null,
                })
              }
            />
          }
        />
        <InputLabel
          className="mt-2"
          label="Only last by customer/scenario"
          input={
            <div className="pt-2">
              <Checkbox
                label={"Show only last"}
                value={filters.only_last || false}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    only_last: e,
                  });
                }}
              />
            </div>
          }
        />
      </div>
      <div className="w-1/6 mr-2">
        <InputLabel
          label="Review status"
          input={
            <Select
              highlight
              value={filters.exclude_if_status ? "-1" : filters.status_id || ""}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  exclude_if_status: e.target.value === "-1",
                  status_id:
                    e.target.value === "-1"
                      ? null
                      : Number(e.target.value) || null,
                })
              }
            >
              <option value="">All</option>
              <option value="-1">No status</option>
              {states.map((status) => (
                <option key={"review_" + status.id} value={status.id}>
                  {status.label}
                </option>
              ))}
            </Select>
          }
        />
        <InputLabel
          label="Assigned to"
          className="mt-2"
          input={
            <SelectMultiple
              highlight
              selectionLimit={1}
              value={
                filters.only_without_assignment
                  ? ["none"]
                  : filters.assigned_to_member_id
                  ? [filters.assigned_to_member_id.toString()]
                  : []
              }
              options={[
                { value: "none", label: "(None)" },
                ..._.sortBy(
                  agentsActives.map((agent) => ({
                    value: agent.id.toString(),
                    label: agent.name,
                  })) || [],
                  "label"
                ),
              ]}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  only_without_assignment: e.includes("none"),
                  assigned_to_member_id:
                    !e.includes("none") && e.length > 0 ? parseInt(e[0]) : null,
                })
              }
            />
          }
        />
        <InputLabel
          className="mt-2"
          label="Unfinished sessions"
          input={
            <div className="pt-2">
              <Checkbox
                label={"Include unfinished"}
                value={filters.include_unfinished || false}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    include_unfinished: e,
                  });
                }}
              />
            </div>
          }
        />
      </div>
    </div>
  );
}

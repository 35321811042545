import { atom, atomFamily } from "recoil";
import {
  DocumentType,
  NoteType,
  SearchDocumentsRequestOptions,
} from "../types";

export const CustomerDocumentsAtom = atomFamily<
  { data: DocumentType[]; total: number },
  string
>({
  key: "CustomerDocumentsAtom",
  default: () => ({ data: [], total: 0 }),
});

export const CustomerNotesAtom = atomFamily<
  { data: NoteType[]; total: number },
  string
>({
  key: "CustomerNotesAtom",
  default: () => ({ data: [], total: 0 }),
});

export const CustomerDocumentsFilterAtom = atom<
  Partial<Omit<SearchDocumentsRequestOptions, "customer_external_id">>
>({
  key: "CustomerDocumentsFilterAtom",
  default: {},
});

import { useGlobalEffect } from "@features/utils";
import { LoadingAtom } from "@features/utils/loading";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import { AlertsFiltersApiClient } from "../api-client/api-client";
import { PostFilteringType } from "../types";
import { PostFilteringsAtom } from "./store";

export const useAlertsPostFilters = () => {
  const [list, setList] = useRecoilState(PostFilteringsAtom);
  const [loading, setLoading] = useRecoilState(
    LoadingAtom("useAlertsPostFilters")
  );

  const refresh = useCallback(async () => {
    setLoading(true);
    setList(await AlertsFiltersApiClient.get());
    setLoading(false);
  }, [setList, setLoading]);

  const save = useCallback(
    async (name: string, settings: PostFilteringType) => {
      setLoading(true);
      try {
        await AlertsFiltersApiClient.save(name, settings);
        toast.success("Saved filter " + name);
      } catch (e) {
        console.error(e);
        toast.error("Failed to save filter " + name);
        return false;
      }
      refresh();
      return true;
    },
    [refresh, setLoading]
  );

  const remove = useCallback(
    async (name: string) => {
      if (!list.aliases.find((a) => a.name === name)?.settings) {
        return;
      }
      setLoading(true);
      try {
        await AlertsFiltersApiClient.delete(
          name,
          list.aliases.find((a) => a.name === name)?.settings!
        );
        toast.success("Removed filter " + name);
      } catch (e) {
        console.error(e);
        toast.error("Failed to remove filter " + name);
        return false;
      }
      refresh();
      return true;
    },
    [refresh, setLoading, list.aliases]
  );

  useGlobalEffect(
    "useAlertsPostFilters",
    () => {
      refresh();
    },
    []
  );

  return {
    filters: list.aliases,
    save,
    remove,
    loading,
    refresh,
  };
};

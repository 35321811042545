import { useGlobalEffect } from "@features/utils";
import { atom, useRecoilState } from "recoil";
import { BillingApiClient } from "./api-client/api-client";

const BillingPlanAtom = atom<{
  products_enabled: (
    | "go!scan"
    | "go!score"
    | "go!vid"
    | "go!checker"
    | "go!risk"
    | "go!chat"
    | "go!press"
    | "go!kyt"
  )[];
  scan_factiva_enabled: boolean;
  scan_available_lists: ("watchlist" | "ame" | "soc")[];
}>({
  key: "BillingPlanAtom",
  default: {
    products_enabled: [],
    scan_factiva_enabled: false,
    scan_available_lists: [],
  },
});

export const useBilling = () => {
  const [plan, setPlan] = useRecoilState(BillingPlanAtom);

  useGlobalEffect(
    "useBilling",
    async () => {
      setPlan(await BillingApiClient.getPlan());
    },
    []
  );

  const toggleProduct = async (
    product:
      | "go!scan"
      | "go!press"
      | "go!kyt"
      | "go!vid"
      | "go!press"
      | "go!risk"
      | "go!score",
    val: boolean
  ) => {
    await BillingApiClient.enableProduct(product, val);
    setPlan(await BillingApiClient.getPlan());
  };

  const isScanEnabled =
    plan.products_enabled.includes("go!scan") ||
    plan.products_enabled.includes("go!checker");
  const isRiskEnabled =
    plan.products_enabled.includes("go!score") ||
    plan.products_enabled.includes("go!risk");
  const isOnboardingEnabled = plan.products_enabled.includes("go!vid");
  const isPressEnabled = plan.products_enabled.includes("go!press");
  const isChatEnabled = plan.products_enabled.includes("go!chat");
  const isKYTEnabled = plan.products_enabled.includes("go!kyt");

  return {
    plan,
    isScanEnabled,
    isRiskEnabled,
    isOnboardingEnabled,
    isPressEnabled,
    isChatEnabled,
    isKYTEnabled,
    toggleProduct,
  };
};

import { Loader } from "@atoms/loader";
import { getSizeNodesGraph, positionLink } from "@features/kyt/d3-kyt-utils";
import { FocusedNodeAtom } from "@features/kyt/state/store";
import { useCypherQuery } from "@features/kyt/state/use-cypher-query";
import useExploreGraph from "@features/kyt/state/use-explore-graph";
import { KytEdge, KytGraph, KytNode } from "@features/kyt/types";
import * as d3 from "d3";
import "d3-transition";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useRecoilValue } from "recoil";
import GraphBackgroundLayer from "./components/graph-background-layer";
import GraphControlsPanel from "./components/graph-controls-panel";
import GraphFocusedNode from "./components/graph-focused-node";
import GraphLegendPanel from "./components/graph-legend-panel";
import { GraphLinks } from "./components/graph-links";
import GraphNodes from "./components/graph-nodes";

export function ExploreGraph() {
  const { transactionsGraph: graph, setTransactionsGraph: setGraph } =
    useExploreGraph();
  const focusedNode = useRecoilValue(FocusedNodeAtom);
  const { cypherData, cypherLoading: loading, cypherError } = useCypherQuery();

  useEffect(() => {
    if (cypherData) {
      setGraph(cypherData);
    }
  }, [cypherData, setGraph]);

  useEffect(() => {
    if (cypherError) {
      toast.error(
        "Error getting Transactions Graph elements with the given query"
      );
      console.error(cypherError);
    }
  }, [cypherError]);

  if (loading || !graph)
    return (
      <div className="grow flex justify-center h-full items-center">
        <Loader color="text-blue-500" />
      </div>
    );

  if (graph!.nodes.length === 0)
    return <div className="text-gray-500 m-auto">No elements found</div>;

  return (
    <div id="graph-layout" className="relative h-full w-full">
      <KytExploreGraph graph={graph!} />
      {focusedNode && (
        <div className="absolute w-1/3 p-4 top-0 left-0">
          <GraphFocusedNode node={focusedNode} />
        </div>
      )}
      <div className="absolute right-0 bottom-0">
        <GraphControlsPanel />
      </div>
      <div className="absolute  right-0 top-0 p-2">
        <GraphLegendPanel
          nodesLength={graph!.nodes.length}
          edgesLength={graph!.edges.length}
        />
      </div>
    </div>
  );
}

export function KytExploreGraph(props: { readonly graph: KytGraph }) {
  const [simulation, setSimulation] =
    useState<d3.Simulation<KytNode, KytEdge>>();

  const [nodes, setNodes] = useState<KytNode[]>([]);
  const [links, setLinks] = useState<KytEdge[]>([]);

  const focusedNode = useRecoilValue(FocusedNodeAtom);

  const zoom = d3.zoom().on("zoom", () => {
    const transform = d3.event.transform;
    d3.select("#graph-explore").attr("transform", transform);
  });

  const radius = getSizeNodesGraph(nodes); // Replace with the actual radius of the node circle

  const focusOnNode = useCallback(
    (focusedNodeId: string) => {
      // Filter nodes and links that are related to the focused node
      const relatedNodes = new Set();
      const selectedNode = nodes.find((node) => node.id === focusedNodeId);
      if (selectedNode) {
        relatedNodes.add(selectedNode);
      }
      const relatedLinks = links.filter((link) => {
        if (link.start_id === focusedNodeId || link.end_id === focusedNodeId) {
          relatedNodes.add(link.source);
          relatedNodes.add(link.target);
          return true;
        }
        return false;
      });

      const clusterNodes = nodes.filter(
        (node) => node.cluster === selectedNode?.cluster
      );

      const clusterLinks = links.filter((link) => {
        if (
          clusterNodes.find((node) => node.id === link.start_id) &&
          clusterNodes.find((node) => node.id === link.end_id)
        ) {
          return true;
        }
        return false;
      });

      // Update the visibility or style of nodes and links
      d3.selectAll(".node-wrapper").style("opacity", (node) =>
        relatedNodes.has(node)
          ? "1"
          : clusterNodes.includes(node as KytNode)
          ? ".8"
          : ".1"
      );

      d3.selectAll(".link-label").style("opacity", (link: any) =>
        relatedLinks.includes(link)
          ? "1"
          : clusterLinks.includes(link)
          ? ".8"
          : ".1"
      );

      d3.selectAll(".link").style("opacity", (link: any) =>
        relatedLinks.includes(link)
          ? "1"
          : clusterLinks.includes(link)
          ? ".8"
          : ".1"
      );
    },
    [links, nodes, zoom.transform]
  );

  const resetFocus = useCallback(() => {
    d3.selectAll(".node-wrapper").style("opacity", "1");
    d3.selectAll(".link").style("opacity", "1");
    d3.selectAll(".link-label").style("opacity", "1");
  }, []);

  useEffect(() => {
    if (focusedNode) {
      focusOnNode(focusedNode.id);
    } else {
      resetFocus();
    }
  }, [focusOnNode, focusedNode, resetFocus]);

  useEffect(() => {
    setNodes(props.graph.nodes.map((node) => ({ ...node })));
    setLinks(props.graph.edges.map((edge) => ({ ...edge })));
  }, [props.graph]);

  useEffect(() => {
    // take parent space (full-h full-w)
    const element = document.getElementById("graph-layout");
    const rect = element?.getBoundingClientRect() ?? {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    // d3 simulation
    const simulation: d3.Simulation<KytNode, KytEdge> = d3
      .forceSimulation<KytNode>(nodes)
      .force(
        "link",
        d3
          .forceLink<KytNode, KytEdge>()
          .id((node: KytNode) => node.id)
          .distance(radius * 2)
      )
      .force("center", d3.forceCenter(rect.width / 2, rect.height / 2))
      // .force("charge", d3.forceManyBody())
      .force(
        "collide",
        d3
          .forceCollide((node: KytNode) => radius * 5 + (node.cluster ?? 0) * 3)
          .strength(0.5)
      );
    // .force("x", d3.forceX())
    // .force("y", d3.forceY());
    const forceLink = simulation.force("link") as d3.ForceLink<
      KytNode,
      KytEdge
    >;
    forceLink.links(links);

    const node = d3.selectAll(".node-wrapper");
    const straightLink = d3.selectAll("line.link");
    const curvedLink = d3.selectAll("path.link");
    const linkLabel = d3.selectAll(".link-label");
    linkLabel.data(links);
    node.data(nodes);

    const ticked = () => {
      // update link positions
      straightLink
        .attr("x1", (d: any) => {
          return d.source.x;
        })
        .attr("y1", (d: any) => d.source.y)
        .attr("x2", (d: any) => {
          const angle = Math.atan2(
            d.target.y - d.source.y,
            d.target.x - d.source.x
          );
          return d.target.x - Math.cos(angle) * (radius * 1.2);
        })
        .attr("y2", (d: any) => {
          const angle = Math.atan2(
            d.target.y - d.source.y,
            d.target.x - d.source.x
          );
          return d.target.y - Math.sin(angle) * (radius * 1.2);
        });
      curvedLink.attr("d", (d: any) => positionLink(d, radius));

      // update node group positions
      node.attr("transform", (d: any) => `translate(${d.x}, ${d.y})`);
    };

    simulation.nodes(nodes).on("tick", ticked);

    setSimulation(simulation);

    return () => {
      simulation.stop();
    };
  }, [links, nodes, radius]);

  (
    d3.select("#graph-container") as d3.Selection<
      Element,
      unknown,
      HTMLElement,
      any
    >
  ).call(zoom);

  return (
    <svg id="graph-container" className="h-full w-full">
      <g id="graph-explore">
        <GraphBackgroundLayer />
        <GraphLinks links={links} size={radius} />
        <GraphNodes nodes={nodes} simulation={simulation} />
      </g>
    </svg>
  );
}

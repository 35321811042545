import { Info } from "@atoms/text";
import { useDashboard } from "@features/dashboard/state/hooks";
import { formatNumber } from "@features/utils";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { VidChart } from "./charts/vid";

ChartJS.register(ArcElement, Tooltip, Legend);

export const Vid = () => {
  const { onboarding } = useDashboard();

  if (!onboarding?.global_onboarding_info) return <div className="h-44" />;

  return (
    <div className="flex embed-flex-auto-layout flex-col sm:flex-row">
      <div className="sm:w-1/3 py-4" style={{ minWidth: "316px" }}>
        <VidChart />
      </div>
      <div className="sm:w-1/3 sm:ml-8">
        <div className="mb-2">
          <Info>Completed sessions</Info>
          <br />
          <div className="overflow-hidden mr-2 text-base">
            {formatNumber(
              onboarding?.global_onboarding_info.completed_sessions || 0
            )}{" "}
            <Info>
              (+
              {formatNumber(onboarding?.last_24h_completed_sessions.total || 0)}
              )
            </Info>
          </div>
        </div>
        <div className="mb-2">
          <Info>Unfinished sessions</Info>
          <br />
          <div className="overflow-hidden mr-2 text-base">
            {formatNumber(
              onboarding?.global_onboarding_info.unfinished_sessions || 0
            )}{" "}
            <Info>
              (+
              {formatNumber(
                onboarding?.last_24h_unfinished_sessions.total || 0
              )}
              )
            </Info>
          </div>
        </div>
      </div>
      <div className="grow sm:ml-4">
        <div className="mb-2">
          <Info>Average score (24h)</Info>
          <br />
          <div className="overflow-hidden mr-2 text-base">
            {!!onboarding?.last_24h_sessions_average_score
              .last_24h_average_score && (
              <>
                {formatNumber(
                  onboarding?.last_24h_sessions_average_score
                    .last_24h_average_score || 0
                )}
                %
              </>
            )}
            {!onboarding?.last_24h_sessions_average_score
              .last_24h_average_score && <Info>-</Info>}
          </div>
        </div>
      </div>
    </div>
  );
};

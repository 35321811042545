import { useState } from "react";
import { Button } from "@atoms/button/button";
import { Input } from "@atoms/input/input-text";
import { TrashIcon } from "@heroicons/react/24/outline";

export const BlackListInput = ({
  value,
  onChange,
}: {
  value: string[];
  onChange: (values: string[]) => void;
}) => {
  const [input, setInput] = useState("");

  const handleAdd = () => {
    if (!input.trim()) return; // Check to prevent adding empty strings
    onChange([...value, input]);
    setInput(""); // Clear the input after adding
  };

  const handleRemove = (itemToRemove: string) => {
    onChange(value.filter((item) => item !== itemToRemove));
  };

  return (
    <div>
      <div className="flex flex-row space-x-2">
        <Input
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="From:.*spam\."
        />
        <Button
          onClick={handleAdd}
          disabled={
            !input.split(",").every((a) => {
              try {
                new RegExp(a);
                return !!a.trim();
              } catch (e) {
                return false;
              }
            })
          }
        >
          Add
        </Button>
      </div>
      {value.map((item) => (
        <div
          key={item}
          className="border p-2 rounded flex flex-row space-2 mt-2 items-center"
          style={{ fontFamily: "monospace" }}
        >
          <div className="grow">{item}</div>
          <Button
            className="inline-block !px-2 w-8 rounded-full shrink-0"
            size="sm"
            theme="danger"
            onClick={() => handleRemove(item)}
          >
            <TrashIcon className="w-4 h-4" />
          </Button>
        </div>
      ))}
    </div>
  );
};

export const RecipientsInput = ({
  value,
  onChange,
}: {
  value: string[];
  onChange: (values: string[]) => void;
}) => {
  const [input, setInput] = useState("");

  const handleAdd = () => {
    if (!input.trim()) return; // Check to prevent adding empty strings
    onChange([...value, input.toLocaleLowerCase()]);
    setInput(""); // Clear the input after adding
  };

  const handleRemove = (itemToRemove: string) => {
    onChange(value.filter((item) => item !== itemToRemove));
  };

  return (
    <div>
      <div className="flex flex-row space-x-2">
        <Input
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="James <james@mi5.co.uk>, albert@mi5.co.uk"
        />
        <Button
          onClick={handleAdd}
          disabled={
            !input
              .split(",")
              .every((a) => a.trim().match(/@[^.]{3,}\.[^.]{2,}/))
          }
        >
          Add
        </Button>
      </div>
      {value.map((item) => (
        <div
          key={item}
          className="border p-2 rounded flex flex-row space-2 mt-2 items-center"
          style={{ fontFamily: "monospace" }}
        >
          <div className="grow">{item}</div>
          <Button
            className="inline-block !px-2 w-8 rounded-full shrink-0"
            size="sm"
            theme="danger"
            onClick={() => handleRemove(item)}
          >
            <TrashIcon className="w-4 h-4" />
          </Button>
        </div>
      ))}
    </div>
  );
};

import { Page } from "@atoms/layout/page";
import { Title } from "@atoms/text";
import { MessagesLabelsTables } from "./messages-labels";

export const MessageTagsSetup = () => {
  return (
    <Page>
      <Title>Message tags</Title>
      <br />
      <MessagesLabelsTables />
    </Page>
  );
};

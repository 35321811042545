import { useRecoilState } from "recoil";
import { WebhookSettingsStateAtom } from "./store";
import { WebhookSettingsType } from "../types";
import { LoadingAtom } from "@features/utils/loading";
import { useCallback } from "react";
import { delayRequest } from "@features/utils";
import { WebhookApiClient } from "../api-client/api-client";

export const useWebhookSettings = () => {
  const [settings, setSettings] = useRecoilState<WebhookSettingsType>(
    WebhookSettingsStateAtom
  );
  const [loading, setLoading] = useRecoilState(
    LoadingAtom("useWebhookSettings")
  );

  const refresh = useCallback(async () => {
    setLoading(true);
    setSettings(await WebhookApiClient.getWebhookSettings());
    delayRequest("useWebhookSettingsRefresh", async () => {
      setLoading(false);
    });
  }, [setSettings, setLoading]);

  const update = useCallback(
    async (input: WebhookSettingsType) => {
      await WebhookApiClient.updateWebhookSettings(input);
      await refresh();
    },
    [refresh]
  );

  return { settings, loading,  refresh, update };
};

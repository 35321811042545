import { Input } from "@atoms/input/input-text";
import { Info } from "@atoms/text";
import { delayRequest, useControlledEffect } from "@features/utils";
import {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";

export default function InputSuggestions({
  getSuggestions,
  value,
  onChange,
  placeholder,
  className,
  single,
  debounce,
  disabled,
}: {
  getSuggestions?: (
    query: string
  ) => Promise<
    { value: string; label: string | ReactNode; header?: string | ReactNode }[]
  >;
  value: string[];
  onChange: (value: string[]) => void;
  placeholder?: string;
  className?: string;
  single?: boolean;
  debounce?: number;
  disabled?: boolean;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  const [query, setQuery] = useState("");
  const [result, setResult] = useState<
    { label: string | ReactNode; header?: string | ReactNode; value: string }[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    if (getSuggestions) {
      setLoading(true);
      delayRequest(
        "suggestions-search",
        async () => {
          const suggestions = await getSuggestions(query);
          setResult(suggestions);
          setLoading(false);
        },
        { timeout: debounce || 500, doInitialCall: true }
      );
    }
  }, [query, focused, getSuggestions]);

  let textValue = "";
  if (value.length > 0) {
    textValue = value.filter((a) => a).join(", ");
    if (textValue && focused && !single) textValue += ", ";
  }

  const _onChange = (values: string[]) => {
    if (single) {
      onChange([values.filter((a) => a)[0]]);
    } else {
      onChange(values);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "ArrowDown") {
      setSelectedIndex((prevIndex) =>
        Math.min(prevIndex + 1, result.length - 1)
      );
    } else if (e.key === "ArrowUp") {
      setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    } else if (e.key === "Enter" && selectedIndex > -1) {
      const newValue = [...value, result[selectedIndex].value];
      setQuery("");
      setResult([]);
      _onChange(newValue);
    }
  };

  const updatePosition = useCallback(() => {
    if (inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      const pos = {
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      };
      setPosition(pos);
    }
  }, []);

  useEffect(() => {
    if (inputRef.current && focused && hovering) {
      updatePosition();
      setTimeout(updatePosition, 100);
    }
  }, [updatePosition, focused, hovering]);

  useControlledEffect(() => {
    window.addEventListener("mousewheel", updatePosition);
    window.addEventListener("mousemove", updatePosition);
    window.addEventListener("resize", updatePosition);
    return () => {
      window.removeEventListener("mousewheel", updatePosition);
      window.removeEventListener("mousemove", updatePosition);
      window.removeEventListener("resize", updatePosition);
    };
  }, [inputRef.current]);

  return (
    <div className="relative -mb-0.5">
      <Input
        disabled={disabled}
        className={
          className +
          " " +
          ((focused || hovering) && !!result.length ? "rounded-b-none" : "")
        }
        placeholder={placeholder}
        onFocus={() => setFocused(true)}
        value={textValue + query}
        onBlur={() => setFocused(false)}
        onChange={(e) => {
          //Remove last contact if we're after a comma
          if (e.target.value.length < textValue.length) {
            const values = [...value];
            values.pop();
            _onChange(values);
          }
          const newQuery = e.target.value.substring(textValue.length);
          setQuery(newQuery);
        }}
        onKeyDown={handleKeyDown}
      />
      <div className="p-px">
        <div className="relative" ref={inputRef}>
          {(focused || (hovering && !!result.length)) &&
            result.length > 0 &&
            createPortal(
              <div
                style={{
                  top: `${position.top}px`,
                  left: `${position.left}px`,
                  width: `${inputRef.current?.clientWidth}px`,
                  zIndex: 9999,
                }}
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
                className={
                  "float-left fixed bg-white dark:bg-slate-900 rounded-b shadow-lg overflow-y-auto max-h-1/3 w-full ring-2 ring-blue-600 "
                }
              >
                {result.length === 0 && (
                  <div className="w-full p-2 text-center">
                    <Info>No suggestions</Info>
                  </div>
                )}
                {result.map((suggestion, idx) => (
                  <Fragment key={idx}>
                    {suggestion.header && (
                      <div className="px-2 bg-slate-100 dark:bg-slate-700">
                        {suggestion.header}
                      </div>
                    )}
                    <div
                      className={
                        "p-2 hover:bg-blue-50 dark:hover:bg-slate-800 cursor-pointer " +
                        (idx === selectedIndex
                          ? "bg-blue-50 dark:bg-slate-800 "
                          : "") +
                        (loading ? "opacity-75" : "")
                      }
                      onClick={() => {
                        const newValue = [
                          ...value,
                          suggestion.value.toString(),
                        ];
                        setHovering(false);
                        setQuery("");
                        setResult([]);
                        _onChange(newValue);
                      }}
                    >
                      {suggestion.label}
                    </div>
                  </Fragment>
                ))}
              </div>,
              document.body
            )}
        </div>
      </div>
    </div>
  );
}

import { MinusIcon, PlusIcon } from "@heroicons/react/20/solid";
import { CpuChipIcon } from "@heroicons/react/24/outline";

export default function GraphControlsPanel() {
  return (
    <div className="shrink flex flex-col  divide-red-100 divide-y bg-white dark:bg-slate-900 border rounded m-4 ">
      <button>
        <PlusIcon className="h-4 w-4 m-1" aria-hidden="true" />
      </button>
      <button>
        <MinusIcon className="h-4 w-4 m-1" aria-hidden="true" />
      </button>
      <button>
        <CpuChipIcon className="h-4 w-4 m-1" aria-hidden="true" />
      </button>
    </div>
  );
}

import { RiskResourceType } from "@features/risk-decisions/types";
import { ArrowsRightLeftIcon, UserCircleIcon } from "@heroicons/react/20/solid";
import { CSSProperties } from "react";
import { Handle, HandleProps, NodeProps, Position } from "reactflow";

const handleStyle: CSSProperties = {
  height: 15,
  width: 15,
  right: -24,
};

const handleProps: HandleProps = {
  type: "source",
  id: "start",
  position: Position.Right,
};

export default function StartNode(
  props: NodeProps<{
    resource: RiskResourceType;
  }>
) {
  if (props.data.resource === "kyt") {
    return (
      <div className="bg-white p-4 rounded-md border-gray-200 flex flex-row space-x-4 border-solid border dark:bg-slate-800 dark:text-white dark:border-slate-600">
        <div className="rounded bg-slate-500 p-2">
          <ArrowsRightLeftIcon className="h-full w-8 text-white" />
        </div>
        <div>
          <div className="font-bold">Transaction information</div>
          <div className="relative text-right">
            <small>Start</small>
            <Handle {...handleProps} style={handleStyle} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-white p-4 rounded-md border-gray-200 flex flex-row space-x-4 border-solid border dark:bg-slate-800 dark:text-white dark:border-slate-600">
        <div className="rounded bg-slate-500 p-2">
          <UserCircleIcon className="h-full w-8 text-white" />
        </div>
        <div>
          <div className="font-bold">Customer information</div>
          <div className="relative text-right">
            <small>Start</small>
            <Handle {...handleProps} style={handleStyle} />
          </div>
        </div>
      </div>
    </>
  );
}

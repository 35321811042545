import { Page } from "@atoms/layout/page";
import { Info, Section, Title } from "@atoms/text";
import Assignments from "@components/assignations";
import { PageLoader } from "@components/page-loader";
import { useAlert } from "@features/alerts/state/use-alerts";
import { extractCustomerAlertFullName } from "@features/alerts/utils";
import { useHasAccess } from "@features/auth/state/use-access";
import { useControlledEffect } from "@features/utils";
import { useParams } from "react-router-dom";
import { atom, useRecoilState } from "recoil";
import { ListProfileDetails } from "../shared/list-profile-details";
import { AlertCustomerDetails } from "./customer-details";
import { AlertMatchSummary } from "./match-summary";
import { Menu } from "./menu";
import { AlertNavigation } from "./navigation";
import { AlertTimeline } from "./timeline";
import { PageResponsiveColumns } from "@atoms/layout/page-block";

export const AlertsDetailsPage = () => {
  const { id } = useParams();
  return <AlertsDetailsPageContent key={id} />;
};

export const AlertCustomerExtendedViewAtom = atom({
  key: "AlertCustomerExtendedViewAtom",
  default: false,
});

export const AlertsDetailsPageContent = () => {
  const { id } = useParams();
  const { alert, loading, refresh } = useAlert(id || "");
  const revision = alert?.last_alert_revision;
  const [extendedView, setExtendedView] = useRecoilState(
    AlertCustomerExtendedViewAtom
  );
  const hasAccess = useHasAccess();

  useControlledEffect(() => {
    if (!loading) refresh();
  }, [refresh]);

  return (
    <Page>
      <Title className="flex items-center">
        <div className="grow">
          Alert for "{revision && extractCustomerAlertFullName(revision)}"
        </div>
        <AlertNavigation id={id!} />
      </Title>
      <Menu />

      {!alert && loading && (
        <div style={{ marginTop: "30vh" }}>
          <PageLoader />
        </div>
      )}
      {!alert && !loading && <Info>This alert was not found.</Info>}
      {alert && (
        <>
          <Section className="mt-4">Match summary</Section>
          <AlertMatchSummary
            alert={alert}
            extendedView={extendedView}
            setExtendedView={setExtendedView}
          />

          <div className="justify-between mt-2">
            <PageResponsiveColumns>
              <div
                className={
                  extendedView ? "md:w-1/2 shrink-0 pr-1" : "w-full md:max-w-md"
                }
                style={extendedView ? {} : { minWidth: "30%", width: "400px" }}
              >
                {extendedView && (
                  <>
                    <Section className="mt-4">Customer information</Section>
                    <AlertCustomerDetails alert={alert} />
                  </>
                )}
                <Assignments
                  type="alert"
                  id={id!}
                  groups={alert.review_groups || []}
                  readonly={!hasAccess("ALERT_CASEMANAGEMENT")}
                />
                <AlertTimeline revisions={alert.alert_revisions} />
              </div>
              <div className="grow w-full">
                <Section className="mt-4">Complete match record</Section>
                <ListProfileDetails
                  record={revision!.list_profile}
                  associates={revision!.list_associates}
                  categories={revision!.list_categories}
                  alert={revision}
                />
              </div>
            </PageResponsiveColumns>
          </div>
        </>
      )}
    </Page>
  );
};

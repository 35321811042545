import _ from "lodash";
import { CSSProperties, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export const Tag = ({
  className,
  noColor,
  children,
  style,
  ellipsis,
  ...props
}: {
  className?: string;
  noColor?: boolean;
  children: ReactNode;
  style?: CSSProperties;
  ellipsis?: boolean;
} & JSX.IntrinsicElements["div"]) => {
  if (!children) return <></>;

  return (
    <div
      style={{ ...(style || {}), minWidth: "21px" }}
      className={twMerge(
        "text-left rounded inline-block px-1.5 py-px text-sm text-center w-max",
        !noColor
          ? "bg-slate-300 text-slate-800 dark:bg-slate-600 dark:text-slate-100 "
          : "",
        ellipsis ? "whitespace-nowrap text-ellipsis overflow-hidden " : "",
        className
      )}
      {..._.omit(props, "className", "noColor", "children", "style")}
    >
      {children}
    </div>
  );
};

import { Info } from "@atoms/text";
import { useDashboard } from "@features/dashboard/state/hooks";
import { formatNumber } from "@features/utils";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { KYTChart } from "./charts/kyt";

ChartJS.register(ArcElement, Tooltip, Legend);

export const KYT = () => {
  const { kyt } = useDashboard();

  return (
    <div className="flex embed-flex-auto-layout flex-col sm:flex-row">
      <div className="sm:w-1/3 py-4" style={{ minWidth: "316px" }}>
        <KYTChart />
      </div>
      <div className="grow sm:ml-4">
        <div className="mb-2">
          <Info>Total transactions (last 30d)</Info>
          <br />
          <div className="overflow-hidden mr-2 text-base">
            {formatNumber(
              Object.values(
                kyt?.last_30_days_transactions_by_status || {}
              ).reduce((acc, a) => a + acc, 0) || 0
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

import { ButtonConfirm } from "@atoms/button/confirm";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Table } from "@components/table";
import { useHasAccess } from "@features/auth/state/use-access";
import { InboxApiClient } from "@features/inbox/api-client/api-client";
import { useThread } from "@features/inbox/state/use-thread";
import { useThreadMessages } from "@features/inbox/state/use-thread-messages";
import {
  InboxMessageAttachment,
  ThreadRelatedCustomer,
} from "@features/inbox/types";
import { UserIcon } from "@heroicons/react/24/outline";
import { isEmpty } from "lodash";
import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { atom, useRecoilState, useSetRecoilState } from "recoil";

export const UploadInboxAttachmentToCustomersModalAtom = atom<{
  open: boolean;
  attachment?: InboxMessageAttachment;
  threadId?: string;
}>({
  key: "UploadInboxAttachmentToCustomersModalAtom",
  default: {
    open: false,
  },
});

export const UploadAttachmentToCustomersModal = () => {
  const [modal, setModal] = useRecoilState(
    UploadInboxAttachmentToCustomersModalAtom
  );

  return (
    <>
      <Modal
        className="min-w-[40vw]"
        open={modal.open}
        onClose={() => {
          setModal({ open: false });
        }}
      >
        {modal.open && (
          <CreateUploadAttachmentToCustomersModalContent
            key={(modal.threadId || "") + (modal.attachment?.id || "")}
            cancel={() => {
              setModal({ open: false });
            }}
            attachment={modal.attachment}
            threadId={modal.threadId}
          />
        )}
      </Modal>
    </>
  );
};

const CreateUploadAttachmentToCustomersModalContent = (props: {
  cancel: () => void;
  attachment?: InboxMessageAttachment;
  threadId?: string;
}) => {
  const hasAccess = useHasAccess();
  const { thread, refresh: refreshThread } = useThread(props.threadId ?? "");
  const { refresh: refreshMessages } = useThreadMessages(props.threadId ?? "");
  const setModal = useSetRecoilState(UploadInboxAttachmentToCustomersModalAtom);
  const attachmentAlreadyHasMovements = !!(
    props.attachment?.attachment_movements?.movements &&
    props.attachment.attachment_movements.total
  );
  console.log(attachmentAlreadyHasMovements);
  const selectedCustomers = useRef([] as ThreadRelatedCustomer[]);
  const [isMinOneSelected, setIsMinOneSelected] = useState(false);
  const save = async () => {
    try {
      const selectedCustomerIds: string[] = [];
      selectedCustomers.current.forEach((selectedCustomer) => {
        selectedCustomerIds.push(selectedCustomer.id);
      });
      InboxApiClient.uploadAttachmentToCustomersDocuments({
        attachment_id: props.attachment?.id || "",
        customer_ids: selectedCustomerIds,
      });

      refreshThread();
      refreshMessages();

      setModal({ open: false });
      toast.success("Attachment transfered !");
    } catch (e) {
      toast.error("Error trasnfering attachment");
    }
  };

  function customersWhomAttachmentWasAlreadyTransferedTo(): ThreadRelatedCustomer[] {
    if (attachmentAlreadyHasMovements) {
      const customerIdsMovedTo: string[] = [];
      props.attachment?.attachment_movements?.movements?.forEach((movement) => {
        customerIdsMovedTo.push(movement.customer_id);
      });

      const customers: ThreadRelatedCustomer[] = thread?.customers || [];
      const customersMovedTo: ThreadRelatedCustomer[] = [];
      customers.forEach((customer) => {
        if (customerIdsMovedTo.includes(customer.id)) {
          customersMovedTo.push(customer);
        }
      });

      return customersMovedTo;
    } else {
      return [];
    }
  }

  return (
    <>
      <ModalContent
        title={"Transfer " + props.attachment?.file_name + " to customers"}
      >
        <InputLabel
          label={"Thread customers"}
          input={
            <Table
              showPagination={false}
              rowIndex="id"
              onSelect={
                hasAccess("CUSTOMER_UPDATE")
                  ? (rows) => {
                      selectedCustomers.current = rows;
                      if (isEmpty(selectedCustomers.current)) {
                        setIsMinOneSelected(false);
                      } else {
                        setIsMinOneSelected(true);
                      }
                    }
                  : undefined
              }
              data={(thread?.customers || []).filter(
                (c) =>
                  !customersWhomAttachmentWasAlreadyTransferedTo()
                    .map((a) => a.id)
                    .includes(c.id)
              )}
              columns={[
                {
                  render: (row) => (
                    <div className="flex items-center">{row.external_id}</div>
                  ),
                },
                {
                  render: (row) => (
                    <div className="flex items-center">
                      <UserIcon className="h-5 w-5 inline mr-2 shrink-0" />
                      {row.first_name + " " + row.last_name}
                    </div>
                  ),
                },
              ]}
            />
          }
        />
        {attachmentAlreadyHasMovements && (
          <>
            <InputLabel
              className="mt-4"
              label="Customer(s) whom attachment was already transfered to"
              input={
                <Table
                  showPagination={false}
                  data={customersWhomAttachmentWasAlreadyTransferedTo()}
                  columns={[
                    {
                      render: (row) => (
                        <div className="flex items-center">
                          {row.external_id}
                        </div>
                      ),
                    },
                    {
                      render: (row) => (
                        <div className="flex items-center">
                          <UserIcon className="h-5 w-5 inline mr-2 shrink-0" />
                          {row.first_name + " " + row.last_name}
                        </div>
                      ),
                    },
                  ]}
                />
              }
            />
          </>
        )}
        <ButtonConfirm
          className="mt-6 float-left mr-2"
          theme="outlined"
          confirmMessage="Are you sure you want to cancel?"
          onClick={async () => props.cancel()}
        >
          Cancel
        </ButtonConfirm>
        <ButtonConfirm
          disabled={!isMinOneSelected}
          className="mt-6 float-right"
          theme="primary"
          confirmMessage="Are you sure you want to transfer this attachment ?"
          onClick={async () => {
            await save();
          }}
        >
          Confirm
        </ButtonConfirm>
      </ModalContent>
    </>
  );
};

import {
  MonitoringMetricHistoryOutput,
  MonitoringMetricsByDate,
} from "@features/dashboard/types";

export const getMetric = (
  a: MonitoringMetricsByDate[0],
  m: MonitoringMetricHistoryOutput["metric_name"]
) => {
  return (
    (a.monitoring_metrics || []).find((a) => a.metric_name === m)?.value || 0
  );
};

export const commonProductsOptions = {
  plugins: {
    legend: {
      position: "bottom",
      align: "start",
      // Dots instead of squares, width 8px
      labels: {
        pointStyle: "circle",
        boxWidth: 8,
        boxHeight: 8,
        borderRadius: 4,
        useBorderRadius: true,
      },
    },
  },
  responsive: true,
  animation: false,
  interaction: {
    mode: "nearest",
    intersect: false,
  },
  scales: {
    y: {
      stacked: true,
      ticks: {
        precision: 0,
      },
      min: 0,
    },
  },
  maintainAspectRatio: false,
};

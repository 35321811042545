import { useCallback } from "react";
import { useMutation, useQuery } from "react-query";
import { ScheduledAccessApiClient } from "../api-client/api-client";
import { ScheduledAccess, ScheduledAccessData } from "../types";

export function useScheduledAccess() {
  const getScheduledAccessQuery = useQuery<ScheduledAccess>({
    queryKey: ["scheduled-access"],
    queryFn: () => {
      return ScheduledAccessApiClient.getScheduledAccess();
    },
    refetchOnWindowFocus: false,
  });

  const enableScheduledAccessMutation = useMutation({
    mutationFn: async (enabled: boolean) => {
      await ScheduledAccessApiClient.enableScheduledAccess(enabled);
    },
  });

  const createAccessMutation = useMutation({
    mutationFn: async (access: ScheduledAccessData) => {
      await ScheduledAccessApiClient.createAccess(access);
    },
  });

  const updateAccessMutation = useMutation({
    mutationFn: async (access: ScheduledAccessData) => {
      await ScheduledAccessApiClient.editAccess(access);
    },
  });

  const deleteAccessMutation = useMutation({
    mutationFn: async (access: ScheduledAccessData) => {
      await ScheduledAccessApiClient.deleteAccess(access);
    },
  });

  const {
    data: scheduledAccess,
    isLoading,
    refetch: refetchScheduledAccess,
    isRefetching,
  } = getScheduledAccessQuery;
  const { mutateAsync: enableScheduledAccessMutate } =
    enableScheduledAccessMutation;
  const { mutateAsync: createAccessMutate, isLoading: creatingAccess } =
    createAccessMutation;
  const { mutateAsync: updateAccessMutate, isLoading: updatingAccess } =
    updateAccessMutation;
  const { mutateAsync: deleteAccessMutate, isLoading: deletingAccess } =
    deleteAccessMutation;

  const enableScheduledAccess = useCallback(
    async (enabled: boolean) => {
      await enableScheduledAccessMutate(enabled);
      await refetchScheduledAccess();
    },
    [enableScheduledAccessMutate, refetchScheduledAccess]
  );

  const createAccess = useCallback(
    async (access: ScheduledAccessData) => {
      await createAccessMutate(access);
      await refetchScheduledAccess();
    },
    [createAccessMutate, refetchScheduledAccess]
  );

  const updateAccess = useCallback(
    async (access: ScheduledAccessData) => {
      await updateAccessMutate(access);
      await refetchScheduledAccess();
    },
    [updateAccessMutate, refetchScheduledAccess]
  );

  const deleteAccess = useCallback(
    async (access: ScheduledAccessData) => {
      await deleteAccessMutate(access);
      await refetchScheduledAccess();
    },
    [deleteAccessMutate, refetchScheduledAccess]
  );

  return {
    scheduledAccess,
    isLoading: isLoading,
    creatingAccess,
    updatingAccess,
    deletingAccess,
    isRefetching,
    enableScheduledAccess,
    createAccess,
    updateAccess,
    deleteAccess,
  };
}

import { useFetch } from "@features/utils";
import { AuditExportFilters } from "../types";

export class AuditApiClient {
  static exportAlertsCSVZipped = async (
    filter: Omit<AuditExportFilters, "with_revisions">
  ) => {
    const response = await useFetch(`/web/audit/alerts/csv`, {
      method: "POST",
      body: JSON.stringify(filter),
    });
    if (!response.ok) {
      throw new Error(
        `POST /web/audit/alerts/csv HTTP error! Status: ${response.status}`
      );
    }
    const data = await response.blob();
    return data;
  };

  static exportCustomersCSVZipped = async (filter: AuditExportFilters) => {
    const response = await useFetch(`/web/audit/customers/csv`, {
      method: "POST",
      body: JSON.stringify(filter),
    });
    if (!response.ok) {
      throw new Error(
        `POST /web/audit/customers/csv HTTP error! Status: ${response.status}`
      );
    }
    const data = await response.blob();
    return data;
  };

  static exportSessionsCSVZipped = async (
    filter: Omit<AuditExportFilters, "with_revisions">
  ) => {
    const response = await useFetch(`/web/audit/sessions/csv`, {
      method: "POST",
      body: JSON.stringify(filter),
    });
    if (!response.ok) {
      throw new Error(
        `POST /web/audit/sessions/csv HTTP error! Status: ${response.status}`
      );
    }
    const data = await response.blob();
    return data;
  };

  static exportConfigurationsCSVZipped = async (
    filter: Omit<AuditExportFilters, "from_date" | "to_date">
  ) => {
    const response = await useFetch(`/web/audit/configurations/csv`, {
      method: "POST",
      body: JSON.stringify(filter),
    });
    if (!response.ok) {
      throw new Error(
        `POST /web/audit/configurations/csv HTTP error! Status: ${response.status}`
      );
    }
    const data = await response.blob();
    return data;
  };

  static exportMetricsHistoryCSVZipped = async (
    filter: Omit<AuditExportFilters, "with_revisions">
  ) => {
    const response = await useFetch(`/web/audit/dashboardmetricshistory/csv`, {
      method: "POST",
      body: JSON.stringify(filter),
    });
    if (!response.ok) {
      throw new Error(
        `POST /web/audit/dashboardmetricshistory/csv HTTP error! Status: ${response.status}`
      );
    }
    const data = await response.blob();
    return data;
  };

  static exportLogsAndNotifCSVZipped = async (
    filter: Omit<AuditExportFilters, "with_revisions">
  ) => {
    const response = await useFetch(`/web/audit/logsandnotifhistory/csv`, {
      method: "POST",
      body: JSON.stringify(filter),
    });
    if (!response.ok) {
      throw new Error(
        `POST /web/audit/logsandnotifhistory/csv HTTP error! Status: ${response.status}`
      );
    }
    const data = await response.blob();
    return data;
  };
}

import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import Select from "@atoms/select";
import { Base, Info, Section } from "@atoms/text";
import { Table } from "@components/table";
import { Column } from "@components/table/table";
import { useHasAccess } from "@features/auth/state/use-access";
import { useSessionsLabels } from "@features/sessions-labels/state/use-sessions-labels";
import { SessionLabelType } from "@features/sessions-labels/types";
import { useEffect, useState } from "react";

export const SessionsLabelsTables = () => {
  const { labels, save, remove, loading, refresh } = useSessionsLabels();
  const [modal, setModal] = useState<
    Partial<SessionLabelType> & { open: boolean }
  >({ open: false });
  const hasAccess = useHasAccess();

  useEffect(() => {
    refresh();
  }, [refresh]);

  const columns: Column<SessionLabelType>[] = [
    {
      title: "Label",
      render: (row) => (
        <Base className="flex items-center mr-2">{row.label}</Base>
      ),
    },
    {
      title: "Type",
      render: (row) => (
        <>
          <Tag
            noColor
            className={
              "text-white " +
              (row.type === "NEGATIVE"
                ? "bg-red-500"
                : row.type === "POSITIVE"
                ? "bg-green-500"
                : "bg-yellow-500")
            }
          >
            {row.type}
          </Tag>
        </>
      ),
    },
    {
      title: "Bot allowed",
      render: (row) => (
        <>
          <Tag
            noColor
            className={
              "text-white " + (row.is_auto ? "bg-yellow-500" : "bg-slate-500")
            }
          >
            {row.is_auto ? "YES" : "NO"}
          </Tag>
        </>
      ),
    },
    {
      title: "Actions",
      headClassName: "justify-end",
      className: "justify-end h-12",
      thClassName: "w-24",
      render: (row) =>
        hasAccess("SCENARIO_UPDATE") && (
          <Button
            theme="outlined"
            size="sm"
            onClick={() => setModal({ open: true, ...row })}
          >
            Edit
          </Button>
        ),
    },
  ];

  return (
    <>
      <Modal
        open={modal.open}
        onClose={() =>
          setModal({
            open: false,
          })
        }
      >
        <ModalContent title="Add/Edit session status">
          <InputLabel
            label="Label"
            input={
              <Input
                placeholder="Identity Session"
                value={modal.label || ""}
                onChange={(e) =>
                  setModal({
                    ...modal,
                    label: e.target.value,
                  })
                }
              />
            }
          />

          <InputLabel
            className="mt-4"
            label="Bot allowed"
            input={
              <>
                <Info>
                  "Bot allowed" status can be used by the Go!Vid robot
                  automatically.
                </Info>
                <Select
                  value={modal.is_auto ? "YES" : "NO"}
                  onChange={(e) =>
                    setModal({ ...modal, is_auto: e.target.value === "YES" })
                  }
                >
                  <option value="YES">Yes</option>
                  <option value="NO">No</option>
                </Select>
              </>
            }
          />

          <InputLabel
            className="mt-4"
            label="Type"
            input={
              <>
                <Select
                  value={modal.type || ""}
                  onChange={(e) =>
                    setModal({ ...modal, type: e.target.value as any })
                  }
                >
                  <option value="POSITIVE">Positive</option>
                  <option value="NEUTRAL">Neutral</option>
                  <option value="NEGATIVE">Negative</option>
                </Select>
              </>
            }
          />

          <ButtonConfirm
            confirmTitle="Save session label"
            className="mt-6 float-right"
            theme="primary"
            loading={loading}
            disabled={
              !modal.label ||
              !modal.type ||
              labels.some(
                (f) => f.type !== modal.type && f.label === modal.label
              )
            }
            onClick={async () => {
              if (
                await save(modal.id, {
                  is_auto: modal.is_auto || false,
                  type: modal.type || "NEUTRAL",
                  label: modal.label || "",
                })
              ) {
                setModal({ ...modal, open: false });
              }
            }}
          >
            Save
          </ButtonConfirm>

          {labels.find((f) => f.id === modal.id) && (
            <ButtonConfirm
              confirmTitle="Delete session label"
              confirmMessage="This will be applied now and sessions will not be searchable using this label anymore"
              className="mt-6"
              theme="danger"
              loading={loading}
              onClick={async () => {
                if (await remove(modal.id!)) {
                  setModal({ ...modal, open: false });
                }
              }}
            >
              Remove
            </ButtonConfirm>
          )}
        </ModalContent>
      </Modal>

      {hasAccess("SCENARIO_UPDATE") && (
        <Button
          className="float-right"
          size="sm"
          onClick={() =>
            setModal({
              open: true,
              type: "NEUTRAL",
              is_auto: false,
            })
          }
        >
          Create status
        </Button>
      )}
      <Section>Session status</Section>

      <Info className="block my-4">
        When a session is automatically scored, a label is chosen from the "bot
        allowed" list of status. Then any label can be chosen by a human agent.
      </Info>

      <Table
        data={labels}
        columns={columns}
        showPagination={false}
        loading={loading}
      />
    </>
  );
};

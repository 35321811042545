import { Info, InfoSmall } from "@atoms/text";

export default function GraphLegendPanel(props: {
  readonly nodesLength: number;
  readonly edgesLength: number;
}) {
  return (
    <div className="shrink flex flex-col items-end p-2 space-y-1 rounded mr-4 ">
      <Info className="font-bold">
        {props.nodesLength} NODES / {props.edgesLength} LINKS
      </Info>
      <div className="w-full rounded h-5 bg-gradient-to-r from-blue-100 to-blue-800"></div>
      <div className="w-full flex flex-row justify-between">
        <InfoSmall className="">
          Low <br /> Volume
        </InfoSmall>
        <InfoSmall className="text-right">
          Top <br /> Volume
        </InfoSmall>
      </div>
    </div>
  );
}

import InputDate from "@atoms/input/input-date";
import { InputDecorationIcon } from "@atoms/input/input-decoration-icon";
import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Input } from "@atoms/input/input-text";
import Select from "@atoms/select";
import { useReviewGroups } from "@features/agents/state/use-review-groups";
import { KytTransactionsRequestOptions } from "@features/kyt/types";
import {
  KYT_TRANSACTION_STATES,
  blockchainCurrencies,
} from "@features/kyt/utils";
import { useControlledEffect } from "@features/utils";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import _ from "lodash";
import { useState } from "react";

export const KytTransactionsListFilter = ({
  value,
  onChange,
}: {
  value: Partial<KytTransactionsRequestOptions>;
  onChange: (params: Partial<KytTransactionsRequestOptions>) => void;
}) => {
  const [filters, setFilters] =
    useState<Partial<KytTransactionsRequestOptions>>(value);

  const [advancedCustomer, setAdvancedCustomer] = useState(false);

  const { reviewGroups } = useReviewGroups();

  const [searchInput, setSearchInput] = useState<string>();

  useControlledEffect(() => {
    onChange({
      ...filters,
    });
  }, [filters]);

  const currencyOptions = [
    ...(filters.crypto_blockchain
      ? [
          { value: "converted", label: "~ Converted (EUR)" },
          ...blockchainCurrencies[filters.crypto_blockchain].map(
            (currency) => ({
              value: currency.code,
              label: currency.label,
            })
          ),
        ]
      : [
          { value: "converted", label: "~ Converted (EUR)" },
          { value: "EUR", label: "EUR" },
          ...Object.keys(blockchainCurrencies)
            .flatMap((blockchain) =>
              blockchainCurrencies[blockchain].map((currency) => currency)
            )
            .map((currency) => ({
              value: currency.code,
              label: currency.label,
            })),
        ]),
  ];
  if (
    searchInput &&
    !currencyOptions.find(
      (currency) =>
        currency.label.toUpperCase() === searchInput.toUpperCase() ||
        currency.value.toUpperCase() === searchInput.toUpperCase()
    )
  ) {
    currencyOptions.unshift({
      value: searchInput.toUpperCase(),
      label: searchInput.toUpperCase(),
    });
  }

  return (
    <div className="flex flex-row w-full space-x-2">
      <div className="grow flex flex-col space-y-2">
        <InputLabel
          label="Transaction ID"
          input={
            <Input
              value={filters.external_id ?? ""}
              onChange={(e) =>
                setFilters({ ...filters, external_id: e.target.value || null })
              }
              placeholder="TNX-123456"
            />
          }
        />
        <InputLabel
          label="Customer ID"
          className="-mr-4"
          input={
            <InputDecorationIcon
              input={({ className }) => (
                <Input
                  placeholder="Customer ID or name"
                  value={filters.customer ?? ""}
                  disabled={advancedCustomer}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      customer: e.target.value || null,
                    });
                  }}
                  className={className}
                />
              )}
              suffix={({ className }) => {
                className =
                  "absolute m-auto top-0 bottom-0 right-3 cursor-pointer h-5 w-5 hover:text-blue-700 text-blue-600";
                return advancedCustomer ? (
                  <MinusCircleIcon
                    onClick={() => {
                      setAdvancedCustomer(!advancedCustomer);
                      setFilters({
                        ...filters,
                        from_customer: null,
                        from_institution: null,
                        to_customer: null,
                        customer: null,
                        to_institution: null,
                      });
                    }}
                    className={className}
                  />
                ) : (
                  <PlusCircleIcon
                    data-tooltip="Precise from/to"
                    onClick={() => {
                      setAdvancedCustomer(!advancedCustomer);
                      setFilters({
                        ...filters,
                        customer: null,
                        from_customer: null,
                        from_institution: null,
                        to_customer: null,
                        to_institution: null,
                      });
                    }}
                    className={className}
                  />
                );
              }}
              className="mr-4 grow"
            />
          }
        />
        {advancedCustomer && (
          <div className="flex flex-col space-y-2 p-3 border rounded bg-slate-100">
            <div className="flex flex-row items-end space-x-2">
              <CustomerOrInstitution
                label={"From "}
                onChange={(type, value) => {
                  setFilters({
                    ...filters,
                    customer: null,
                    from_customer: null,
                    from_institution: null,
                    [type === "customer"
                      ? "from_customer"
                      : "from_institution"]: value,
                  });
                }}
              />
            </div>
            <div className="flex flex-row items-end space-x-2">
              <CustomerOrInstitution
                label={"To "}
                onChange={(type, value) => {
                  setFilters({
                    ...filters,
                    customer: null,
                    to_customer: null,
                    to_institution: null,
                    [type === "customer" ? "to_customer" : "to_institution"]:
                      value,
                  });
                }}
              />
            </div>
          </div>
        )}
      </div>

      <div className="w-1/4 flex flex-col space-y-2">
        <InputLabel
          label="Amount"
          input={
            <div className="flex relative">
              <Input
                className="rounded-r-none focus:z-10 -mr-px"
                highlight
                value={filters.amount_gte ?? ""}
                type="number"
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    amount_equals: null,
                    amount_gte: parseFloat(e.target.value) || null,
                  })
                }
                placeholder="Min"
              />
              <Input
                className="rounded-l-none focus:z-10"
                highlight
                value={filters.amount_lte ?? ""}
                type="number"
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    amount_equals: null,
                    amount_lte: parseFloat(e.target.value) || null,
                  })
                }
                placeholder="Max"
              />
            </div>
          }
        />
        <div className="flex flex-row relative">
          <InputLabel
            label="Currency"
            className="w-full"
            input={
              <SelectMultiple
                placeholder="Currency"
                className="focus:z-10"
                highlight
                selectionLimit={1}
                value={filters.currency ? [filters.currency] : []}
                onSearch={(_searchInput: string) => {
                  setSearchInput(_searchInput.trim());
                }}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    currency: e.length > 0 ? e[0] : null,
                  })
                }
                options={currencyOptions}
              />
            }
          />
        </div>
      </div>

      <div className="w-1/5 flex flex-col space-y-2">
        <div className="flex flex-row relative">
          <InputLabel
            label="Created After"
            className="w-1/2 focus-within:z-10 -mr-px"
            input={
              <InputDate
                className="rounded-r-none"
                highlight
                value={filters.after ?? null}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    after: e?.toISOString() ?? null,
                  })
                }
              />
            }
          />
          <InputLabel
            label="Created Before"
            className="w-1/2 focus-within:z-10"
            input={
              <InputDate
                className="rounded-l-none"
                highlight
                value={filters.before ?? null}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    before: e?.toISOString() ?? null,
                  })
                }
              />
            }
          />
        </div>
        <div className="flex flex-row relative">
          <InputLabel
            label="Blockchain"
            className="w-1/2 -mr-px focus-within:z-10"
            input={
              <SelectMultiple
                className="rounded-r-none"
                highlight
                selectionLimit={1}
                value={
                  filters.crypto_blockchain ? [filters.crypto_blockchain] : []
                }
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    crypto_blockchain: e.length > 0 ? e[0] : null,
                    currency: null,
                  })
                }
                options={Object.keys(blockchainCurrencies).map(
                  (blockchain) => ({
                    value: blockchain,
                    label: blockchain,
                  })
                )}
              />
            }
          />
          <InputLabel
            label="Transaction Hash"
            className="w-1/2 focus-within:z-10"
            input={
              <Input
                className="rounded-l-none focus:z-10"
                value={filters.crypto_tx_hash ?? ""}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    crypto_tx_hash: e.target.value || null,
                  })
                }
                placeholder="0x1234567890abcdef1234567890abcdef1234567890abcdef1234567890abcdef"
              />
            }
          />
        </div>
      </div>
      <div className="w-1/5 flex flex-col space-y-2">
        <InputLabel
          label="Status"
          input={
            <SelectMultiple
              highlight
              selectionLimit={1}
              value={filters.state ? [`${filters.state}`] : []}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  state: e.length > 0 ? parseInt(e[0]) : null,
                })
              }
              options={Object.keys(KYT_TRANSACTION_STATES).map((c) => ({
                value: `${c}`,
                label: _.capitalize(KYT_TRANSACTION_STATES[parseInt(c)]),
              }))}
            />
          }
        />
        <InputLabel
          label="Review groups"
          input={
            <SelectMultiple
              highlight
              value={
                filters.only_without_review_groups
                  ? ["none"]
                  : filters.review_groups ?? []
              }
              options={[
                { value: "none", label: "(None)" },
                ..._.sortBy(
                  reviewGroups.map((c) => ({
                    value: c,
                    label: c,
                  })),
                  "label"
                ),
              ]}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  only_without_review_groups: e.includes("none"),
                  review_groups: (!e.includes("none") && e) || [],
                })
              }
            />
          }
        />
      </div>
    </div>
  );
};

export const CustomerOrInstitution = ({
  label,
  onChange,
}: {
  label: string;
  onChange: (type: "customer" | "institution", value: string) => void;
}) => {
  const [institution, setInstitution] = useState(false);
  const [value, setValue] = useState("");
  return (
    <InputLabel
      label={label + (institution ? "Institution" : "Customer")}
      className="grow"
      input={
        <div className="flex flex-row relative">
          <Input
            className="focus:z-10 rounded-r-none -mr-px grow"
            highlight
            value={value ?? ""}
            onChange={(e) => {
              setValue(e.target.value || "");
              onChange(
                institution ? "institution" : "customer",
                e.target.value
              );
            }}
            placeholder="MI5007"
          />
          <Select
            className="focus:z-10 rounded-l-none !w-auto"
            value={institution ? "1" : ""}
            onChange={(e) => {
              const sel = e.target.value;
              setInstitution(sel === "1");
              onChange(
                sel === "1" ? "institution" : "customer",
                value ? value : ""
              );
            }}
          >
            <option value={""}>Customer</option>
            <option value={"1"}>Institution</option>
          </Select>
        </div>
      }
    />
  );
};

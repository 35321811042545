import { TransactionsAggregate } from "@features/kyt/types";

type TransactionsAggregateDay = {
  count: number;
  date: string;
  direction: string;
  external_id: string;
  max: number;
  min: number;
  status: string;
  sum: number;
  type: string;
};

export const alignDates = (
  dates: string[],
  data: TransactionsAggregateDay[]
) => {
  const aligned: TransactionsAggregateDay[] = [];
  for (const d of dates) {
    const found = data.find((a) => a.date === d);
    if (found) {
      aligned.push(found);
    } else {
      aligned.push({
        date: d,
        count: 0,
        max: 0,
        min: 0,
        sum: 0,
        direction: data[0]?.direction,
        external_id: data[0]?.external_id,
        status: data[0]?.status,
        type: data[0]?.type,
      });
    }
  }
  return aligned;
};

export const dailyAggregates = (
  agg: TransactionsAggregate[]
): TransactionsAggregateDay[] => {
  const daily: TransactionsAggregateDay[] = [];
  for (const a of agg) {
    for (const i in a.count) {
      daily.push({
        external_id: a.external_id,
        date: a.date + "-" + (parseInt(i) + 1).toString().padStart(2, "0"),
        count: a.count[i] || 0,
        max: a.max[i] || 0,
        min: a.min[i] || 0,
        sum: a.sum[i] || 0,
        direction: a.direction,
        status: a.status,
        type: a.type,
      });
    }
  }
  return daily;
};

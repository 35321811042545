import { useAuth } from "@features/auth/state/hooks";
import { useControlledEffect, useGlobalEffect } from "@features/utils";
import { LoadingAtom } from "@features/utils/loading";
import { websocketBus } from "@features/websockets";
import { useCallback, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { useRecoilCallback, useRecoilState, useRecoilValue } from "recoil";
import { AssignmentsApiClient } from "../api-client/api-client";
import { AssignmentType } from "../types";
import { AssignmentAtom, AssignmentsAtom } from "./store";

// This variable is just to avoid getting twice the refresh for manual change + websockets event from myself
let cancelRealtimeUpdate = false;

export const useAssignmentsRealtime = () => {
  const { agent, clientId } = useAuth();

  const updateAssignmentInRecoil = useRecoilCallback(
    ({ set }) =>
      async (event: AssignmentType) => {
        if (cancelRealtimeUpdate && event.agent_id === agent?.agent_id) {
          cancelRealtimeUpdate = false;
          return;
        }
        const assignment = await AssignmentsApiClient.getObjectAssignment(
          event.type,
          event.id
        );
        if (assignment)
          set(
            AssignmentAtom(`${assignment.type}-${assignment.id}`),
            assignment
          );
      },
    []
  );

  useGlobalEffect(
    `useRealtimeAssignments`,
    () => {
      if (clientId) {
        const room = `client/${clientId}/assignment`;
        websocketBus.emit("join", null, { room });
        websocketBus.on(room, (event: { data: AssignmentType }) => {
          updateAssignmentInRecoil(event.data);
        });
      }
    },
    [clientId]
  );

  return {};
};

export const useAssignments = (
  type:
    | "customer"
    | "alert"
    | "session"
    | "thread"
    | "transaction"
    | "pressreport",
  objectsIds: string[]
) => {
  const assignments = useRecoilValue(AssignmentsAtom(objectsIds));

  const updateAssignmentInRecoil = useRecoilCallback(
    ({ set }) =>
      async (assignment: AssignmentType) => {
        set(AssignmentAtom(`${assignment.type}-${assignment.id}`), assignment);
      },
    []
  );

  const refresh = useCallback(async () => {
    const assignments = await AssignmentsApiClient.getObjectAssignments(
      type,
      objectsIds
    );
    if (assignments)
      assignments.forEach((assignment) => {
        updateAssignmentInRecoil(assignment);
      });
  }, [type, objectsIds, updateAssignmentInRecoil]);

  useControlledEffect(() => {
    if (objectsIds?.length) refresh();
  }, [objectsIds.join("-")]);

  return { assignments, refresh };
};

export const useAssignmentState = (
  type:
    | "customer"
    | "alert"
    | "session"
    | "thread"
    | "transaction"
    | "pressreport",
  objectId: string
) => {
  const assignment = useRecoilValue(AssignmentAtom(`${type}-${objectId}`));
  return { assignment };
};

export const useAssignment = (
  type:
    | "customer"
    | "alert"
    | "session"
    | "thread"
    | "transaction"
    | "pressreport",
  objectId: string
) => {
  const loadedOnce = useRef(false);

  const [assignment, setAssignment] = useRecoilState(
    AssignmentAtom(`${type}-${objectId}`)
  );

  const [loading, setLoading] = useRecoilState(
    LoadingAtom(`use${type}Assignement${objectId}`)
  );

  const refresh = useCallback(async () => {
    setLoading(true);
    try {
      const assignment = await AssignmentsApiClient.getObjectAssignment(
        type,
        objectId
      );
      setAssignment(assignment);
      loadedOnce.current = true;
    } catch (e) {
      toast.error("Error loading customer assignment");
      console.log(e);
    }
    setLoading(false);
  }, [setLoading, setAssignment, objectId, type]);

  const assignMembers = useCallback(
    async (membersId: number[]) => {
      try {
        // add assignment members to the memberIds
        setLoading(true);
        cancelRealtimeUpdate = true;
        await AssignmentsApiClient.setObjectAssignment(
          type,
          objectId,
          membersId
        );
        refresh();
      } catch (error) {
        cancelRealtimeUpdate = false;
        setLoading(false);
        toast.error("Error assigning " + type + " to members");
        console.log(error);
      }
    },
    [objectId, refresh, type, setLoading]
  );

  useControlledEffect(() => {
    if (!assignment?.members?.length) refresh();
  }, []);

  useEffect(() => {
    //Only execute it the second time
    if (loadedOnce.current) toast.success("Assignment updated");
  }, [assignment?.members?.length]);

  return {
    assignment,
    loading,
    refresh,
    assignMembers,
  };
};

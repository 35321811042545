import {
  ALERTS_STATES_COLORS,
  ALERTS_STATES_NAMES,
  defaultAlertStates,
} from "@features/alerts/utils";
import { useBilling } from "@features/billing/use-billing";
import {
  CUSTOMERS_EDD_STATES,
  CUSTOMERS_EDD_STATES_COLORS,
  CUSTOMERS_EDD_STATES_ID,
} from "@features/customers/utils";
import {
  DASHBOARD_RISK_STATE,
  DASHBOARD_SCAN_STATE,
  MonitoringRiskLevelEnum,
} from "@features/dashboard/enums";
import { useDashboard } from "@features/dashboard/state/hooks";
import { formatNumber } from "@features/utils";
import { formatDuration, formatTime } from "@features/utils/dates";
import { useEmbedUtils } from "@features/utils/use-embed-utils";
import React from "react";
import {
  CHAT_THREAD_STATE_COLORS,
  CHAT_THREAD_STATE_NAMES,
} from "../live/charts/chat";

export const EmbedDashboard = () => {
  const {
    isKYTEnabled,
    isRiskEnabled,
    isOnboardingEnabled,
    isChatEnabled,
    isScanEnabled,
    plan,
  } = useBilling();
  const { displayProduct } = useEmbedUtils();
  const { risk, scan, onboarding, chat, kyt } = useDashboard();
  return (
    <div
      style={{
        overflow: "hidden",
      }}
    >
      <h3 style={{ marginBottom: "0.5rem" }}>Outstanding tasks</h3>
      <div
        className="embed-flex-auto-layout flex flex-col sm:flex-row"
        style={{
          justifyContent: "space-between",
          overflow: "hidden",
          marginRight: "-0.375rem",
        }}
      >
        {isRiskEnabled && displayProduct("gorisk") && (
          <OutstandingNumber
            label="Go!Risk"
            total={
              risk?.global_risk_info?.edd_states?.find(
                (a) => a.id === CUSTOMERS_EDD_STATES_ID.ACTION_REQUIRED
              )?.value
            }
            amount={risk?.outstanding?.amount}
            days={risk?.outstanding?.days}
          />
        )}
        {isChatEnabled && displayProduct("gochat") && (
          <OutstandingNumber
            label="Go!Chat"
            total={chat?.all_threads_by_status?.open_threads_count}
            amount={chat?.outstanding?.amount}
            days={chat?.outstanding?.days}
          />
        )}
        {isScanEnabled && displayProduct("goscan") && (
          <OutstandingNumber
            label="Go!Scan"
            total={
              scan?.global_scan_info?.find(
                (a) => a.alert_state === defaultAlertStates.new
              )?.value
            }
            amount={scan?.outstanding?.amount}
            days={scan?.outstanding?.days}
          />
        )}
        {isOnboardingEnabled && displayProduct("govid") && (
          <OutstandingNumber
            label="Go!Vid"
            total={
              (onboarding?.status_statistics?.total_without_status || 0) +
              (onboarding?.status_statistics?.items
                ?.filter((a) => a.status.type === "NEUTRAL")
                ?.reduce((acc, a) => acc + a.total, 0) || 0)
            }
            amount={onboarding?.outstanding?.amount}
            days={onboarding?.outstanding?.days}
          />
        )}
        {isKYTEnabled && displayProduct("gokyt") && (
          <OutstandingNumber
            label="Go!KYT"
            total={
              kyt?.last_30_days_transactions_by_status
                ?.delayed_transactions_count
            }
            amount={kyt?.outstanding?.amount}
            days={kyt?.outstanding?.days}
          />
        )}
      </div>

      {isRiskEnabled && displayProduct("gorisk") && (
        <>
          <h3 style={{ marginBottom: "0.5rem" }}>Go!Risk</h3>
          <ProductLayout
            chart={
              <Chart
                data={Object.values(risk?.global_risk_info.edd_states || {})
                  .filter((a) => a.value > 0)
                  .map((a) => ({
                    label: (CUSTOMERS_EDD_STATES as any)[a.id] || "Unknown",
                    value: a.value,
                    color:
                      (CUSTOMERS_EDD_STATES_COLORS as any)[a.id] || "#000000",
                  }))}
              />
            }
            labels1={
              <>
                <LabelValue
                  label="Active customers"
                  value={formatNumber(
                    risk?.global_risk_info?.risk_levels?.reduce(
                      (acc, a) => acc + a.value,
                      0
                    ) || 0
                  )}
                />
                <LabelValue
                  label="Scoring status"
                  value={
                    <>
                      <Dot
                        color={
                          risk?.last_risk_scoring_info?.state === 3
                            ? "#10B981"
                            : risk?.last_risk_scoring_info?.state === 1
                            ? "#FBBF24"
                            : "#EF4444"
                        }
                      />
                      {risk?.last_risk_scoring_info?.start_date
                        ? DASHBOARD_RISK_STATE[
                            risk?.last_risk_scoring_info?.state || 1
                          ]
                        : "Not started"}{" "}
                      {risk?.last_risk_scoring_info?.start_date
                        ? formatTime(
                            risk?.last_risk_scoring_info?.start_date || 0,
                            undefined,
                            { keepTime: true, keepDate: true }
                          )
                        : "Never"}{" "}
                      (
                      {formatDuration(
                        Math.max(
                          0,
                          new Date(
                            risk?.last_risk_scoring_info?.end_date || 0
                          ).getTime() -
                            new Date(
                              risk?.last_risk_scoring_info?.start_date || 0
                            ).getTime()
                        )
                      )}
                      )
                    </>
                  }
                />
              </>
            }
            labels2={
              <LabelValue
                label="Risk levels"
                value={
                  <>
                    <Dot color={"#10B981"} />
                    {formatNumber(
                      risk?.global_risk_info.risk_levels?.find(
                        (a) => a.id === MonitoringRiskLevelEnum.LOW
                      )?.value || 0
                    )}
                    <br />
                    <Dot color={"#FBBF24"} />
                    {formatNumber(
                      risk?.global_risk_info.risk_levels?.find(
                        (a) => a.id === MonitoringRiskLevelEnum.MEDIUM
                      )?.value || 0
                    )}
                    <br />
                    <Dot color={"#EF4444"} />
                    {formatNumber(
                      risk?.global_risk_info.risk_levels?.find(
                        (a) => a.id === MonitoringRiskLevelEnum.HIGH
                      )?.value || 0
                    )}
                    <br />
                    <Dot color={"#888888"} />
                    {formatNumber(
                      risk?.global_risk_info.risk_levels?.find(
                        (a) => a.id === MonitoringRiskLevelEnum.UNDEFINED
                      )?.value || 0
                    )}
                  </>
                }
              />
            }
          />
        </>
      )}

      {isChatEnabled && displayProduct("gochat") && (
        <>
          <h3 style={{ marginBottom: "0.5rem" }}>Go!Chat</h3>
          <ProductLayout
            chart={
              <Chart
                data={Object.keys(chat?.all_threads_by_status || {})
                  .map((a) => ({
                    label: a,
                    value: (chat?.all_threads_by_status || ({} as any))[
                      a
                    ] as number,
                  }))
                  .filter((a) => a.value > 0)
                  .map((a) => ({
                    value: a.value,
                    label:
                      (CHAT_THREAD_STATE_NAMES as any)[a.label] || "Unknown",
                    color:
                      (CHAT_THREAD_STATE_COLORS as any)[a.label] || "#000000",
                  }))}
              />
            }
            labels1={
              <>
                <LabelValue
                  label="Close (last 7 days)"
                  value={formatNumber(
                    chat?.last_7_days_threads_by_status?.closed_threads_count ||
                      0
                  )}
                />
                <LabelValue
                  label="Open (last 7 days)"
                  value={formatNumber(
                    chat?.last_7_days_threads_by_status?.open_threads_count || 0
                  )}
                />
              </>
            }
            labels2={
              <LabelValue
                label="Customer action (last 7 days)"
                value={
                  <>
                    {formatNumber(
                      chat?.last_7_days_threads_by_status
                        ?.waiting_threads_count || 0
                    )}{" "}
                    waiting,{" "}
                    {formatNumber(
                      chat?.last_7_days_threads_by_status
                        ?.reminder_threads_count || 0
                    )}{" "}
                    reminders
                  </>
                }
              />
            }
          />
        </>
      )}

      {isScanEnabled && displayProduct("goscan") && (
        <>
          <h3 style={{ marginBottom: "0.5rem" }}>Go!Scan</h3>
          <ProductLayout
            chart={
              <Chart
                data={Object.values(scan?.global_scan_info || {})
                  .filter((a) => a.value > 0)
                  .map((a) => ({
                    label:
                      (ALERTS_STATES_NAMES as any)[a.alert_state] || "Unknown",
                    value: a.value,
                    color:
                      (ALERTS_STATES_COLORS as any)[a.alert_state] || "#000000",
                  }))}
              />
            }
            labels2={
              <>
                <LabelValue
                  label="Scan status"
                  value={
                    <>
                      <Dot
                        color={
                          scan?.last_scan_info?.state === 3
                            ? "#10B981"
                            : scan?.last_scan_info?.state === 1
                            ? "#FBBF24"
                            : "#EF4444"
                        }
                      />
                      {scan?.last_scan_info?.start_date
                        ? DASHBOARD_SCAN_STATE[scan?.last_scan_info?.state || 1]
                        : "Not started"}
                      {scan?.last_scan_info?.state !== 3 && (
                        <> ({scan?.last_scan_info?.progress || 0}%)</>
                      )}
                    </>
                  }
                />
                <LabelValue
                  label="Customers scanned"
                  value={formatNumber(
                    scan?.last_scan_info?.customer_records || 0
                  )}
                />
              </>
            }
            labels1={
              <>
                <LabelValue
                  label="Watchlists last update"
                  value={
                    <>
                      {formatTime(
                        scan?.last_dj_import_info?.end_date || 0,
                        undefined,
                        {
                          keepTime: true,
                          keepDate: true,
                        }
                      )}
                      <br />
                      <br />
                      <Dot color={"#10B981"} />
                      Sanctions lists (SAN)
                      <br />
                      <Dot color={"#10B981"} />
                      PEP lists
                      <br />
                      <Dot color={"#10B981"} />
                      Special Interest Entities lists (SIP/SIE)
                      <br />
                      {plan.scan_available_lists.includes("ame") && (
                        <>
                          <Dot color={"#10B981"} />
                          Adverse Medias
                          <br />
                        </>
                      )}
                      {plan.scan_available_lists.includes("soc") && (
                        <>
                          <Dot color={"#10B981"} />
                          State Owned Entities
                          <br />
                        </>
                      )}
                    </>
                  }
                />

                <LabelValue
                  label="Watchlists total records"
                  value={formatNumber(
                    scan?.last_dj_import_info?.records_after_prefiltering || 0
                  )}
                />
              </>
            }
          />
        </>
      )}

      {isOnboardingEnabled && displayProduct("govid") && (
        <>
          <h3 style={{ marginBottom: "0.5rem" }}>Go!Vid</h3>
          <ProductLayout
            chart={
              <Chart
                data={(onboarding?.status_statistics?.items || [])
                  .filter((a) => a.total > 0)
                  .map((a) => ({
                    label: a.status.label,
                    value: a.total,
                    color:
                      a.status.type === "NEUTRAL"
                        ? "#FBBF24"
                        : a.status.type === "POSITIVE"
                        ? "#10B981"
                        : "#EF4444",
                  }))}
              />
            }
            labels2={
              <LabelValue
                label="Average score (24h)"
                value={
                  <>
                    {formatNumber(
                      onboarding?.last_24h_sessions_average_score
                        .last_24h_average_score || 0
                    )}
                    %
                  </>
                }
              />
            }
            labels1={
              <>
                <LabelValue
                  label="Completed sessions"
                  value={formatNumber(
                    onboarding?.global_onboarding_info.completed_sessions || 0
                  )}
                />

                <LabelValue
                  label="Unfinished sessions"
                  value={formatNumber(
                    onboarding?.global_onboarding_info.unfinished_sessions || 0
                  )}
                />
              </>
            }
          />
        </>
      )}

      {isKYTEnabled && displayProduct("gokyt") && (
        <>
          <h3 style={{ marginBottom: "0.5rem" }}>Go!KYT</h3>
          <ProductLayout
            labels1={
              <LabelValue
                label={"Total transactions (last 30d)"}
                value={formatNumber(
                  Object.values(
                    kyt?.last_30_days_transactions_by_status || {}
                  ).reduce((acc, a) => a + acc, 0) || 0
                )}
              />
            }
            labels2={<></>}
          />
        </>
      )}
    </div>
  );
};

export const LabelValue = ({
  label,
  value,
}: {
  label: string;
  value: number | string | React.ReactNode;
}) => {
  return (
    <div style={{ marginBottom: 12, lineHeight: "1rem" }}>
      <div
        style={{
          opacity: 1,
          marginBottom: 4,
          fontSize: "0.875rem",
          lineHeight: "1rem",
          color: "#888888",
        }}
      >
        {label}
      </div>
      <div
        style={{
          fontSize: "1rem",
        }}
      >
        {value}
      </div>
    </div>
  );
};

export const OutstandingNumber = ({
  amount,
  total,
  days,
  label,
}: {
  label: string;
  amount?: number;
  total?: number;
  days?: number;
}) => {
  let color = "";

  if (amount && amount > 0) {
    color = "#EF4444";
  } else if (total && total > 0) {
    color = "#FBBF24";
  }
  if (amount && amount === 0 && total && total === 0) {
    color = "#34D399";
  }

  return (
    <div
      style={{
        opacity: 1,
        backgroundColor: color || "#88888844",
        color: color === "" ? "black" : "white",
        width: "100%",
        padding: "1rem",
        textAlign: "center",
        justifyItems: "center",
        alignItems: "center",
        boxSizing: "border-box",
        borderRadius: "5px",
        marginRight: "0.375rem",
        marginBottom: "0.375rem",
      }}
    >
      <h3 style={{ margin: 0, fontSize: "1rem", marginBottom: "0.375rem" }}>
        {label}
      </h3>
      <div
        style={{
          fontSize: "2rem",
          fontWeight: 700,
          display: "block",
          marginBottom: "0.5rem",
        }}
      >
        {total === undefined ? "-" : total || "0"}
      </div>
      {(amount || 0) > 0 && (
        <p
          style={{
            fontSize: "1rem",
            textAlign: "center",
            margin: 0,
          }}
        >
          <b>
            {amount || 0} late ({days || 0}d old)
          </b>
        </p>
      )}
      {(amount || 0) === 0 && (
        <p
          style={{
            fontSize: "1rem",
            textAlign: "center",
            margin: 0,
          }}
        >
          No late tasks
        </p>
      )}
    </div>
  );
};

const Dot = ({ color }: { color: string }) => (
  <div
    style={{
      display: "inline-block",
      marginRight: "0.375rem",
      width: "12px",
      height: "12px",
      borderRadius: "50%",
      backgroundColor: color,
    }}
  />
);

const Chart = (props: {
  data: { label: string; value: number; color: string }[];
}) => {
  const max = Math.max(...props.data.map((a) => a.value));

  return (
    <div
      style={{
        lineHeight: "1rem",
        width: "100%",
        borderCollapse: "collapse",
        borderSpacing: 0,
        fontSize: "1rem",
      }}
    >
      {props.data.map((a) => (
        <div
          key={a.label}
          style={{
            height: "1.375rem",
            flexDirection: "row",
            display: "flex",
          }}
        >
          <div
            style={{
              width: "75%",
            }}
          >
            <div
              style={{
                borderRadius: "5px",
                width: Math.max(5, Math.floor((a.value / max) * 100)) + "%",
                backgroundColor: a.color,
                height: "1rem",
              }}
            />
          </div>
          <div
            style={{
              paddingLeft: "1rem",
              lineHeight: "1rem",
              textAlign: "left",
              whiteSpace: "nowrap",
            }}
          >
            <Dot color={a.color} /> {a.label}: {formatNumber(a.value)}
          </div>
        </div>
      ))}
    </div>
  );
};

const ProductLayout = ({
  chart,
  labels1,
  labels2,
}: {
  chart?: React.ReactNode;
  labels1: React.ReactNode;
  labels2: React.ReactNode;
}) => (
  <div
    style={{
      borderRadius: "5px",
      border: "1px solid #88888844",
    }}
  >
    {chart && (
      <div
        style={{
          width: "100%",
          borderBottom: "1px solid #88888844",
        }}
      >
        <div style={{ padding: "1rem" }}>{chart}</div>
      </div>
    )}
    <div
      style={{
        width: "100%",
        display: "flex",
        padding: "1rem",
        paddingBottom: "0",
        flexDirection: "row",
      }}
    >
      <div
        style={{
          width: "49%",
          marginRight: "1%",
        }}
      >
        {labels1}
      </div>
      <div>{labels2}</div>
    </div>
  </div>
);

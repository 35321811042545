import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { Page } from "@atoms/layout/page";
import { PageBlock, PageBlockHr } from "@atoms/layout/page-block";
import { Base, Info, Section, SectionSmall, Title } from "@atoms/text";
import Assignments from "@components/assignations";
import { PageLoader } from "@components/page-loader";
import { useHasAccess } from "@features/auth/state/use-access";
import { useTransaction } from "@features/kyt/state/use-transaction";
import {
  KYT_TRANSACTION_STATES_COLORS,
  KYT_TRANSACTION_STATES_LABELS,
} from "@features/kyt/utils";
import { formatTime } from "@features/utils/dates";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TransactionAmount from "../components/transaction-amount";
import TransactionParticipant from "../components/transaction-participant";
import TransactionTimeline from "./timeline";
import Link from "@atoms/link";
import { ROUTES } from "@features/routes";
import { Menu } from "./menu";

export default function TransactionDetailsPage() {
  const { id } = useParams();
  const { transaction, revisions, loading, refresh, from, to } = useTransaction(id ?? "");
  const hasAccess = useHasAccess();
  const [viewMore, setViewMore] = useState(false);

  useEffect(() => {
    refresh();
  }, [id, refresh]);
  return (
    <Page>
      <Title>
        <div className="flex flex-row items-center">
          <div className="grow flex items-center">
            <span>Transaction "{transaction?.external_id}"</span>
            {transaction?.state !== undefined && (
              <Tag
                noColor
                className={
                  "bg-" +
                  KYT_TRANSACTION_STATES_COLORS[transaction.state] +
                  " text-white ml-2"
                }
              >
                {KYT_TRANSACTION_STATES_LABELS[transaction.state]}
              </Tag>
            )}
          </div>
          <div>
            <Button
              size="sm"
              theme="outlined"
              onClick={() => {
                // navigate to graph explorer
              }}
            >
              <Link
                target="_blank"
                to={ROUTES.ExploreTransaction.replace(
                  ":id",
                  transaction?.id ?? ""
                )}
              >
                Show in graph explorer
              </Link>
            </Button>
          </div>
        </div>
      </Title>
      {!transaction && loading && (
        <div style={{ marginTop: "30vh" }}>
          <PageLoader />
        </div>
      )}
      {!transaction && !loading && <Info>This transaction was not found.</Info>}
      {transaction && (
        <>
          <div className="flex flex-row space-x-2 py-2 items-center justify-between ">
            <Section className="mb-0">Customers</Section>
            <Menu fromId={from?.data?.id} toId={to?.data?.id} />
          </div>
          <div className="flex flex-row space-x-2">
            <TransactionParticipant
              id={from?.data?.id}
              participant={transaction.from}
              full={viewMore}
              direction="from"
              className="border-l-2 border-l-blue-500"
              onShowMore={(viewMore) => setViewMore(viewMore)}
            />
            <TransactionParticipant
              id={to?.data?.id}
              participant={transaction.to}
              full={viewMore}
              direction="to"
              className="border-l-2 border-l-red-500"
              onShowMore={(viewMore) => setViewMore(viewMore)}
            />
          </div>
          <div className="flex flex-row space-x-10">
            <div className="w-1/4 flex flex-col mt-4">
              <Assignments
                type="transaction"
                id={transaction.id}
                groups={transaction.review_groups ?? []}
                readonly={!hasAccess("KYT_MANAGE")}
              />
              <TransactionTimeline
                transaction={transaction}
                revisions={revisions ?? []}
              />
            </div>
            <div className="grow flex flex-col">
              <Section className="mt-4">Transaction details</Section>
              <PageBlock>
                <Info>
                  Date:{" "}
                  {formatTime(transaction.date, undefined, {
                    keepDate: true,
                    keepTime: true,
                  })}
                </Info>
                <SectionSmall>Amount</SectionSmall>
                <TransactionAmount transaction={transaction} />
                <PageBlockHr />
                <SectionSmall>Blockchain</SectionSmall>
                <Base>
                  <Info>Blockchain: </Info>
                  {transaction.crypto?.blockchain ?? "Undefined"}
                </Base>
                <br />
                <Base>
                  <Info>Transaction hash: </Info>
                  {transaction.crypto?.tx_hash ?? "Undefined"}
                </Base>
                <PageBlockHr />
                <div className="flex flex-row">
                  <SectionSmall>Transaction Scoring</SectionSmall>
                  <div className="grow"></div>
                  <Button
                    onClick={() => {
                      // TODO: add scoring rules details here.
                    }}
                    disabled
                    size="sm"
                    theme="outlined"
                    className="float-right"
                  >
                    Scoring Rules
                  </Button>
                </div>
                <Base>
                  <Info>Transaction status: </Info>
                  <Tag
                    noColor
                    className={
                      "text-white block  text-sm bg-" +
                      KYT_TRANSACTION_STATES_COLORS[transaction.state]
                    }
                  >
                    {KYT_TRANSACTION_STATES_LABELS[transaction.state] ||
                      "Undefined"}
                  </Tag>
                </Base>
                <br />
                <Base>
                  <Info>Risk Score: </Info>
                  {(transaction.score ?? 0) * 100 + "%"}
                </Base>
              </PageBlock>
            </div>
          </div>
        </>
      )}
    </Page>
  );
}

/**
 * This util will create a bottom up genealogic tree
 * @param nodes
 * @param edges
 * @param boundingBox
 */
export const computePositions = (
  nodes: { id: string }[],
  edges: { id: string; source: string; target: string }[],
  sizes: { w: number; h: number; px: number; py: number },
  alreadyProcessed: { id: string }[] = [],
  direction: "right" | "top" = "right"
) => {
  const boundingBox: { w: number; h: number } = {
    w: 0,
    h: 0,
  };
  const nodesPosition: { id: string; x: number; y: number }[] = [];

  const currentNode = nodes[0];
  const directChildrenEdges = edges.filter(
    (e) => e.source === currentNode.id || e.target === currentNode.id
  );
  const directChildren = nodes
    .filter(
      (n) =>
        directChildrenEdges.find(
          (e) => e.source === n.id || e.target === n.id
        ) && n.id !== currentNode.id
    )
    .filter((e) => !alreadyProcessed.find((n) => n.id === e.id));

  nodesPosition.push({
    id: currentNode.id,
    x: 0,
    y: 0,
  });
  boundingBox.w = sizes.w;
  boundingBox.h = sizes.h;

  if (directChildren.length === 0) {
    return {
      boundingBox,
      nodesPosition,
    };
  }

  let childrenW = 0;
  let childrenH = 0;
  const childrenPositions: { id: string; x: number; y: number }[] = [];

  const computed = [];

  for (const child of directChildren) {
    computed.push(
      computePositions(
        [child, ...nodes],
        edges,
        sizes,
        [...alreadyProcessed, currentNode, ...directChildren],
        direction
      )
    );
  }

  for (const res of computed) {
    let yDelta = 0;
    let xDelta = 0;

    if (direction === "right") {
      //Move all the nodes to the right of the current node
      xDelta = sizes.w + sizes.px;
      const maxBBH = computed
        .map((a) => a.boundingBox.h)
        .reduce((a, acc) => Math.max(a, acc), 0);
      //Move all the nodes to the bottom of the other child
      yDelta = childrenH + (maxBBH - res.boundingBox.h) / 2;
      childrenH += maxBBH + sizes.py;
      childrenW = Math.max(childrenW, res.boundingBox.w + sizes.px);
    } else {
      //Move up all the nodes to the top of the current node
      yDelta = -res.boundingBox.h - sizes.py;
      const maxBBW = computed
        .map((a) => a.boundingBox.w)
        .reduce((a, acc) => Math.max(a, acc), 0);
      //Move all the nodes to the right of the other child
      xDelta = childrenW + (maxBBW - res.boundingBox.w) / 2;
      childrenW += maxBBW + sizes.px;
      childrenH = Math.max(childrenH, res.boundingBox.h + sizes.py);
    }

    res.nodesPosition.forEach((p) => {
      childrenPositions.push({
        id: p.id,
        x: p.x + xDelta,
        y: p.y + yDelta,
      });
    });
  }
  childrenW -= sizes.px;
  childrenH -= sizes.py;

  //Now recenter all children
  const xDelta = direction === "top" ? -childrenW / 2 + sizes.w / 2 : 0;
  const yDelta = direction === "right" ? -childrenH / 2 + sizes.h / 2 : 0;
  childrenPositions.forEach((p) => {
    p.x += xDelta;
    p.y += yDelta;
  });
  nodesPosition.push(...childrenPositions);

  //Now make sure all nodes are on top left corner
  const finalDX = -nodesPosition.reduce(
    (acc, p) => Math.min(acc, p.x),
    Number.MAX_SAFE_INTEGER
  );
  const finalDY = -nodesPosition.reduce(
    (acc, p) => Math.min(acc, p.y),
    Number.MAX_SAFE_INTEGER
  );
  nodesPosition.forEach((p) => {
    p.x += finalDX;
    p.y += finalDY;
  });

  if (direction === "right") {
    boundingBox.h = Math.max(boundingBox.h, childrenH);
    boundingBox.w = childrenW + sizes.w + sizes.px;
  } else {
    boundingBox.w = Math.max(boundingBox.w, childrenW);
    boundingBox.h = childrenH + sizes.h + sizes.py;
  }

  return {
    boundingBox,
    nodesPosition,
  };
};

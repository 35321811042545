export const DASHBOARD_RISK_STATE: any = {
  1: "In progress",
  2: "Error",
  3: "Finished",
};

export const DASHBOARD_SCAN_STATE: any = {
  1: "In progress",
  2: "Error",
  3: "Finished",
};

export const MonitoringRiskLevelEnum = {
  UNDEFINED: 1,
  LOW: 2,
  MEDIUM: 3,
  HIGH: 4,
};

export const MonitoringRiskEddEnum = {
  UNDEFINED: 1,
  ERROR: 2,
  REQUIRED: 3,
  NOT_REQUIRED: 4,
  PENDING: 5,
  FAILED: 6,
  PASSED: 7,
};

export const MonitoringScanStateEnum = {
  UNKNOWN: 1,
  NEW: 2,
  PENDING: 3,
  TRUE_HIT: 4,
  TRUE_HIT_ACCEPTED: 5,
  TRUE_HIT_REJECTED: 6,
  FALSE_POSITIVE: 7,
};

export const MonitoringKytState = {
  ALLOWED: 1,
  DELAYED: 2,
  BLOCKED: 3,
};

import Avatar from "@atoms/avatar/avatar";
import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { Base, InfoSmall } from "@atoms/text";
import { useAuth } from "@features/auth/state/hooks";
import {
  LockClosedIcon,
  ArrowRightOnRectangleIcon,
  ArrowsRightLeftIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/20/solid";
import { useState } from "react";

export default function SwitchAccounts(props: {
  setUpdatePassword: (value: boolean) => void;
  setAccountModal: (value: boolean) => void;
  setSecurity: (value: boolean) => void;
}) {
  const { user, logout } = useAuth();

  return (
    <div className="flex flex-col">
      <AccountRow />
      <div className="my-4 -mx-6" />
      <div id="other-accounts" className="space-y-4">
        {(user?.clients || [])
          .filter((c) => c.active)
          .map((client) => {
            return <ClientRow key={client.id} client={client} />;
          })}
      </div>
      <div className="my-4 -mx-6" />
      <Button
        size="md"
        theme="default"
        className="mb-3"
        onClick={() => {
          props.setAccountModal(false);
          setTimeout(() => {
            props.setSecurity(true);
          }, 500);
        }}
      >
        <UserIcon className="h-5 w-5 mr-2" /> Security
      </Button>
      <Button
        size="md"
        theme="default"
        className="mb-3"
        onClick={() => {
          props.setAccountModal(false);
          setTimeout(() => {
            props.setUpdatePassword(true);
          }, 500);
        }}
      >
        <LockClosedIcon className="h-5 w-5 mr-2" /> Change password
      </Button>
      <Button
        size="md"
        theme="danger"
        onClick={() => {
          logout();
        }}
      >
        <ArrowRightOnRectangleIcon className="h-5 w-5 mr-2" /> Logout
      </Button>
    </div>
  );
}

export function AccountRow() {
  const { user: currentUser } = useAuth();

  return (
    <div className="flex flex-row items-center">
      <Avatar
        size={14}
        avatar={currentUser?.avatar}
        fallback={currentUser?.name}
        className=" mr-3"
      />
      <div className="flex flex-col mb-1">
        <Base className="font-semibold">{currentUser?.name}</Base>
        <InfoSmall className="font-semibold ">
          {currentUser?.email_login}
        </InfoSmall>
      </div>
    </div>
  );
}
export function ClientRow(props: {
  client: {
    agent_id: number;
    id: number;
    label: string;
    code: string;
    active: boolean;
    review_groups: string[] | null;
  };
}) {
  const { switchCurrentClient, clientId: _clientId } = useAuth();
  const [clientId, setClientId] = useState(_clientId);
  return (
    <div className="flex flex-row items-center">
      <Avatar
        shape="square"
        size={8}
        fallback={props.client.label}
        className="mr-3"
      />
      <div className="flex flex-col">
        <Base className="font-semibold capitalize">{props.client.label}</Base>
      </div>
      <div className="grow" />
      <div>
        {clientId === props.client.id && (
          <Tag>
            <StarIcon className="w-4 h-4 mr-1 inline-block" />
            Current
          </Tag>
        )}
        {clientId !== props.client.id && (
          <Button
            size="sm"
            onClick={() => {
              setClientId(props.client.id);
              switchCurrentClient(props.client.id);
            }}
          >
            <ArrowsRightLeftIcon className="w-4 h-4 mr-1 inline-block" />
            Switch
          </Button>
        )}
      </div>
    </div>
  );
}

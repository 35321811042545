import { AppFeatureAccess } from "@features/access";
import { useCallback } from "react";
import { useAuth } from "./hooks";

export const useHasAccess = () => {
  const { user } = useAuth();
  return useCallback(
    (requested: keyof typeof AppFeatureAccess) => {
      const permissions = user?.permissions || [];
      return AppFeatureAccess[requested].some((p) => permissions.includes(p));
    },
    [user?.permissions]
  );
};

import React from "react";
import Editor from "@monaco-editor/react";

export default function SQLQueryEditor(props: {
  value: string;
  height?: number;
  onChange: (value: string) => void;
}) {
  return (
    <Editor
      width="100%"
      height={props.height ?? 100}
      options={{
        lineDecorationsWidth: 0,
        minimap: { enabled: false },
        lineNumbers: "off",
        suggest: {
          showWords: false,
        },
        bracketPairColorization: {
          enabled: true,
        },
      }}
      language="sql"
      onChange={(value) => props.onChange(value || "")}
      defaultValue={props.value}
    />
  );
}

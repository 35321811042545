import SingleCenterCard from "@components/single-center-card/single-center-card";
import { useAuth } from "@features/auth/state/hooks";
import { ROUTES } from "@features/routes";
import { useCallback, useEffect } from "react";
import { Route, useNavigate } from "react-router-dom";
import { Login } from "./login";
import { Setup2FA } from "./setup-2fa";

export const LoginRoutes = () => {
  return (
    <Route path={ROUTES.Login}>
      <Route path={""} element={<LoginPage />} />
      <Route path={ROUTES.Setup2FA} element={<Setup2FA />} />
    </Route>
  );
};

const LoginPage = () => {
  const navigate = useNavigate();
  const { user, needs2FA } = useAuth();

  const redirectToApp = useCallback(() => {
    setTimeout(() => {
      navigate(
        decodeURIComponent(
          new URL((window as any).document.location).searchParams.get("r") || ""
        ) || ROUTES.Dashboard
      );
    }, 500);
  }, [navigate]);

  useEffect(() => {
    if (user?.id && !needs2FA) redirectToApp();
  }, [user?.id, needs2FA, redirectToApp]);

  if (user?.name) {
    return <></>;
  }

  return (
    <div className="h-full w-full absolute sm:bg-transparent">
      <SingleCenterCard insetLogo>
        <Login />
      </SingleCenterCard>
    </div>
  );
};

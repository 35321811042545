export const FIELD_TYPES = {
  NUMBER: 2,
  BOOLEAN: 3,
  TEXT: 4,
};

export const FIELD_SOURCES = {
  CUSTOMER_INTERNAL: 2,
  CUSTOMER_CUSTOM: 3,
  CUSTOMER_COMPUTED: 4,
  CUSTOMER_RELATION_INTERNAL: 5,
  CUSTOMER_RELATION_CUSTOM: 6,
};

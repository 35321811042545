import { Modal, ModalContent } from "@atoms/modal/modal";
import { InboxMessageAttachment } from "@features/inbox/types";
import { atom, useRecoilState } from "recoil";
import { InboxAttachment } from "../messages-view/components/message-attachment";

export const AttachmentsModalAtom = atom<{
  attachments: InboxMessageAttachment[];
  threadId: string;
}>({
  key: "AttachmentsModalAtom",
  default: { attachments: [], threadId: "" },
});

export const AttachmentsModal = () => {
  const [attachments, setAttachments] = useRecoilState(AttachmentsModalAtom);
  return (
    <Modal
      open={!!attachments.attachments?.length}
      onClose={() => {
        setAttachments({ attachments: [], threadId: "" });
      }}
    >
      <ModalContent title="Attachments">
        {attachments.attachments?.map((attachment, index) => (
          <div key={index}>
            <InboxAttachment
              file={attachment}
              threadId={attachments.threadId}
            />
          </div>
        ))}
      </ModalContent>
    </Modal>
  );
};

import { Info } from "@atoms/text";
import { useCustomer } from "@features/customers/state/use-customer";
import {
  ChildParentCustomerRelation,
  CustomerAllDetailType,
} from "@features/customers/types";
import { useControlledEffect } from "@features/utils";

import { ReactFlowProvider } from "reactflow";
import { atom, useRecoilState } from "recoil";
import { HoverDetails } from "./details";
import { GraphRender } from "./graph";
import { RelationDetail } from "./relation-details";
import "reactflow/dist/style.css";

export const CustomerGraphSelectionAtom = atom<{
  selectedCustomer: Partial<
    CustomerAllDetailType["details"]["customer"]
  > | null;
  selectedRelations: ChildParentCustomerRelation[];
  rootId: string;
}>({
  key: "CustomerGraphSelectionAtom",
  default: { selectedCustomer: null, selectedRelations: [], rootId: "" },
});

export default function RelationsGraph({
  customer,
}: {
  customer: CustomerAllDetailType;
}) {
  const id = customer.details.customer.id;
  const { relations, refreshRelations } = useCustomer(id || "");
  const [selection, setSelection] = useRecoilState(CustomerGraphSelectionAtom);

  useControlledEffect(() => {
    if (id) {
      setSelection({
        selectedCustomer: customer.details.customer,
        rootId: id,
        selectedRelations: [],
      });
      refreshRelations();
    }
  }, [id]);

  useControlledEffect(() => {
    if (
      selection?.selectedCustomer?.id &&
      !relations?.known?.includes(selection.selectedCustomer?.id)
    )
      refreshRelations(selection.selectedCustomer!);
  }, [selection]);

  if (relations.relations.length <= 1) {
    return <div className="p-4 mt-2">No relations for this customer.</div>;
  }

  return (
    <>
      <div className="p-4">
        <Info className="mt-2 block">
          Click on the nodes to explore deeper relations. Click on the edges to
          get relation details.
        </Info>
      </div>
      <div className="flex flex-col -mt-4 w-full relative">
        <ReactFlowProvider>
          <GraphRender id={id} />
        </ReactFlowProvider>
        <HoverDetails id={id} />
      </div>
      <RelationDetail />
    </>
  );
}

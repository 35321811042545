import Avatar from "@atoms/avatar/avatar";
import { Button } from "@atoms/button/button";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import Link from "@atoms/link";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { BaseSmall } from "@atoms/text";
import { AuthApiClient } from "@features/auth/api-client/api-client";
import { useAuth } from "@features/auth/state/hooks";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import toast from "react-hot-toast";
import SwitchAccounts from "../account/users-management/switch-accounts";
import { AuthJWT } from "@features/auth/jwt";
import { ModalSecurity } from "@components/security/modal-security";

const passwordCheckRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const Account = () => {
  const { user, logout } = useAuth();
  const [updatePassword, setUpdatePassword] = useState(false);
  const [accountModal, setAccountModal] = useState(false);
  const [security, setSecurity] = useState(false);
  const [form, setForm] = useState({
    currentPassword: "",
    newPassword: "",
    passwordConfirm: "",
  });
  const [passwordLoading, setPasswordLoading] = useState(false);

  return (
    <div className="relative flex items-center">
      <ModalSecurity open={security} onClose={() => setSecurity(false)} />
      <Modal open={updatePassword} onClose={() => setUpdatePassword(false)}>
        <ModalContent title="Update account password">
          <InputLabel
            label="Current password"
            input={
              <Input
                placeholder="••••••••"
                type="password"
                value={form.currentPassword}
                onChange={(e) =>
                  setForm({ ...form, currentPassword: e.target.value })
                }
              />
            }
          />

          <BaseSmall className="mt-8 block">
            Your new password must be at least 8 characters long and contain at
            least 1 uppercase letter, 1 lowercase letter, 1 number, and 1
            special character.
          </BaseSmall>

          <InputLabel
            className="mt-4"
            label="New password"
            input={
              <Input
                placeholder="••••••••"
                hasError={!form.newPassword.match(passwordCheckRegex)}
                type="password"
                value={form.newPassword}
                onChange={(e) =>
                  setForm({ ...form, newPassword: e.target.value })
                }
              />
            }
          />
          <InputLabel
            className="mt-4"
            label="New password confirmation"
            input={
              <Input
                placeholder="••••••••"
                hasError={!form.newPassword.match(passwordCheckRegex)}
                type="password"
                value={form.passwordConfirm}
                onChange={(e) =>
                  setForm({ ...form, passwordConfirm: e.target.value })
                }
              />
            }
          />
          <Button
            className="mt-6 float-right"
            disabled={
              form.newPassword !== form.passwordConfirm ||
              !form.passwordConfirm ||
              !form.newPassword ||
              !form.currentPassword ||
              form.passwordConfirm.length < 8
            }
            loading={passwordLoading}
            onClick={async () => {
              setPasswordLoading(true);
              const res = await AuthApiClient.updatePassword(
                form.currentPassword,
                form.newPassword,
                form.passwordConfirm
              );
              if (res.errors?.message) {
                toast.error(
                  "Unable to change your password, check your current and new password and try again."
                );
                setPasswordLoading(false);
              } else {
                toast.success(
                  "Your password has been updated, you will be logged out."
                );
                setTimeout(() => {
                  logout();
                }, 2000);
              }
            }}
          >
            Change password and logout
          </Button>
        </ModalContent>
      </Modal>
      <Modal open={accountModal} onClose={() => setAccountModal(false)}>
        <ModalContent title="Account">
          <SwitchAccounts
            setAccountModal={setAccountModal}
            setUpdatePassword={setUpdatePassword}
            setSecurity={setSecurity}
          />
        </ModalContent>
      </Modal>
      <Link
        onClick={() => setAccountModal(true)}
        noColor
        className="inline-flex flex-row text-white items-center"
      >
        <Avatar
          size={5}
          avatar={user?.avatar}
          fallback={user?.name}
          className="h-5 w-5 mr-2 shrink-0"
        />
        <BaseSmall noColor className="hidden sm:block mr-1">
          {user?.name} @{" "}
          {
            user?.clients.filter((client) => client.id === AuthJWT.clientId)[0]?.label
          }
        </BaseSmall>
        <ChevronDownIcon className="h-4 w-4" />
      </Link>
    </div>
  );
};

import { useAuth } from "@features/auth/state/hooks";
import { useControlledEffect } from "@features/utils";
import { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { InboxApiClient } from "../api-client/api-client";
import { InboxThreadMessagesAtom } from "./store";
import { useThread } from "./use-thread";

export const useReadMessage = (threadId: string, id: string) => {
  const messages = useRecoilValue(InboxThreadMessagesAtom(threadId));
  const message = messages.data.find((m) => m.id === id);
  const { refresh } = useThread(threadId);
  const [read, setRead] = useState(true);
  const { agent } = useAuth();

  //Html ref for the element that must be visible in the viewport
  const ref = useRef<HTMLDivElement>(null);
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const didReadThisMessage = useRef<boolean>(false);

  const readPrepare =
    message?.delivery !== 4 ||
    message?.read_by_agents?.find((e) => e === agent?.agent_id);
  useEffect(() => {
    if (readPrepare) {
      didReadThisMessage.current = true;
    } else {
      setRead(false);
    }
  }, [readPrepare]);

  //Use effect to add events and detect when the element is visible in the viewport
  useControlledEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        //Update our state when observer callback fires
        if (entry.isIntersecting) {
          if (!timeout.current) {
            timeout.current = setTimeout(async () => {
              if (didReadThisMessage.current) return;
              await InboxApiClient.readMessage(id);
              await refresh();
              setRead(true);
              didReadThisMessage.current = true;
            }, 3000);
          }
        } else {
          clearTimeout(timeout.current!);
          timeout.current = null;
        }
      },
      {
        rootMargin: "0px 0px -100px 0px",
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) observer.unobserve(ref.current!);
    };
  }, []);

  return { ref, read };
};

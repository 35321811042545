import { Info } from "@atoms/text";
import { getSizeNodesGraph } from "@features/kyt/d3-kyt-utils";
import { FocusedNodeAtom } from "@features/kyt/state/store";
import { KytEdge, KytNode } from "@features/kyt/types";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";
import Tippy from "@tippyjs/react";
import * as d3 from "d3";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import "tippy.js/dist/tippy.css";

const TooltipContent = ({ node }: { node: KytNode }) => (
  <div className="tippy-node overflow-ellipsis overflow-y-clip">
    <p className="font-bold">{node.full_name?.toUpperCase()}</p>
    <p>
      <Info>Volume:</Info> {node.volume[0].toLocaleString()} € / {node.count[0]}
    </p>
    <p>
      <ArrowLongRightIcon className="inline-block w-5 h-5 mr-1 opacity-50" />
      {node.volume[1].toLocaleString()} € / {node.count[1]}
    </p>
    <p>
      <ArrowLongLeftIcon className="inline-block w-5 h-5 mr-1 opacity-50" />
      {node.volume[2].toLocaleString()} € / {node.count[2]}
    </p>
  </div>
);

const Node = ({
  node,
  color,
  size,
}: {
  node: KytNode;
  color: string;
  size: number;
}) => {
  const [focusedNode, setFocusedNode] = useRecoilState(FocusedNodeAtom);

  let icon;
  if (node.account_type === "F") {
    icon = "office-icon";
  } else {
    icon = "user-icon";
  }

  const verified = node.external_id !== null;

  return (
    <g
      className="node-wrapper"
      onClick={() => {
        setFocusedNode({
          ...node,
        });
      }}
    >
      <Tippy content={<TooltipContent node={node} />}>
        <g>
          <circle
            className="node"
            r={!verified ? size : size / 1.5}
            fill={!verified ? color : d3.rgb(color).brighter(8).formatHex()}
            strokeWidth={node.id === focusedNode?.id ? (verified ? 1 : 8) : 1}
            stroke={d3.rgb(color).darker(1).formatHex()}
            strokeDasharray={node.id === focusedNode?.id ? "40,5" : undefined}
          ></circle>
          <use
            className="node-icon"
            width={size}
            x={size / -2}
            y={size / -2}
            height={size}
            color={d3.rgb(color).darker(1).formatHex()}
            href={`#${icon}`}
          />
          <use
            className="verified"
            display={verified ? "inline" : "none"}
            stroke={d3.rgb(color).darker(1).formatHex()}
            strokeWidth={node.id === focusedNode?.id ? 10 : 1}
            strokeDasharray={node.id === focusedNode?.id ? "40,5" : undefined}
            width={size * 2.15}
            height={size * 2.15}
            x={(size * 2.15) / -2}
            y={(size * 2.15) / -2}
            color={color}
            href={`#verified-icon`}
          />
        </g>
      </Tippy>
    </g>
  );
};

export default function GraphNodes({
  nodes,
  simulation,
}: {
  readonly nodes: KytNode[];
  readonly simulation: d3.Simulation<KytNode, KytEdge> | undefined;
}) {
  useEffect(() => {
    const drag = d3
      .drag()
      .on("start", function (d: any) {
        if (!d3.event.active) simulation!.alphaTarget(0.1).restart();
        d.fx = d.x;
        d.fy = d.y;
      })
      .on("drag", function (d: any) {
        d.fx = d3.event.x;
        d.fy = d3.event.y;
      })
      .on("end", function (d: any) {
        if (!d3.event.active) simulation!.alphaTarget(0);
        d.fx = null;
        d.fy = null;
      });

    (
      d3.selectAll(".node-wrapper") as d3.Selection<
        Element,
        unknown,
        HTMLElement,
        any
      >
    ).call(drag);
  }, [simulation]);

  const size = getSizeNodesGraph(nodes);
  const groups = Array.from(new Set(nodes.map((node) => node.group ?? "NA")));
  const colorScheme = d3.scaleOrdinal(groups, d3.schemeBlues[4]);

  const renderedNodes = nodes.map((node: KytNode) => (
    <Node
      key={node.id}
      node={node}
      color={colorScheme(node.group ?? "NA")}
      size={size}
    />
  ));

  return (
    <>
      <defs>
        <symbol
          id="office-icon"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="1.5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
          />
        </symbol>
        <symbol
          id="user-icon"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="1.5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
          />
        </symbol>
        <symbol id="verified-icon" viewBox="0 -15 187 240">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.6691 25.4886C2.4118 26.4339 0.17041 29.4218 0.17041 32.8184V82.4529C0.17041 139.851 36.5768 190.9 90.8173 209.98C92.3615 210.523 94.0533 210.523 95.5975 209.98C149.823 190.9 186.217 139.862 186.217 82.4784V32.8169C186.217 29.421 183.977 26.4336 180.721 25.4876L95.3601 0.689292C93.9743 0.286698 92.5028 0.286508 91.1169 0.688746L5.6691 25.4886ZM92.2226 145.734C119.224 145.734 141.113 123.811 141.113 96.7661C141.113 69.7217 119.224 47.7979 92.2226 47.7979C65.2209 47.7979 43.3318 69.7217 43.3318 96.7661C43.3318 123.811 65.2209 145.734 92.2226 145.734Z"
            fill="currentColor"
          />
        </symbol>
      </defs>
      <g className="nodes w-full h-full">{renderedNodes}</g>;
    </>
  );
}

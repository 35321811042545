// Utils methods to handle Kyt Explore graph D3 boilerplate code

import { AuthJWT } from "@features/auth/jwt";
import { ExplorePatternsRequestOptions, KytEdge, KytNode } from "./types";
import * as d3 from "d3";

// links are drawn as curved paths between nodes
export function positionLink(d: any, radius: number) {
  const offset = 90;

  const midpoint_x = (d.source.x + d.target.x) / 2;
  const midpoint_y = (d.source.y + d.target.y) / 2;

  const dx = d.target.x - d.source.x;
  const dy = d.target.y - d.source.y;

  const normalise = Math.sqrt(dx * dx + dy * dy);

  const offSetX = midpoint_x + offset * (dy / normalise);
  const offSetY = midpoint_y - offset * (dx / normalise);
  const angle = Math.atan2(dy, dx);
  const targetX = d.target.x - Math.cos(angle) * (radius * 1.2);
  const targetY = d.target.y - Math.sin(angle) * (radius * 1.2);

  return (
    "M" +
    d.source.x +
    "," +
    d.source.y +
    "S" +
    offSetX +
    "," +
    offSetY +
    " " +
    targetX +
    "," +
    targetY
  );
}

const zoom = d3.zoom();

export const zoomIn = () => {
  zoom.scaleBy(d3.select("#svg-container"), 1.2); // 1.2 is the zoom factor for zoom in
};

export const zoomOut = () => {
  zoom.scaleBy(d3.select("#svg-container"), 0.8); // 0.8 is the zoom factor for zoom out
};

export const zoomReset = () => {
  zoom.scaleTo(d3.select("#svg-container"), 1); // 1 is the zoom factor for reset
};

export const classifyNodesGroups = (nodes: KytNode[]) => {
  if (nodes.length === 0) {
    return;
  }
  // sort by volume
  nodes.sort((a, b) => {
    return b.volume[0] - a.volume[0];
  });

  // classification by volume with percentiles
  // 10% - Rich persons - group 1 - platinum
  // 30% - Wealthy persons - group 2 - gold
  // 80% - Middle class - group 3 - silver
  // else - Poor persons - group 4 - bronze
  const percentilesVolumes = [
    nodes[Math.floor(nodes.length * 0.1)].volume[0],
    nodes[Math.floor(nodes.length * 0.3)].volume[0],
    nodes[Math.floor(nodes.length * 0.8)].volume[0],
  ];

  nodes.forEach((node) => {
    if (node.volume[0] >= percentilesVolumes[0]) {
      node.group = "platinum";
    } else if (node.volume[0] >= percentilesVolumes[1]) {
      node.group = "gold";
    } else if (node.volume[0] >= percentilesVolumes[2]) {
      node.group = "silver";
    } else {
      node.group = "bronze";
    }
  });

  nodes.reverse();
};

export const classifyLinksGroups = (links: KytEdge[]) => {
  if (links.length === 0) {
    return;
  }
  // sort by volume
  links.sort((a, b) => {
    return b.volume[0] - a.volume[0];
  });

  const percentilesVolumes = [
    links[Math.floor(links.length * 0.1)].volume[0],
    links[Math.floor(links.length * 0.3)].volume[0],
    links[Math.floor(links.length * 0.8)].volume[0],
  ];

  const linkMap = new Map();

  links.forEach((link) => {
    // groups
    if (link.volume[0] >= percentilesVolumes[0]) {
      link.group = 3;
    } else if (link.volume[0] >= percentilesVolumes[1]) {
      link.group = 2;
    } else if (link.volume[0] >= percentilesVolumes[2]) {
      link.group = 1;
    } else {
      link.group = 0;
    }

    const key = link.source + "-" + link.target;
    linkMap.set(key, link);
  });

  links.forEach((link) => {
    // doubleway: is there a link in the opposite direction?
    link.doubleway = linkMap.has(link.target + "-" + link.source);
  });

  links.reverse();
};

export const classifyClusters = (nodes: KytNode[], edges: KytEdge[]) => {
  classifyNodesGroups(nodes); // colors & groups
  classifyLinksGroups(edges); // thickness & groups (volumes) & doubleway

  const adjacencyList: Record<string, string[]> = {};

  // Initialize adjacency list
  nodes.forEach((node) => (adjacencyList[node.id] = []));

  // Populate adjacency list with edges
  edges.forEach((edge) => {
    adjacencyList[edge.source].push(edge.target);
  });
  let clusterId = 0;
  nodes.forEach((node) => {
    if (node.cluster === undefined) {
      // If the node hasn't been visited
      _bfs(node.id, clusterId, nodes, adjacencyList);
      clusterId++; // Increment cluster ID for a new cluster
    }
  });
};

function _bfs(
  startNode: string,
  clusterId: number,
  nodes: KytNode[],
  adjacencyList: Record<string, string[]>
) {
  const queue = [startNode];
  const visited = new Set(queue);

  while (queue.length > 0) {
    const node = queue.shift(); // dequeue
    if (!node) break;
    nodes.find((n) => n.id === node)!.cluster = clusterId;

    // Enqueue all unvisited neighbors
    adjacencyList[node].forEach((neighbor) => {
      if (!visited.has(neighbor)) {
        visited.add(neighbor);
        queue.push(neighbor);
      }
    });
  }
}

export const getSizeNodesGraph = (nodes: KytNode[]) => {
  let size;
  if (nodes.length > 20) {
    //sm
    size = 30;
  } else if (nodes.length <= 20 && nodes.length > 10) {
    //md
    size = 40;
  } else {
    //lg
    size = 50;
  }
  return size;
};

export const formatNumberLinkVolume = (num: number) => {
  if (num >= 1e9) {
    return (num / 1e9).toFixed(1) + "B";
  }
  if (num >= 1e6) {
    return (num / 1e6).toFixed(1) + "M";
  }
  if (num >= 1e3) {
    return (num / 1e3).toFixed(1) + "k";
  }
  return num.toString();
};

// generate cypher query for transactions from request options
export const getTransactionsQuery = (
  options: ExplorePatternsRequestOptions
) => {
  // if query is not null and not empty
  if (options.query && options.query !== "") {
    return options.query;
  }

  const client_param = AuthJWT.clientId?.toString()
    ? `{client_id: ${AuthJWT.clientId?.toString()}}`
    : "";

  // All node without pattern
  let query = `MATCH (a:Node ${client_param})-[e:TRANSACTION_ALL ${client_param}]->(b:Node ${client_param})`;
  let whereParticipants = "";
  let whereVolumeAllowed = "";
  let whereCountAllowed = "";
  let whereVolumeBlocked = "";
  let whereCountBlocked = "";

  let queryHasWhere = false;

  if (options.participants_filter.ids.length > 0) {
    queryHasWhere = true;
    whereParticipants = `a.node_id IN ['${options.participants_filter.ids.join(
      "','"
    )}'] OR b.node_id IN ['${options.participants_filter.ids.join("','")}']`;
  }
  if (
    options.transactions_filter.allowed.volume.min ||
    options.transactions_filter.allowed.volume.max
  ) {
    queryHasWhere = true;
    whereVolumeAllowed = ` `;
    if (options.transactions_filter.allowed.volume.min) {
      whereVolumeAllowed += `e.volume_allowed >= ${options.transactions_filter.allowed.volume.min} `;
    }
    if (options.transactions_filter.allowed.volume.max) {
      if (whereVolumeAllowed !== " ") {
        whereVolumeAllowed += `AND `;
      }
      whereVolumeAllowed += `e.volume_allowed <= ${options.transactions_filter.allowed.volume.max} `;
    }
  }

  if (
    options.transactions_filter.allowed.count.min ||
    options.transactions_filter.allowed.count.max
  ) {
    queryHasWhere = true;
    whereCountAllowed = ` `;
    if (options.transactions_filter.allowed.count.min) {
      whereCountAllowed += `e.count_allowed >= ${options.transactions_filter.allowed.count.min} `;
    }
    if (options.transactions_filter.allowed.count.max) {
      if (whereCountAllowed !== " ") {
        whereCountAllowed += `AND `;
      }
      whereCountAllowed += `e.count_allowed <= ${options.transactions_filter.allowed.count.max} `;
    }
  }

  if (
    options.transactions_filter.blocked.volume.min ||
    options.transactions_filter.blocked.volume.max
  ) {
    queryHasWhere = true;
    whereVolumeBlocked = ` `;
    if (options.transactions_filter.blocked.volume.min) {
      whereVolumeBlocked += `e.volume_blocked >= ${options.transactions_filter.blocked.volume.min} `;
    }
    if (options.transactions_filter.blocked.volume.max) {
      if (whereVolumeBlocked !== " ") {
        whereVolumeBlocked += `AND `;
      }
      whereVolumeBlocked += `e.volume_blocked <= ${options.transactions_filter.blocked.volume.max} `;
    }
  }

  if (
    options.transactions_filter.blocked.count.min ||
    options.transactions_filter.blocked.count.max
  ) {
    queryHasWhere = true;
    whereCountBlocked = ` `;
    if (options.transactions_filter.blocked.count.min) {
      whereCountBlocked += `e.count_blocked >= ${options.transactions_filter.blocked.count.min} `;
    }
    if (options.transactions_filter.blocked.count.max) {
      if (whereCountBlocked !== " ") {
        whereCountBlocked += `AND `;
      }
      whereCountBlocked += `e.count_blocked <= ${options.transactions_filter.blocked.count.max} `;
    }
  }

  if (!queryHasWhere) {
    return `${query} RETURN a, e, b`;
  }

  query += " WHERE ";

  if (whereParticipants) {
    query += `(${whereParticipants})`;
  }
  if (whereVolumeAllowed) {
    if (whereParticipants) {
      query += ` AND `;
    }
    query += `(${whereVolumeAllowed})`;
  }
  if (whereCountAllowed) {
    if (whereParticipants || whereVolumeAllowed) {
      query += ` AND `;
    }
    query += `(${whereCountAllowed})`;
  }

  if (whereVolumeBlocked) {
    if (
      whereParticipants ||
      whereVolumeAllowed ||
      whereCountAllowed ||
      whereCountBlocked
    ) {
      query += ` AND `;
    }
    query += `(${whereVolumeBlocked})`;
  }

  if (whereCountBlocked) {
    if (
      whereParticipants ||
      whereVolumeAllowed ||
      whereCountAllowed ||
      whereVolumeBlocked
    ) {
      query += ` AND `;
    }
    query += `(${whereCountBlocked})`;
  }

  return `${query} RETURN a, e, b`;
};

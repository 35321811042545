export const PEPRoles = [
  { code: 1, name: "Heads & Deputies State/National Government" },
  { code: 2, name: "National Government Ministers" },
  { code: 3, name: "Members of the National Legislature" },
  { code: 4, name: "Senior Civil Servants-National Government" },
  { code: 5, name: "Senior Civil Servants-Regional Government" },
  { code: 6, name: "Embassy & Consular Staff" },
  { code: 7, name: "Senior Members of the Armed Forces" },
  { code: 8, name: "Senior Members of the Police Services" },
  { code: 9, name: "Senior Members of the Secret Services" },
  { code: 10, name: "Senior Members of the Judiciary" },
  { code: 11, name: "State Corporation Executives" },
  { code: 12, name: "State Agency Officials" },
  { code: 13, name: "Heads & Deputy Heads of Regional Government" },
  { code: 14, name: "Regional Government Ministers" },
  { code: 15, name: "Religious Leaders" },
  { code: 16, name: "Political Party Officials" },
  { code: 17, name: "International Organisation Officials" },
  { code: 18, name: "City Mayors" },
  { code: 19, name: "Political Pressure and Labour Group Officials" },
  { code: 20, name: "Other" },
  { code: 22, name: "Local Public Officials" },
  { code: 26, name: "International Sporting Organisation Officials" },
];

export const RCATypes = [
  { code: 1, name: "Wife" },
  { code: 2, name: "Husband" },
  { code: 3, name: "Brother" },
  { code: 4, name: "Sister" },
  { code: 5, name: "Son" },
  { code: 6, name: "Daughter" },
  { code: 7, name: "Mother" },
  { code: 8, name: "Father" },
  { code: 9, name: "Cousin" },
  { code: 10, name: "Step-Son" },
  { code: 11, name: "Step-Daughter" },
  { code: 12, name: "Brother-in-law" },
  { code: 13, name: "Sister-in-law" },
  { code: 14, name: "Uncle" },
  { code: 15, name: "Aunt" },
  { code: 16, name: "Mother-in-law" },
  { code: 17, name: "Father-in-law" },
  { code: 18, name: "Grandfather" },
  { code: 19, name: "Grandmother" },
  { code: 20, name: "Son-in-law" },
  { code: 21, name: "Daughter-in-law" },
  { code: 22, name: "Niece" },
  { code: 23, name: "Nephew" },
  { code: 24, name: "Grandson" },
  { code: 25, name: "Granddaughter" },
  { code: 26, name: "Stepfather" },
  { code: 27, name: "Stepmother" },
  { code: 28, name: "Business Associate" },
  { code: 29, name: "Friend" },
  { code: 30, name: "Financial Adviser" },
  { code: 31, name: "Legal Adviser" },
  { code: 32, name: "Colleague" },
  { code: 33, name: "Agent/Representative" },
  { code: 34, name: "Employee" },
  { code: 35, name: "Associate" },
  { code: 36, name: "Child" },
  { code: 37, name: "Family Member" },
  { code: 38, name: "Political Adviser" },
  { code: 39, name: "Senior Official" },
  { code: 40, name: "Unmarried Partner" },
  { code: 41, name: "Same-sex Spouse" },
  { code: 42, name: "Employer" },
  { code: 43, name: "Shareholder/Owner" },
  { code: 44, name: "Associated Special Interest Person" },
  { code: 45, name: "Parent Company" },
  { code: 46, name: "Subsidiary" },
  { code: 47, name: "Asset" },
  { code: 100, name: "Affiliate Company" },
  { code: 101, name: "Ultimate Beneficial Owner (UBO)" },
  { code: 102, name: "Legal Representative" },
  { code: 103, name: "Subscriber" },
  { code: 104, name: "Provider" },
  { code: 105, name: "Trustee" },
  { code: 106, name: "Beneficiary" },
  { code: 107, name: "Sole Proprietor" },
  { code: 108, name: "Director" },
  { code: 109, name: "Officer" },
  { code: 110, name: "Contractor" },
];

export const SITypes = [
  { codes: [12, 6], name: "Terror" },
  { codes: [7, 13], name: "Organised Crime" },
  { codes: [8, 14], name: "Financial Crime" },
  { codes: [9, 15], name: "Trafficking" },
  { codes: [10, 17], name: "Corruption" },
  { codes: [11, 16], name: "War Crimes" },
  { codes: [31], name: "Tax Crime" },
  { codes: [20], name: "Additional Domestic Screening Requirement" },
  { codes: [21, 22], name: "Organised Crime Japan" },
  { codes: [23, 24], name: "Enhanced Country Risk" },
  { codes: [25, 26], name: "Other Exclusion Lists" },
  { codes: [34], name: "Sanctions Control and Ownership" },
];

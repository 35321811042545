import { useAuth } from "@features/auth/state/hooks";
import { useGlobalEffect } from "@features/utils";
import _ from "lodash";
import { useAgents } from "./use-agents";

export const useReviewGroups = (additionalReviewGroups?: string[]) => {
  const { getAgentForClient, refresh } = useAgents();
  const agents = getAgentForClient();
  const { clientId, agent } = useAuth();
  const reviewGroups = _.uniq([
    ...(additionalReviewGroups || []),
    ...agents
      .map((a) => a?.clients?.find((c) => c.id === clientId)?.review_groups!)
      .reduce((acc, val) => acc.concat(val), []),
  ]);

  useGlobalEffect(
    "useReviewGroups",
    () => {
      refresh();
    },
    []
  );

  return {
    current: agent?.review_groups || [],
    all: reviewGroups,
    reviewGroups: agent?.review_groups?.length
      ? agent?.review_groups
      : reviewGroups,
    refresh,
  };
};

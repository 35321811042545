import { useFetch } from "@features/utils";

export class BillingApiClient {
  static getPlan = async () => {
    const response = await useFetch(`/web/billing/plan`, {
      method: "GET",
    });
    const data = await response.json();
    return data;
  };

  static enableProduct = async (name: string, enabled = true) => {
    const products: any = await (
      await useFetch(`/web/products`, {
        method: "GET",
      })
    ).json();
    const productId = products.find(
      (p: any) => p.product_label === name
    ).product_id;
    await useFetch(`/web/products-enabled` + (enabled ? "" : "/" + productId), {
      method: enabled ? "POST" : "DELETE",
      body: enabled ? JSON.stringify({ product_id: productId }) : undefined,
    });
  };
}

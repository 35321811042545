import { KytApiClient } from "@features/kyt/api-client/api-client";
import { LoadingAtom } from "@features/utils/loading";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import { CustomerTransactionsAggregatesAtom } from "./store";

export const useCustomerTransactionsAggregate = (id: string) => {
    const [aggregates, setAggregates] = useRecoilState(CustomerTransactionsAggregatesAtom(id));
    const [loading, setLoading] = useRecoilState(LoadingAtom("useCustomerTransactionsAggregate-" + id));


    const refresh = useCallback(
        async () => {
            try {
                setLoading(true);
                const aggregates = await KytApiClient.getTransactionsAggregates(
                    {
                        external_id: id,
                        // from_date: '2023-05-11T16:34:00.863Z',
                        // to_date: '2023-05-16T16:34:00.863Z'
                    }
                );
                setAggregates(aggregates);
            } catch (error) {
                console.log(error);
                toast.error("Error loading customer transactions aggregates");

            } finally {
                setLoading(false);
            }
        }
        , [setAggregates, id]);

    return {
        aggregates,
        refresh,
        loading
    }

}
import { atom, atomFamily } from "recoil";
import { AlertsRequestOptions, AlertListType, AlertType } from "../types";

export const CustomerReviewAlertsAtom = atomFamily<
  {
    per_state: { [key: string]: AlertListType[] };
    total: number;
    loading: boolean;
  },
  string
>({
  key: "CustomerReviewAlertsAtom",
  default: () => ({
    per_state: {},
    total: 0,
    loading: true,
  }),
});

export const AlertsCategoriesAtom = atom<{ [key: string]: string }>({
  key: "AlertsCategoriesAtom",
  default: {},
});

export const AlertsStatesAtom = atom<{ [key: number]: string }>({
  key: "AlertsStatesAtom",
  default: {},
});

export const AlertsListAtom = atom<{ data: AlertListType[]; total: number }>({
  key: "AlertsListAtom",
  default: { data: [], total: 0 },
});

export const AlertsDetailsAtom = atomFamily<AlertType | null, string>({
  key: "AlertsDetailsAtom",
  default: null,
});

export const AlertsFiltersAtom = atom<AlertsRequestOptions>({
  key: "AlertsFiltersAtom",
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newValue) => {
        localStorage.setItem("saved.alerts_filters", JSON.stringify(newValue));
      });
    },
  ],
  default: (() => {
    let saved = {};
    try {
      saved = JSON.parse(localStorage.getItem("saved.alerts_filters") || "{}");
    } catch (e) {
      // No-op
    }

    return {
      name: "",
      external_id: "",
      id: "",
      categories: [],
      states: [],
      creation_date_from: null,
      creation_date_to: null,
      last_update_from: null,
      last_update_to: null,
      score_from: 0,
      score_to: 100,
      on_page: 1,
      order_by: "STATUS",
      order_way: "ASC",
      per_page: 10,
      review_groups: null,
      assigned_to_member_id: null,
      only_without_review_groups: false,
      only_without_assignment: false,
      ...saved,
    } as AlertsRequestOptions;
  })(),
});

import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import { Checkbox } from "@atoms/input/input-checkbox";
import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Info, Section } from "@atoms/text";
import { ReviewGroupInput } from "@components/review-group-input";
import { useReviewGroups } from "@features/agents/state/use-review-groups";
import { useScheduledAccess } from "@features/scheduled-access/state/use-scheduled-access";
import {
  DayOfWeek,
  Schedule,
  ScheduledAccessData,
} from "@features/scheduled-access/types";
import { isDayFull } from "@features/scheduled-access/utils";
import { ChangeEvent, useEffect, useState } from "react";

const daysOfWeek: DayOfWeek[] = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];
const hoursOfDay = Array.from({ length: 24 }, (_, i) => i);

type ScheduledAccessModalProps = {
  open: boolean;
  onClose: () => void;
  scheduledAccess: ScheduledAccessData | null;
};

function defaultForm() {
  return {
    reviewGroups: [],
    ips: "",
    schedule: {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    },
  };
}

export function ScheduledAccessModal({
  open,
  onClose,
  scheduledAccess,
}: ScheduledAccessModalProps) {
  const { current: reviewGroups } = useReviewGroups();
  const {
    createAccess,
    creatingAccess,
    updateAccess,
    updatingAccess,
    isRefetching,
  } = useScheduledAccess();
  const [form, setForm] = useState<{
    ips: string;
    reviewGroups: string[];
    schedule: Schedule;
  }>(defaultForm());

  const onIPsChange = (
    e: ChangeEvent<HTMLInputElement> & ChangeEvent<HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    setForm({ ...form, ips: value.replace(/[^0-9.:,]/g, "") });
  };

  useEffect(() => {
    if (!scheduledAccess) {
      setForm(defaultForm());
    } else {
      setForm({
        ips: scheduledAccess.ips.join(","),
        reviewGroups: scheduledAccess.review_groups,
        schedule: scheduledAccess.schedule,
      });
    }
  }, [scheduledAccess]);

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <ModalContent
        title={
          scheduledAccess ? "Edit scheduled access" : "New scheduled access"
        }
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className="flex flex-col gap-2"
        >
          <div>
            <Section>IP</Section>
            <Input
              value={form.ips}
              onChange={onIPsChange}
              placeholder={"192.168.1.72,255.34.39.01"}
            />
            <Info>Separated with ",". Leave blank or * for all.</Info>
          </div>
          <div>
            <Section>Review groups</Section>

            <ReviewGroupInput
              canCreateReviewGroup={false}
              value={form.reviewGroups}
              onChange={(reviewGroups: string[]) =>
                setForm({ ...form, reviewGroups })
              }
            />
            <Info>Leave blank for all review groups.</Info>
          </div>
          <div>
            <Section className="mb-0">Schedule</Section>
            <div className="max-h-64 overflow-auto">
              <table className="table-auto border-separate mx-auto text-center bg-white">
                <thead className="sticky top-0 bg-white border">
                  <tr>
                    <th className="border-b-2 border-slate-300"></th>
                    {daysOfWeek.map((day) => (
                      <th key={day} className="p-1 border-b-2 border-slate-300">
                        {day.charAt(0).toUpperCase()}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="p-1 text-right">*</td>
                    {daysOfWeek.map((day) => (
                      <td key={day} className="p-1">
                        <Checkbox
                          value={isDayFull(form.schedule, day)}
                          onChange={(checked) => {
                            let daySchedule: number[] = [];
                            if (checked) {
                              daySchedule = [...hoursOfDay];
                            } else {
                              daySchedule = [];
                            }
                            setForm({
                              ...form,
                              schedule: {
                                ...form.schedule,
                                [day]: daySchedule,
                              },
                            });
                          }}
                        />
                      </td>
                    ))}
                  </tr>
                  {hoursOfDay.map((hour) => (
                    <tr key={hour}>
                      <td className="p-1 border-t border-slate-300">
                        {hour.toString().padStart(2, "0")}
                      </td>
                      {daysOfWeek.map((day) => (
                        <td key={day} className="p-1 border-t border-slate-300">
                          <Checkbox
                            value={!!form.schedule[day].includes(hour) || false}
                            onChange={(checked) => {
                              const daySchedule = form.schedule[day];
                              if (checked) {
                                daySchedule.push(hour);
                              } else {
                                daySchedule.splice(
                                  daySchedule.indexOf(hour),
                                  1
                                );
                              }
                              setForm({
                                ...form,
                                schedule: {
                                  ...form.schedule,
                                  [day]: daySchedule,
                                },
                              });
                            }}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </form>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse text-center sm:text-left">
          <ButtonConfirm
            confirmTitle="Schedule change"
            confirmMessage={`After this rule is applied, you may lose access to the application. Please verify that you will not be affected by this scheduler access rule by mistake. You current review groups are : ${
              reviewGroups.length > 0 ? reviewGroups.join(", ") : "None"
            }`}
            loading={creatingAccess || updatingAccess || isRefetching}
            theme={"primary"}
            onClick={async () => {
              let ips = form.ips.split(",");
              ips = ips.map((ip) => ip.trim());
              if (scheduledAccess) {
                await updateAccess({
                  id: scheduledAccess.id,
                  ips,
                  review_groups: form.reviewGroups,
                  schedule: form.schedule,
                });
              } else {
                await createAccess({
                  ips,
                  review_groups: form.reviewGroups,
                  schedule: form.schedule,
                });
              }
              onClose();
            }}
            className="mr-4 my-2"
          >
            Save
          </ButtonConfirm>
          <Button
            onClick={onClose}
            theme="default"
            className={"mr-4 my-2 shadow-none"}
          >
            Cancel
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
}

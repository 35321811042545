import {
  RiskNodeFormulaDefinition,
  RiskResourceType,
} from "@features/risk-decisions/types";
import Editor from "@monaco-editor/react";
import { useFormulaEditorComponent } from "./formula_editor-hook";
import { useEffect, useState } from "react";
import { useCustomFields } from "@features/custom-fields/state/use-custom-fields";

export default function FormulaEditor(props: {
  type: RiskResourceType;
  withRelations?: boolean;
  readonly?: boolean;
  value: RiskNodeFormulaDefinition;
  width?: string;
  height?: string;
  onChange: (value: RiskNodeFormulaDefinition | null) => void;
}) {
  const { fields, loading: loadingFields } = useCustomFields();
  const [value, setValue] = useState<string>("");
  const { id, loading, onValueChange, mapFromDefinitionExpression } =
    useFormulaEditorComponent(
      props.type,
      props.withRelations || false,
      props.onChange
    );

  useEffect(() => {
    onValueChange(value);
  }, [value]);

  useEffect(() => {
    if (!value || props.readonly) {
      setValue(mapFromDefinitionExpression({ formula: props.value }) || "");
    }
  }, [props.value, fields.length]);

  if (loadingFields || loading) return <></>;

  return (
    <Editor
      loading={loading}
      width={props.width || "100%"}
      height={props.height}
      onChange={(str) => setValue(str || "")}
      options={{
        wordWrap: "on",
        lineDecorationsWidth: 0,
        minimap: { enabled: false },
        lineNumbers: props.readonly ? "off" : "on",
        renderLineHighlight: "none",
        suggest: {
          showWords: false,
        },
        bracketPairColorization: {
          enabled: true,
        },
        formatOnType: true,
      }}
      theme={"algoregtheme" + props.type + id}
      defaultLanguage={"algoreg" + props.type + id}
      value={value}
    />
  );
}

export default function GraphBackgroundLayer() {
  return (
    <g id="graph-background">
      <defs>
        <pattern
          id="dotPattern"
          patternUnits="userSpaceOnUse"
          width="100"
          height="100"
          patternTransform="translate(100 100)"
        >
          {/* <circle cx="50" cy="50" r="2" fill="red" /> */}
          <rect x="45" y="45" width="4" height="4" fill="grey" />
        </pattern>
      </defs>
      <rect
        x="-1000%"
        y="-1000%"
        width="2000%"
        height="2000%"
        fill="url(#dotPattern)"
      />
    </g>
  );
}

import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import { PageResponsiveColumns } from "@atoms/layout/page-block";
import Link from "@atoms/link";
import { Info, Menu } from "@atoms/text";
import { ScenarioType } from "@features/scenarios/types";
import React from "react";

export default function ScenarioIntegrationTab(props: {
  scenario: ScenarioType;
  onChange: (scenario: ScenarioType) => void;
}) {
  return (
    <PageResponsiveColumns>
      <div className="grow md:max-w-md w-full flex-col space-y-2">
        <Menu className="mb-2">Integration documentation</Menu>
        <Info className="my-4 block">
          You can find the integration documentation at <br />
          <Link
            href="https://docs.algoreg.com/docs/govid/get-started#integration-example"
            target="BLANK"
          >
            Go!Vid Integration Documentation
          </Link>
          .
        </Info>
      </div>
      <div className="grow w-full md:pl-0 pl-10 flex flex-col space-y-4">
        <div className="flex flex-col space-y-2">
          <Menu>Return URL</Menu>
            <Info>
                Redirect users to this URL after they complete the onboarding process
            </Info>
            <InputLabel
                label=" "
                input={
                    <Input
                        placeholder="https://yourwebsite.com/onboarding-complete"
                        value={props.scenario.integration_preferences.return_url}
                        onChange={(e) => {
                            props.onChange({
                                ...props.scenario,
                                integration_preferences: {
                                    ...props.scenario.integration_preferences,
                                    return_url: e.target.value,
                                },
                            });
                        }}
                    />
                }
            />
        </div>
        <div className="flex flex-col space-y-2">
          <Menu>Translation override</Menu>
          <Info>Upload your own translation to override defaults</Info>
          <InputLabel
            className="w-full"
            label=" "
            input={
              <Input
                style={{ minHeight: 200 }}
                multiline
                placeholder="// Write your translation here in JSON format"
                value={
                  props.scenario.integration_preferences
                    .translation_override_json
                }
                onChange={(e) => {
                  props.onChange({
                    ...props.scenario,
                    integration_preferences: {
                      ...props.scenario.integration_preferences,
                      translation_override_json: e.target.value,
                    },
                  });
                }}
              />
            }
          />
        </div>
        <div className="flex flex-col space-y-2">
          <Menu>Style override</Menu>
          <Info>Change onboarding style using your own stylesheets</Info>
          <InputLabel
            className="w-full"
            label=" "
            input={
              <Input
                style={{ minHeight: 200 }}
                multiline
                placeholder="// Write your CSS rules here"
                value={
                  props.scenario.integration_preferences.style_override_css
                }
                onChange={(e) => {
                  props.onChange({
                    ...props.scenario,
                    integration_preferences: {
                      ...props.scenario.integration_preferences,
                      style_override_css: e.target.value,
                    },
                  });
                }}
              />
            }
          />
        </div>
      </div>
    </PageResponsiveColumns>
  );
}

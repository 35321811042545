import { Button } from "@atoms/button/button";
import { Info } from "@atoms/text";
import { ROUTES } from "@features/routes";
import { useCustomerSessions } from "@features/sessions/state/use-customer-sessions";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  QueueListIcon,
} from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { CustomerSessionsModalAtom } from "./customer-sessions-modal";

export default function CustomerOtherSessions(props: {
  external_id: string;
  current_session_id: string;
}) {
  const { sessions, loading, refresh } = useCustomerSessions(props.external_id);
  const [, setModal] = useRecoilState(CustomerSessionsModalAtom);

  const navigate = useNavigate();

  useEffect(() => {
    if (sessions.length === 0) {
      refresh();
    }
  }, [props.external_id, refresh]);

  if (loading) return <></>;

  const currentIndex = sessions.findIndex(
    (session) => session.session_id === props.current_session_id
  );

  return (
    <div className="flex flex-row w-fit space-x-4 items-center">
      <div className="w-fit flex flex-row items-center space-x-4 bg-blue-100 dark:bg-slate-700 border border-slate-200 dark:border-slate-600  rounded-b pl-2 ">
        <Info className="text-blue-600">
          Other sessions for this customer {currentIndex + 1} /{" "}
          {sessions.length}
        </Info>
        <div className="flex flex-row">
          <Button
            data-tooltip="Previous session from this customer"
            size="sm"
            disabled={currentIndex === 0}
            theme="outlined"
            onClick={() => {
              navigate(
                ROUTES.SessionView.replace(
                  ":id",
                  sessions[currentIndex - 1].session_id
                )
              );
            }}
            className="rounded-r-none"
          >
            <ArrowLeftIcon className="h-5 w-5" />
          </Button>
          <Button
            data-tooltip="View all sessions for this customer"
            size="sm"
            theme="outlined"
            className="rounded-none border-x-0"
            onClick={() =>
              setModal({
                open: true,
                external_id: props.external_id,
                heighlighted_session_id: props.current_session_id,
              })
            }
          >
            <QueueListIcon className="h-5 w-5" />
          </Button>
          <Button
            data-tooltip="Next session from this customer"
            size="sm"
            disabled={currentIndex === sessions.length - 1}
            theme="outlined"
            className="rounded-l-none"
            onClick={() => {
              navigate(
                ROUTES.SessionView.replace(
                  ":id",
                  sessions[currentIndex + 1].session_id
                )
              );
            }}
          >
            <ArrowRightIcon className="h-5 w-5" />
          </Button>
        </div>
      </div>
    </div>
  );
}

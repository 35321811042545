import { Page } from "@atoms/layout/page";
import { Title } from "@atoms/text";
import { SessionsLabelsTables } from "./session-labels";

export const SessionStatusSetupPage = () => {
  return (
    <Page>
      <Title>Session status</Title>

      <br />

      <SessionsLabelsTables />
    </Page>
  );
};

import { Page } from "@atoms/layout/page";
import Link from "@atoms/link";
import { Info, Title } from "@atoms/text";
import DeveloperApiKeys from "./developer-api-keys";
import DeveloperWebhook from "./developer-webhook";
import { useHasAccess } from "@features/auth/state/use-access";

export const DevelopersPage = () => {
  const hasAccess = useHasAccess();
  return (
    <Page>
      <Title>Developers</Title>
      <Info className="my-4 block">
        API documentation is available at{" "}
        <Link href="https://docs.algoreg.com" target="BLANK">
          https://docs.algoreg.com
        </Link>
        .
      </Info>
      <DeveloperApiKeys />
      {hasAccess("AGENT_EDIT") && (
        <>
          <div className="mt-12" />
          <DeveloperWebhook />
        </>
      )}
    </Page>
  );
};

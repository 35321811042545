import { Modal } from "@atoms/modal/modal";
import {
  ArrowsPointingOutIcon,
  ArrowsRightLeftIcon,
  ArrowTurnUpLeftIcon,
  MinusIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

let isPan = false;

export const ModalImage = ({
  className,
  small,
  large,
  alt,
}: {
  className?: string;
  small: string;
  large: string;
  alt: string;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        style={{
          height: "calc(100vh - 128px)",
          width: "calc(100vw - 128px)",
          maxWidth: "none",
          padding: 0,
          backgroundColor: "black",
        }}
      >
        {open && <PreviewContent src={large} />}
      </Modal>
      <img
        className={twMerge(className, "cursor-zoom-in")}
        src={small}
        alt={alt}
        onClick={() => setOpen(true)}
        data-tooltip="Click to enlarge"
      />
    </>
  );
};

const PreviewContent = ({ src }: { src: string }) => {
  const [pan, setPan] = useState({ x: 0, y: 0 });
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [flipXval, setFlipX] = useState(1);

  const zoomIn = () => setScale((prev) => prev * 1.1);
  const zoomOut = () => setScale((prev) => prev / 1.1);
  const zoomReset = () => {
    setScale(1);
    setPan({ x: 0, y: 0 });
  };

  const rotateRight = () => setRotate((prev) => prev - 90);

  const flipX = () => setFlipX((prev) => prev * -1);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isPan) {
        console.log("panning");
        setPan((prev) => ({
          x: prev.x + e.movementX,
          y: prev.y + e.movementY,
        }));
      }
    };

    const handleMouseUp = () => {
      isPan = false;
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  return (
    <>
      <div
        className="flex w-full h-full items-center justify-center cursor-move select-none"
        onMouseDown={() => {
          isPan = true;
        }}
        onWheel={(e) => {
          if (e.deltaY < 0) {
            zoomIn();
          } else {
            zoomOut();
          }
        }}
      >
        <div
          className="flex w-full h-full items-center justify-center"
          style={{
            transform: `translate(${pan.x}px, ${pan.y}px)`,
          }}
        >
          <div
            className="flex w-full h-full items-center justify-center transition-all"
            style={{
              transform: `scaleX(${flipXval})`,
            }}
          >
            <img
              src={src}
              alt={""}
              className="transition-all pointer-events-none"
              style={{
                transform: `scale(${scale}) rotate(${rotate}deg)`,
              }}
            />
          </div>
        </div>
      </div>
      <div className="absolute top-0 right-4 bottom-0 bg-white h-max py-1 m-auto rounded-md">
        <div
          className="hover:opacity-50 border-b cursor-pointer w-10 h-10 flex items-center justify-center"
          onClick={zoomIn}
        >
          <PlusIcon className="w-6 h-6" />
        </div>
        <div
          className="hover:opacity-50 border-b cursor-pointer w-10 h-10 flex items-center justify-center"
          onClick={zoomOut}
        >
          <MinusIcon className="w-6 h-6" />
        </div>
        <div
          className="hover:opacity-50 border-b cursor-pointer w-10 h-10 flex items-center justify-center"
          onClick={zoomReset}
        >
          <ArrowsPointingOutIcon className="w-6 h-6" />
        </div>
        <div
          className="hover:opacity-50 border-b cursor-pointer w-10 h-10 flex items-center justify-center"
          onClick={rotateRight}
        >
          <ArrowTurnUpLeftIcon className="w-6 h-6" />
        </div>
        <div
          className="hover:opacity-50 cursor-pointer w-10 h-10 flex items-center justify-center"
          onClick={flipX}
        >
          <ArrowsRightLeftIcon className="w-6 h-6" />
        </div>
      </div>
    </>
  );
};

import { LoadingAtom } from "@features/utils/loading";
import _ from "lodash";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import { CustomersApiClient } from "../api-client/api-client";
import { ChildParentCustomerRelation, CustomerAllDetailType } from "../types";
import { CustomerAtom, CustomerRelationsAtom } from "./store";

export const useCustomer = (id: string) => {
  const [customer, setCustomer] = useRecoilState(CustomerAtom(id));
  const [relations, setRelations] = useRecoilState(CustomerRelationsAtom(id));
  const [loading, setLoading] = useRecoilState(
    LoadingAtom("useCustomer-" + id)
  );

  const refresh = useCallback(async () => {
    setLoading(true);
    try {
      const customer = {
        details: await CustomersApiClient.getCustomerDetails(id),
        risk_factors: await CustomersApiClient.getCustomerRiskFactors(id),
        edd_history: await CustomersApiClient.getCustomerEddHistory(id),
      };
      setCustomer(customer);
    } catch (e) {
      toast.error("Error loading customer details");
      console.log(e);
    }
    setLoading(false);
  }, [setCustomer, setLoading, id]);

  const update = useCallback(
    async (state: number, comment: string) => {
      if (id && comment) {
        setLoading(true);
        await CustomersApiClient.updateCustomerStatus(id, state, comment);
        await refresh();
        setLoading(false);
      }
    },
    [setLoading, refresh, id]
  );

  const disable = useCallback(
    async (disable = true) => {
      if (customer?.details?.customer?.external_id) {
        setLoading(true);
        await CustomersApiClient.updateCustomer({
          verify_input: false,
          disable_risk_scan: true,
          customers: [
            {
              external_id: customer?.details.customer.external_id,
              disabled: disable,
            } as any,
          ],
          relations: [],
        });
        await refresh();
        setLoading(false);
      }
    },
    [setLoading, refresh, id]
  );

  const refreshRelations = useCallback(
    async (
      focusCustomer?: Partial<CustomerAllDetailType["details"]["customer"]>
    ) => {
      try {
        const focusCustomerRisk = customer?.details.overall_risk;
        focusCustomer = focusCustomer || customer?.details.customer;
        const focusId = focusCustomer!.id;
        const related = await CustomersApiClient.getCustomersRelated(focusId!);
        const newRelations = [
          ...(related.child_relations || []).map((r) => ({
            ...r,
            child: {
              customer: focusCustomer as any,
              risk: focusCustomerRisk,
            },
            parent: { customer: r.customer, risk: r.customer_risk },
          })),
          ...(related.parent_relations || []).map((r) => ({
            ...r,
            child: { customer: r.customer, risk: r.customer_risk },
            parent: {
              customer: focusCustomer as any,
              risk: focusCustomerRisk,
            },
          })),
        ] as ChildParentCustomerRelation[];
        const allRelations = _.uniqBy(
          focusId ? [...relations.relations, ...newRelations] : newRelations,
          "relation_external_id"
        );

        setRelations({
          relations: allRelations,
          known: [...relations.known, focusId!],
        });
      } catch (e) {
        toast.error("Error loading related customers");
        console.log(e);
      }
    },
    [
      relations,
      setRelations,
      customer?.details.customer,
      customer?.details.overall_risk,
    ]
  );

  return {
    customer,
    relations,
    loading,
    update,
    disable,
    refresh,
    refreshRelations,
  };
};

import { is2XX, useFetch } from "@features/utils";
import { CustomFieldType } from "../types";

export class CustomFieldsApiClient {
  static get = async () => {
    const response = await useFetch(`/web/importsetup/export_json`, {
      method: "GET",
    });
    const data = await response.json();
    return (data?.fields as CustomFieldType[]).map((a) => {
      let format = {};
      try {
        format = JSON.parse(a.format as string);
      } catch {
        format = {};
      }
      return {
        ...a,
        format,
      };
    });
  };

  static save = async (fields: CustomFieldType[]) => {
    const response = await useFetch(`/web/importsetup/import_json`, {
      method: "POST",
      formData: true,
      body: JSON.stringify({
        fields: JSON.stringify(
          fields.map((a) => ({
            ...a,
            format: JSON.stringify(a.format || {}),
          }))
        ),
      }),
    });
    if (!is2XX(response.status)) {
      throw new Error("Failed to save custom fields");
    }
  };
}

import toast from "react-hot-toast";

import { PressApiClient } from "../api-client/api-client";

import { useMutation, useQuery } from "react-query";

export const usePressSettings = () => {
  const settings = useQuery({
    queryKey: ["pressSettings"],
    queryFn: PressApiClient.getSettings,
  });
  const save = useMutation({
    mutationFn: PressApiClient.saveSettings,
    onSuccess: () => {
      settings.refetch();
      toast.success("Saved Go!Press settings");
    },
    onError: () => {
      toast.error("Failed to save Go!Press settings");
    },
  });

  return {
    settings: settings.data,
    save,
    loading: settings.isLoading,
    refresh: settings.refetch,
  };
};

import { LinkConfirm } from "@atoms/link/confirm";
import { Base, SectionSmall } from "@atoms/text";
import { Table } from "@components/table";
import { useCustomFields } from "@features/custom-fields/state/use-custom-fields";
import { useMatrixEntries } from "@features/custom-matrix/state/use-matrix-entries";
import { CustomerAllDetailType } from "@features/customers/types";
import { formatDuration, formatTime } from "@features/utils/dates";
import parsePhoneNumber from "libphonenumber-js";

import _ from "lodash";

export const IdentityCustomFields = ({
  customer,
}: {
  customer: CustomerAllDetailType;
}) => {
  const { fields } = useCustomFields();
  const customFields = customer?.details.custom_fields;
  const groups = _.sortBy(
    _.uniq(fields.filter((a) => a.field_source === 3).map((a) => a.group)),
    (a) => a || "zzzzz"
  );

  return (
    <>
      {groups.map((group) => (
        <>
          <hr className="my-4 -mx-4" />
          <SectionSmall>{group || "Other fields"}</SectionSmall>
          <div className="mt-2" />
          <Table
            data={_.sortBy(
              customFields.filter((a) =>
                fields
                  .filter((a) => a.group === group)
                  .map((a) => a.field_id)
                  .includes(a.id)
              ),
              (f) => f.name
            )}
            columns={[
              {
                thClassName: "w-1/2",
                render: (row) => (
                  <Base className="capitalize">
                    {fields.find((a) => a.field_id === row.id)?.header_name}
                  </Base>
                ),
              },
              {
                render: (row) => (
                  <Base>
                    {typeof row.value === "boolean"
                      ? row.value
                        ? "Yes"
                        : "No"
                      : formatFieldValue(
                          row.value,
                          fields.find((a) => a.field_id === row.id)?.format
                        )}
                  </Base>
                ),
              },
            ]}
            showPagination={false}
          />
          <div className="mt-2" />
        </>
      ))}
    </>
  );
};

const formatFieldValue = (
  value: any,
  format: { type?: string; multiple?: boolean } = {}
) => {
  if (format.multiple) {
    return (
      <>
        {(value || "")
          .split(/,|;/)
          .map((a: string) => a.trim())
          .map((v: any, i: number) => (
            <>
              {i > 0 ? ", " : ""}
              {formatFieldValue(v, { type: format?.type })}
            </>
          ))}
      </>
    );
  }

  if (format.type?.match(/currency_/)) {
    return Intl.NumberFormat(navigator.language, {
      style: "currency",
      currency: format.type.split("_")[1],
    }).format(value || 0);
  }

  if (format.type === "number") {
    return Intl.NumberFormat(navigator.language, {}).format(value || 0);
  }

  if (format.type?.match(/date_/)) {
    const isSeconds = format.type.split("_")[1] === "s";
    const time = (value || 0) * (isSeconds ? 1000 : 1);
    return formatTime(time);
  }

  if (format.type?.match(/elapsed_/)) {
    const isSeconds = format.type.split("_")[1] === "s";
    const time = (value || 0) * (isSeconds ? 1000 : 1);
    return formatDuration(time);
  }

  if (format.type === "iban") {
    return (value || "").replace(/(.{4})/g, "$1 ").toUpperCase();
  }

  if (format.type === "phone") {
    return parsePhoneNumber(value || "")?.formatInternational() || value;
  }

  if (format.type === "url") {
    return <LinkConfirm href={value}>{value}</LinkConfirm>;
  }

  if (format.type?.match(/matrix_/)) {
    return <MatrixValue value={value} matrixId={format.type.split("_")[1]} />;
  }

  return value;
};

const MatrixValue = ({ value, matrixId }: { value: any; matrixId: string }) => {
  const { matrix } = useMatrixEntries(matrixId);
  const label = matrix.find((a) => a.key === value)?.label || value;
  const color = matrix.find((a) => a.key === value)?.value || "U";
  return (
    <span
      className={
        (
          {
            H: "text-red-600",
            M: "text-yellow-600",
            L: "text-green-600",
          } as any
        )[color] || ""
      }
    >
      {label}
    </span>
  );
};

import { AgentType } from "@features/auth/state/store";
import { InboxMessage, ThreadRelatedCustomer } from "@features/inbox/types";
import { useScenarios } from "@features/scenarios/state/use-scenarios";

import { useHasAccess } from "@features/auth/state/use-access";
import { useCustomersCache } from "@features/customers/state/use-customers-cache";
import { extractCustomerFullName } from "@features/customers/utils";
import { ScenariosApiClient } from "@features/scenarios/api-client/api-client";
import { SessionsApiClient } from "@features/sessions/api-client/api-client";
import { CreateSessionInput } from "@features/sessions/types";
import { useControlledEffect } from "@features/utils";
import { formatTime } from "@features/utils/dates";
import { getBestLocaleFromList } from "@features/utils/strings";
import { InboxTemplateContextType } from "../types";

export const useTemplateContext = ({
  customers,
  agent,
  lastMessage,
}: {
  customers?: ThreadRelatedCustomer[];
  agent?: AgentType;
  lastMessage?: InboxMessage;
}) => {
  const { customers: cachedCustomers } = useCustomersCache(
    customers?.map((a) => a.id) || []
  );
  const { scenarios, refresh: refreshScenarios } = useScenarios();
  const hasAccess = useHasAccess();

  useControlledEffect(() => {
    if (hasAccess("SIDENAV_SCENARIO")) {
      refreshScenarios();
    }
  }, [refreshScenarios, hasAccess]);

  const scenarioCodesAndAssociatedFunction: {
    [key: string]: string | (() => void);
  } = {};
  const enabledScenarios = (scenarios?.data || []).filter(
    (scenario) => scenario.enabled === true
  );
  enabledScenarios.forEach((scenario) => {
    scenarioCodesAndAssociatedFunction[scenario.code] = async () => {
      const customer = cachedCustomers?.[0];
      const languages = Object.keys(
        (await ScenariosApiClient.getScenario(scenario.id)).questions[0]
          .description_localized
      );
      const input: CreateSessionInput = {
        scenario_code: scenario.code,
        external_id: customer?.external_id || "",
        language: getBestLocaleFromList(
          customer?.language ||
            customer?.domicile_code ||
            customer?.nationality_code ||
            "en",
          languages,
          languages[0]
        ),
        extra_data: undefined,
        input_data: undefined,
      };
      return SessionsApiClient.createSessionAndGetHostURL(input);
    };
  });

  const customersContext = cachedCustomers.map((customer) => ({
    id: customer.customer_id,
    ...customer,
    date_of_birth: customer?.date_of_birth,
    ...customer?.details?.custom_fields
      .map((f) => ({ ["customer." + f.name]: f.value }))
      .reduce((a, b) => ({ ...a, ...b }), {}),
    full_name: extractCustomerFullName(customer),
  }));

  const context: InboxTemplateContextType = {
    customers: customersContext,
    customer: customersContext[0]
      ? customersContext[0]
      : ({} as ThreadRelatedCustomer & { full_name: string }),
    agent: agent
      ? { ...agent, full_name: agent.name }
      : ({} as AgentType & { full_name: string }),
    general: {
      date: formatTime(new Date().toISOString()),
    },
    last_message: {
      date: lastMessage
        ? formatTime(new Date(lastMessage?.created_at || 0).toISOString())
        : "",
      from: lastMessage?.from || "",
      subject: lastMessage?.subject || "",
      content: lastMessage?.content || "",
    },
    scenario: scenarioCodesAndAssociatedFunction,
  };

  return context;
};

import Select from "@atoms/select";
import { inboxAvailableTemplateLanguages } from "@features/inbox-templates/enum";
import { useInboxTemplates } from "@features/inbox-templates/state/use-inbox-templates";
import {
  InboxTemplateContextType,
  InboxTemplateType,
} from "@features/inbox-templates/types";
import { generateContent, generateSubject } from "@features/inbox/utils";
import { useControlledEffect } from "@features/utils";
import { useState } from "react";

export type TemplateWithPreviousType = {
  current?: InboxTemplateType;
  previous?: InboxTemplateType;
};

export const applyTemplateAndSignature = async (
  subject: string,
  content: string,
  {
    template,
    signature,
    context,
    language,
  }: {
    template: TemplateWithPreviousType;
    signature: TemplateWithPreviousType;
    context?: InboxTemplateContextType;
    language?: string;
  }
) => {
  // Remove history if any
  content = content
    .replace(/> On .*? wrote:[\s\S]*/, "")
    .replace(/(\n+)$/gm, "");

  const originalSubject = subject;
  const originalContent = content;

  // Template
  if (template.current && template.current?.id !== template.previous?.id) {
    subject = generateSubject(context, template.current!, language);
    content = await generateContent(context, template.current!, language).then(
      (content) => {
        return content;
      }
    );
  }

  // Delete previous signature
  for (const sign of [signature.current, signature.previous].filter(Boolean)) {
    if (sign) {
      for (const language in Object.keys(sign!.content)) {
        const signatureCtt = await generateContent(context, sign!, language);
        content = content.replace(signatureCtt.trim(), "");
      }
    }
  }
  // Add signature
  if (signature.current) {
    const signatureCtt = await generateContent(
      context,
      signature.current!,
      language
    );
    content = `${content.trim()}\n\n${signatureCtt.trim()}`;
  }

  if (
    template.current &&
    template.current?.id !== template.previous?.id &&
    ((originalSubject && originalSubject !== subject) ||
      (originalContent && originalContent !== content))
  ) {
    if (
      !window.confirm(
        "Are you sure you want to apply this template and override your changes?"
      )
    ) {
      subject = originalSubject;
      content = originalContent;
    }
  }

  // Previous message
  if (context?.last_message?.from) {
    content =
      content +
      "\n\n> On " +
      new Date(context?.last_message?.date).toLocaleString() +
      ", " +
      context?.last_message.from +
      " wrote:\n> \n" +
      context?.last_message.content
        ?.split("\n")
        .map((a) => a.substring(0, 100))
        .slice(0, 100)
        .join("\n")
        ?.substring(0, 500)
        ?.replace(/^/gm, "> ");
  }

  return { subject, content };
};

export const MessageTemplates = ({
  autoSelectSignature,
  setSelectedSignature,
  setSelectedTemplate,
  setSelectedTemplateLanguage,
  language,
  disabled,
}: {
  autoSelectSignature?: boolean;
  setSelectedSignature: (signature: InboxTemplateType | undefined) => void;
  setSelectedTemplate: (template: InboxTemplateType | undefined) => void;
  setSelectedTemplateLanguage: (language: string) => void;
  language?: string;
  disabled: boolean;
}) => {
  const { templates, loading } = useInboxTemplates();
  const [didAutoSelectSignature, setDidAutoSelectSignature] = useState(false);

  useControlledEffect(() => {
    const signature = templates.filter((t) => t.use_as_signature);
    if (
      autoSelectSignature &&
      !didAutoSelectSignature &&
      signature?.length === 1 &&
      !loading
    ) {
      setSelectedSignature(signature[0]);
      setDidAutoSelectSignature(true);
    }
  }, [templates, loading, didAutoSelectSignature]);

  return (
    <div className="z-20 absolute bottom-3 right-3 w-auto flex -space-x-px">
      <Select
        size="sm"
        className="rounded-r-none"
        style={{ maxWidth: 172 }}
        onChange={(e) => {
          const v = templates.find((t) => t.id === e.target.value);
          if (e.target.value === "no_signature") {
            setSelectedSignature(undefined);
          } else if (v?.use_as_signature) {
            setSelectedSignature(v);
          } else {
            setSelectedTemplate(v);
          }
          e.target.selectedIndex = 0;
        }}
        disabled={disabled}
      >
        <option disabled selected>
          Change template
        </option>
        {templates
          .filter((t) => !t.use_as_signature && !t.use_as_receipt)
          .map((t) => (
            <option key={t.id} value={t.id}>
              {t.label}
            </option>
          ))}
        {templates.filter((t) => t.use_as_signature).length >= 1 && (
          <>
            <option disabled>Change signature</option>
            {templates
              .filter((t) => t.use_as_signature && !t.use_as_receipt)
              .map((t) => (
                <option key={t.id} value={t.id}>
                  {t.label}
                </option>
              ))}
            <option value={"no_signature"}>No signature</option>
          </>
        )}
      </Select>
      {!disabled && (
        <Select
          size="sm"
          className="rounded-l-none w-max"
          onChange={(e) => setSelectedTemplateLanguage(e.target.value)}
        >
          {Object.keys(inboxAvailableTemplateLanguages).map((lang) => (
            <option key={lang} value={lang} selected={lang === language}>
              {lang}
            </option>
          ))}
        </Select>
      )}
    </div>
  );
};

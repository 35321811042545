import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { PageBlockHr } from "@atoms/layout/page-block";
import { Base, BaseSmall, Info, InfoSmall, SectionSmall } from "@atoms/text";
import {
  ExploreKytLayoutAtom,
  FocusedNodeAtom,
} from "@features/kyt/state/store";
import useExploreGraph from "@features/kyt/state/use-explore-graph";
import { KytNode } from "@features/kyt/types";
import { KYT_TRANSACTION_STATES_COLORS } from "@features/kyt/utils";
import { ROUTES } from "@features/routes";
import {
  BuildingOfficeIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  UserIcon,
} from "@heroicons/react/20/solid";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  QueueListIcon,
  RectangleStackIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useSetRecoilState } from "recoil";

export default function GraphFocusedNode({ node }: { node: KytNode }) {
  const { refreshSQL } = useExploreGraph();
  const setLayout = useSetRecoilState(ExploreKytLayoutAtom);
  const setFocusedNode = useSetRecoilState(FocusedNodeAtom);
  const [collaps, setCollaps] = useState({
    allowed: false,
    blocked: false,
  });

  return (
    <div className="flex flex-col space-y-2 bg-white dark:bg-slate-900 border rounded  px-4 py-2 shadow-sm">
      <div className="flex flex-row items-start">
        <div className="grow overflow-hidden">
          {/* <InfoSmall>Customer Node</InfoSmall> */}
          {node.external_id && <Info>{node.external_id}</Info>}
          <SectionSmall className="overflow-ellipsis">
            {node.account_type === "F" ? (
              <BuildingOfficeIcon className="h-5 w-5 inline mr-2 shrink-0" />
            ) : (
              <UserIcon className="h-5 w-5 inline mr-2 shrink-0" />
            )}
            {node.full_name?.toUpperCase()}
          </SectionSmall>
          {node.external_id && (
            <Link
              to={ROUTES.CustomerView.replace(":id", node.customer!.id || "")}
            >
              <Button className="mt-2" size="sm" theme="outlined">
                View Profile
              </Button>
            </Link>
          )}
        </div>
        <button
          type="button"
          className="bg-slate-300 dark:bg-slate-600 rounded-full p-1 text-slate-600 dark:text-slate-300 hover:opacity-75 focus:outline-none "
          onClick={() => {
            setLayout((layout) => ({
              ...layout,
              relations: false,
              transactions: false,
            }));
            setFocusedNode(null);
          }}
        >
          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <PageBlockHr />
      <div className="flex flex-col items-start">
        <div className="w-full flex flex-row items-center justify-between">
          <div className="grow">
            <Tag
              noColor
              className={
                "text-white bg-" +
                (KYT_TRANSACTION_STATES_COLORS[0] || "slate-500")
              }
            >
              Allowed
            </Tag>
          </div>
          <div className="flex flex-row space-x-2 items-center">
            <BaseSmall className="block font-bold">
              {node.volume[0].toLocaleString()}€ / {node.count[0]}
            </BaseSmall>
            <button
              onClick={() => {
                setCollaps({ ...collaps, allowed: !collaps.allowed });
              }}
            >
              {collaps.allowed ? (
                <MinusCircleIcon className="h-5 w-5 text-blue-600" />
              ) : (
                <PlusCircleIcon className="h-5 w-5 text-blue-600" />
              )}
            </button>
          </div>
        </div>
        {collaps.allowed && (
          <div className="w-full">
            <div className="flex flex-row w-full justify-between">
              <div>
                <InfoSmall>Volume Total </InfoSmall>
                <Base className="block font-bold">
                  {node.volume[0].toLocaleString()}€
                </Base>
              </div>
              <div>
                <InfoSmall>Count Total </InfoSmall>
                <Base className="block text-right font-bold">
                  / {node.count[0]}
                </Base>
              </div>
            </div>
            <div className="flex flex-row w-full justify-between ">
              <BaseSmall className="">
                <ArrowLongRightIcon className="inline-block w-6 h-6 mr-1 text-green-600" />
                {node.volume[1].toLocaleString()}€
                <Info className="inline-block ml-1">
                  [ {node.min[1].toLocaleString()}€ -{" "}
                  {node.max[1].toLocaleString()}€ ]
                </Info>
              </BaseSmall>
              <BaseSmall className="block">/ {node.count[1]}</BaseSmall>
            </div>

            <div className="flex flex-row w-full justify-between ">
              <BaseSmall className="">
                <ArrowLongLeftIcon className="inline-block w-6 h-6 mr-1 text-red-600" />
                {node.volume[2].toLocaleString()}€
                <Info className="inline-block ml-1">
                  [ {node.min[2].toLocaleString()}€ -{" "}
                  {node.max[2].toLocaleString()}€ ]
                </Info>
              </BaseSmall>
              <BaseSmall className="block">/ {node.count[2]}</BaseSmall>
            </div>
          </div>
        )}
      </div>

      <PageBlockHr />
      <div className="flex flex-col items-start">
        <div className="flex flex-row w-full items-center justify-between">
          <div className="grow">
            <Tag
              noColor
              className={
                "text-white bg-" +
                (KYT_TRANSACTION_STATES_COLORS[1] || "slate-500")
              }
            >
              Blocked
            </Tag>
          </div>
          <div className="flex flex-row space-x-2 items-center">
            <BaseSmall className="block font-bold">
              {node.volume[3].toLocaleString()}€ / {node.count[3]}
            </BaseSmall>
            <button
              onClick={() => {
                setCollaps({ ...collaps, blocked: !collaps.blocked });
              }}
            >
              {collaps.blocked ? (
                <MinusCircleIcon className="h-5 w-5 text-blue-600" />
              ) : (
                <PlusCircleIcon className="h-5 w-5 text-blue-600" />
              )}
            </button>
          </div>
        </div>
        {collaps.blocked && (
          <div className="w-full">
            <div className="flex flex-row w-full justify-between">
              <div>
                <InfoSmall>Volume Total </InfoSmall>
                <Base className="block font-bold">
                  {node.volume[3].toLocaleString()}€
                </Base>
              </div>
              <div>
                <InfoSmall>Count Total </InfoSmall>
                <Base className="block text-right font-bold">
                  / {node.count[3]}
                </Base>
              </div>
            </div>
            <div className="flex flex-row w-full justify-between ">
              <BaseSmall className="">
                <ArrowLongRightIcon className="inline-block w-6 h-6 mr-1 text-green-600" />
                {node.volume[4].toLocaleString()}€
                <InfoSmall className="inline-block ml-1">
                  [ {node.min[4].toLocaleString()}€ -{" "}
                  {node.max[4].toLocaleString()}€ ]
                </InfoSmall>
              </BaseSmall>
              <BaseSmall className="block">/ {node.count[4]}</BaseSmall>
            </div>
            <div className="flex flex-row w-full justify-between ">
              <BaseSmall className="">
                <ArrowLongLeftIcon className="inline-block w-6 h-6 mr-1 text-red-600" />
                {node.volume[5].toLocaleString()}€
                <InfoSmall className="inline-block ml-1">
                  [ {node.min[5].toLocaleString()}€ -{" "}
                  {node.max[5].toLocaleString()}€ ]
                </InfoSmall>
              </BaseSmall>
              <BaseSmall className="block">/ {node.count[5]}</BaseSmall>
            </div>
          </div>
        )}
      </div>
      <PageBlockHr />
      <InfoSmall>Actions</InfoSmall>
      <div className="flex flex-row space-x-2">
        <span data-tooltip={"Show In/Out Relations"}>
          <button
            onClick={() => {
              setLayout((layout) => ({
                ...layout,
                relations: true,
              }));
            }}
            className="rounded bg-blue-400 p-1 hover:bg-blue-500"
          >
            <RectangleStackIcon
              className="h-6 w-6 m-1 text-white"
              aria-hidden="true"
            />
          </button>
        </span>
        <span data-tooltip={"Show Node Transactions Details"}>
          <button
            onClick={() => {
              setLayout((layout) => ({
                ...layout,
                relations: false,
                transactions: true,
              }));
              const sqlQuery = `SELECT * FROM kyt_transactions\
                WHERE '${node.id}' = ANY(customers)\
              ORDER BY AMOUNT DESC;`;
              refreshSQL(sqlQuery);
            }}
            className="rounded bg-blue-400 p-1 hover:bg-blue-500"
          >
            <QueueListIcon
              className="h-6 w-6 m-1 text-white"
              aria-hidden="true"
            />
          </button>
        </span>
      </div>
    </div>
  );
}

import { useAuth } from "@features/auth/state/hooks";

export const SignUp = () => {
  const { user } = useAuth();

  if (user?.id) {
    return <></>;
  }

  return <>Signup</>;
};

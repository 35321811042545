import { useGlobalEffect } from "@features/utils";
import { LoadingAtom } from "@features/utils/loading";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import { MessageLabelType } from "../types";
import { MessagesLabelsApiClient } from "../api-client/api-client";
import { MessagesLabelsAtom } from "./store";
import { useHasAccess } from "@features/auth/state/use-access";

export const useMessagesLabels = () => {
  const hasAccess = useHasAccess();
  const [list, setList] = useRecoilState(MessagesLabelsAtom);
  const [loading, setLoading] = useRecoilState(
    LoadingAtom("useMessagesLabels")
  );

  const refresh = useCallback(async () => {
    setLoading(true);
    try {
      if (hasAccess("CHAT")) setList(await MessagesLabelsApiClient.get());
    } catch (e) {
      setLoading(false);
    }
    setLoading(false);
  }, [setList, setLoading]);

  const save = useCallback(
    async (data: MessageLabelType[]) => {
      setLoading(true);
      try {
        await MessagesLabelsApiClient.save(data);
        toast.success("Saved Messages labels");
      } catch (e) {
        console.error(e);
        toast.error("Failed to save Message labels");
        setLoading(false);
        return false;
      }
      await refresh();
      return true;
    },
    [refresh, setLoading]
  );

  useGlobalEffect(
    "useMessagesLabels",
    () => {
      refresh();
    },
    []
  );

  return {
    labels: list,
    save,
    loading,
    refresh,
  };
};

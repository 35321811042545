import { atom } from "recoil";
import { Agentsrole_type, AgentType } from "../types";

export const AvailableRolesAtom = atom<Agentsrole_type[]>({
  key: "AvailableRolesAtom",
  default: [],
});

export const AgentsAtom = atom<AgentType[]>({
  key: "AgentsAtom",
  default: [],
});

import { is2XX, useFetch } from "@features/utils";
import { WebAuthnCredential, WebAuthnLoginResponse, WebAuthnRegisterResponse } from "../types";
import { RegistrationJSON, AuthenticationJSON } from "@passwordless-id/webauthn/dist/esm/types";

export class WebAuthnApiClient {
  static getChallenge = async () => {
    const response = await useFetch(`/web/authentication/web_authn/new`, {
      method: "GET",
    });
    const data = await response.json();
    if (!is2XX(response.status)) {
      throw new Error("Failed to start webauthn registration.");
    }
    return data as WebAuthnRegisterResponse;
  };

  static register = async (webAuthnRegistration: RegistrationJSON) => {
    const response = await useFetch(`/web/authentication/web_authn/new`, {
      method: "POST",
      body: JSON.stringify(webAuthnRegistration)
    });
    if (!is2XX(response.status)) {
      throw new Error("Failed to register webauthn.");
    }
    return;
  };

  static getLoginChallenge = async () => {
    const response = await useFetch(`/api/v1/web_authn/login`, {
      method: "GET",
    });
    const data = await response.json();
    if (!is2XX(response.status)) {
      throw new Error("Failed to login through.");
    }
    return data as WebAuthnLoginResponse;
  };

  static login = async (webAuthnAuthentication: AuthenticationJSON) => {
    const response = await useFetch(`/api/v1/web_authn/login`, {
      method: "POST",
      body: JSON.stringify(webAuthnAuthentication)
    });
    if (!is2XX(response.status)) {
      throw new Error("Failed to login webauthn.");
    }
    return;
  };

  static getCredentials = async () => {
    const response = await useFetch(`/web/authentication/web_authn/list`, {
      method: "GET",
    });
    const data = await response.json();
    if (!is2XX(response.status)) {
      throw new Error("Failed to login webauthn.");
    }
    return data as WebAuthnCredential[];
  };

  static deleteCredential = async (id: number) => {
    const response = await useFetch(`/web/authentication/web_authn/${id}`, {
      method: "DELETE",
    });
    if (!is2XX(response.status)) {
      throw new Error("Failed to delete device.");
    }
    return;
  }
}

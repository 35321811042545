import { is2XX, useFetch } from "@features/utils";
import {
  PressSettings,
  InstantPressRequestOptions,
  PressReportBody,
  ReportSearchField,
  ReportSearchResultType,
  PressReportCompleteWithHistory,
  UpdatePressReportOptions,
} from "../types";
import _ from "lodash";
import { RestSearchQuery } from "@components/search-bar/utils/types";
import { REPORT_EDD_STATUS_TO_UINT_BACKEND } from "../utils";

type PressInstantReportRequestOptions = {
  name: string;
  additional_info: {
    entity_type: number; // 1: person, 2: company
    date_of_birth: number | null;
    country_codes: string[] | null;
  };
};

export class PressApiClient {
  static generateInstantReportAbout = async (
    options: Partial<InstantPressRequestOptions>
  ) => {
    const response = await useFetch(`/web/press/instantreport`, {
      method: "POST",
      body: JSON.stringify({
        name: options.name,
        additional_info: {
          entity_type: options.entity_type || 0,
          date_of_birth: options.date_of_birth,
          country_codes: _.uniq(options.country_codes || []),
        },
      } as PressInstantReportRequestOptions),
    });
    const data = await response.json();
    return data as PressReportBody;
  };

  static getExistingInstantReport = async (reportBodyid: string) => {
    const response = await useFetch(`/web/press/reportbody/${reportBodyid}`, {
      method: "GET",
    });
    const data = await response.json();
    return data as PressReportBody;
  };

  static getSettings = async () => {
    const response = await useFetch(`/web/press/configuration`, {
      method: "GET",
    });
    const data = await response.json();
    return data as PressSettings;
  };

  static saveSettings = async (config: PressSettings) => {
    const response = await useFetch(`/web/press/configuration`, {
      method: "POST",
      body: JSON.stringify(config),
    });
    if (!is2XX(response.status)) {
      console.error("Failed to save settings", response);
      throw new Error("Failed to save settings");
    }
  };

  static getReportsSearchSchema = async () => {
    const response = await useFetch(`/web/pressreports/schema`, {
      method: "GET",
    });
    const data = await response.json();
    return data.searchable_fields as ReportSearchField[];
  };

  static searchReportsAdvanced = async (
    query: RestSearchQuery[] | null,
    options: Partial<{
      limit: number;
      offset: number;
      order_by: string;
      order_way: "ASC" | "DESC";
    }>
  ) => {
    const response = await useFetch(`/web/pressreports`, {
      method: "POST",
      body: JSON.stringify({
        query,
        options,
      }),
    });
    const data = await response.json();
    data.data = (data.data || []).map((report: any) => {
      return {
        ...report,
      } as ReportSearchResultType;
    });
    return data as { data: ReportSearchResultType[]; total: number };
  };

  static getReportCompleteWithHistory = async (customerID: string) => {
    const response = await useFetch(
      `/web/press/report/${customerID}/full_history`,
      {
        method: "GET",
      }
    );
    const data = await response.json();
    return data as PressReportCompleteWithHistory;
  };

  static getReportBody = async (reportBodyID: string) => {
    const response = await useFetch(`/web/press/reportbody/${reportBodyID}`, {
      method: "GET",
    });
    const data = await response.json();
    return data as PressReportBody;
  };

  static updateReportStatusAndRisk = async (
    customerID: string,
    options: UpdatePressReportOptions
  ) => {
    const response = await useFetch(`/web/press/report/${customerID}/update`, {
      method: "POST",
      body: JSON.stringify({
        new_edd_state:
          options.new_edd_state != null
            ? REPORT_EDD_STATUS_TO_UINT_BACKEND[options.new_edd_state]
            : null,
        new_active_risk_level: options.new_active_risk_level,
        comment: options.comment,
      }),
    });
    if (!is2XX(response.status)) {
      console.error("Failed to update report", response);
      throw new Error("Failed to update report");
    }
  };

  static addNoteToReport = async (customerID: string, noteContent: string) => {
    const response = await useFetch(`/web/press/report/${customerID}/notes`, {
      method: "POST",
      body: JSON.stringify({ body: noteContent }),
    });
    if (!is2XX(response.status)) {
      console.error("Failed to add note to report", response);
      throw new Error("Failed to add note to report");
    }
  };
}

import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { GetStartedCard } from "@atoms/card/get-started";
import { Page } from "@atoms/layout/page";
import { Info, SectionSmall, Title } from "@atoms/text";
import { RiskElementEditModalAtom } from "@components/risks/modals/metadata";
import { Table } from "@components/table";
import { useHasAccess } from "@features/auth/state/use-access";
import { useRiskFactors } from "@features/risk-decisions/use-risks-decisions";
import { getSubLabels } from "@features/risk-decisions/utils";
import { ROUTES } from "@features/routes";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";

export const RiskFactorsPage = () => {
  const { riskFactors: factors, loading } = useRiskFactors("customer");
  const edit = useSetRecoilState(RiskElementEditModalAtom("customer"));
  const navigate = useNavigate();
  const hasAccess = useHasAccess();

  const sortedRiskFactors = [...(factors || [])];
  sortedRiskFactors.sort((a, b) => a.label.localeCompare(b.label));
  const groupedRiskFactors = _.groupBy(
    sortedRiskFactors,
    (a) => getSubLabels(a.label)[0]
  );

  const totalWeight = factors.reduce((acc, item) => acc + item.weight, 0);

  return (
    <Page>
      {factors.length > 0 && !loading && hasAccess("RISK_SETTINGS_WRITE") && (
        <Button
          onClick={() => {
            edit({ open: true });
          }}
          className="float-right"
          theme="primary"
          size="sm"
        >
          Create risk factor
        </Button>
      )}
      <Title>Risk factors</Title>
      <div className="my-4">
        <GetStartedCard
          show={factors.length <= 0 && !loading}
          title={<>Risk factors</>}
          text={
            <>
              Your customers are scored based on risk factors. Risk factors are
              logic decision trees with a high level of customization.
              {hasAccess("RISK_SETTINGS_WRITE") && (
                <>
                  <br />
                  <Button
                    className="mt-4 block"
                    onClick={() => {
                      edit({ open: true });
                    }}
                    theme="primary"
                    size="lg"
                  >
                    Create risk factor
                  </Button>
                </>
              )}
            </>
          }
        />
      </div>
      {_.sortBy(Object.entries(groupedRiskFactors), (a) => a[0]).map(
        ([, v]) => (
          <>
            {(Object.keys(groupedRiskFactors).length > 1 ||
              getSubLabels(v[0].label)[0]) && (
              <SectionSmall className="mt-4 mb-2">
                {getSubLabels(v[0].label)[0] || "General"}
              </SectionSmall>
            )}
            <Table
              className="mb-8"
              loading={loading}
              showPagination={false}
              data={v}
              columns={[
                {
                  thClassName: "w-full",
                  title: "Risk factors",
                  render: (item) => getSubLabels(item.label)[1],
                },
                {
                  thClassName: "w-40 whitespace-nowrap",
                  title: "Weight",
                  render: (item) => {
                    const useWeight = factors.find(
                      (a) => a.id === item.id
                    )?.use_weight;
                    const weight =
                      factors.find((a) => a.id === item.id)?.weight || 0;

                    if (weight === 0 && useWeight) {
                      return (
                        <>
                          <Tag
                            color="gray"
                            data-tooltip="This rule won't participate to the final score."
                          >
                            Excluded
                          </Tag>
                        </>
                      );
                    }

                    return (
                      <>
                        {!useWeight && <Info>[max]</Info>}
                        {useWeight && (
                          <>
                            <Info>
                              {weight}
                              {weight > 0 && (
                                <>
                                  {" "}
                                  {" - "}
                                  {Math.round((100 * weight) / totalWeight)}%
                                </>
                              )}
                            </Info>
                          </>
                        )}
                      </>
                    );
                  },
                },
                {
                  thClassName: "w-24",
                  title: "Actions",
                  render: (item) => (
                    <>
                      {hasAccess("RISK_SETTINGS_WRITE") && (
                        <Button
                          size="sm"
                          theme="outlined"
                          onClick={() => {
                            edit({ open: true, id: `${item.id}` });
                          }}
                        >
                          Metadata
                        </Button>
                      )}
                      <Button
                        className="ml-2"
                        size="sm"
                        theme="primary"
                        onClick={() => {
                          navigate(
                            ROUTES.CustomerRiskFactorsDetails.replace(
                              ":id",
                              `${item.id}`
                            )
                          );
                        }}
                      >
                        Open tree
                      </Button>
                    </>
                  ),
                },
              ]}
            />
          </>
        )
      )}
    </Page>
  );
};

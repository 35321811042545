import { useGlobalEffect } from "@features/utils";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { AgentsApiClient } from "../api-client/api-client";
import { AvailableRolesAtom } from "./store";

export const useAvailableRoles = () => {
  const [roles, setRoles] = useRecoilState(AvailableRolesAtom);

  const refresh = useCallback(async () => {
    setRoles(await AgentsApiClient.getRoles());
  }, [setRoles]);

  useGlobalEffect(
    "useAvailableRoles",
    () => {
      refresh();
    },
    []
  );

  return {
    roles,
    refresh,
  };
};

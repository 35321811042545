import _ from "lodash";

export const inboxAvailableTemplateLanguages = {
  en: "English",
  fr: "French",
  de: "German",
  it: "Italian",
  es: "Spanish",
  pt: "Portuguese",
  ru: "Russian",
  ja: "Japanese",
  zh: "Chinese",
  ar: "Arabic",
};

export const inboxAvailableTemplateVariables = {
  customer: {
    external_id: true,
    first_name: true,
    middle_name: true,
    last_name: true,
    date_of_birth: true,
    company_name: true,
    trading_name: true,
    full_name: true,
    domicile_code: true,
    nationality_code: true,
  },
  agent: {
    full_name: true,
    email: true,
  },
  general: {
    date: true,
  },
  last_message: {
    date: true,
    subject: true,
    from: true,
  },
  "% for customer in customers %": true,
  "% endfor %": true,
};

export const inboxAvailableTemplateVariablesFlat = flattenObject(
  inboxAvailableTemplateVariables
);

function flattenObject(o: any, prefix = "", result: any = {}, keepNull = true) {
  if (
    _.isString(o) ||
    _.isNumber(o) ||
    _.isBoolean(o) ||
    (keepNull && _.isNull(o))
  ) {
    result[prefix] = o;
    return result;
  }

  if (_.isArray(o) || _.isPlainObject(o)) {
    for (const i in o) {
      let pref = prefix;
      if (_.isArray(o)) {
        pref = pref + `[${i}]`;
      } else {
        if (_.isEmpty(prefix)) {
          pref = i;
        } else {
          pref = prefix + "." + i;
        }
      }
      flattenObject(o[i], pref, result, keepNull);
    }
    return result;
  }
  return result;
}

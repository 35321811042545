import { is2XX, useFetch } from "@features/utils";
import { DocumentLabelType } from "../types";

export class DocumentsLabelsApiClient {
  static get = async () => {
    const response = await useFetch(`/web/customer_documents/tags`, {
      method: "GET",
    });
    const data = await response.json();
    return (data?.tags as DocumentLabelType[]) || [];
  };

  static save = async (labels: DocumentLabelType[]) => {
    const response = await useFetch(`/web/customer_documents/tags`, {
      method: "POST",
      body: JSON.stringify({
        tags: labels,
      }),
    });
    if (!is2XX(response.status)) {
      throw new Error("Failed to save custom labels");
    }
  };
}

import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import Select from "@atoms/select";
import { InfoSmall } from "@atoms/text";
import { AgentsRolesLabels } from "@features/agents/enum";
import { useAvailableRoles } from "@features/agents/state/use-roles";
import { XMarkIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import { ReactNode, useCallback, useState } from "react";

export const RolesManagement = ({
  roles,
  availableRoles,
  onChange,
  readOnly,
}: {
  roles: string[];
  availableRoles?: string[];
  onChange: (roles: string[]) => Promise<void>;
  readOnly: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const { roles: _availableRoles } = useAvailableRoles();
  const availableRolesObject = _availableRoles.filter(
    (role) => !availableRoles || availableRoles.includes(role.code)
  );

  const _onChange = useCallback(
    async (roles: string[]) => {
      setLoading(true);
      await onChange(roles);
      setLoading(false);
    },
    [onChange, setLoading]
  );

  return (
    <>
      {roles.map((role) => (
        <Role
          key={role}
          code={role}
          className="h-8 px-3 font-semibold"
          suffix={
            !readOnly &&
            roles.length > 1 && (
              <Button
                theme="danger"
                size="sm"
                className="rounded-full !h-5 !w-5 !p-0 ml-2 -mr-1"
                onClick={() => _onChange(roles.filter((a) => a !== role))}
              >
                <XMarkIcon className="h-4 w-4 text-white" />
              </Button>
            )
          }
        />
      ))}

      {!readOnly && (
        <Select
          disabled={loading}
          className="mt-3 block w-auto"
          onChange={(e) => _onChange(_.uniq([...roles, e.target.value]))}
          value=""
        >
          <option value="" disabled selected>
            Add role
          </option>
          {_.sortBy(
            availableRolesObject,
            (role) => AgentsRolesLabels[role.code] || role.label
          )
            .filter((a) => !roles.includes(a.code))
            .map((role) => (
              <option key={role.code} value={role.code}>
                {AgentsRolesLabels[role.code] || role.label}
              </option>
            ))}
        </Select>
      )}
    </>
  );
};

export const Role = ({
  code,
  suffix,
  className,
}: {
  code: string;
  suffix?: ReactNode;
  className?: string;
}) => {
  const { roles } = useAvailableRoles();
  const idcode = roles.find((r) => r.code === code)?.code || "";
  return (
    <Tag
      className={
        "mx-px my-px inline-flex items-center justify-center " +
        (className || "")
      }
    >
      <InfoSmall noColor>{AgentsRolesLabels[idcode] || idcode}</InfoSmall>
      {suffix}
    </Tag>
  );
};

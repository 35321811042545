import { Tag } from "@atoms/badge/tag";
import { Info } from "@atoms/text";
import { CustomerAllDetailType } from "@features/customers/types";
import { useMessagesLabels } from "@features/messages-labels/state/use-messages-labels";
import { stringToColor } from "@features/utils";

export const ComputedFields = ({
  customer,
}: {
  customer: CustomerAllDetailType;
}) => {
  const { labels } = useMessagesLabels();
  const openChatCategories = (
    (customer.details.open_chat_categories.value || "") + ""
  )
    .split(/,|;/g)
    .filter((e) => e);

  return (
    <>
      <div className="mt-4 grid gap-4 grid-cols-2 sm:grid-cols-4">
        <div>
          <span
            data-tooltip={
              "Related to Go!Scan politically exposed persons (or relative and close associates)"
            }
            tabIndex={0}
          >
            <Info>PEP (or RCA)</Info>
          </span>

          <br />
          <div className="overflow-hidden mr-2 text-base">
            {customer.details.pep.value && (
              <span
                data-tooltip={
                  "Confirmed alert exists for this customer or this value was manually set to true"
                }
                tabIndex={0}
              >
                <Tag noColor className="bg-red-500 text-white">
                  Confirmed PEP
                </Tag>
              </span>
            )}
            {customer.details.may_be_pep.value && (
              <span
                data-tooltip={"New or pending alert exists for this customer"}
                tabIndex={0}
              >
                <Tag noColor className="bg-yellow-500 text-white">
                  Unconfirmed PEP
                </Tag>
              </span>
            )}
            {!customer.details.may_be_pep.value &&
              !customer.details.pep.value && <Tag>N/A</Tag>}
          </div>
        </div>
        <div>
          <span
            data-tooltip={
              "Related to Go!Scan sanctioned entities (or relative and close associates)"
            }
            tabIndex={0}
          >
            <Info>Sanction (or RCA)</Info>
          </span>

          <br />
          <div className="overflow-hidden mr-2 text-base">
            {customer.details.se.value && (
              <span
                data-tooltip={
                  "Confirmed alert exists for this customer or this value was manually set to true"
                }
                tabIndex={0}
              >
                <Tag noColor className="bg-red-500 text-white">
                  Confirmed SAN
                </Tag>
              </span>
            )}
            {customer.details.may_be_san.value && (
              <span
                data-tooltip={"New or pending alert exists for this customer"}
                tabIndex={0}
              >
                <Tag noColor className="bg-yellow-500 text-white">
                  Unconfirmed SAN
                </Tag>
              </span>
            )}
            {!customer.details.may_be_san.value &&
              !customer.details.se.value && <Tag>N/A</Tag>}
          </div>
        </div>
        {customer.details.tm.value && (
          <div>
            <Info>Transactions monitored (t)TM)</Info>
            <br />
            <div className="overflow-hidden mr-2 text-base">
              <Tag>Yes</Tag>
            </div>
          </div>
        )}
        {customer.details.str.value && (
          <div>
            <Info>Suspicious transaction report (STR)</Info>
            <br />
            <div className="overflow-hidden mr-2 text-base">
              <Tag>Yes</Tag>
            </div>
          </div>
        )}
      </div>

      <div className="mt-4 grid gap-4 grid-cols-2 sm:grid-cols-4">
        {!!openChatCategories.length && (
          <div>
            <Info>Open discussions</Info>
            <br />
            <div className="overflow-hidden mr-2 text-base">
              {openChatCategories.map((e, i) => (
                <Tag
                  key={i}
                  className={"mr-2"}
                  style={{ backgroundColor: stringToColor(e) }}
                >
                  {labels.find((a) => a.code === e)?.name || e}
                </Tag>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

import { LoadingAtom } from "@features/utils/loading";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { SessionsApiClient } from "../api-client/api-client";
import { SessionDetailsAtom } from "./store";
import { getSessionAvatar, getVersion, setSessionAlerts } from "../utils";

export const useSession = (id: string) => {
    const [session, setSession] = useRecoilState(SessionDetailsAtom(id));
    const [loading, setLoading] = useRecoilState(LoadingAtom("useSession-" + id));

    const refresh = useCallback(async () => {
      setLoading(true);
      const session = await SessionsApiClient.getSession(id);
      session.version = getVersion(session);
      // TODO: improve with better api structure.
      session.onboarding_customer.avatar = getSessionAvatar(session);
      setSessionAlerts(session);
      
      if (session) {
        setSession(session);
      }
      setLoading(false);
    }
    , [setSession, id, setLoading]);

    return { session, loading, refresh };
  };


import { LoadingAtom } from "@features/utils/loading";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { CustomMatrixApiClient } from "../api-client/api-client";
import { CustomMatrixAtom } from "./store";

export const useCustomMatrix = () => {
  const [listMatrix, setListMatrix] = useRecoilState(CustomMatrixAtom);
  const [loading, setLoading] = useRecoilState(
    LoadingAtom("useCustomListMatrix")
  );

  const refresh = useCallback(async () => {
    setLoading(true);
    setListMatrix(await CustomMatrixApiClient.getMatrices());
    setLoading(false);
  }, [setListMatrix, setLoading]);

  const createMatrix = async (name: string) => {
    try {
      await CustomMatrixApiClient.createMatrice(name);
      refresh();
    } catch (error) {
      console.log("error creating matrix", error);
    }
  };

  const editMatrix = async (id: string, name: string) => {
    try {
      await CustomMatrixApiClient.editMatrice(id, name);
      refresh();
    } catch (error) {
      console.log("error editing matrix", error);
    }

  }

  const deleteMatrix = async (id: string) => {
    try {
      await CustomMatrixApiClient.deleteMatrice(id);
      refresh();
    } catch (error) {
      console.log("error deleting matrix", error);
    }
  }

  return {
    matrices: listMatrix,
    loading,
    refresh,
    editMatrix,
    deleteMatrix,
    createMatrix
  };
};

import { DoughnutChart } from "@components/dognut-chart";
import {
  ALERTS_STATES_COLORS,
  ALERTS_STATES_NAMES,
} from "@features/alerts/utils";
import { useDashboard } from "@features/dashboard/state/hooks";
import { formatNumber } from "@features/utils";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

export const ScanChart = () => {
  const { scan } = useDashboard();

  const scanInfo = Object.values(scan?.global_scan_info || {}).filter(
    (a) => a.value > 0
  );

  const scanInfoAssignment = Object.values(
    scan?.assigned_global_scan_info || {}
  ).filter((a) => a.value > 0);

  return (
    <div className="w-80 h-40">
      <DoughnutChart
        options={{
          animations: false as any,
          plugins: {
            legend: {
              position: "right",
              labels: {
                usePointStyle: true,
              },
            },
          },
          maintainAspectRatio: false,
        }}
        data={{
          labels: scanInfo.map(
            (a) =>
              `${
                (ALERTS_STATES_NAMES as any)[a.alert_state] || "Unknown"
              }: ${formatNumber(a.value)}`
          ),
          datasets: [
            ...(!scanInfo.length
              ? [
                  {
                    label: "No data",
                    backgroundColor: ["rgba(220,220,220,1.0)"],
                    data: [1],
                  },
                ]
              : []),
            {
              data: scanInfo.map((a) => a.value),
              backgroundColor: scanInfo.map(
                (a) => (ALERTS_STATES_COLORS as any)[a.alert_state] || "#000000"
              ),
            },
            {
              label: "Assigned",
              data: scanInfoAssignment.map((a) => a.value),
              backgroundColor: scanInfoAssignment.map(
                (a) => (ALERTS_STATES_COLORS as any)[a.alert_state] || "#000000"
              ),
            },
          ],
        }}
      />
    </div>
  );
};

import Avatar from "@atoms/avatar/avatar";
import { Button } from "@atoms/button/button";
import { PageBlock } from "@atoms/layout/page-block";
import { BaseSmall, Info, SectionSmall } from "@atoms/text";
import { KytTransactionParticipant } from "@features/kyt/types";
import { ROUTES } from "@features/routes";
import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

export default function TransactionParticipant(props: {
  id?: string | null;
  participant: KytTransactionParticipant;
  direction: "from" | "to";
  onShowMore: (more: boolean) => void;
  full?: boolean;
  className?: string;
}) {
  return (
    <PageBlock className={props.className + " grow"}>
      <div className="flex flex-row">
        <SectionSmall className="mb-2">
          Transaction {props.direction === "from" ? "source" : "target"}
        </SectionSmall>
        <div className="grow"></div>

        <Button
          size="sm"
          theme="outlined"
          onClick={() => props.onShowMore(!props.full)}
          shortcut={["e"]}
          className="rounded-r-none -mr-px"
        >
          {!props.full ? "Show more" : "Show less"}
        </Button>
        <Link
          className={!props.id ? "pointer-events-none" : ""}
          to={ROUTES.CustomerView.replace(":id", props.id || "")}
        >
          <Button
            size="sm"
            theme="primary"
            className="rounded-l-none -ml-px"
            disabled={!props.id}
          >
            Open customer
          </Button>
        </Link>
      </div>
      <div className="flex flex-row items-start space-x-2">
        {props.participant.account_type === "N" && (
          <Avatar className="shrink-0" size={14} />
        )}
        {props.participant.account_type === "F" && (
          <BuildingOfficeIcon className="h-8 w-8" />
        )}
        <div className="grow flex flex-col">
          <SectionSmall>{props.participant.full_name}</SectionSmall>
          <BaseSmall>
            <Info>ID </Info> {props.participant.external_id}
          </BaseSmall>
          <BaseSmall>
            <Info>Domicile </Info>
            {props.participant.domicile_code !== ""
              ? props.participant.domicile_code
              : "Undefined"}
          </BaseSmall>
          {props.full && (
            <>
              <BaseSmall className="mt-3">
                <b>Device </b>
                <Info>
                  (
                  {props.participant.device?.fingerprint !== ""
                    ? props.participant.device?.fingerprint
                    : "Undefined"}
                  )
                </Info>
              </BaseSmall>
              <div className="flex flex-row space-x-4">
                <BaseSmall>
                  <Info>User agent </Info>
                  <br />
                  {props.participant.device?.user_agent !== ""
                    ? props.participant.device?.user_agent
                    : "Undefined"}
                </BaseSmall>
                <BaseSmall>
                  <Info>IP </Info>
                  <br />
                  {props.participant.device?.ip !== ""
                    ? props.participant.device?.ip
                    : "Undefined"}
                </BaseSmall>
                <BaseSmall>
                  <Info>Location </Info>
                  <br />
                  {props.participant.device?.geolocation !== ""
                    ? props.participant.device?.geolocation
                    : "Undefined"}
                </BaseSmall>
                <BaseSmall>
                  <Info>Language </Info>
                  <br />
                  {props.participant.device?.language !== ""
                    ? props.participant.device?.language
                    : "Undefined"}
                </BaseSmall>
                <BaseSmall>
                  <Info>Country </Info>
                  <br />
                  {props.participant.device?.country !== ""
                    ? props.participant.device?.country
                    : "Undefined"}
                </BaseSmall>
              </div>
              <div className="flex flex-row space-x-4">
                <BaseSmall>
                  <Info>Session ID</Info>
                  <br />
                  {props.participant.device?.session_id !== ""
                    ? props.participant.device?.session_id
                    : "Undefined"}
                </BaseSmall>
                <BaseSmall>
                  <Info>Session duration</Info>
                  <br />
                  {props.participant.device?.session_duration ?? "Undefined"}
                </BaseSmall>
                <BaseSmall>
                  <Info>3DS</Info>
                  <br />
                  {props.participant.device?.["3ds"] ? "Yes" : "No"}
                </BaseSmall>
                <BaseSmall>
                  <Info>2FA</Info>
                  <br />
                  {props.participant.device?.["2fa"] ? "Yes" : "No"}
                </BaseSmall>
              </div>
              <BaseSmall className="mt-3">
                <b>Payment institution </b>
                <Info>
                  (
                  {props.participant.payment_institution?.external_id !== ""
                    ? props.participant.payment_institution?.external_id
                    : "Undefined"}
                  )
                </Info>
              </BaseSmall>
              <div className="flex flex-row space-x-4">
                <BaseSmall>
                  <Info>Code</Info>
                  <br />
                  {props.participant.payment_institution?.code !== ""
                    ? props.participant.payment_institution?.code
                    : "Undefined"}
                </BaseSmall>
                <BaseSmall>
                  <Info>Name</Info>
                  <br />
                  {props.participant.payment_institution?.name !== ""
                    ? props.participant.payment_institution?.name
                    : "Undefined"}
                </BaseSmall>
                <BaseSmall>
                  <Info>Country</Info>
                  <br />
                  {props.participant.payment_institution?.country !== ""
                    ? props.participant.payment_institution?.country
                    : "Undefined"}
                </BaseSmall>
              </div>
              <BaseSmall className="mt-3">
                <b>Payment institution </b>
              </BaseSmall>
              <div className="flex flex-row space-x-4">
                <BaseSmall>
                  <Info>Type</Info>
                  <br />
                  {props.participant.payment_method?.type !== ""
                    ? props.participant.payment_method?.type
                    : "Undefined"}
                </BaseSmall>
                <BaseSmall>
                  <Info>Code</Info>
                  <br />
                  {props.participant.payment_method?.code !== ""
                    ? props.participant.payment_method?.code
                    : "Undefined"}
                </BaseSmall>
                <BaseSmall>
                  <Info>Country</Info>
                  <br />
                  {props.participant.payment_method?.country !== ""
                    ? props.participant.payment_method?.country
                    : "Undefined"}
                </BaseSmall>
              </div>
            </>
          )}
          <div className="h-4"></div>
        </div>
      </div>
    </PageBlock>
  );
}

import { useRefreshDashboardScan } from "@features/dashboard/state/hooks";
import { delayRequest, useGlobalEffect } from "@features/utils";
import { LoadingAtom } from "@features/utils/loading";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import { AlertsApiClient } from "../api-client/api-client";
import { AlertsRequestOptions } from "../types";
import { AlertsDetailsAtom, AlertsListAtom } from "./store";

export const useAlerts = () => {
  const [alerts, setAlerts] = useRecoilState(AlertsListAtom);
  const [loading, setLoading] = useRecoilState(LoadingAtom("useAlerts"));

  const refresh = useCallback(
    async (options?: AlertsRequestOptions) => {
      setLoading(true);
      delayRequest("useAlertsRefresh", async () => {
        setAlerts(await AlertsApiClient.getAlerts({ ...options }));
        setLoading(false);
      });
    },
    [setAlerts, setLoading]
  );

  return { alerts, loading, refresh };
};

export const useAlert = (id: string) => {
  const [alert, setAlert] = useRecoilState(AlertsDetailsAtom(id));
  const [loading, setLoading] = useRecoilState(LoadingAtom("useAlert-" + id));
  const refreshScanMonitoring = useRefreshDashboardScan();

  const refresh = useCallback(async () => {
    setLoading(true);
    const alert = await AlertsApiClient.getAlert(id);
    if (alert?.alert_id) {
      setAlert(alert);
    }
    setLoading(false);
  }, [setAlert, id, setLoading]);

  const update = useCallback(
    async (state: number, comment: string) => {
      if (id && comment) {
        setLoading(true);
        await AlertsApiClient.updateAlertStatus(id, state, comment);
        await refresh();
        refreshScanMonitoring();
      } else {
        toast.error("Missing comment and/or state");
      }
    },
    [setLoading, id, refresh, refreshScanMonitoring]
  );

  useGlobalEffect(
    "useAlert-" + id,
    () => {
      if (id) refresh();
    },
    []
  );

  return { alert, loading, update, refresh };
};

import { LoadingAtom } from "@features/utils/loading";
import _ from "lodash";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import { KytApiClient } from "../api-client/api-client";
import { KytGraph } from "../types";
import {
  ExploreKytGraphAtom,
  ExploreKytLayoutAtom,
  ExploreKytTransactionsAtom,
} from "./store";

const useExploreGraph = () => {
  const [transactionsGraph, setTransactionsGraph] =
    useRecoilState(ExploreKytGraphAtom);

  // SQL Query + Recoil
  const [transactionsSQL, setTransactionsSQL] = useRecoilState(
    ExploreKytTransactionsAtom
  );
  const [loadingSQL, setLoadingSQL] = useRecoilState(
    LoadingAtom(`useExploreSQL`)
  );
  const [layout, setLayout] = useRecoilState(ExploreKytLayoutAtom);

  const fetchSQLElements = useCallback(
    async (query: string) => {
      // Fetch graph elements from the server
      try {
        const result = await KytApiClient.getTransactionsSQLQuery(query);
        setTransactionsSQL(result);
      } catch (error) {
        toast.error(
          "Error getting Transactions Graph elements with the given query"
        );
        console.error(error);
        setTransactionsSQL({ transactions: [], total: 0 });
        // remove kyt graph
      }
    },
    [setTransactionsSQL, setTransactionsGraph]
  );

  const refreshSQL = useCallback(
    async (query: string) => {
      setLayout({ ...layout, transactions: true });
      setLoadingSQL(true);
      await fetchSQLElements(query);
      setLoadingSQL(false);
    },
    [fetchSQLElements, setLoadingSQL]
  );

  const nodeAlreadyExists = useCallback(
    (nodeId: string) => {
      if (!transactionsGraph) return false;
      return transactionsGraph.nodes.some((node) => node.id === nodeId);
    },
    [transactionsGraph]
  );

  const addToGraph = useCallback(
    (addition: KytGraph) => {
      // dont duplicate nodes or edges
      const newNodes = _.uniqBy(
        (transactionsGraph?.nodes ?? []).concat(addition.nodes),
        "id"
      );
      const newEdges = _.uniqWith(
        (transactionsGraph?.edges ?? []).concat(addition.edges),
        (a, b) => a.start_id === b.start_id && a.end_id === b.end_id
      );
      setTransactionsGraph({ nodes: newNodes, edges: newEdges });
    },
    [transactionsGraph, setTransactionsGraph]
  );

  return {
    transactionsGraph,
    setTransactionsGraph,
    refreshSQL,
    transactionsSQL,
    loadingSQL,
    nodeAlreadyExists,
    addToGraph,
  };
};

export default useExploreGraph;

import { ReactNode } from "react";

interface InputLabelProps {
  prefix?: (args: { className?: string }) => ReactNode;
  suffix?: (args: { className?: string }) => ReactNode;
  input: ({ className }: { className: string }) => ReactNode;
  className?: string;
}

export const InputDecorationIcon = (props: InputLabelProps) => {
  return (
    <div className={"relative " + props.className}>
      {props.prefix &&
        props.prefix({
          className:
            "h-4 w-4 absolute m-auto top-0 bottom-0 left-3 text-slate-500",
        })}
      {props.input({
        className:
          (props.prefix ? "pl-9 " : "") + (props.suffix ? "pr-9 " : ""),
      })}
      {props.suffix &&
        props.suffix({
          className:
            "h-4 w-4 absolute m-auto top-0 bottom-0 left-3 text-slate-500",
        })}
    </div>
  );
};

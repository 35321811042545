import { Page } from "@atoms/layout/page";
import { Title } from "@atoms/text";
import { DocumentsLabelsTables } from "./documents-labels";

export const DocumentTagsSetup = () => {
  return (
    <Page>
      <Title>Document tags</Title>
      <br />
      <DocumentsLabelsTables />
    </Page>
  );
};

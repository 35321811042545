import Avatar from "@atoms/avatar/avatar";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Info, SectionSmall } from "@atoms/text";
import { AlertType } from "@features/alerts/types";
import {
  extractCountries,
  extractDates,
  extractNames,
  isFirm,
} from "@features/alerts/utils";

import { Button } from "@atoms/button/button";
import { PageBlock, PageResponsiveColumns } from "@atoms/layout/page-block";
import Link from "@atoms/link";
import { ROUTES } from "@features/routes";
import FuzzySet from "fuzzyset.js";
import _ from "lodash";
import { useCustomer } from "@features/customers/state/use-customer";

const matchingLevelHighlightScore = (a: string, b: string) => {
  const explode = (s: string) =>
    s
      .replace(/[,-]/g, " ")
      .split(" ")
      .filter((x) => x.trim());
  const set = FuzzySet(explode(a));
  const score = explode(b).reduce(
    (c, acc) => Math.max(c, set.get(acc)?.[0]?.[0] || 0),
    0
  );
  return score;
};
const matchingLevelHighlight = (a: string, b: string, exact = false) => {
  const score = matchingLevelHighlightScore(a, b);
  return score >= 1
    ? "text-green-600 dark:text-green-400"
    : exact || score <= 0.2
    ? "line-through "
    : score >= 0.5
    ? "text-green-800 dark:text-green-200"
    : "";
};

export const AlertMatchSummary = ({
  alert,
  setExtendedView,
  extendedView,
}: {
  alert: AlertType;
  setExtendedView: (e: boolean) => void;
  extendedView: boolean;
}) => {
  const { customer } = useCustomer(alert.customer_id);

  const revision = alert.last_alert_revision;
  const profile = revision.list_profile;
  const firm = isFirm(revision.customer_type);

  const customerFirstname = _.uniq([
    revision.customer_firstname ||
      customer?.details?.customer?.first_name ||
      "",
    ...(revision.customer_name_variations || []).map((a) => a.first_name || ""),
  ])
    .filter((a) => a.trim())
    .join(", ");
  const customerMiddlename = _.uniq([
    revision.customer_middlename ||
      customer?.details?.customer?.middle_name ||
      "",
    ...(revision.customer_name_variations || []).map(
      (a) => a.middle_name || ""
    ),
  ])
    .filter((a) => a.trim())
    .join(", ");
  const customerLastname = _.uniq([
    revision.customer_lastname || customer?.details?.customer?.last_name || "",
    ...(revision.customer_name_variations || []).map((a) => a.last_name || ""),
  ])
    .filter((a) => a.trim())
    .join(", ");
  const customerCountriesStr = _.uniq([
    revision.customer_domicile_code ||
      customer?.details?.customer?.domicile_code ||
      "",
    revision.customer_nationality_code ||
      customer?.details?.customer?.nationality_code ||
      "",
  ])
    .filter((a) => a)
    .join(", ");
  const customerCompanyNamesStr = [
    revision.customer_company_name ||
      customer?.details?.customer?.company_name ||
      "",
    revision.customer_trading_name ||
      customer?.details?.customer?.trading_name ||
      "",
    ...(revision.customer_name_variations || []).map(
      (a) => a.company_name || ""
    ),
    ...(revision.customer_name_variations || []).map(
      (a) => a.trading_name || ""
    ),
  ]
    .filter((a) => a)
    .join(", ");
  const profileLastNamesStr = [
    extractNames(profile, "surname"),
    extractNames(profile, "maiden_name"),
  ]
    .filter((a) => a)
    .join(", ");

  const customerAllNames = [
    customerCompanyNamesStr,
    customerFirstname,
    customerMiddlename,
    customerLastname,
  ].join(" ");
  const profileAllNames = [
    extractNames(profile, "entity_name"),
    extractNames(profile, "first_name"),
    extractNames(profile, "middle_name"),
    profileLastNamesStr,
  ].join(" ");
  return (
    <div className="mt-4 flex flex-row">
      <PageResponsiveColumns>
        <PageBlock className="border-l-2 border-l-blue-500 grow w-full relative">
          <div className="absolute right-2 top-2">
            <Button
              size="sm"
              theme="outlined"
              className="rounded-r-none"
              onClick={() => setExtendedView(!extendedView)}
              shortcut={["e"]}
            >
              {!extendedView ? "Show more" : "Show less"}
            </Button>
            <Link
              to={ROUTES.CustomerView.replace(":id", alert.customer_id)}
              shortcut={["c"]}
            >
              <Button
                size="sm"
                theme="primary"
                className="rounded-l-none -ml-px"
              >
                Open customer
              </Button>
            </Link>
          </div>
          <SectionSmall className="mb-2">Customer</SectionSmall>
          <div className="flex flex-row items-start">
            {!firm && <Avatar className="mr-6 shrink-0" size={14} />}
            <div className="grow">
              {!!customerCompanyNamesStr.trim() && (
                <LabeledFieldWithMatchingLevel
                  className="mb-2 "
                  label="Company name"
                  isCompanyName
                  value={customerCompanyNamesStr}
                  compareTo={profileAllNames}
                />
              )}
              <div className="flex flex-row">
                {!!customerFirstname.trim() && (
                  <LabeledFieldWithMatchingLevel
                    className="mb-2 grow"
                    label="First names"
                    value={customerFirstname}
                    compareTo={profileAllNames}
                  />
                )}
                {!!customerMiddlename.trim() && (
                  <LabeledFieldWithMatchingLevel
                    className="mb-2 grow"
                    label="Middle names"
                    value={customerMiddlename}
                    compareTo={profileAllNames}
                  />
                )}
                {!!customerLastname.trim() && (
                  <LabeledFieldWithMatchingLevel
                    className="mb-2 grow"
                    label="Last names"
                    value={customerLastname}
                    compareTo={profileAllNames}
                  />
                )}
              </div>
              {!!(
                revision.customer_date_of_birth ||
                customer?.details?.customer?.date_of_birth
              ) && (
                <LabeledFieldWithMatchingLevel
                  className="mb-2"
                  label="Dates of birth"
                  isDate
                  value={
                    revision.customer_date_of_birth ||
                    customer?.details?.customer?.date_of_birth ||
                    ""
                  }
                  compareTo={extractDates(profile, "Date of Birth")}
                />
              )}
              <LabeledFieldWithMatchingLevel
                className="mb-2"
                label="Countries"
                value={customerCountriesStr}
                compareTo={extractCountries(profile)}
              />
            </div>
          </div>
        </PageBlock>
        <PageBlock className="border-l-2 border-l-orange-500 grow w-full relative">
          <div className="absolute right-2 top-2 bg-white dark:bg-slate-900 border rounded p-2">
            <Info className="flex flex-col items-center justify-center">
              {alert.last_alert_revision.score}% match
            </Info>
          </div>

          <SectionSmall className="mb-2">Potential match</SectionSmall>

          <div className="flex flex-row items-start">
            {!firm && (
              <Avatar
                className="mr-6 shrink-0"
                size={14}
                avatar={profile.images?.[0]}
              />
            )}
            <div className="grow">
              {!!extractNames(profile, "entity_name") && (
                <LabeledFieldWithMatchingLevel
                  className="mb-2 "
                  label="Company name"
                  isCompanyName
                  value={extractNames(profile, "entity_name")}
                  compareTo={customerAllNames}
                />
              )}
              <div className="flex flex-row">
                {!!extractNames(profile, "first_name") && (
                  <LabeledFieldWithMatchingLevel
                    className="mb-2 grow"
                    label="First names"
                    value={extractNames(profile, "first_name")}
                    compareTo={customerAllNames}
                  />
                )}
                {!!extractNames(profile, "middle_name") && (
                  <LabeledFieldWithMatchingLevel
                    className="mb-2 grow"
                    label="Middle names"
                    value={extractNames(profile, "middle_name")}
                    compareTo={customerAllNames}
                  />
                )}
                {!!profileLastNamesStr && (
                  <LabeledFieldWithMatchingLevel
                    className="mb-2 grow"
                    label="Last names"
                    value={profileLastNamesStr}
                    compareTo={customerAllNames}
                  />
                )}
              </div>
              {!!revision.customer_date_of_birth && (
                <LabeledFieldWithMatchingLevel
                  className="mb-2"
                  label="Dates of birth"
                  isDate
                  value={extractDates(profile, "Date of Birth")}
                  compareTo={revision.customer_date_of_birth}
                />
              )}
              <LabeledFieldWithMatchingLevel
                className="mb-2"
                label="Countries"
                value={extractCountries(profile)}
                compareTo={customerCountriesStr}
              />
            </div>
          </div>
        </PageBlock>
      </PageResponsiveColumns>
    </div>
  );
};

export const LabeledFieldWithMatchingLevel = ({
  label,
  compareTo,
  value,
  className,
  isCompanyName,
}: {
  label: string;
  value: string;
  compareTo: string;
  className?: string;
  isDate?: boolean;
  isCompanyName?: boolean;
}) => {
  const set = _.uniqBy(
    isCompanyName ? value.split(",") : value.replace(/[,]/g, " ").split(" "),
    (a) => a.toLocaleLowerCase()
  ).filter((a) => a.trim());
  const res = _.reverse(
    _.sortBy(
      _.uniq([
        ...((FuzzySet(set).get(compareTo, undefined, 0) || [])?.map(
          (a) => a[1]
        ) || []),
        ...set,
      ]),
      (v) => matchingLevelHighlightScore(compareTo, v)
    )
  ).slice(0, 3);

  const ignored = set.length - (res?.length || set.length);

  return (
    <InputLabel
      className={className || ""}
      label={label}
      input={
        <div
          data-tooltip={
            !!ignored &&
            _.uniqBy(value.split(" "), (a) => a.toLocaleLowerCase())
              .join(" ")
              .trim()
          }
        >
          {_.reverse(
            _.sortBy((res.join(", ") || "").split(", "), (v) =>
              matchingLevelHighlightScore(compareTo, v)
            )
          ).map((v, i) => (
            <span key={i}>
              {i !== 0 && ", "}
              <span
                className={
                  v && compareTo
                    ? matchingLevelHighlight(compareTo, v)
                    : "opacity-50"
                }
              >
                {v || "Undefined"}
              </span>
            </span>
          ))}
          {!!ignored && ` (+${ignored})`}
        </div>
      }
    />
  );
};

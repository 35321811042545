import { useMutation, useQuery } from "react-query";
import { WebAuthnApiClient } from "../api-client/api-client";
import { client } from "@passwordless-id/webauthn";
import { WebAuthnCredential } from "../types";

export function useWebAuthn() {
  const useRegisterMutation = () =>
    useMutation({
      mutationFn: async () => {
        const response = await WebAuthnApiClient.getChallenge();
        const registration = await client.register(response);
        await WebAuthnApiClient.register(registration);
      },
    });

  const useDeleteDeviceMutation = () =>
    useMutation({
      mutationFn: async (credentialId: number) => {
        await WebAuthnApiClient.deleteCredential(credentialId);
      },
    });

  const getDevicesQuery = () =>
    useQuery<WebAuthnCredential[]>({
      queryKey: ["webauthn", "credentials"],
      queryFn: () => {
        return WebAuthnApiClient.getCredentials();
      },
      refetchOnWindowFocus: false,
    });

  return {
    useRegisterMutation,
    useDeleteDeviceMutation,
    getDevicesQuery,
  };
}

import { LoadingState, useGlobalEffect } from "@features/utils";
import _ from "lodash";
import { useRecoilCallback, useRecoilState, useRecoilValue } from "recoil";
import { CustomersApiClient } from "../api-client/api-client";
import { CachedCustomerType } from "../types";
import { CachedCustomersAtom } from "./store";
import { buildQueryFromMap } from "@components/search-bar/utils/utils";

const useAddToCache = () =>
  useRecoilCallback(({ set }) => async (customers: CachedCustomerType[]) => {
    set(CachedCustomersAtom, (oldCustomers) => {
      return _.uniqBy([...oldCustomers, ...customers], "customer_id");
    });
  });

let currentlyCacheLoading: string[] = [];

export const useCustomersCache = (ids: string[]) => {
  const [loading, setLoading] = useRecoilState(
    LoadingState("useCustomersCache")
  );
  const customers = useRecoilValue(CachedCustomersAtom);

  const addToCache = useAddToCache();

  useGlobalEffect(
    "useCustomersCache",
    () => {
      (async () => {
        setLoading(true);
        await Promise.all(
          ids
            .filter(
              (a) =>
                !customers.find((b) => b.customer_id === a || b.external_id) &&
                currentlyCacheLoading.includes(a) === false
            )
            .map(async (id) => {
              currentlyCacheLoading.push(id);
              let customer = null;
              try {
                customer = await CustomersApiClient.getCustomerDetails(id);
                if (!customer?.customer?.id) {
                  throw new Error("Customer not found");
                }
              } catch (e) {
                // Search using external_id
                const search = await CustomersApiClient.getCustomersAdvanced(
                  buildQueryFromMap({ external_ids: id }),
                  { limit: 1 }
                );
                customer = await CustomersApiClient.getCustomerDetails(
                  search?.data?.[0]?.customer_id
                );
              }
              addToCache([
                {
                  ...customer.customer,
                  details: customer,
                  customer_id: customer.customer.id,
                },
              ]);
              currentlyCacheLoading = currentlyCacheLoading.filter(
                (a) => a !== id
              );
            })
        );
        setLoading(false);
      })();
    },
    [ids.join("")]
  );

  return {
    customers: customers.filter(
      (customer) =>
        ids.includes(customer.customer_id) || ids.includes(customer.external_id)
    ),
    loading,
  };
};

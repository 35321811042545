import { Info } from "@atoms/text";
import { DASHBOARD_RISK_STATE } from "@features/dashboard/enums";
import { useDashboard } from "@features/dashboard/state/hooks";
import { formatNumber } from "@features/utils";
import { formatDuration, formatTime } from "@features/utils/dates";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { RiskChart } from "./charts/risk";
import { RiskLevelsChart } from "./charts/risk-levels";

ChartJS.register(ArcElement, Tooltip, Legend);

export const Risk = () => {
  const { risk } = useDashboard();

  if (!risk?.last_risk_scoring_info?.state) return <div className="h-44" />;

  return (
    <div className="flex embed-flex-auto-layout flex-col sm:flex-row">
      <div className="sm:w-1/3 py-4" style={{ minWidth: "316px" }}>
        <RiskChart />
      </div>
      <div className="sm:w-1/3 sm:ml-8">
        <div className="mb-2">
          <Info>Scoring status</Info>
          <br />
          <div className="overflow-hidden mr-2 text-base">
            <div
              className={
                "mr-2 rounded-full w-3 h-3 inline-block " +
                (risk?.last_risk_scoring_info?.state === 3
                  ? "bg-green-500"
                  : risk?.last_risk_scoring_info?.state === 1
                  ? "bg-yellow-500"
                  : "bg-red-500")
              }
            />
            {risk?.last_risk_scoring_info?.start_date
              ? DASHBOARD_RISK_STATE[risk?.last_risk_scoring_info?.state || 1]
              : "Not started"}
          </div>
        </div>
        <div className="mb-2">
          <Info>Start date and duration</Info>
          <br />
          <div className="overflow-hidden mr-2 text-base">
            {risk?.last_risk_scoring_info?.start_date
              ? formatTime(
                  risk?.last_risk_scoring_info?.start_date || 0,
                  undefined,
                  { keepTime: true, keepDate: true }
                )
              : "Never"}{" "}
            (
            {formatDuration(
              Math.max(
                0,
                new Date(
                  risk?.last_risk_scoring_info?.end_date || 0
                ).getTime() -
                  new Date(
                    risk?.last_risk_scoring_info?.start_date || 0
                  ).getTime()
              )
            )}
            )
          </div>
        </div>
      </div>
      <div className="grow sm:ml-4">
        <div className="mb-2">
          <Info>Total active customers</Info>
          <br />
          <div className="overflow-hidden mr-2 text-base">
            {formatNumber(
              risk?.global_risk_info.risk_levels.reduce(
                (acc, a) => a.value + acc,
                0
              ) || 0
            )}
          </div>
        </div>
        <div className="mb-2">
          <Info>Risks levels</Info>
          <br />
          <RiskLevelsChart />
        </div>
      </div>
    </div>
  );
};

import { Button } from "@atoms/button/button";
import { Checkbox } from "@atoms/input/input-checkbox";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { PageBlock } from "@atoms/layout/page-block";
import { AuditApiClient } from "@features/audit/api-client/api-client";
import { AuditExportFilters } from "@features/audit/types";
import { useState } from "react";
import { toast } from "react-hot-toast";

export default function ConfigurationsExport() {
  const [filter, setFilter] = useState<
    Omit<AuditExportFilters, "from_date" | "to_date">
  >({
    with_revisions: false,
  });
  const [loadingExport, setLoadingExport] = useState(false);

  return (
    <PageBlock>
      <div className="flex flex-row align-bottom relative">
        <InputLabel
          className="mr-2"
          label="With revisions"
          input={
            <Checkbox
              className="ml-2 mt-3"
              value={filter.with_revisions}
              onChange={(e) => {
                setFilter({ ...filter, with_revisions: e });
              }}
            />
          }
        />
        <Button
          loading={loadingExport}
          className="absolute right-0 self-center"
          onClick={async () => {
            setLoadingExport(true);
            try {
              const data = await AuditApiClient.exportConfigurationsCSVZipped(
                filter
              );
              const fileURL = URL.createObjectURL(data);
              const link = document.createElement("a");
              link.href = fileURL;
              // prettier-ignore
              const fileName = "audit-export-configurations" 
                + (filter.with_revisions ? "-withRevisions" : "-withoutRevisions")
                + ".zip";
              // prettier-ignore
              link.setAttribute("download", fileName);
              document.body.appendChild(link);
              link.click();
            } catch (e) {
              console.error(e);
              toast.error(
                "An error has occurred while retrieving configurations, please try again later"
              );
            }
            setLoadingExport(false);
          }}
        >
          Export
        </Button>
      </div>
    </PageBlock>
  );
}

import Avatar from "@atoms/avatar/avatar";
import { Tag } from "@atoms/badge/tag";
import { Info, InfoSmall } from "@atoms/text";
import { useAgents } from "@features/agents/state/use-agents";
import { AssignmentType } from "@features/assignments/types";
import { useAuth } from "@features/auth/state/hooks";
import { formatTime } from "@features/utils/dates";
import { CodeBracketIcon } from "@heroicons/react/24/outline";

export default function ThreadHistoryAssignmentCard(props: {
  item: AssignmentType;
}) {
  const { agent } = useAuth();
  const { getAgentForClient } = useAgents();

  const itemAgent = getAgentForClient().find(
    (a) => a.agent_id === props.item.agent_id
  );

  const operatorName =
    (agent?.agent_id === itemAgent?.id ? "You" : itemAgent?.name) || "System";

  return (
    <div className="border-l-2 ml-6 px-2 pt-4 border-slate-300">
      <div className="flex flex-row space-x-1 -ml-5">
        <span className="flex justify-center items-center w-6 h-6 bg-slate-500 rounded-full mt-1.5">
          {!itemAgent && <CodeBracketIcon className="h-4 w-4 text-white" />}
          {!!itemAgent && (
            <Avatar
              size={6}
              icon={itemAgent.avatar}
              fallback={itemAgent.name}
            />
          )}
        </span>
        <div className="grow flex flex-col">
          <InfoSmall>
            {formatTime(props.item.updated_at, undefined, {
              keepDate: true,
              keepTime: true,
            })}
          </InfoSmall>
          <Info className="text-gray-500">
            <b>{operatorName}</b> set assignees to{" "}
            {props.item.members &&
              props.item.members.map((member) => {
                const agent = getAgentForClient().find(
                  (a) => a.id === member.id
                );
                return (
                  <Tag key={member.id} className="mr-2 relative -top-px">
                    <div className="inline-flex flex-row items-center relative -top-px">
                      <Avatar
                        size={4}
                        avatar={agent?.avatar}
                        fallback={agent?.name}
                        className="inline-block mr-1"
                      />
                      <span>{agent?.name}</span>
                    </div>
                  </Tag>
                );
              })}
            {(!props.item.members || props.item.members.length === 0) && (
              <Tag className="opacity-50">No assignee</Tag>
            )}
          </Info>
        </div>
      </div>
    </div>
  );
}

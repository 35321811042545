import InputSuggestions from "@atoms/input/input-suggestions";
import { Base, Info } from "@atoms/text";
import { CustomersApiClient } from "@features/customers/api-client/api-client";
import { useCustomersCache } from "@features/customers/state/use-customers-cache";
import { extractCustomerFullName } from "@features/customers/utils";
import { ThreadRelatedCustomer } from "@features/inbox/types";
import { BuildingOfficeIcon, UserIcon } from "@heroicons/react/20/solid";

export default function CustomerSearchInput(props: {
  useExternalId?: boolean;
  placeholder?: string;
  suggestionsLength?: number;
  predefinedCustomers?: string[]; // if defined & not empty, the search will be done on this list
  value: string[];
  className?: string;
  onCustomersChange: (customers: string[]) => void;
}) {
  const { customers } = useCustomersCache(props.value);
  const { customers: predefinedCustomers } = useCustomersCache(
    props.predefinedCustomers || []
  );

  console.log(customers, props.value);

  const onCustomersChange = (csts: string[]) => {
    const list = csts.map((c) => {
      const extId = c.match(/\(([^)]+)\)$/)?.[1];
      if (extId) {
        return customers.find((c) => c.external_id === extId)?.customer_id || c;
      } else {
        return c;
      }
    });
    props.onCustomersChange(list);
  };

  const filterCustomers = async (query: string) => {
    if (query.trim().length > 0) {
      if (predefinedCustomers && predefinedCustomers.length > 0) {
        const filteredCustomers = predefinedCustomers.filter((c) => {
          return (
            c.first_name?.toLowerCase().includes(query.toLowerCase()) ||
            c.last_name?.toLowerCase().includes(query.toLowerCase()) ||
            c.company_name?.toLowerCase().includes(query.toLowerCase()) ||
            c.trading_name?.toLowerCase().includes(query.toLowerCase()) ||
            c.external_id?.toLowerCase().includes(query.toLowerCase())
          );
        });
        return {
          data: filteredCustomers.map((a) => ({ ...a, id: a.customer_id })),
          total: filteredCustomers.length,
        };
      } else {
        const res = await CustomersApiClient.getCustomersAdvanced(
          query
            ?.replace(/-/gm, " ")
            .split(" ")
            .filter((a) => a.trim())
            .map((q) => ({
              key: "search_aggregate",
              operations: [
                {
                  op: "regexp",
                  value: {
                    string: q, // For - in external_id
                  },
                },
              ],
            })),
          {
            limit: props.suggestionsLength ?? 6,
          }
        );
        if (res.data) {
          return {
            total: res.total,
            data: res.data.map(
              (e) => ({ ...e, id: e.customer_id } as ThreadRelatedCustomer)
            ),
          };
        }
      }
    }
    return { total: 0, data: [] };
  };

  let textValue: string[] = [];
  if (customers.length > 0) {
    textValue = customers.map(
      (c) => extractCustomerFullName(c) + " (" + c.external_id + ")"
    );
  }

  return (
    <div className="relative -mb-0.5">
      <InputSuggestions
        className={props.className}
        placeholder={props.placeholder ?? "Search customers"}
        value={textValue}
        onChange={onCustomersChange}
        getSuggestions={async (query) => {
          return (await filterCustomers(query))?.data.map((customer) => ({
            value: props.useExternalId ? customer.external_id : customer.id,
            label: (
              <div>
                <Base className="block">
                  {extractCustomerFullName(customer)}
                </Base>
                <Info className="flex items-center">
                  {!!(customer!.trading_name || customer!.company_name) && (
                    <BuildingOfficeIcon className="h-4 w-4 mr-1 inline shrink-0" />
                  )}
                  {!(customer!.trading_name || customer!.company_name) && (
                    <UserIcon className="h-4 w-4 mr-1 inline shrink-0" />
                  )}{" "}
                  {customer.external_id}
                </Info>
              </div>
            ),
          }));
        }}
      />
    </div>
  );
}

import { Button } from "@atoms/button/button";
import Link from "@atoms/link";
import { AlertsFiltersAtom } from "@features/alerts/state/store";
import { useAlert, useAlerts } from "@features/alerts/state/use-alerts";
import { ROUTES } from "@features/routes";
import { useControlledEffect } from "@features/utils";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Bars4Icon,
} from "@heroicons/react/24/outline";
import { useRecoilValue } from "recoil";

export const PreloadAlert = ({ id }: { id: string }) => {
  useAlert(id);
  return <></>;
};

export const AlertNavigation = ({ id }: { id: string }) => {
  const { alerts, refresh } = useAlerts();

  const filters = useRecoilValue(AlertsFiltersAtom);

  const index = alerts.data?.findIndex((a) => a.alert_id === id);
  const previous = alerts.data?.[index - 1];
  const next = alerts.data?.[index + 1];

  useControlledEffect(() => {
    if (!previous || !next) {
      refresh({ ...filters, per_page: 100, on_page: 1 });
    }
  }, [previous?.alert_id, next?.alert_id, filters]);

  return (
    <div className="float-right">
      {!!previous?.alert_id && <PreloadAlert id={previous?.alert_id} />}
      {!!next?.alert_id && <PreloadAlert id={next?.alert_id} />}

      <div className="m-auto inline-flex items-center">
        <Link
          className="flex"
          to={
            !!previous?.alert_id &&
            ROUTES.AlertView.replace(":id", previous?.alert_id)
          }
          shortcut={["left"]}
        >
          <Button
            size="sm"
            disabled={!previous?.alert_id}
            theme="outlined"
            className="rounded-r-none"
          >
            <ArrowLeftIcon className="h-5 w-5 mr-2" /> Previous alert
          </Button>
        </Link>

        <Link to={ROUTES.Alerts} className="flex">
          <Button size="sm" theme="outlined" className="rounded-none -ml-px">
            <Bars4Icon className="h-5 w-5 mr-2" /> List of alert
          </Button>
        </Link>

        <Link
          to={
            !!next?.alert_id && ROUTES.AlertView.replace(":id", next?.alert_id)
          }
          className="flex"
          shortcut={["right"]}
        >
          <Button
            size="sm"
            disabled={!next?.alert_id}
            theme="outlined"
            className="rounded-l-none  -ml-px"
          >
            Next alert <ArrowRightIcon className="h-5 w-5 ml-2" />
          </Button>
        </Link>
      </div>
    </div>
  );
};

import { useGlobalEffect } from "@features/utils";
import { useRecoilState } from "recoil";
import { AlertsApiClient } from "../api-client/api-client";
import { AlertsStatesAtom } from "./store";

export const useAlertStates = () => {
  const [states, setStates] = useRecoilState(AlertsStatesAtom);

  const refresh = async () => {
    setStates(await AlertsApiClient.getAlertsState());
  };

  useGlobalEffect(
    "useAlertStates",
    () => {
      refresh();
    },
    []
  );

  return { states, refresh };
};

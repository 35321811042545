import Avatar from "@atoms/avatar/avatar";
import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { PageBlock } from "@atoms/layout/page-block";
import Link from "@atoms/link";
import { Base, BaseSmall, Info, InfoSmall, Section } from "@atoms/text";
import { useCustomer } from "@features/customers/state/use-customer";
import { useScenarios } from "@features/scenarios/state/use-scenarios";
import {
  SessionCustomerType,
  SessionDecisionType,
  SessionDetailsType,
  SessionScoringType,
} from "@features/sessions/types";
import { LABEL_TYPE_COLORS } from "@features/sessions/utils";
import { formatDuration, formatTime } from "@features/utils/dates";
import {
  CheckIcon,
  ExclamationTriangleIcon,
  FlagIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { SharedProductsMenu } from "@views/client/customers/customer-details/menu";
import { capitalize } from "lodash";
import { useRecoilState } from "recoil";
import { twMerge } from "tailwind-merge";
import CustomerOtherSessions from "./customer-other-sessions";
import { SessionDecisionAddModalAtom } from "./decision-add-modal";
import { ScoringPreferences } from "@features/scenarios/types";

export default function SessionSummary({
  sessionCustomer,
  ...props
}: {
  sessionCustomer: SessionCustomerType;
  scoring: SessionScoringType;
  details: SessionDetailsType;
  decision?: SessionDecisionType;
  scoringPreferences?: ScoringPreferences;
  alertsSummary: {
    code: string;
    label: string;
    score: number;
  }[];
}) {
  const { customer } = useCustomer(sessionCustomer.customer_id!);
  const { scenarios } = useScenarios();
  const scenario = scenarios?.data?.find(
    (s) => s.id === props.details.scenario_id
  );

  const stateLabel = capitalize(props.details.state.replace("_", " "));

  const _formatDates = (date: string) => {
    return formatTime(date, undefined, { keepTime: true, keepDate: true });
  };

  return (
    <div>
      <Section className="mb-4">
        <div className="float-right">
          <Link to={document.location.pathname + "/previous"}>
            <Button size="sm" className="mr-2" theme="default">
              Use previous interface
            </Button>
          </Link>
          <SharedProductsMenu
            id={sessionCustomer.customer_id!}
            className="mt-0"
          />
        </div>
        Session Summary
      </Section>
      <div className="flex flex-col">
        {/* On Boarding Customer Data Info */}
        <PageBlock className="flex flex-row space-x-2" style={{ padding: 0 }}>
          <div className="grow flex flex-col space-y-2 p-4">
            <div className="flex-1 flex flex-row space-x-4">
              <Avatar
                size={28}
                avatar={sessionCustomer.avatar?.url}
                type={sessionCustomer.avatar?.type}
              />
              <div className="grow flex flex-col">
                <Section>
                  {sessionCustomer.firstname} {sessionCustomer.lastname}
                  {sessionCustomer.external_id && (
                    <Info> {sessionCustomer.external_id}</Info>
                  )}
                </Section>
                <BaseSmall className="-mt-2">
                  <Info>Date of birth </Info>{" "}
                  {
                    new Date(sessionCustomer.birthdate)
                      .toISOString()
                      .split("T")[0]
                  }
                  <Info>, Domicile </Info>{" "}
                  {customer?.details?.customer?.domicile_code ||
                    sessionCustomer.country ||
                    "-"}
                  {customer && (
                    <>
                      <Info>, Nationality </Info>{" "}
                      {customer?.details?.customer?.nationality_code || "-"}
                    </>
                  )}
                  {!!customer?.details.customer?.review_groups?.length && (
                    <Info>
                      {", "}
                      {customer?.details.customer?.review_groups?.map((r) => (
                        <Tag key={r}>{r}</Tag>
                      ))}
                    </Info>
                  )}
                </BaseSmall>
                <BaseSmall className="mt-4">
                  <div>
                    <Tag
                      className="bg-blue-500 text-white"
                      noColor
                      data-tooltip="Category"
                    >
                      {scenario?.result_label || "-"}
                    </Tag>
                    <Tag
                      noColor
                      className="rounded-r-none ml-2 bg-blue-500 text-white"
                      data-tooltip="Scenario name"
                    >
                      {scenario?.label}
                    </Tag>
                    <Tag
                      noColor
                      className="rounded-l-none bg-slate-200 text-black dark:bg-slate-800 dark:text-white"
                    >
                      ({props.details.language})
                    </Tag>
                  </div>
                </BaseSmall>
                {stateLabel?.toLocaleLowerCase() !== "completed" && (
                  <BaseSmall>
                    Session in state "{stateLabel}" since{" "}
                    {_formatDates(props.details.date_start)}
                  </BaseSmall>
                )}
                {stateLabel?.toLocaleLowerCase() === "completed" && (
                  <BaseSmall>
                    {stateLabel},{" "}
                    {stateLabel?.toLocaleLowerCase() === "completed" && (
                      <>
                        {_formatDates(props.details.date_end)} in{" "}
                        <b>
                          {formatDuration(
                            new Date(props.details.date_end).getTime() -
                              new Date(
                                props.details.consent_date ||
                                  props.details.date_start
                              ).getTime()
                          )}
                        </b>
                      </>
                    )}
                  </BaseSmall>
                )}
              </div>
            </div>
          </div>
          <SessionAlertsSummary
            details={props.details}
            decision={props.decision}
            alerts={props.alertsSummary}
            scoring={props.scoring as any}
            scoringPreferences={props.scoringPreferences}
          />
        </PageBlock>
        <div>
          {sessionCustomer.external_id && (
            <CustomerOtherSessions
              external_id={sessionCustomer.external_id}
              current_session_id={props.details.session_id}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function SessionAlertsSummary(props: {
  details: SessionDetailsType;
  decision?: SessionDecisionType;
  scoringPreferences?: ScoringPreferences;
  scoring: {
    final_result: number;
    success: boolean;
  };
  alerts: {
    code: string;
    label: string;
    score: number;
  }[];
}) {
  const [modal, setModal] = useRecoilState(SessionDecisionAddModalAtom);

  if (props.details.state?.toLocaleLowerCase() !== "completed") {
    return <></>;
  }

  return (
    <div className="shrink-0 flex flex-col items-start justify-end relative w-64 py-1">
      {props.alerts.map((alert) => {
        if (
          (alert.score === undefined || alert.score === -1) &&
          alert.code !== "scenario"
        )
          return null;

        let bgColor = LABEL_TYPE_COLORS.UNDEFINED;
        if (alert.score !== undefined) {
          bgColor =
            alert.score < 50
              ? LABEL_TYPE_COLORS.NEGATIVE
              : alert.score > 95
              ? LABEL_TYPE_COLORS.POSITIVE
              : LABEL_TYPE_COLORS.NEUTRAL;
        }

        if (alert.code === "scenario") {
          const scenarioBg =
            (LABEL_TYPE_COLORS as any)[props.decision?.type as any] ||
            "bg-slate-500";
          const scenarioScoreType =
            props.scoring?.final_result >
            (props.scoringPreferences?.threshold?.fail || 0) * 100
              ? props.scoring?.final_result <
                (props.scoringPreferences?.threshold?.success || 0) * 100
                ? "NEUTRAL"
                : "POSITIVE"
              : "NEGATIVE";
          return (
            <>
              <div key={alert.code} className={`w-full absolute top-full`}>
                <div
                  className={`relative w-full p-1 flex flex-row space-x-2 items-start justify-start text-white rounded-b-lg ${scenarioBg} border-t border-slate-300`}
                >
                  <Base
                    noColor
                    className={twMerge(
                      "px-1",
                      props.decision?.type !== scenarioScoreType &&
                        "line-through"
                    )}
                  >
                    <InfoSmall noColor>System score</InfoSmall>
                    <br />
                    {Math.floor(props.scoring?.final_result || 0) ?? "-"} %
                  </Base>

                  <div className="grow" />

                  <div className="px-1">
                    <InfoSmall noColor>Decision</InfoSmall>
                    <br />
                    {props.decision ? (
                      <Tag
                        noColor
                        className={
                          "text-white border border-white " +
                          (props.decision.type === "NEGATIVE"
                            ? "bg-red-500"
                            : props.decision.type === "POSITIVE"
                            ? "bg-green-500"
                            : "bg-yellow-500")
                        }
                      >
                        {props.decision.label}
                      </Tag>
                    ) : (
                      <Button
                        theme="secondary"
                        className="flex flex-row space-x-2"
                        size="sm"
                        onClick={() =>
                          setModal({
                            ...modal,
                            open: true,
                            session_id: props.details.session_id,
                          })
                        }
                        shortcut={["u"]}
                      >
                        <FlagIcon className="w-5 h-5" />
                        <BaseSmall noColor>Take Decision</BaseSmall>
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </>
          );
        }

        return (
          <BaseSmall
            key={alert.code}
            className={`py-1 flex flex-row space-x-2 items-center justify-start`}
          >
            <div
              className={`w-5 h-5 p-1 flex text-white items-center justify-center rounded-full ${bgColor}`}
            >
              {alert.score < 50 ? (
                <XMarkIcon className="w-4 h-4" />
              ) : alert.score > 95 ? (
                <CheckIcon className="w-4 h-4" />
              ) : (
                <ExclamationTriangleIcon className="w-4 h-4" />
              )}
            </div>
            <BaseSmall>{alert.label}</BaseSmall>
          </BaseSmall>
        );
      })}
    </div>
  );
}

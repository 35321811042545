import { useCallback } from "react";
import { ClientApiClient } from "../api-client/api-client";
import { ClientSettings } from "../types";
import { useMutation, useQuery } from "react-query";

export const useClientSettings = () => {
  const getClientSettingsQuery = useQuery<ClientSettings>({
    queryKey: ["client-settings"],
    queryFn: () => {
      return ClientApiClient.getSettings();
    },
    refetchOnWindowFocus: false,
  });
  const {
    data: settings,
    isLoading,
    refetch: refetchSettings,
  } = getClientSettingsQuery;

  const updateClientSettingsMutation = useMutation({
    mutationFn: async (settings: ClientSettings): Promise<boolean> => {
      return ClientApiClient.saveSettings(settings);
    },
  });
  const { mutateAsync: updateSettingsMutate } =
    updateClientSettingsMutation;
  const updateClientSettings = useCallback(
    async (settings: ClientSettings) => {
      if (await updateSettingsMutate(settings)) {
        await refetchSettings();
      }
    },
    [updateSettingsMutate]
  );

  return {
    settings,
    isLoading,
    updateClientSettings
  };
};

import { PageBlock } from "@atoms/layout/page-block";
import { Section } from "@atoms/text";
import { PageLoader } from "@components/page-loader";
import { useCustomer } from "@features/customers/state/use-customer";
import { Identity } from "@views/client/customers/customer-details/identity";
import { OnboardingReview } from "@views/client/customers/customer-details/review";
import { useEffect } from "react";

export default function SessionCustomerOverview({
  customer_id,
}: {
  customer_id: string;
}) {
  const { customer: customerDetails, refresh } = useCustomer(customer_id || "");

  useEffect(() => {
    if (!customerDetails) {
      refresh();
    }
  }, [customerDetails, refresh]);

  if (!customerDetails) {
    return (
      <PageBlock>
        <PageLoader />;
      </PageBlock>
    );
  }

  return (
    <>
      <div id="customer" />
      <Section className="mt-8">Customer details</Section>
      <PageBlock className="mb-2">
        <Identity customer={customerDetails} />
      </PageBlock>
      <OnboardingReview customer={customerDetails} />
    </>
  );
}

import Avatar from "@atoms/avatar/avatar";
import { Tag } from "@atoms/badge/tag";
import { BaseSmall, Info, InfoSmall } from "@atoms/text";
import { useAgents } from "@features/agents/state/use-agents";
import { useAuth } from "@features/auth/state/hooks";
import { extractCustomerFullName } from "@features/customers/utils";
import { ThreadHistoryItemType } from "@features/inbox/types";
import {
  INBOX_STATUS_COLORS,
  INBOX_STATUS_LABELS,
} from "@features/inbox/utils";
import { useMessagesLabels } from "@features/messages-labels/state/use-messages-labels";
import { stringToColor } from "@features/utils";
import { formatTime } from "@features/utils/dates";
import { CodeBracketIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import { TextWithLinks } from "./text-with-links";

export default function ThreadHistoryItemCard({
  item,
  history,
}: {
  item: ThreadHistoryItemType;
  history: ThreadHistoryItemType[];
}) {
  const { agent } = useAuth();
  const { getAgentForClient } = useAgents();
  const itemAgent = getAgentForClient().find(
    (a) => a.agent_id === item.agent_id
  );
  const { labels: tags } = useMessagesLabels();

  const previousRevision = history.find(
    (h) => h.revision_number === item.revision_number - 1
  );

  const diff = {
    tags: _.difference(item.tags, previousRevision?.tags || []),
    ids: _.difference(
      (item.customers || []).map((c) => c.id),
      (previousRevision?.customers || []).map((c) => c.id) || []
    ),
    status: item.status !== previousRevision?.status ? item.status : null,
    comment:
      item.comment && item.comment !== previousRevision?.comment
        ? item.comment
        : null,
  };

  const operatorName =
    (agent?.agent_id === itemAgent?.id ? "You" : itemAgent?.name) || "System";

  if (
    diff.tags.length === 0 &&
    diff.ids.length === 0 &&
    diff.status === null &&
    diff.comment === null
  )
    return <></>;

  return (
    <div className="border-l-2 ml-6 px-2 border-slate-300 pt-4">
      <div className="flex flex-row space-x-1 -ml-5">
        <span className="flex justify-center items-center w-6 h-6 bg-slate-500 rounded-full mt-1.5">
          {!itemAgent && <CodeBracketIcon className="h-4 w-4 text-white" />}
          {!!itemAgent && (
            <Avatar
              size={6}
              icon={itemAgent.avatar}
              fallback={itemAgent.name}
            />
          )}
        </span>
        <div className="grow flex flex-col ">
          <InfoSmall>
            {formatTime(item.created_at, undefined, {
              keepDate: true,
              keepTime: true,
            })}
          </InfoSmall>
          {diff.tags && !!diff.tags.length && (
            <>
              <Info className="text-gray-500 mb-1">
                <b>{operatorName} </b>
                set thread tags to{" "}
                {(!item.tags || item.tags.length === 0) && (
                  <Tag className="opacity-50">No tags</Tag>
                )}
                {item.tags &&
                  item.tags.length > 0 &&
                  item.tags!.map((tagCode) => {
                    const tag = tags.find((t) => t.code === tagCode);
                    if (!tag) return null;
                    return (
                      <Tag
                        key={tag.code}
                        noColor
                        className="mr-1 text-black"
                        style={{ backgroundColor: stringToColor(tag.code) }}
                      >
                        {tag.name}
                      </Tag>
                    );
                  })}
              </Info>
            </>
          )}
          {diff.ids && !!diff.ids.length && (
            <>
              <Info className="text-gray-500 mb-1">
                <b>{operatorName} </b>
                set thread customers to{" "}
                {(!item.customers || item.customers.length === 0) && (
                  <Tag className="opacity-50">No customers</Tag>
                )}
                {item.customers &&
                  item.customers.length > 0 &&
                  item.customers!.map((customer) => {
                    return (
                      <Tag key={customer.id} className="mr-1">
                        {extractCustomerFullName(customer)} (
                        {customer.external_id})
                      </Tag>
                    );
                  })}
              </Info>
            </>
          )}
          {diff.status && (
            <>
              <Info className="text-gray-500 mb-1">
                <b>{operatorName} </b>
                set thread status to{" "}
                <Tag
                  noColor
                  className={
                    "text-white bg-" + INBOX_STATUS_COLORS[item.status]
                  }
                >
                  {INBOX_STATUS_LABELS[item.status]}
                </Tag>
              </Info>
            </>
          )}
          {diff.comment && (
            <>
              <Info className="text-gray-500 mb-1">
                <b>{operatorName} </b>
                added a comment:{" "}
              </Info>
              <>
                {item.comment && item.comment !== "" && (
                  <BaseSmall
                    noColor
                    className={
                      "p-2 w-fit rounded-lg whitespace-pre-wrap " +
                      (item.agent_id === agent?.agent_id
                        ? "rounded-bl-none bg-blue-500 text-white"
                        : "rounded-tl-none bg-slate-300 text-slate-800 dark:text-white")
                    }
                  >
                    <TextWithLinks text={item.comment} />
                  </BaseSmall>
                )}
              </>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

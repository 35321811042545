import _ from "lodash";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { AlertsApiClient } from "../api-client/api-client";
import { CustomerReviewAlertsAtom } from "./store";

export const useCustomerReviewAlert = (externalId: string) => {
  const [review, setReview] = useRecoilState(
    CustomerReviewAlertsAtom(externalId)
  );

  const refresh = useCallback(async () => {
    if (!externalId) return;
    setReview({ ...review, loading: true });
    const alerts = await AlertsApiClient.getAlerts({
      external_id: externalId,
      per_page: 100,
      order_by: "STATUS",
      order_way: "DESC",
    });
    const stats = _.groupBy(alerts.data, (alert) => alert.state);
    const total = alerts.total;
    setReview({ per_state: stats, total, loading: false });
  }, [externalId, review, setReview]);

  return { review, refresh };
};

import { useFetch } from "@features/utils";
import _ from "lodash";
import {
  DowJonesRecord,
  NameSearchOutput,
  NameSearchRequestOptions,
} from "../types";

export class WatchlistApiClient {
  static getProfile = async (id: string) => {
    const response = await useFetch(`/web/djlist/get-profile-data`, {
      method: "POST",
      body: JSON.stringify({
        profile_number: id,
      }),
      formData: true,
    });
    const data = await response.json();
    return data as DowJonesRecord;
  };

  static search = async (query: NameSearchRequestOptions) => {
    query = _.cloneDeep(query);
    query.person_input_data.first_name =
      query.person_input_data.first_name || " ";
    query.person_input_data.last_name =
      query.person_input_data.last_name || " ";

    query.page_token = query.page_token || "0";
    query.page_size = query.page_size || 100;

    const response = await useFetch(`/web/namesearch`, {
      method: "POST",
      body: JSON.stringify({ ...query, keep_results_under_threshold: true }),
    });
    const data = await response.json();
    return data as {
      results: NameSearchOutput[];
      next_page_token: string;
      total: number;
    };
  };
}

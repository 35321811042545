export const WEBHOOK_EVENTS_LABELS: {
  [key: string]: string;
} = {
  customer_document_updated: "Customer document updated",
  customer_event_updated: "Customer event created",
  customers_updated: "Customer updated",
  edds_updated: "Customer EDD updated",
  alerts_updated: "Alert updated",
  session_status_updated: "Session status updated",
  session_decision_updated: "Session decision updated",
  thread_updated: "Thread updated",
  thread_message_updated: "Thread message updated",
  transaction_updated: "Transaction updated",
};

// functions to convert between the comma separated list of event names
// and the array of event names
export const webhookEventsToArray = (eventsString: string) => {
  return eventsString.split(";").filter((e) => e !== "");
};

export const webhookEventsToString = (eventsList: string[]) => {
  return eventsList.join(";");
};

import { useGlobalEffect } from "@features/utils";
import { LoadingAtom } from "@features/utils/loading";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import { KytApiClient } from "../api-client/api-client";
import { KytPostHocPatternRequestOptions } from "../types";
import { PostHocPatternsListAtom } from "./store";

export const usePostHocPatterns = () => {
  const [patterns, setPatterns] = useRecoilState(PostHocPatternsListAtom);
  const [loading, setLoading] = useRecoilState(
    LoadingAtom(`usePostHocPatterns`)
  );

  const refresh = useCallback(async () => {
    setLoading(true);
    const patterns = await KytApiClient.getPostHocPatterns();
    setPatterns(patterns ?? []);
    setLoading(false);
  }, [setLoading, setPatterns]);

  const create = useCallback(
    async (pattern: any) => {
      try {
        await KytApiClient.createPostHocPattern(pattern);
        toast.success(`Created pattern ${pattern.name}`);
        refresh();
        return true;
      } catch (error) {
        toast.error(`Failed to create pattern ${pattern.name}`);
        return false;
      }
    },
    [refresh]
  );

  const update = useCallback(
    async (id: string, pattern: Partial<KytPostHocPatternRequestOptions>) => {
      try {
        await KytApiClient.editPostHocPattern(id, pattern);
        toast.success(`Updated pattern ${pattern.name}`);
        refresh();
        return true;
      } catch (e) {
        toast.error(`Failed to update pattern ${pattern.name}`);
        return false;
      }
    },
    [refresh]
  );

  const deletePattern = useCallback(
    async (id: string) => {
      await KytApiClient.deletePostHocPattern(id);
      refresh();
    },
    [refresh]
  );

  useGlobalEffect(
    "usePostHocPatterns",
    () => {
      refresh();
    },
    []
  );

  return { patterns, refresh, create, update, loading, deletePattern };
};

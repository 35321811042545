import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { Page } from "@atoms/layout/page";
import { Base, Title } from "@atoms/text";
import { Table } from "@components/table";
import { Column } from "@components/table/table";
import { useHasAccess } from "@features/auth/state/use-access";
import { usePostHocPatterns } from "@features/kyt/state/use-post-hoc-patterns";
import { KytPostHocPattern } from "@features/kyt/types";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { PostHocPatternModalAtom } from "./component/modal-post-hoc-pattern";

export default function SavedSearchesPage() {
  const { patterns, loading, refresh } = usePostHocPatterns();
  const hasAccess = useHasAccess();

  const [, setModal] = useRecoilState(PostHocPatternModalAtom);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const columns: Column<KytPostHocPattern>[] = [
    {
      title: "Name",
      render: (row) => (
        <Base className="flex items-center mr-2">{row.name}</Base>
      ),
    },
    {
      title: "Evaluation Frequency",
      render: (row) => (
        <>
          <Tag className="capitalize">{row.evaluation_frequency}</Tag>
        </>
      ),
    },
    {
      title: "Actions",
      headClassName: "justify-end",
      className: "justify-end",
      thClassName: "w-24",
      render: (row) =>
        hasAccess("KYT_MANAGE") && (
          <Button
            theme="outlined"
            size="sm"
            onClick={() => setModal({ open: true, pattern: { ...row } })}
          >
            Edit
          </Button>
        ),
    },
  ];

  return (
    <Page>
      <div className="flex flex-row justify-between">
        <Title>Saved Searches (Queries)</Title>
        {hasAccess("KYT_MANAGE") && (
          <Button
            className="float-right"
            size="sm"
            onClick={() =>
              setModal({
                open: true,
                pattern: {
                  name: "",
                  query: "",
                },
              })
            }
          >
            Create Pattern
          </Button>
        )}
      </div>
      <Table
        data={patterns}
        columns={columns}
        showPagination={false}
        loading={loading}
      />
    </Page>
  );
}

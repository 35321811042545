import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

export const NodeLayout = (props: {
  invalidMessage?: string;
  selected?: boolean;
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <div
      className={
        "relative max-w-xs bg-white rounded-md border flex flex-col dark:bg-slate-800 dark:text-white " +
        (props.selected
          ? " shadow-lg ring-4 ring-blue-200 dark:ring-blue-700 "
          : "") +
        (props.invalidMessage
          ? " border-red-500"
          : " border-gray-200 dark:border-slate-600 ") +
        (props.className || "")
      }
    >
      {props.invalidMessage && (
        <div className="bottom-0 absolute">
          <span data-tooltip={props.invalidMessage}>
            <div className="rounded-tl-none rounded-br-none flex flex-row items-center bg-red-500 rounded-md text-white text-sm px-2 py-1">
              <ExclamationCircleIcon className="h-5 w-5 mr-2 inline-block" />
              Invalid node
            </div>
          </span>
        </div>
      )}
      {props.children}
    </div>
  );
};

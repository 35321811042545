import { DropdownRoot } from "@atoms/dropdown";
import { Tooltip } from "@atoms/tooltip";
import { PageLoader } from "@components/page-loader";
import { useAssignmentsRealtime } from "@features/assignments/state/use-assignment";
import { useAuth } from "@features/auth/state/hooks";
import { ROUTES } from "@features/routes";
import { isEmbed } from "@features/utils/use-embed-utils";
import { useWebSockets } from "@features/websockets";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { BackOfficeRoutes } from "./client";
import { LoginRoutes } from "./signin";
import { SignUpRoutes } from "./signup";

export default function InitialRouter() {
  const { pathname } = useLocation();
  const { loading } = useAuth();
  useWebSockets();
  useAssignmentsRealtime();

  useEffect(() => {
    console.log("Navigate to:", pathname);
    window.scrollTo(0, 0);
  }, [pathname]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full w-full dark:bg-slate-900 bg-white">
        <PageLoader />
      </div>
    );
  }

  if (isEmbed()) {
    return (
      <Routes>
        <Route path="/">{BackOfficeRoutes()}</Route>
      </Routes>
    );
  }

  return (
    <div className="flex min-h-full dark:bg-slate-900 bg-white h-full">
      <Routes>
        <Route path="/">
          {BackOfficeRoutes()}
          {LoginRoutes()}
          {SignUpRoutes()}
          <Route path="*" element={<Navigate to={ROUTES.Login} />} />
          <Route path="" element={<Navigate to={ROUTES.Login} />} />
        </Route>
      </Routes>
      <DropdownRoot />
      <Tooltip />
      <Toaster position="top-center" />
    </div>
  );
}

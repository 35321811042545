import { useEffect, useState } from "react";
import { useScenarios } from "@features/scenarios/state/use-scenarios";
import { inboxAvailableTemplateVariablesFlat } from "@features/inbox-templates/enum";
import { useCustomFields } from "@features/custom-fields/state/use-custom-fields";

// Used to compute dynamic template variables, unlike some hardcoded variables available in src\features\inbox-templates\enum.ts
// May expand its use in the future, for the moment the only additional variables are based on enabled Go!Vid scenarios
export const useTemplateVariables = () => {
  const [additionalScenariosVariableMap, setadditionalScenariosVariableMap] =
    useState({});
  const { scenarios, refresh } = useScenarios();
  const { fields } = useCustomFields();

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    const enabledScenarios = (scenarios?.data || []).filter(
      (scenario) => scenario.enabled === true
    );

    const additionalScenariosVariableMap: { [key: string]: boolean } = {};
    enabledScenarios.forEach((scenario) => {
      additionalScenariosVariableMap["scenario." + scenario.code] = true;
    });

    setadditionalScenariosVariableMap(additionalScenariosVariableMap);
  }, [scenarios]);

  return {
    ...inboxAvailableTemplateVariablesFlat,
    ...additionalScenariosVariableMap,
    ...fields
      .filter((a) => a.field_source === 3)
      .map((field) => ({
        ["customer." + field.label]: true,
      }))
      .reduce((a, b) => ({ ...a, ...b }), {}),
  };
};

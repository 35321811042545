import InputDate from "@atoms/input/input-date";
import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Input } from "@atoms/input/input-text";
import { useAgents } from "@features/agents/state/use-agents";
import { useReviewGroups } from "@features/agents/state/use-review-groups";
import { useAlertCategories } from "@features/alerts/state/use-alert-categories";
import { useAlertStates } from "@features/alerts/state/use-alert-states";
import { AlertsRequestOptions } from "@features/alerts/types";
import { categoryToName } from "@features/alerts/utils";
import { AuthJWT } from "@features/auth/jwt";
import { useControlledEffect } from "@features/utils";
import _ from "lodash";
import { useState } from "react";

export const AlertsListFilters = ({
  value,
  onChange,
}: {
  value: Partial<AlertsRequestOptions>;
  onChange: (params: Partial<AlertsRequestOptions>) => void;
}) => {
  const [filters, setFilters] = useState<Partial<AlertsRequestOptions>>(value);
  const { categories } = useAlertCategories();
  const { states } = useAlertStates();
  const { reviewGroups } = useReviewGroups();
  const { members: agents } = useAgents();

  useControlledEffect(() => {
    onChange({
      ...filters,
    });
  }, [filters]);

  // Need agents actives
  const agentsActives = agents.filter(
    (agent) =>
      agent.active &&
      agent.clients.filter(
        (client) => client.active && client.id === AuthJWT.clientId
      ).length > 0
  );

  return (
    <div className="flex flex-row w-full">
      <div className="grow mr-2">
        <InputLabel
          label="Customer Name"
          input={
            <Input
              highlight
              value={filters.name || ""}
              onChange={(e) =>
                setFilters({ ...filters, name: e.target.value || null })
              }
              placeholder="James Bond"
            />
          }
        />
        <InputLabel
          className="mt-2"
          label="Customer ID"
          input={
            <Input
              highlight
              value={filters.id || ""}
              onChange={(e) =>
                setFilters({ ...filters, id: e.target.value || null })
              }
              placeholder="MI5007"
            />
          }
        />
        <InputLabel
          label="Assigned to"
          className="mt-2"
          input={
            <SelectMultiple
              highlight
              selectionLimit={1}
              value={
                filters.only_without_assignment
                  ? ["none"]
                  : filters.assigned_to_member_id
                  ? [filters.assigned_to_member_id.toString()]
                  : []
              }
              options={[
                { value: "none", label: "(None)" },
                ..._.sortBy(
                  agentsActives.map((agent) => ({
                    value: agent.id.toString(),
                    label: agent.name,
                  })) || [],
                  "label"
                ),
              ]}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  only_without_assignment: e.includes("none"),
                  assigned_to_member_id:
                    !e.includes("none") && e.length > 0 ? parseInt(e[0]) : null,
                })
              }
            />
          }
        />
      </div>
      <div className="mr-2 w-1/6">
        <InputLabel
          label="Score range"
          input={
            <div className="flex">
              <Input
                className="rounded-r-none"
                highlight
                value={filters.score_from || ""}
                type="number"
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    score_from: parseInt(e.target.value) || null,
                  })
                }
                placeholder="0"
              />
              <Input
                className="rounded-l-none -ml-px"
                highlight
                value={filters.score_to || ""}
                type="number"
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    score_to: parseInt(e.target.value) || null,
                  })
                }
                placeholder="100"
              />
            </div>
          }
        />
        <InputLabel
          className="mt-2"
          label="Review groups"
          input={
            <SelectMultiple
              highlight
              value={
                filters.only_without_review_groups
                  ? ["none"]
                  : filters.review_groups || []
              }
              options={[
                { value: "none", label: "(None)" },
                ..._.sortBy(
                  reviewGroups.map((c) => ({
                    value: c,
                    label: c,
                  })),
                  "label"
                ),
              ]}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  only_without_review_groups: e.includes("none"),
                  review_groups: (!e.includes("none") && e) || [],
                })
              }
            />
          }
        />
      </div>
      <div className="mr-2 w-1/6">
        <InputLabel
          label="Created After"
          input={
            <InputDate
              highlight
              value={filters.creation_date_from || null}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  creation_date_from: e?.toISOString() || null,
                })
              }
            />
          }
        />
        <InputLabel
          className="mt-2"
          label="Updated After"
          input={
            <InputDate
              highlight
              value={filters.last_update_from || null}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  last_update_from: e?.toISOString() || null,
                })
              }
            />
          }
        />
      </div>
      <div className="mr-2 w-1/6">
        <InputLabel
          label="Created Before"
          input={
            <InputDate
              highlight
              value={filters.creation_date_to || null}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  creation_date_to: e?.toISOString() || null,
                })
              }
            />
          }
        />
        <InputLabel
          className="mt-2"
          label="Updated Before"
          input={
            <InputDate
              highlight
              value={filters.last_update_to || null}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  last_update_to: e?.toISOString() || null,
                })
              }
            />
          }
        />
      </div>
      <div className="w-64">
        <InputLabel
          label="Categories"
          input={
            <SelectMultiple
              highlight
              value={filters.categories || []}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  categories: e || [],
                })
              }
              options={_.sortBy(
                Object.keys(categories).filter((c) => categories[c] !== "rca"),
                (c) => (categories[c] || "").replace("rca_", "zzz_")
              ).map((c) => ({
                value: categories[c],
                label: categoryToName[categories[c]] || categories[c],
              }))}
            />
          }
        />
        <InputLabel
          className="mt-2"
          label="Status"
          input={
            <SelectMultiple
              highlight
              value={filters.states?.map((a) => `${a}`) || []}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  states: e.map((a) => parseInt(a)) || [],
                })
              }
              options={Object.keys(states).map((c) => ({
                value: `${c}`,
                label: _.capitalize(states[parseInt(c)]),
              }))}
            />
          }
        />
      </div>
    </div>
  );
};

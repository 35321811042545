export const ApiAgentsRolesCodes = [
  "API_Agent_Scan_ARO",
  "API_Agent_Risk_ARO",
  "API_Agent_Vid_ARO",
  "API_Agent_Chat_ARO",
  "API_Agent_KYT_ARO",
  "API_Agent_Press_ARO",
];

export const AgentsRolesLabels: any = {
  Agent_Scan_ARO: "Go!Scan agent",
  Supervisor_Scan_ARO: "Go!Scan supervisor",
  ReadOnly_Scan_ARO: "Go!Scan read-only",
  API_Agent_Scan_ARO: "Go!Scan API",

  Agent_Risk_ARO: "Go!Risk agent",
  Supervisor_Risk_ARO: "Go!Risk supervisor",
  ReadOnly_Risk_ARO: "Go!Risk read-only",
  API_Agent_Risk_ARO: "Go!Risk API",

  Agent_Vid_ARO: "Go!Vid agent",
  Supervisor_Vid_ARO: "Go!Vid supervisor",
  ReadOnly_Vid_ARO: "Go!Vid read-only",
  API_Agent_Vid_ARO: "Go!Vid API",

  API_Agent_Chat_ARO: "Go!Chat API",
  Supervisor_Chat_ARO: "Go!Chat supervisor",
  Agent_Chat_ARO: "Go!Chat agent",
  ReadOnly_Chat_ARO: "Go!Chat read-only",

  Agent_KYT_ARO: "Go!KYT agent",
  Supervisor_KYT_ARO: "Go!KYT supervisor",
  ReadOnly_KYT_ARO: "Go!KYT read-only",
  API_Agent_KYT_ARO: "Go!KYT API",

  API_Agent_Press_ARO: "Go!Press API",
  Supervisor_Press_ARO: "Go!Press supervisor",
  Agent_Press_ARO: "Go!Press agent",
  ReadOnly_Press_ARO: "Go!Press read-only",

  Client_IT_Manager_ARO: "Admin (Client IT Manager)",
  SuperAdmin_ARO: "Admin (Super admin)",
};

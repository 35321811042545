import { useQuery } from "react-query";
import { CustomersApiClient } from "../api-client/api-client";
import { CustomerSearchType } from "../types";
import { RestSearchQuery } from "@components/search-bar/utils/types";
import { buildQueryFromMap } from "@components/search-bar/utils/utils";

export const useCustomerBy = (queryMap: { [key: string]: string }) => {
    const customer = useQuery<CustomerSearchType | null>({
        queryKey: ["customer-by", queryMap],
        queryFn: async () => {
            const query: RestSearchQuery[] = buildQueryFromMap(queryMap)
            const options = {
                limit: 1,
                offset: 0
            };
            const search = await CustomersApiClient.getCustomersAdvanced(
                query,
                options
            );
            return search.data?.[0] || null;
        },
        cacheTime: 2000,
    });

    return {
        customer: customer,
        loading: customer.isLoading,
    };
};

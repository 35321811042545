import _ from "lodash";
import { useCallback } from "react";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { KytApiClient } from "../api-client/api-client";
import { classifyClusters } from "../d3-kyt-utils";
import { KytGraph } from "../types";
import { GraphQueryAtom } from "./store";

export const useCypherQuery = () => {
  const cypherQuery = useRecoilValue(GraphQueryAtom);

  const fetchGraphElements = async () => {
    if (!cypherQuery) return { nodes: [], edges: [] };
    const queryGraph = await KytApiClient.getTransactionsCypherQuery(
      cypherQuery
    );
    // TODO: this i dont like, maybe, we should handle this in the backend or manually via the graph editor via a button
    // check missing edges (a second query to get missing edges)
    const nodesId = queryGraph.nodes.map((node) => node.id);
    const missingEdgeQuery = _checkMissingEdgeQuery(nodesId);
    if (!missingEdgeQuery) return queryGraph;
    const completeGraph = await KytApiClient.getTransactionsCypherQuery(
      missingEdgeQuery
    );
    // merge the two graphs
    completeGraph.nodes = [...completeGraph.nodes, ...queryGraph.nodes];
    completeGraph.edges = [...completeGraph.edges, ...queryGraph.edges];

    // remove duplicates
    completeGraph.nodes = [..._.uniqBy(completeGraph.nodes, "id")];
    completeGraph.edges = [
      ..._.uniqWith(
        completeGraph.edges,
        (a, b) => a.start_id === b.start_id && a.end_id === b.end_id
      ),
    ];
    classifyClusters(completeGraph.nodes, completeGraph.edges); // to handle clusters
    return completeGraph;
  };

  const { data, isLoading, refetch, error } = useQuery<KytGraph>(
    ["cypherQuery", cypherQuery],
    fetchGraphElements,
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const _checkMissingEdgeQuery = useCallback((nodesId: string[]) => {
    if (nodesId.length < 1) return;
    // sql query to check if there is any missing edge that doesnt exist in the graph (links)
    const nodesIds = nodesId.map((nodeId) => `'${nodeId}'`).join(",");

    const missingQuery = `MATCH (a)-[e:TRANSACTION_ALL]->(b) WHERE a.node_id IN (${nodesIds}) \
     AND b.node_id IN (${nodesIds}) \
     RETURN a, b, e`;

    return missingQuery;
  }, []);

  return {
    cypherData: data,
    cypherLoading: isLoading,
    refetch,
    cypherError: error,
  };
};

import { Button } from "@atoms/button/button";
import { FullScreenPage } from "@atoms/layout/page";
import { BaseSmall, Info, Section, Title } from "@atoms/text";
import { PageLoader } from "@components/page-loader";
import { KytApiClient } from "@features/kyt/api-client/api-client";
import {
  ExploreKytLayoutAtom,
  GraphQueryAtom,
} from "@features/kyt/state/store";
import useExploreGraph from "@features/kyt/state/use-explore-graph";
import { cypherQuickQueries, sqlQuickQueries } from "@features/kyt/utils";
import { ROUTES } from "@features/routes";
import { PlusCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { QueueListIcon, ShareIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { ExploreGraph } from "./explore-graph";
import GraphNodeRelationsTable from "./explore-graph/components/graph-node-relations";
import ExploreTransactionsTable from "./explore-transaction";
import { ExploreQueryRequestModalAtom } from "./query-patterns/query-modal";
import { PageBlock } from "@atoms/layout/page-block";

export function ExploreFromTransaction() {
  const [error, setError] = useState<boolean>(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const setGraphQuery = useSetRecoilState(GraphQueryAtom);
  const setLayout = useSetRecoilState(ExploreKytLayoutAtom);

  useEffect(() => {
    // get transaction details
    if (!id) {
      navigate(ROUTES.Explore);
      return;
    }
    _fetchTransaction().then((transaction) => {
      if (!transaction) return;
      const cypherTransactionQuery = `MATCH (a)-[e:TRANSACTION_ALL]->(b) WHERE a.node_id = "${transaction.from.external_id}"AND b.node_id = "${transaction.to.external_id}"RETURN a, b ,e`;

      setGraphQuery(cypherTransactionQuery);
      setLayout({
        graph: true,
        relations: false,
        transactions: false,
      });
    });
    navigate("/kyt/explore");
  }, [id, navigate, setGraphQuery, setLayout]);

  console.log("transaction id", id);

  const _fetchTransaction = async () => {
    try {
      const transaction = await KytApiClient.getTransaction(id!);
      return transaction;
    } catch (error) {
      toast.error("Error fetching transaction details");
      setError(true);
      console.error("Error fetching transaction details", error);
      return null;
    }
  };

  if (error)
    return (
      <Info>There was an error fetching transaction details (not fount)</Info>
    );

  return <PageLoader />;
}

export default function KytExplorePage() {
  const [layout, setLayout] = useRecoilState(ExploreKytLayoutAtom);
  const [queryRequest, setQueryRequest] = useRecoilState(
    ExploreQueryRequestModalAtom
  );

  return (
    <FullScreenPage>
      <div className="flex flex-col h-full">
        <div className="p-4 pt-6">
          <Button
            theme="primary"
            className="float-right mr-2"
            onClick={() =>
              setQueryRequest({
                ...queryRequest,
                open: true,
              })
            }
          >
            Query Builder
            <PlusCircleIcon className="w-5 h-5 ml-2" />
          </Button>
          <Title>Explore Transactions</Title>
        </div>
        <div className="border-t flex flex-row grow overflow-hidden">
          {!layout.graph && !layout.transactions && !layout.relations && (
            <QuickRequests />
          )}
          {layout.graph && (
            <div className="flex flex-col grow">
              <div className="flex flex-row border-b p-2 justify-between">
                <Info>Transactions Graph</Info>
                <button
                  type="button"
                  className="bg-slate-300 dark:bg-slate-600 rounded text-slate-600 dark:text-slate-300 hover:opacity-75 focus:outline-none "
                  onClick={() => {
                    setLayout(() => ({
                      graph: false,
                      relations: false,
                      transactions: false,
                    }));
                  }}
                >
                  <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                </button>
              </div>
              <div className="grow">
                <ExploreGraph />
              </div>
            </div>
          )}
          {(layout.relations || layout.transactions) && (
            <div
              className={
                "flex flex-col border-l " + (!layout.graph ? "w-full" : "w-1/3")
              }
            >
              {layout.relations && (
                <div className="overflow-auto border-b" style={{ flex: 3 }}>
                  <div className="flex flex-col w-full h-full max-h-full overflow-none">
                    <div className="border-b p-2 flex flex-row justify-between">
                      <Info>Focused Node Relations</Info>
                      <button
                        type="button"
                        className="bg-slate-300 dark:bg-slate-600 rounded text-slate-600 dark:text-slate-300 hover:opacity-75 focus:outline-none "
                        onClick={() => {
                          setLayout((layout) => ({
                            ...layout,
                            relations: false,
                          }));
                        }}
                      >
                        <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                      </button>
                    </div>
                    <GraphNodeRelationsTable />
                  </div>
                </div>
              )}
              {layout.transactions && (
                <div className="overflow-auto" style={{ flex: 2 }}>
                  <div className="flex flex-col w-full h-full max-h-full overflow-none">
                    <div className="border-b p-2 flex flex-row justify-between">
                      <Info>Transactions Details</Info>
                      <button
                        type="button"
                        className="bg-slate-300 dark:bg-slate-600 rounded text-slate-600 dark:text-slate-300 hover:opacity-75 focus:outline-none "
                        onClick={() => {
                          setLayout((layout) => ({
                            ...layout,
                            transactions: false,
                          }));
                        }}
                      >
                        <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                      </button>
                    </div>
                    <ExploreTransactionsTable />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </FullScreenPage>
  );
}

export function QuickRequests() {
  const { refreshSQL } = useExploreGraph();
  const setGraphQuery = useSetRecoilState(GraphQueryAtom);
  const setLayout = useSetRecoilState(ExploreKytLayoutAtom);

  return (
    <div className="h-full w-full flex-col flex justify-center space-y-4 items-center">
      <PageBlock className="max-w-lg w-full flex flex-col items-start space-y-2 pb-4">
        <div className="flex flex-row items-center space-x-2">
          <QueueListIcon className="h-6 w-6 shrink-0" />
          <Section className="font-bold !my-0">Transactions Explore</Section>
        </div>
        <BaseSmall className="opacity-50">
          Transactions Quick Requests
        </BaseSmall>
        <div className="">
          {sqlQuickQueries.map((query) => (
            <Button
              key={query.code}
              theme="outlined"
              size="sm"
              className="m-1"
              onClick={() => {
                return refreshSQL(query.query);
              }}
            >
              {query.label}
            </Button>
          ))}
        </div>
      </PageBlock>
      <PageBlock className="max-w-lg w-full flex flex-col items-start space-y-2 pb-4">
        <div className="flex flex-row items-center space-x-2">
          <ShareIcon className="h-6 w-6 shrink-0" />
          <Section className="font-bold !my-0">Graph Explore</Section>
        </div>
        <BaseSmall className="opacity-50">Graph Quick Requests</BaseSmall>
        <div className="flex flex-wrap">
          {cypherQuickQueries.map((query) => (
            <Button
              key={query.code}
              theme="outlined"
              size="sm"
              className="m-1"
              onClick={() => {
                setGraphQuery(query.query);
                setLayout({
                  graph: true,
                  relations: false,
                  transactions: false,
                });
              }}
            >
              {query.label}
            </Button>
          ))}
        </div>
      </PageBlock>
    </div>
  );
}

import { useGlobalEffect } from "@features/utils";
import { useRecoilState } from "recoil";
import { CUSTOMERS_EDD_STATES } from "../utils";
import { CustomersStatesAtom } from "./store";


export const useCustomerStates = () => {
  const [states, setStates] = useRecoilState(CustomersStatesAtom);

  const refresh = async () => {
    // There should be fetching here
    setStates(CUSTOMERS_EDD_STATES);
  };

  useGlobalEffect(
    "useCustomerStates",
    () => {
      refresh();
    },
    []
  );

  return { states, refresh };
};

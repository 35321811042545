import { Tag } from "@atoms/badge/tag";
import { SessionWarnings } from "@features/sessions/utils";
import {
  Bars4Icon,
  EyeIcon,
  FingerPrintIcon,
  GlobeAmericasIcon,
  IdentificationIcon,
} from "@heroicons/react/24/outline";

export default function SessionWarning(props: { warning: SessionWarnings }) {
  if (props.warning === SessionWarnings.ip_mismatch) {
    return (
      <span data-tooltip={"Location verification failed"} tabIndex={0}>
        <Tag noColor className="text-white">
          <GlobeAmericasIcon className="h-5 w-5 text-black"></GlobeAmericasIcon>
        </Tag>
      </span>
    );
  }
  if (props.warning === SessionWarnings.missing_id_checks) {
    return (
      <span data-tooltip="ID document not fully verified" tabIndex={0}>
        <Tag noColor className=" text-white">
          <IdentificationIcon className="h-5 w-5 text-black"></IdentificationIcon>
        </Tag>
      </span>
    );
  }
  if (props.warning === SessionWarnings.watch_list) {
    return (
      <span data-tooltip={"Potential watchlist hit(s) detected"} tabIndex={0}>
        <Tag noColor className=" text-white">
          <Bars4Icon className="h-5 w-5 text-black"></Bars4Icon>
        </Tag>
      </span>
    );
  }
  if (props.warning === SessionWarnings.id_fail) {
    return (
      <span data-tooltip={"ID document verification failed"} tabIndex={0}>
        <Tag noColor className=" text-white">
          <FingerPrintIcon className="h-5 w-5 text-black"></FingerPrintIcon>
        </Tag>
      </span>
    );
  }
  if (props.warning === SessionWarnings.face_fail) {
    return (
      <span data-tooltip={"Face verification failed"} tabIndex={0}>
        <Tag noColor className=" text-white">
          <EyeIcon className="h-5 w-5 text-black"></EyeIcon>
        </Tag>
      </span>
    );
  }

  return <div>{props.warning}</div>;
}

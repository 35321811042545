import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { BaseSmall, Info } from "@atoms/text";
import { Table } from "@components/table";
import { Column } from "@components/table/table";
import { FocusedNodeAtom } from "@features/kyt/state/store";
import useExploreGraph from "@features/kyt/state/use-explore-graph";
import { useRelationsQuery } from "@features/kyt/state/use-relations-query";
import { KytGraph, KytGraphRelation } from "@features/kyt/types";
import { KYT_TRANSACTION_STATES_COLORS } from "@features/kyt/utils";
import { LoadingAtom } from "@features/utils/loading";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  BuildingOfficeIcon as OfficeOutline,
  UserIcon as UserOutline,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useRecoilState, useRecoilValue } from "recoil";

export default function GraphNodeRelationsTable() {
  const relationsQuery = useRelationsQuery();
  const node = useRecoilValue(FocusedNodeAtom);
  const [loading, setLoading] = useRecoilState(
    LoadingAtom(`GraphNodeRelations`)
  );
  const [relations, setRelations] = useState<KytGraphRelation[]>([]);
  const { refreshSQL, nodeAlreadyExists, addToGraph } = useExploreGraph();

  useEffect(() => {
    setLoading(relationsQuery.cypherLoading);
    setRelations(relationsQuery.cypherData ?? []);
    if (relationsQuery.cypherError) {
      toast.error("Error getting relations for the focused node");
      console.error(relationsQuery.cypherError);
    }
  }, [
    relationsQuery.cypherLoading,
    relationsQuery.cypherData,
    setLoading,
    setRelations,
  ]);

  useEffect(() => {
    if (!node) return;
    relationsQuery.setQueryNode(node.id);
  }, [node, relationsQuery.setQueryNode]);

  function _getColumns(): Column<KytGraphRelation>[] {
    return [
      {
        title: "Type",
        className: "",
        render: (row) => (
          <div className="w-16 flex items-start flex-row capitalize whitespace-nowrap">
            <Tag
              noColor
              className={
                "text-xs  my-1 text-white bg-" +
                (KYT_TRANSACTION_STATES_COLORS[row.type === "in" ? 0 : 1] ||
                  "slate-500")
              }
            >
              {row.type === "in" ? (
                <ArrowLongRightIcon className="inline-block w-6 h-6 mr-1" />
              ) : (
                <ArrowLongLeftIcon className="inline-block w-6 h-6 mr-1" />
              )}
            </Tag>
          </div>
        ),
      },
      {
        title: "Name",
        className: "overflow-hidden text-ellipsis",
        render: (row) => (
          <div className="w-36 flex items-center">
            {row.node?.account_type === "F" ? (
              <OfficeOutline className="h-4 w-4 inline mr-2 shrink-0" />
            ) : (
              <UserOutline className="h-4 w-4 inline mr-2 shrink-0" />
            )}
            <BaseSmall>{row.node?.full_name ?? "-"}</BaseSmall>
          </div>
        ),
      },
      {
        title: "Total Amount",
        render: (row) => (
          <div className="w-36">
            <Info>
              {row.type === "in" ? "+" : "-"}{" "}
              {row.link.volume[0].toLocaleString()}€
            </Info>
          </div>
        ),
      },
      {
        title: "Count",
        render: (row) => <Info>/ {row.link.count[0]}</Info>,
      },
      {
        title: "Min Amount",
        className: "overflow-hidden text-ellipsis",
        render: (row) => (
          <div className="w-36">
            <Info>
              {row.type === "in" ? "+" : "-"} {row.link.min[0].toLocaleString()}
              €
            </Info>
          </div>
        ),
      },
      {
        title: "Max Amount",
        className: "overflow-hidden text-ellipsis",
        render: (row) => (
          <div className="w-36">
            <Info>
              {row.type === "in" ? "+" : "-"} {row.link.max[0].toLocaleString()}
              €
            </Info>
          </div>
        ),
      },
      {
        title: "Actions",
        className: "text-right",
        headClassName: "justify-end",
        thClassName: "w-24",
        render: (row) => (
          <div className="flex flex-row space-x-2 items-end justify-end">
            {!nodeAlreadyExists(row.node?.id ?? "") && (
              <Button
                onClick={() => {
                  if (!row.node) return;
                  const graph: KytGraph = {
                    nodes: [{ ...row.node, cluster: node!.cluster }],
                    edges: [
                      {
                        ...row.link,
                        source: row.link.start_id,
                        target: row.link.end_id,
                      },
                    ],
                  };
                  addToGraph(graph);
                }}
                size="sm"
                theme="secondary"
                className="text-xs"
              >
                Add to Graph
              </Button>
            )}
            <Button
              onClick={() => {
                // SQL Query for transactions between the two nodes
                const isIn = row.type === "in";
                const sqlQuery = `SELECT * FROM kyt_transactions\
                          WHERE ${
                            isIn ? '"to"' : '"from"'
                          }->>'external_id' = '${node?.id ?? ""}'\
                          AND ${isIn ? '"from"' : '"to"'}->>'external_id' = '${
                  row.node?.id ?? ""
                }'\
                          ORDER BY AMOUNT DESC;`;
                refreshSQL(sqlQuery);
              }}
              size="sm"
              theme="outlined"
              className="text-xs"
            >
              Transactions Details
            </Button>
          </div>
        ),
      },
    ];
  }
  return (
    <Table
      total={relations.length}
      data={relations}
      loading={loading}
      scrollable
      columns={_getColumns()}
    />
  );
}

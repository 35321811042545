import { useFetch } from "@features/utils";
import Env from "../../../config/environment";
import { SearchDocumentsRequestOptions, DocumentType } from "../types";
import { AuthJWT } from "@features/auth/jwt";

export class DocumentsApiClient {
  static search = async (options: Partial<SearchDocumentsRequestOptions>) => {
    const response = await useFetch(`/web/customer_documents/search`, {
      method: "POST",
      body: JSON.stringify({
        filters: {
          customer_external_id: "",
          file_types: [],
          max_created_at: new Date().getTime(),
          min_created_at: 0,
          ...options,

          //Force string to be undefined or it will be used as filter
          display_name: options.display_name || undefined,
          file_name: options.file_name || undefined,
        },
        on_page: options.on_page || 1,
        per_page: options.per_page || 10,
      }),
    });
    const data = await response.json();
    if (!data.data) data.data = [];
    return data as { data: DocumentType[]; total: number };
  };

  static upload = async (
    document: {
      customer_external_id: string;
      name: string;
      tag_codes: string[];
    },
    file: {
      file_size: number; //For external files only
      file_url: string; //For external files only
      file_name: string;
      file_base_64: string;
    }
  ) => {
    return await useFetch(`/web/customer_documents/upload`, {
      method: "POST",
      body: JSON.stringify({
        customer_external_id: document.customer_external_id,
        display_name: document.name,
        tag_codes: document.tag_codes || [],

        file_name: file.file_name,
        external: {
          file_size: file.file_size || "",
          file_url: file.file_url || "",
        },
        internal: {
          file_data: file.file_base_64,
        },
      }),
    });
  };

  static update = async (
    id: string,
    update: { name: string; tag_codes: string[] }
  ) => {
    return await useFetch(`/web/customer_documents/${id}`, {
      method: "POST",
      body: JSON.stringify({
        display_name: update.name,
        tag_codes: update.tag_codes,
      }),
    });
  };

  static downloadContent = async (document: DocumentType) => {
    const url = await DocumentsApiClient.getDownloadRoute(document);
    const response = await useFetch(url, {
      method: "GET",
    });
    return await response.text();
  };

  static getDownloadRoute = async (document: DocumentType) => {
    if (document.file_source === "internal") {
      return `${Env.server.replace(/\/^/, "")}/web/customer_documents/${
        document.id
      }/download?clients=${AuthJWT.clientId}`;
    }

    /* 
    Pour les fichiers de type url
      - Si file_url commence par https?:// (regex) alors on prend l'url telle quelle
      - Si file_url commence par /api/ alors on ajoute serveur.api.algoreg.com (variable d'environement.server)
      - Sinon on concat le serveur.backoffice.algoreg.com ... (en utilisant location.host)
    */
    if (/^https?:\/\//.test(document.file_url)) return document.file_url;
    if (/^\/api\//.test(document.file_url))
      return `${Env.server.replace(/\/^/, "")}${document.file_url}`;
    const location = window.location;
    return `${location.protocol}//${location.host}${document.file_url}`;
  };

  static delete = async (id: string) => {
    return await useFetch(`/web/customer_documents/${id}`, {
      method: "DELETE",
    });
  };
}

import countries from "@assets/countries.json";
import Avatar from "@atoms/avatar/avatar";
import { Button } from "@atoms/button/button";
import { Page } from "@atoms/layout/page";
import { PageBlock } from "@atoms/layout/page-block";
import Link from "@atoms/link";
import { Base, Info, Section, Title } from "@atoms/text";
import Assignments from "@components/assignations";
import { PageLoader } from "@components/page-loader";
import { useHasAccess } from "@features/auth/state/use-access";
import { useCustomer } from "@features/customers/state/use-customer";
import { extractCustomerFullName } from "@features/customers/utils";
import { usePressReport } from "@features/press/state/use-press-report";
import { ROUTES } from "@features/routes";
import { useControlledEffect } from "@features/utils";
import { BuildingOfficeIcon, UserIcon } from "@heroicons/react/24/outline";
import { Identity } from "@views/client/customers/customer-details/identity";
import { SharedProductsMenu } from "@views/client/customers/customer-details/menu";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { atom, useRecoilState } from "recoil";
import { PressReportBodyDetailsPage } from "./body-details";
import { Notes } from "./notes";
import { ReportTimeline } from "./report-timeline";

export const PressReportCustomerExtendedViewAtom = atom({
  key: "PressReportCustomerExtendedViewAtom",
  default: false,
});

export default function PressReportDetailsPage() {
  const hasAccess = useHasAccess();
  const { customer_id } = useParams();
  const { report, focusedReportBody, loading, refresh, focusReportBody } =
    usePressReport(customer_id || "");
  const {
    customer,
    loading: loadingCustomer,
    refresh: refreshCustomer,
  } = useCustomer(customer_id || "");
  const [extendedView, setExtendedView] = useRecoilState(
    PressReportCustomerExtendedViewAtom
  );

  useEffect(() => {
    refresh();
    refreshCustomer();
  }, [customer_id, refresh]);

  useControlledEffect(() => {
    if (report?.latest_revision.report_body_id) {
      focusReportBody(report?.latest_revision.report_body_id);
    }
  }, [report?.latest_revision.report_body_id]);

  return (
    <>
      <Page>
        <SharedProductsMenu id={customer_id!} className="mt-0" />

        <Title className="flex-row flex space-x-2 items-center">
          <div className="grow">
            Detailed press report about
            {customer &&
              " " + extractCustomerFullName(customer.details.customer)}
          </div>
        </Title>
        <div className="mt-4 flex flex-row">
          <PageBlock className="relative grow w-1/2">
            {!customer && loadingCustomer && (
              <div style={{ marginTop: "30vh" }}>
                <PageLoader />
              </div>
            )}
            {customer && (
              <>
                <div className="absolute right-2 top-2">
                  <Button
                    size="sm"
                    theme="outlined"
                    className="rounded-r-none"
                    onClick={() => setExtendedView(!extendedView)}
                    shortcut={["e"]}
                  >
                    {!extendedView ? "Show more" : "Show less"}
                  </Button>
                  <Link
                    to={ROUTES.CustomerView.replace(":id", customer_id!)}
                    shortcut={["c"]}
                  >
                    <Button
                      size="sm"
                      theme="primary"
                      className="rounded-l-none -ml-px"
                    >
                      Open customer
                    </Button>
                  </Link>
                </div>
                <div className="flex flex-row w-full py-2 items-start">
                  {customer.details.customer.customer_type !== "3" && (
                    <Avatar size={14} className="mr-4 shrink-0" />
                  )}
                  <div className="grow">
                    <Section className="flex flex-row items-center">
                      {customer.details.customer.customer_type !== "3" ? (
                        <UserIcon className="h-5 w-5 inline mr-2 shrink-0" />
                      ) : (
                        <BuildingOfficeIcon className="h-5 w-5 inline mr-2 shrink-0" />
                      )}
                      {extractCustomerFullName(customer.details.customer)}
                    </Section>

                    <Base className="mr-4">
                      <Info>ID</Info> {customer.details.customer?.external_id}
                    </Base>

                    {customer.details.customer?.registration_number && (
                      <Base className="mr-4">
                        <Info>Registration Number</Info>{" "}
                        {customer.details.customer?.registration_number}
                      </Base>
                    )}
                    <br />

                    {customer.details.customer?.date_of_birth && (
                      <Base className="mr-4">
                        <Info>Date of birth</Info>{" "}
                        {(customer.details.customer?.date_of_birth
                          ? new Date(customer.details.customer?.date_of_birth)
                              .toISOString()
                              .split("T")[0]
                          : null) || "Not defined"}
                      </Base>
                    )}
                    {customer.details.customer?.nationality_code && (
                      <Base className="mr-4">
                        <Info>Nationality</Info>{" "}
                        {countries.find(
                          (c) =>
                            c["alpha-3"] ===
                            customer.details.customer?.nationality_code
                        )?.name || "Invalid"}{" "}
                        <Info>
                          ({customer.details.customer?.nationality_code})
                        </Info>
                      </Base>
                    )}
                    {customer.details.customer?.domicile_code && (
                      <Base className="mr-4">
                        <Info>Domicile</Info>{" "}
                        {countries.find(
                          (c) =>
                            c["alpha-3"] ===
                            customer.details.customer?.domicile_code
                        )?.name || "Invalid"}{" "}
                        <Info>
                          ({customer.details.customer?.domicile_code})
                        </Info>
                      </Base>
                    )}
                  </div>
                </div>
              </>
            )}
          </PageBlock>
        </div>
        {extendedView && customer && (
          <>
            <PageBlock className="mb-2">
              <Identity customer={customer} />
            </PageBlock>
          </>
        )}

        {!report && loading && (
          <div style={{ marginTop: "30vh" }}>
            <PageLoader />
          </div>
        )}
        {report && (
          <>
            <div className="flex flex-col lg:flex-row lg:space-x-8 justify-between mt-2">
              <div className="shrink-0 lg:w-1/3 lg:max-w-md w-full">
                <Assignments
                  type="pressreport"
                  id={customer_id!}
                  groups={/* report.review_groups || */ []}
                  /* TODO: find a way to get review_groups
                   from reportType (which is different from ES report type
                   that holds review groups) */
                  readonly={!hasAccess("PRESS_MANAGE")}
                />
                <ReportTimeline
                  disabled={false} // temporary
                  customer_id={customer_id || ""}
                  revisions={[report.latest_revision]
                    .concat(report.previous_revisions)
                    .filter(Boolean)}
                />
                <Notes customerID={report.latest_revision.customer_id} />
              </div>
              <div className="grow overflow-hidden">
                <div className="mt-4">
                  {focusedReportBody && (
                    <PressReportBodyDetailsPage
                      reportBody={focusedReportBody}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </Page>
    </>
  );
}

import { LoadingAtom } from "@features/utils/loading";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { WatchlistApiClient } from "../api-client/api-client";
import { ProfileDetailsAtom } from "./store";

export const useProfileRecord = (id: string) => {
  const [record, setRecord] = useRecoilState(ProfileDetailsAtom(id));
  const [loading, setLoading] = useRecoilState(
    LoadingAtom("useProfileRecord-" + id)
  );

  const refresh = useCallback(async () => {
    setLoading(true);
    const record = await WatchlistApiClient.getProfile(id);
    if (record?.list_id)
      setRecord({ record: record, associates: [], categories: null });
    setLoading(false);
  }, [setRecord, setLoading, id]);

  return {
    record: record?.record,
    associates: record?.associates,
    categories: record?.categories,
    loading,
    refresh,
  };
};

import { LoadingAtom } from "@features/utils/loading";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { SessionsApiClient } from "../api-client/api-client";
import { CustomerSessionsAtom } from "./store";
import _ from "lodash";

export const useCustomerSessions = (external_id: string) => {
    const [sessions, setSessions] = useRecoilState(CustomerSessionsAtom(external_id));
    const [loading, setLoading] = useRecoilState(LoadingAtom("useCustomerSessions-" + external_id));

    const refresh = useCallback(async () => {
        if (!external_id) return;
        setLoading(true);
        const sessions = await SessionsApiClient.getCustomerSessions(external_id);
        if (sessions) {
            // sort by date using lodash
            setSessions(_.sortBy(sessions.data, "start_timestamp"));
        }
        setLoading(false);
    }, [setSessions, external_id, setLoading]);

    return { sessions, loading, refresh };
};
import { Base, BaseSmall, Info } from "@atoms/text";
import { useCustomersCache } from "@features/customers/state/use-customers-cache";
import { CachedCustomerType } from "@features/customers/types";
import { extractCustomerFullName } from "@features/customers/utils";
import { ThreadRelatedCustomer } from "@features/inbox/types";
import { useControlledEffect } from "@features/utils";
import { BuildingOfficeIcon, UserIcon } from "@heroicons/react/20/solid";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { sortBy, uniq } from "lodash";
import { useCallback, useEffect } from "react";
import InputSuggestions from "../../../../atoms/input/input-suggestions";
import { useHasAccess } from "@features/auth/state/use-access";

export default function ContactsSearchInput(props: {
  placeholder?: string;
  suggestionsLength?: number;
  customers: string[];
  additionalEmails?: string[];
  value: string[];
  className?: string;
  auto?: boolean;
  onContactsChange: (
    contacts: string[],
    customers: ThreadRelatedCustomer[]
  ) => void;
}) {
  const { customers } = useCustomersCache(props.customers);
  const hasAccess = useHasAccess();

  const onChangeContacts = useCallback(
    (contacts: string[]) => {
      props.onContactsChange(
        contacts,
        customers
          ?.filter((customer) => {
            const customerContact =
              customer?.contacts
                ?.split(/,|;/g)
                .map((a) => a.trim().toLocaleLowerCase()) ?? [];
            return customerContact.some((contact) =>
              contacts.includes(contact.trim().toLocaleLowerCase())
            );
          })
          .map((a) => ({
            ...a,
            id: a.customer_id,
          })) ?? []
      );
    },
    [props, customers]
  );

  //Needed to update the customers part of the onChangeContacts
  useControlledEffect(() => {
    onChangeContacts(props.value);
  }, [customers.length]);

  const contacts = (
    customers.map((customer) => customer.contacts).join(",") +
    (props.additionalEmails || []).join(",")
  )
    .split(/,|;/g)
    .map((a) => a.trim().toLocaleLowerCase())
    .map((contact) => {
      const details = customers?.find((customerDetail) => {
        return customerDetail.contacts
          .trim()
          .toLocaleLowerCase()
          .includes(contact.trim().toLocaleLowerCase());
      });
      return { contact, details };
    })
    .filter(
      ({ details, contact }) =>
        !(
          !details ||
          props.value
            .map((a) => a.trim().toLocaleLowerCase())
            .includes(contact.trim().toLocaleLowerCase())
        )
    );

  useEffect(() => {
    if (props.auto && !props.value && contacts?.length === 1) {
      onChangeContacts(contacts[0].contact.split(/,|;/g).map((a) => a.trim()));
    }
  }, [contacts, props.auto, props.value, onChangeContacts]);

  const filterContacts = (query: string, customers: CachedCustomerType[]) => {
    const groupedLists: { [key: string]: string[] } = {
      Autre: [...(props.additionalEmails || [])],
    };

    for (const customer of customers) {
      groupedLists[customer.external_id] = [
        ...customers
          .map((customer) => customer.contacts)
          .join(",")
          .split(/,|;/g),
      ];

      // Look for emails anywhere in the customer object
      for (const field of Object.keys(customer.custom_fields || {})) {
        const value = customer.custom_fields[field];
        if (typeof value === "string") {
          const emails = Array.from(
            value.matchAll(/[\w.+-]+@[\w-]+\.[\w-]{2,4}/gm)
          ).map((a) => a[0]);
          if (emails.length > 0) {
            groupedLists[customer.external_id + " - " + field] = emails;
          }
        }
      }
    }

    const list = [];

    for (const origin of Object.keys(groupedLists)) {
      const items = groupedLists[origin]
        .map((a) => a.trim().toLocaleLowerCase())
        .filter(
          (a) =>
            !query || a.toLocaleLowerCase().includes(query.toLocaleLowerCase())
        )
        .map((contact) => {
          const details = customers?.find((customerDetail) => {
            return customerDetail.contacts
              .trim()
              .toLocaleLowerCase()
              .includes(contact.trim().toLocaleLowerCase());
          });
          return { contact, details, origin };
        })
        .filter(
          ({ contact }) =>
            !props.value
              .map((a) => a.trim().toLocaleLowerCase())
              .includes(contact.trim().toLocaleLowerCase())
        );

      list.push(...items);
    }

    if (
      list.length === 0 &&
      query.length > 0 &&
      query.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/) &&
      hasAccess("CHAT_MANAGE")
    ) {
      list.push({ contact: query, details: undefined, origin: "Autre" });
    }
    return sortBy(uniq(list), (a) =>
      a.origin === "Autre" ? "zzzzz" : a.origin
    );
  };

  return (
    <div className="relative -mb-0.5">
      <InputSuggestions
        value={props.value}
        onChange={onChangeContacts}
        className={props.className}
        placeholder={props.placeholder ?? "Search customers contacts"}
        getSuggestions={async (query) => {
          const results = filterContacts(query, customers);
          return results.map(({ contact, details, origin }, i) => {
            const showCategory = i === 0 || origin !== results[i - 1].origin;
            return {
              value: contact,
              header: showCategory ? (
                <BaseSmall>{origin}</BaseSmall>
              ) : undefined,
              label: (
                <div className="flex flex-row items-center">
                  <div>
                    <Base className="block">{contact}</Base>
                    {details && (
                      <Info className="flex items-center">
                        {!!(details!.trading_name || details!.company_name) && (
                          <BuildingOfficeIcon className="h-4 w-4 mr-1 inline shrink-0" />
                        )}
                        {!(details!.trading_name || details!.company_name) && (
                          <UserIcon className="h-4 w-4 mr-1 inline shrink-0" />
                        )}{" "}
                        {extractCustomerFullName(details!)}
                      </Info>
                    )}
                    {!details && (
                      <Info className="flex items-center">
                        <PlusCircleIcon className="h-4 w-4 mr-1 inline shrink-0" />
                        Add this email
                      </Info>
                    )}
                  </div>
                </div>
              ),
            };
          });
        }}
      />
    </div>
  );
}

import { atom, atomFamily } from "recoil";
import {
  DowJonesAssociate,
  DowJonesRecord,
  DowJonesRecordCategories,
  NameSearchRequestOptions,
} from "../types";

export const NameSearchFormAtom = atom<NameSearchRequestOptions>({
  key: "NameSearchFormAtom",
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newValue) => {
        localStorage.setItem(
          "saved.name_search_form",
          JSON.stringify(newValue)
        );
      });
    },
  ],
  default: (() => {
    let saved = {};
    try {
      saved = JSON.parse(
        localStorage.getItem("saved.name_search_form") || "{}"
      );
    } catch (e) {
      // No-op
    }
    return {
      entity_type: 1,
      person_input_data: {
        last_name: "",
        first_name: "",
        date_of_birth: null,
        exact_date_of_birth: false,
        domicile_code: null,
        nationality_code: null,
      },
      company_input_data: { company_name: "" },
      exact_match: false,
      post_filtering_alias: null,
      ...saved,
    } as NameSearchRequestOptions;
  })(),
});

export const ProfileDetailsAtom = atomFamily<
  {
    record: DowJonesRecord;
    associates: DowJonesAssociate[];
    categories: DowJonesRecordCategories | null;
  } | null,
  string
>({
  key: "ProfileDetailsAtom",
  default: null,
});

import { useGlobalEffect } from "@features/utils";
import { useRecoilState } from "recoil";
import { SessionsApiClient } from "../api-client/api-client";
import { SessionsStatesAtom } from "./store";

export const useSessionStates = () => {
  const [states, setStates] = useRecoilState(SessionsStatesAtom);

  const refresh = async () => {
    setStates((await SessionsApiClient.getSessionsStates()).status);
  };

  useGlobalEffect(
    "useSessionStates",
    () => {
      refresh();
    },
    []
  );

  return { states, refresh };
};

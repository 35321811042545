import { useCustomer } from "@features/customers/state/use-customer";
import {
  extractCustomerFullName,
  RELATIONSHIP,
} from "@features/customers/utils";
import { useControlledEffect } from "@features/utils";
import _ from "lodash";
import { useEffect, useMemo } from "react";
import ReactFlow, {
  Background,
  Controls,
  Edge,
  Node,
  useEdgesState,
  useNodesState,
  useOnSelectionChange,
  useReactFlow,
} from "reactflow";
import { useRecoilState } from "recoil";
import { CustomerGraphSelectionAtom } from ".";
import { computePositions } from "./utils/auto-position";
import { NodeComponent, NodeInvisible } from "./utils/customer-node";

const NODE_WIDTH = 200;
const NODE_HEIGHT = 50;

export const GraphRender = ({ id }: { id: string }) => {
  const { customer, relations: loadedRelations } = useCustomer(id || "");
  const relations = loadedRelations.relations;
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const { setCenter, fitView, viewportInitialized } = useReactFlow();
  const [selection, setSelection] = useRecoilState(CustomerGraphSelectionAtom);

  const nodeTypes = useMemo(
    () => ({ customer: NodeComponent, invisible: NodeInvisible }),
    []
  );

  useOnSelectionChange({
    onChange: ({ nodes, edges }) => {
      if (nodes && nodes[0]?.data?.customer?.id) {
        setSelection({
          ...selection,
          selectedRelations: [],
          selectedCustomer: nodes[0].data.customer,
        });
      }
      if (edges && edges[0]?.data?.relations?.length) {
        setSelection({
          ...selection,
          selectedRelations: edges[0].data.relations,
        });
      }
    },
  });

  useControlledEffect(() => {
    const nodes: Node[] = _.uniqBy(
      [
        {
          customer: customer?.details.customer!,
          risk: customer?.details.overall_risk,
        },
        ...relations.map((r) => r.child),
        ...relations.map((r) => r.parent),
      ],
      (c) => c.customer.id
    ).map((relatedCustomer) => ({
      id: relatedCustomer.customer.id,
      type: "customer",
      className: "transition-all duration-200",
      position: { x: 0, y: 0 },
      data: {
        known: loadedRelations?.known?.includes(relatedCustomer.customer.id),
        label: extractCustomerFullName(relatedCustomer.customer),
        ...relatedCustomer,
      },
    }));

    const edges: Edge[] = Object.values(
      _.groupBy(relations, (a) =>
        [a.child.customer.id, a.parent.customer.id].sort().join(",")
      )
    ).map((r) => ({
      id: r[0].relation_external_id,
      source: r[0].parent.customer.id,
      target: r[0].child.customer.id,
      type: "simplebezier",
      label: r
        .map((a) => RELATIONSHIP[a.relation_type] || "Unknown")
        .join(", "),
      data: {
        relations: r,
      },
    }));

    const res = computePositions(
      nodes,
      edges,
      { w: NODE_WIDTH, h: NODE_HEIGHT, px: 200, py: 50 },
      []
    );

    nodes.forEach((n) => {
      const pos = res.nodesPosition.find((r) => r.id === n.id);
      if (pos) {
        n.position.x = pos.x;
        n.position.y = pos.y;
      }
    });

    //Add invisible nodes to signify unexplored nodes
    nodes.forEach((n) => {
      if (loadedRelations.known.includes(n.id)) return;
      nodes.push({
        id: n.id + "_invisible_1",
        type: "invisible",
        position: { x: n.position.x + 215, y: n.position.y - 10 },
        data: {},
      });
      nodes.push({
        id: n.id + "_invisible_2",
        type: "invisible",
        position: { x: n.position.x + 215, y: n.position.y + 10 + NODE_HEIGHT },
        data: {},
      });
      edges.push({
        id: n.id + "_invisible_1",
        source: n.id,
        target: n.id + "_invisible_1",
        type: "simplebezier",
        className: "opacity-25",
        data: {},
      });
      edges.push({
        id: n.id + "_invisible_2",
        source: n.id,
        target: n.id + "_invisible_2",
        type: "simplebezier",
        className: "opacity-25",
        data: {},
      });
    });

    setNodes(nodes);
    setEdges(edges);

    setTimeout(() => {
      const { x, y } = (
        nodes.find((n) => n.id === selection?.selectedCustomer?.id) || nodes[0]
      )?.position || { x: undefined, y: undefined };
      setCenter(x + NODE_WIDTH / 2, y + NODE_HEIGHT / 2, {
        duration: 200,
        zoom: 0.75,
      });
    }, 50);
  }, [relations, setCenter, selection.selectedCustomer?.id]);

  useEffect(() => {
    if (viewportInitialized) {
      setTimeout(() => {
        fitView({ maxZoom: 0.75, minZoom: 0.75, duration: 200 });
      }, 200);
    }
  }, [viewportInitialized, fitView]);

  return (
    <div className="grow" style={{ height: 400 }}>
      <ReactFlow
        nodeTypes={nodeTypes}
        nodesDraggable={false}
        nodesConnectable={false}
        draggable={false}
        nodes={nodes || []}
        edges={edges || []}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        minZoom={0.75}
        maxZoom={0.75}
      >
        <Background />
        <Controls
          showInteractive={false}
          onFitView={() =>
            fitView({
              padding: 0.5,
              maxZoom: 0.75,
              minZoom: 0.75,
              duration: 200,
            })
          }
        />
      </ReactFlow>
    </div>
  );
};

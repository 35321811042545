import { useCustomFields } from "@features/custom-fields/state/use-custom-fields";
import toast from "react-hot-toast";

export const useAllFields = () => {
  const { fields } = useCustomFields();
  const customerFields = (fields || []).filter(
    (a) => a.field_source === 2 || a.field_source === 4 || a.field_source === 3
  );
  const relationsFields = [
    ...(fields || []).filter(
      (a) => a.field_source === 5 || a.field_source === 6
    ),
    {
      label: "parent_customer_external_id",
      field_type: 4,
      field_source: 5,
    },
    {
      label: "child_customer_external_id",
      field_type: 4,
      field_source: 5,
    },
  ];
  return { customerFields, relationsFields };
};

export const validateContent = (
  mode: "relations" | "customers",
  data: any[],
  mapping: any
) => {
  console.log(mapping, data[0]);
  return !data?.some((row, i) => {
    const index = i + 1;
    //Test mandatory fields
    if (
      (mode === "customers" &&
        (!row[mapping["external_id"]] || !row[mapping["account_number"]])) ||
      (mode === "relations" &&
        (!row[mapping["relation_external_id"]] ||
          !row[mapping["child_customer_external_id"]] ||
          !row[mapping["parent_customer_external_id"]]))
    ) {
      if (mode === "customers")
        toast.error(
          `The external_id and account_number fields are mandatory for each row. Please check the row ${JSON.stringify(
            index
          )}`
        );
      else
        toast.error(
          `The relation_external_id/child_customer_external_id/parent_customer_external_id fields are mandatory for each row. Please check the row ${JSON.stringify(
            index
          )}`
        );
      return true;
    }

    //Test ISO3 format
    if (
      (row[mapping["nationality_code"]] &&
        !/^([A-Z]{3})$/.test(row[mapping["nationality_code"]])) ||
      (row[mapping["domicile_code"]] &&
        !/^([A-Z]{3})$/.test(row[mapping["domicile_code"]]))
    ) {
      toast.error(
        `The nationality_code/domicile_code field must be a valid ISO3 code. Please check the row ${JSON.stringify(
          index
        )}`
      );
      return true;
    }

    //Test date format
    if (
      row[mapping["birth_date"]] &&
      !/^([0-9]{4})-([0-9]{2})-([0-9]{2})$/.test(row[mapping["birth_date"]])
    ) {
      toast.error(
        `The date_of_birth field must be a valid date (YYYY-MM-DD). Please check the row ${JSON.stringify(
          index
        )}`
      );
      return true;
    }

    //Test type format
    if (
      mode === "customers" &&
      !["F", "N"].includes(row[mapping["account_type"]])
    ) {
      toast.error(
        `The account_type field must be F or N. Please check the row ${JSON.stringify(
          index
        )}`
      );
      return true;
    }

    return false;
  });
};

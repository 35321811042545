import { useMutation, useQuery } from "react-query";
import { SessionsApiClient } from "../api-client/api-client";
import { SessionSettings } from "../types";
import { queryClient } from "@features/query";

export const useSessionsSettings = () => {
    const sessionsSettings = useQuery<SessionSettings>(["sessions", "settings"], () =>
        SessionsApiClient.getSessionsSettings()
    ); 

    const save = useMutation({
        mutationFn: (input: SessionSettings) => {
          return SessionsApiClient.updateSessionSettings(input);
        },
        onMutate: async () => {
          queryClient.invalidateQueries("sessions");
        },
      });

    return {
        sessionsSettings: sessionsSettings.data,
        loading: sessionsSettings.isLoading || save.isLoading,
        refresh: sessionsSettings.refetch,
        save: save.mutateAsync,
    };
};
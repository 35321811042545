import { SectionSmall } from "@atoms/text";
import { useDashboardTimeseries } from "@features/dashboard/state/hooks";
import { useSessionsLabels } from "@features/sessions-labels/state/use-sessions-labels";
import { formatTime } from "@features/utils/dates";
import { Line } from "react-chartjs-2";
import { getMetric } from "./utils";

const commonOptions = {
  responsive: true,
  animation: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  interaction: {
    mode: "nearest",
    intersect: false,
  },
  elements: {
    point: {
      radius: 0,
    },
  },
  scales: {
    y: {
      stacked: true,
      ticks: {
        precision: 0,
      },
      min: 0,
    },
    x: {
      display: false,
    },
  },
};

export const PastOutstandingTasks = (props: {
  scanEnabled: boolean;
  riskEnabled: boolean;
  onboardingEnabled: boolean;
  chatEnabled: boolean;
  kytEnabled: boolean;
}) => {
  const { data } = useDashboardTimeseries();
  const { labels: scenarioLabels } = useSessionsLabels();

  const neutralLabels = scenarioLabels
    .filter((a) => a.type === "NEUTRAL")
    .map((a) => a.label.toLocaleLowerCase().replace(/[^a-z0-9]/gm, "_"));

  const labels = data.map((a) =>
    formatTime(a.date, "", {
      keepDate: false,
      keepSeconds: false,
      keepTime: false,
    })
  );

  return (
    <div className="mt-2 flex sm:flex-row flex-col justify-between w-full min-h-40 space-y-4 sm:space-y-0 sm:space-x-4">
      {props.riskEnabled && (
        <ChartContainer label={"Go!Risk"}>
          <Line
            data={{
              labels,
              datasets: [
                {
                  label: "Outstanding actions required",
                  data: data.map((a) =>
                    getMetric(a, "monitoringmetric_outstanding_tasks_risks")
                  ),
                  borderColor: "#EF4444",
                  backgroundColor: "#EF4444AA",
                  fill: true,
                },
                {
                  label: "Remaining actions required",
                  data: data.map(
                    (a) =>
                      getMetric(
                        a,
                        "monitoringmetric_gorisk_customersbyeddstate_required"
                      ) -
                      getMetric(a, "monitoringmetric_outstanding_tasks_risks")
                  ),
                  borderColor: "#FB923C",
                  backgroundColor: "#FB923CAA",
                  fill: true,
                },
              ],
            }}
            options={commonOptions as any}
          />
        </ChartContainer>
      )}
      {props.chatEnabled && (
        <ChartContainer label={"Go!Chat"}>
          <Line
            data={{
              labels,
              datasets: [
                {
                  label: "Outstanding open threads",
                  data: data.map((a) =>
                    getMetric(
                      a,
                      "monitoringmetric_outstanding_tasks_chat_threads"
                    )
                  ),
                  borderColor: "#EF4444",
                  backgroundColor: "#EF4444AA",
                  fill: true,
                },
                {
                  label: "Remaining open threads",
                  data: data.map(
                    (a) =>
                      getMetric(a, "monitoringmetric_gochat_threads_open") -
                      getMetric(
                        a,
                        "monitoringmetric_outstanding_tasks_chat_threads"
                      )
                  ),
                  borderColor: "#FB923C",
                  backgroundColor: "#FB923CAA",
                  fill: true,
                },
              ],
            }}
            options={commonOptions as any}
          />
        </ChartContainer>
      )}
      {props.scanEnabled && (
        <ChartContainer label={"Go!Scan"}>
          <Line
            data={{
              labels,
              datasets: [
                {
                  label: "Outstanding new alerts",
                  data: data.map((a) =>
                    getMetric(
                      a,
                      "monitoringmetric_outstanding_tasks_scan_alerts"
                    )
                  ),
                  borderColor: "#EF4444",
                  backgroundColor: "#EF4444AA",
                  fill: true,
                },
                {
                  label: "Remaining alerts",
                  data: data.map(
                    (a) =>
                      getMetric(a, "monitoringmetric_goscan_alerts_new") -
                      getMetric(
                        a,
                        "monitoringmetric_outstanding_tasks_scan_alerts"
                      )
                  ),
                  borderColor: "#FB923C",
                  backgroundColor: "#FB923CAA",
                  fill: true,
                },
              ],
            }}
            options={commonOptions as any}
          />
        </ChartContainer>
      )}
      {props.onboardingEnabled && (
        <ChartContainer label={"Go!Vid"}>
          <Line
            data={{
              labels,
              datasets: [
                {
                  label: "Outstanding actions required",
                  data: data.map((a) =>
                    getMetric(
                      a,
                      "monitoringmetric_outstanding_tasks_vid_sessions"
                    )
                  ),
                  borderColor: "#EF4444",
                  backgroundColor: "#EF4444AA",
                  fill: true,
                },
                {
                  label: "Remaining alerts",
                  data: data.map(
                    (a) =>
                      getMetric(
                        a,
                        "monitoringmetric_govid_sessionsbystatus_none"
                      ) +
                      neutralLabels.reduce(
                        (curr, acc) =>
                          curr +
                          getMetric(
                            a,
                            ("monitoringmetric_govid_sessionsbystatus_" +
                              acc) as any
                          ),
                        0
                      ) -
                      getMetric(
                        a,
                        "monitoringmetric_outstanding_tasks_vid_sessions"
                      )
                  ),
                  borderColor: "#FB923C",
                  backgroundColor: "#FB923CAA",
                  fill: true,
                },
              ],
            }}
            options={commonOptions as any}
          />
        </ChartContainer>
      )}
      {props.kytEnabled && (
        <ChartContainer label={"Go!KYT"}>
          <Line
            data={{
              labels,
              datasets: [
                {
                  label: "Outstanding pending transactions",
                  data: data.map(() => 0),
                  borderColor: "#EF4444",
                  backgroundColor: "#EF4444AA",
                  fill: true,
                },
                {
                  label: "Remaining pending transactions",
                  data: data.map(() => 0),
                  borderColor: "#FB923C",
                  backgroundColor: "#FB923CAA",
                  fill: true,
                },
              ],
            }}
            options={commonOptions as any}
          />
        </ChartContainer>
      )}
    </div>
  );
};

export const ChartContainer = ({
  children,
  label,
}: {
  label: string;
  children: React.ReactNode;
}) => {
  return (
    <div
      className="w-full p-4 flex flex-col justify-center items-center rounded border overflow-hidden"
      style={{
        boxSizing: "border-box",
      }}
    >
      <SectionSmall noColor className="block">
        {label}
      </SectionSmall>
      <>{children}</>
    </div>
  );
};

import { Button } from "@atoms/button/button";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import { ModalContent } from "@atoms/modal/modal";
import { SideModal } from "@atoms/modal/side-modal";
import { FocusedNodeAtom } from "@features/kyt/state/store";
import { useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { PostHocPatternModalAtom } from "../../saved-searches/component/modal-post-hoc-pattern";
import SQLQueryEditor from "./query-editor";

export const ExploreQueryRequestModalAtom = atom({
  key: "ExploreQueryRequestModalAtom",
  default: {
    open: false,
    requestCypher: undefined as string | undefined,
    requestSQL: undefined as string | undefined,
  },
});

export const ExploreTransactionsFilterModal = () => {
  const [queryRequest, setQueryRequest] = useRecoilState(
    ExploreQueryRequestModalAtom
  );

  return (
    <SideModal
      className="bg-slate-100 dark:bg-slate-900"
      open={queryRequest.open}
      onClose={() => setQueryRequest({ ...queryRequest, open: false })}
    >
      {queryRequest.open && <ExploreTransactionsFilterModalContent />}
    </SideModal>
  );
};

export const ExploreTransactionsFilterModalContent = () => {
  const [queryRequest, setQueryRequest] = useRecoilState(
    ExploreQueryRequestModalAtom
  );
  const [, setModal] = useRecoilState(PostHocPatternModalAtom);
  const [, setFocusedNode] = useRecoilState(FocusedNodeAtom);
  const [mode, setMode] = useState<"cypher" | "sql">("cypher");

  const [cypherForm, setCypherForm] = useState<string>();
  const [sqlForm, setSqlForm] = useState<string>();

  useEffect(() => {
    setCypherForm(queryRequest.requestCypher ?? "");
    setSqlForm(queryRequest.requestSQL ?? "");
  }, [queryRequest]);

  return (
    <ModalContent title="Query Builder">
      <div style={{ maxWidth: "800px", width: "100vw" }}>
        <div className="flex flex-row space-x-2">
          <Button
            theme={mode === "cypher" ? "primary" : "outlined"}
            onClick={() => setMode("cypher")}
          >
            Graph
          </Button>
          <Button
            theme={mode === "sql" ? "primary" : "outlined"}
            onClick={() => setMode("sql")}
          >
            SQL
          </Button>
        </div>
        {mode === "cypher" && (
          <>
            <InputLabel
              className="mt-4"
              label="Query (Cypher)"
              input={
                <Input
                  className="focus:z-10 rounded-r-none  min-h-[10rem]  rounded-t-none"
                  placeholder="MATCH (a:Node)-[e:TRANSACTION_ALL]->(b:Node) RETURN a, e, b;"
                  multiline
                  size="sm"
                  value={cypherForm}
                  onChange={(e) => {
                    setCypherForm(e.target.value);
                  }}
                />
              }
            />
          </>
        )}
        {mode === "sql" && (
          <InputLabel
            className="mt-4"
            label="Query (SQL)"
            input={
              <div className="rounded border-2 border-gray-100 ">
                <SQLQueryEditor
                  value={sqlForm ?? ""}
                  onChange={(value) => {
                    setSqlForm(value);
                  }}
                />
              </div>
            }
          />
        )}
        <div className="h-8"></div>
        <div className="flex flex-row  justify-end mt-4">
          <Button
            theme="primary"
            onClick={() => {
              setQueryRequest({
                open: false,
                requestCypher: mode === "cypher" ? cypherForm : undefined,
                requestSQL: mode === "sql" ? sqlForm : undefined,
              });
              setFocusedNode(null);
            }}
            className="justify-end"
          >
            Apply
          </Button>
        </div>
        <div className="flex flex-row  justify-end mt-4">
          <Button
            theme="outlined"
            onClick={() => {
              setModal({
                open: true,
                pattern: {
                  query: mode === "cypher" ? cypherForm : undefined,
                },
              });
            }}
            className="justify-end"
          >
            Save as preset
          </Button>
        </div>
      </div>
    </ModalContent>
  );
};

import { DayOfWeek, Schedule, ScheduledAccessData } from "./types";

export const defaultScheduledAccessData = (): ScheduledAccessData => {
  const defaultSchedule: Schedule = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  };
  return {
    ips: [],
    review_groups: [],
    schedule: defaultSchedule
  }
};


export const isDayFull = (schedule: Schedule, day: DayOfWeek) => {
  const total = (Math.pow(23, 2) + 23) / 2;
  return schedule[day].length === 24 && schedule[day].reduce((acc, time) => acc + time, 0) === total;
}
import { Button } from "@atoms/button/button";
import { Checkbox } from "@atoms/input/input-checkbox";
import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Input } from "@atoms/input/input-text";
import { PageBlock } from "@atoms/layout/page-block";
import Select from "@atoms/select";
import { Base, Info, InfoSmall } from "@atoms/text";
import { Question, QuestionType, ScenarioType } from "@features/scenarios/types";
import {
  QUESTION_TYPES,
  QUESTION_UPLOAD_FILE_FRAME_TYPES,
  QUESTION_UPLOAD_FILE_MODELS,
  SCENARIOS_LANGUAGES,
} from "@features/scenarios/utils";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ShieldCheckIcon
} from "@heroicons/react/24/outline";
import _ from "lodash";

export default function ScenarioQuestionForm(props: {
  scenario: ScenarioType;
  question: Question;
  expanded: boolean;
  last: boolean;
  onClick: () => void;
  onChange: (question: Question | null) => void;
}) {
  return (
    <PageBlock className="flex flex-col space-y-4">
      <div className="flex flex-row cursor-pointer">
        <div className="flex flex-col grow" onClick={() => props.onClick()}>
          <Info>Question {props.question.position + 1}</Info>
          <Base>{props.question.label}</Base>
          <InfoSmall>
            {props.question.description_localized[props.scenario.language]}
          </InfoSmall>
        </div>
        <div className="flex flex-row space-x-1">
          {props.question.position !== 0 && (
            <Button
              theme="secondary"
              size="sm"
              onClick={() => {
                props.onChange({
                  ...props.question,
                  position: props.question.position - 1,
                });
              }}
            >
              <ChevronUpIcon className="w-4 h-4" />
            </Button>
          )}
          {!props.last && (
            <Button
              theme="secondary"
              size="sm"
              onClick={() => {
                props.onChange({
                  ...props.question,
                  position: props.question.position + 1,
                });
              }}
            >
              <ChevronDownIcon className="w-4 h-4" />
            </Button>
          )}
        </div>
      </div>
      {props.expanded && (
        <div className="flex flex-col space-y-4">
          <div className="flex flex-row space-x-4">
            <InputLabel
              label="Type"
              input={
                <Select
                  value={props.question.type ?? ""}
                  onChange={(e) => {
                    props.onChange({
                      ...props.question,
                      type: e.target.value as QuestionType,
                    });
                  }}
                >
                  <option disabled value="">
                    Select type
                  </option>

                  {QUESTION_TYPES.map((type) => (
                    <option key={"type_" + type} value={type}>
                      {type
                        .split("_")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                    </option>
                  ))}
                </Select>
              }
            />
            <InputLabel
              className="grow"
              label="Label"
              input={
                <Input
                  required
                  value={props.question.label}
                  onChange={(e) => {
                    props.onChange({
                      ...props.question,
                      label: e.target.value,
                    });
                  }}
                  placeholder="Label"
                />
              }
            />
          </div>
          {props.question.type === "information" && (
            <div className="flex flex-col space-y-2 px-4 py-2 border-blue-300 bg-blue-50 border rounded-md">
              <Info>Identity settings</Info>
              <InputLabel
                className="grow"
                label="Possible answers (comma separated)"
                input={
                  <Input
                    required
                    value={(props.question.possible_answers ?? []).join(", ")}
                    onChange={(e) => {
                      props.onChange({
                        ...props.question,
                        possible_answers: e.target.value
                          .split(",")
                          .map((s) => s.trim()),
                      });
                    }}
                    placeholder="answer 1, answar 2"
                  />
                }
              />
              <InputLabel
                className="grow"
                label="Should not contain (comma seperated)"
                input={
                  <Input
                    required
                    value={(props.question.should_not_contain ?? []).join(", ")}
                    onChange={(e) => {
                      props.onChange({
                        ...props.question,
                        should_not_contain: e.target.value
                          .split(",")
                          .map((s) => s.trim()),
                      });
                    }}
                    placeholder="blocked 1, blocked 2"
                  />
                }
              />
            </div>
          )}
          {props.question.type === "file_upload" && (
            <div className="flex flex-col space-y-2 px-4 py-2 border-blue-300 bg-blue-50 border rounded-md">
              <Info>File upload settings</Info>
              <InputLabel
                label="Max pages"
                input={
                  <Input
                    required
                    value={props.question.max_pages ?? 0}
                    type="number"
                    onChange={(e) => {
                      props.onChange({
                        ...props.question,
                        max_pages: parseInt(e.target.value),
                      });
                    }}
                    placeholder="Max pages"
                  />
                }
              />
              <div className="flex flex-row space-x-2">
                <InputLabel
                  label="Frame Type"
                  input={
                    <Select
                      value={props.question.frame_type ?? "noframe"}
                      placeholder="Select Frame Type"
                      onChange={(e) => {
                        props.onChange({
                          ...props.question,
                          frame_type: e.target.value,
                        });
                      }}
                    >
                      <option disabled value="">
                        Select Frame
                      </option>

                      {Object.keys(QUESTION_UPLOAD_FILE_FRAME_TYPES).map(
                        (type) => (
                          <option key={"frame_type_" + type} value={type}>
                            {QUESTION_UPLOAD_FILE_FRAME_TYPES[type]}
                          </option>
                        )
                      )}
                    </Select>
                  }
                />
                <InputLabel
                  className="grow"
                  label="Detect and extract Models"
                  input={
                    <SelectMultiple
                      value={props.question.extract_models ?? []}
                      options={[
                        ..._.sortBy(
                          Object.keys(QUESTION_UPLOAD_FILE_MODELS).map(
                            (model) => ({
                              value: model,
                              label: QUESTION_UPLOAD_FILE_MODELS[model],
                            })
                          ),
                          "label"
                        ),
                      ]}
                      onChange={(e) => {
                        props.onChange({
                          ...props.question,
                          extract_models: e,
                        });
                      }}
                    />
                  }
                />
              </div>
            </div>
          )}
          <InputLabel
            className="grow"
            label="Question Text"
            input={
              <div className="flex flex-col space-y-1">
                <div
                  key={"displayed_text_" + props.scenario.language}
                  className="flex flex-row space-x-2 items-center"
                >
                  <div className="w-24">
                    <Info className="flex flex-row items-center">
                      <ShieldCheckIcon className="w-4 h-4 mr-1" />
                      {SCENARIOS_LANGUAGES[props.scenario.language]}
                    </Info>
                  </div>
                  <Input
                    className="grow"
                    required
                    value={
                      props.question.description_localized[
                        props.scenario.language
                      ] ?? ""
                    }
                    onChange={(e) => {
                      props.onChange({
                        ...props.question,
                        description_localized: {
                          ...props.question.description_localized,
                          [props.scenario.language]: e.target.value,
                        },
                      });
                    }}
                    placeholder="Question Text"
                  />
                </div>
                {props.scenario.languages
                  .filter((lang) => lang !== props.scenario.language)
                  .map((lang) => (
                    <div
                      key={"displayed_text_" + lang}
                      className="flex flex-row space-x-2 items-center"
                    >
                      <div className="w-24">
                        <Info>{SCENARIOS_LANGUAGES[lang]}</Info>
                      </div>
                      <Input
                        className="grow"
                        required
                        value={props.question.description_localized[lang] ?? ""}
                        onChange={(e) => {
                          props.onChange({
                            ...props.question,
                            description_localized: {
                              ...props.question.description_localized,
                              [lang]: e.target.value,
                            },
                          });
                        }}
                        placeholder="Question Text"
                      />
                    </div>
                  ))}
              </div>
            }
          />
          <InputLabel
            label="Coefficient"
            input={
              <Input
                required
                value={props.question.coefficient ?? 0}
                type="number"
                onChange={(e) => {
                  props.onChange({
                    ...props.question,
                    coefficient: parseInt(e.target.value),
                  });
                }}
                placeholder="Coefficient"
              />
            }
          />
          <Checkbox
            label="Eliminatory Question"
            value={props.question.eliminatory}
            onChange={(s) => {
              props.onChange({
                ...props.question,
                eliminatory: s,
              });
            }}
          />
          <Button
            theme="danger"
            className="w-fit self-end"
            size="sm"
            onClick={() => {
              props.onChange(null);
            }}
          >
            Delete Question
          </Button>
        </div>
      )}
    </PageBlock>
  );
}

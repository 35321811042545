import { Badge } from "@atoms/badge";
import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { Page } from "@atoms/layout/page";
import { Base, Info, InfoSmall, Title } from "@atoms/text";
import { Table } from "@components/table";
import { useDashboard } from "@features/dashboard/state/hooks";
import { TransactionFiltersAtom } from "@features/kyt/state/store";
import { useTransactions } from "@features/kyt/state/use-transactions";
import {
  KYT_TRANSACTION_STATES_COLORS,
  KYT_TRANSACTION_STATES_LABELS,
} from "@features/kyt/utils";
import { ROUTES } from "@features/routes";
import { formatTime } from "@features/utils/dates";
import { BuildingOfficeIcon, UserIcon } from "@heroicons/react/24/outline";
import SessionScore from "@views/client/vid/sessions/components/session-score";
import _ from "lodash";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useHasAccess } from "@features/auth/state/use-access";
import { TransactionEditionModalAtom } from "./edition-modal";
import { KytTransactionsListFilter } from "./filters";
import { AssigneeTag } from "@components/assignations/tag";
import { useAssignments } from "@features/assignments/state/use-assignment";
import { formatAmount } from "@features/utils/strings";

export default function LiveTransactionsPage() {
  const [filters, setFilters] = useRecoilState(TransactionFiltersAtom);
  const { kyt } = useDashboard();
  const { transactions, refresh, loading } = useTransactions();
  const hasAccess = useHasAccess();
  const [, setTransactionModal] = useRecoilState(TransactionEditionModalAtom);
  useAssignments(
    "transaction",
    transactions.data?.map((transaction) => transaction.id) || []
  );

  useEffect(() => {
    refresh(filters);
  }, [filters, refresh]);

  return (
    <Page>
      {hasAccess("KYT_MANAGE") && (
        <Button
          className="float-right"
          theme="primary"
          onClick={() =>
            setTransactionModal({
              open: true,
              transaction: undefined,
            })
          }
          size="sm"
        >
          Add transaction
        </Button>
      )}
      <Title className="flex-row flex space-x-2 items-center">
        <div className="flex-row flex space-x-2">Transactions</div>
        <div className="flex-row flex space-x-2">
          {(kyt?.last_30_days_transactions_by_status
            ?.delayed_transactions_count ?? 0) !== 0 && (
            <Badge className="bg-yellow-500">
              {
                kyt?.last_30_days_transactions_by_status
                  ?.delayed_transactions_count
              }{" "}
              Pending
            </Badge>
          )}
        </div>
      </Title>
      <div className="mt-4"></div>
      <KytTransactionsListFilter
        value={filters}
        onChange={(value) =>
          setFilters({
            ...filters,
            ..._.omit(value, "on_page", "per_page"),
          })
        }
      />
      <br />
      <Table
        rowIndex="external_id"
        total={transactions.total ?? 0}
        initialPagination={{
          page: 1,
          perPage: filters.per_page,
        }}
        data={transactions.data || []}
        columns={[
          {
            title: "#",
            className: "overflow-hidden text-ellipsis",
            thClassName: "w-40",
            render: (row) => <Info>{row.external_id}</Info>,
          },
          {
            title: "From",
            render: (row) => (
              <div className="flex items-center">
                {row.from.account_type === "F" ? (
                  <BuildingOfficeIcon className="h-5 w-5 inline mr-2 shrink-0" />
                ) : (
                  <UserIcon className="h-5 w-5 inline mr-2 shrink-0" />
                )}
                {row.from.full_name}
              </div>
            ),
          },
          {
            title: "To",
            render: (row) => (
              <div className="flex items-center">
                {row.to.account_type === "F" ? (
                  <BuildingOfficeIcon className="h-5 w-5 inline mr-2 shrink-0" />
                ) : (
                  <UserIcon className="h-5 w-5 inline mr-2 shrink-0" />
                )}
                {row.to.full_name}
              </div>
            ),
          },
          {
            title: "Amount",
            render: (row) => (
              <div className="flex flex-col items-end w-full font-mono">
                <Base>
                  {formatAmount(row.amount, row.currency)}{" "}
                  <Info>{row.currency}</Info>
                </Base>
                {row.converted_currency !== row.currency && (
                  <Info>
                    ~{" "}
                    {formatAmount(row.converted_amount, row.converted_currency)}
                    <InfoSmall className="ml-2">
                      {row.converted_currency}
                    </InfoSmall>
                  </Info>
                )}
              </div>
            ),
          },
          {
            title: "Date",
            className: "overflow-hidden text-ellipsis text-right justify-end",
            thClassName: "w-70",
            render: (row) => (
              <Info>
                {formatTime(row.date, undefined, {
                  keepDate: true,
                  keepTime: true,
                })}
              </Info>
            ),
          },

          {
            title: "Score",
            className: "overflow-hidden text-ellipsis text-right justify-end",
            thClassName: "w-40",
            render: (row) => (
              <SessionScore
                text={row.score !== null ? row.score.toFixed(0) + " %" : "-"}
                status={
                  row.score === null
                    ? "slate"
                    : row.state === 1
                    ? "red"
                    : row.state === 2
                    ? "yellow"
                    : "green"
                }
              />
            ),
          },
          {
            title: "Status",
            thClassName: "w-40",
            render: (row) => (
              <div className="flex items-center flex-row capitalize whitespace-nowrap">
                <Tag
                  noColor
                  className={
                    "mx-1 my-1 text-white bg-" +
                    (KYT_TRANSACTION_STATES_COLORS[row.state] || "slate-500")
                  }
                >
                  {KYT_TRANSACTION_STATES_LABELS[row.state]}
                </Tag>
              </div>
            ),
          },
          {
            title: "",
            className: "text-right",
            headClassName: "justify-end",
            thClassName: "w-24",
            render: (row) => (
              <>
                <AssigneeTag type="transaction" id={row.id} />
              </>
            ),
          },
          {
            title: "Actions",
            className: "text-right",
            headClassName: "justify-end",
            thClassName: "w-24",
            render: (row) => (
              <Link to={ROUTES.TransactionView.replace(":id", row.id)}>
                <Button size="sm" theme="outlined">
                  View
                </Button>
              </Link>
            ),
          },
        ]}
        onRequestData={async (pagination) => {
          setFilters({
            ...filters,
            on_page: pagination.page,
            per_page: pagination.perPage,
          });
        }}
        loading={loading}
      />
    </Page>
  );
}

import { useGlobalEffect } from "@features/utils";
import { LoadingAtom } from "@features/utils/loading";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import { InboxSettingsApiClient } from "../api-client/api-client";
import { InboxSettingsAtom } from "./store";
import { InboxSettingsType } from "../types";

export const useInboxSettings = () => {
  const [settings, setSettings] = useRecoilState(InboxSettingsAtom);
  const [loading, setLoading] = useRecoilState(
    LoadingAtom("useInboxSettings")
  );

  const refresh = useCallback(async () => {
    setLoading(true);
    try {
      setSettings(await InboxSettingsApiClient.get());
    }
    catch (e) {
      setLoading(false);
    }
    setLoading(false);
  }, [setSettings, setLoading]);

  const save = useCallback(
    async (settings: InboxSettingsType) => {
      setLoading(true);
      try {
        await InboxSettingsApiClient.save(settings);
        toast.success("Saved Inbox settings");
      } catch (e) {
        console.error(e);
        toast.error("Failed to save Inbox Settings");
        setLoading(false);
        return false;
      }
      refresh();
      return true;
    },
    [refresh, setLoading]
  );

  useGlobalEffect(
    "useInboxSettings",
    () => {
      refresh();
    },
    []
  );

  return {
    settings: settings,
    save,
    loading,
    refresh,
  };
};

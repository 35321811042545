export const RISKS_COLORS: any = {
  ERR: "red-800",
  NA: "slate-400",
  L: "green-500",
  M: "yellow-500",
  H: "red-500",
};
export const RISKS_LABELS: any = {
  ERR: "Error",
  NA: "Undefined",
  L: "Low",
  M: "Medium",
  H: "High",
};

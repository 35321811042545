import { useFetch } from "@features/utils";
import { WebhookSettingsType } from "../types";

export class WebhookApiClient {
  static getWebhookSettings = async () => {
    const response = await useFetch(`/web/notifications/settings`, {
      method: "GET",
    });
    const data = await response.json();
    return data as WebhookSettingsType;
  };

  static updateWebhookSettings = async (settings: WebhookSettingsType) => {
    const response = await useFetch(`/web/notifications/settings`, {
      method: "POST",
      body: JSON.stringify(settings),
    });
    return response;
  };
}
import { LoadingAtom } from "@features/utils/loading";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import { PressApiClient } from "../api-client/api-client";
import { UpdatePressReportOptions } from "../types";
import { PressReportAtom, PressReportBodyAtom } from "./store";

export const usePressReport = (customerID: string) => {
  const [report, setReport] = useRecoilState(PressReportAtom(customerID));
  const [focusedReportBody, setFocusedReportBody] = useRecoilState(
    PressReportBodyAtom(customerID)
  );
  const [loading, setLoading] = useRecoilState(
    LoadingAtom("usePressReport-" + customerID)
  );

  const refresh = useCallback(async () => {
    setLoading(true);
    try {
      const report = await PressApiClient.getReportCompleteWithHistory(
        customerID
      );
      setReport(report);
    } catch (e) {
      toast.error("Error loading report details");
      console.log(e);
    }
    setLoading(false);
  }, [setReport, setLoading, customerID]);

  const update = useCallback(
    async (options: UpdatePressReportOptions) => {
      if (customerID) {
        setLoading(true);
        try {
          await PressApiClient.updateReportStatusAndRisk(customerID, options);
          await refresh();
          toast.success("Report successfully updated");
        } catch (error) {
          toast.error("Error while trying to update report");
        }
        setLoading(false);
      }
    },
    [setLoading, refresh, customerID]
  );

  const focusReportBody = useCallback(
    async (focusReportBodyID: string) => {
      setLoading(true);
      try {
        const reportBody = await PressApiClient.getReportBody(
          focusReportBodyID
        );
        setFocusedReportBody(reportBody);
      } catch (error) {
        toast.error("Error while trying to retrieve report body");
        setFocusedReportBody(null);
      }
      setLoading(false);
    },
    [setLoading]
  );

  const addNote = useCallback(
    async (content: string) => {
      setLoading(true);
      try {
        await PressApiClient.addNoteToReport(customerID, content);
        await refresh();
        toast.success("Note successfully added");
      } catch (error) {
        toast.error("Error while trying to add note to report");
      }
      setLoading(false);
    },
    [refresh, setLoading]
  );

  return {
    report,
    focusedReportBody,
    loading,
    refresh,
    update,
    focusReportBody,
    addNote,
  };
};

import { atom, atomFamily } from "recoil";
import { CustomMatrixType, MatrixEntryType } from "../types";

export const CustomMatrixAtom = atom<CustomMatrixType[]>({
  key: "CustomMatrixAtom",
  default: [],
});

export const CustomMatrixEntryAtom = atomFamily<MatrixEntryType[], string>({
  key: "CustomMatrixEntryAtom",
  default: [],
});

export const CustomMatrixEntrySortByAtom = atom<{
  key: keyof MatrixEntryType;
  order: "ASC" | "DESC";
}>({
  key: "CustomMatrixEntrySortByAtom",
  default: {
    key: "label",
    order: "ASC",
  },
});

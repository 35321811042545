import { Button } from "@atoms/button/button";
import InputDate from "@atoms/input/input-date";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { PageBlock } from "@atoms/layout/page-block";
import { Info } from "@atoms/text";
import { AuditApiClient } from "@features/audit/api-client/api-client";
import { AuditExportFilters } from "@features/audit/types";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

export default function SessionsExport() {
  const [filter, setFilter] = useState<
    Omit<AuditExportFilters, "with_revisions">
  >({
    from_date: new Date(Date.now() - 1000 * 60 * 60 * 24 * 30).toISOString(),
    to_date: new Date().toISOString(),
  });
  const [loadingExport, setLoadingExport] = useState(false);
  const [areDatesSelected, setAreDatesSelected] = useState(false);
  const [toDateGreaterThanFromDate, setToDateGreaterThanFromDate] =
    useState(true);

  useEffect(() => {
    // Check dates are selected
    setAreDatesSelected(filter.from_date !== "" && filter.to_date !== "");

    // Check that selected to_date >= selected from_date
    if (areDatesSelected) {
      setToDateGreaterThanFromDate(
        new Date(filter.to_date) >= new Date(filter.from_date) ? true : false
      );
    } else {
      setToDateGreaterThanFromDate(true);
    }
  }, [filter, areDatesSelected]);

  return (
    <PageBlock>
      <div className="flex flex-row align-bottom relative">
        <InputLabel
          className="mr-2 "
          label="From Date"
          input={
            <InputDate
              value={filter.from_date}
              onChange={(e) => {
                setFilter({ ...filter, from_date: e?.toISOString() || "" });
              }}
            />
          }
        />
        <InputLabel
          className="mr-2"
          label="To Date"
          input={
            <InputDate
              value={filter.to_date}
              onChange={(e) => {
                setFilter({ ...filter, to_date: e?.toISOString() || "" });
              }}
            />
          }
        />

        <Button
          loading={loadingExport}
          disabled={!(areDatesSelected && toDateGreaterThanFromDate)}
          className="absolute right-0 self-center"
          onClick={async () => {
            setLoadingExport(true);
            try {
              const data = await AuditApiClient.exportSessionsCSVZipped(filter);
              const fileURL = URL.createObjectURL(data);
              const link = document.createElement("a");
              link.href = fileURL;
              // prettier-ignore
              const fileName = "audit-export-sessions" 
                + (filter.from_date !== "" ? "-from-" + filter.from_date.substring(0, 10).replaceAll("-", "") : "")
                + (filter.to_date !== "" ? "-to-" + filter.to_date.substring(0, 10).replaceAll("-", "") : "")
                + ".zip";
              // prettier-ignore
              link.setAttribute("download", fileName);
              document.body.appendChild(link);
              link.click();
            } catch (e) {
              console.error(e);
              toast.error(
                "An error has occurred while retrieving sessions, please try again later"
              );
            }
            setLoadingExport(false);
          }}
        >
          Export
        </Button>
      </div>
      {!areDatesSelected && (
        <Info noColor className="text-red-400">
          {"Both dates must be selected"}
        </Info>
      )}
      {!toDateGreaterThanFromDate && (
        <Info noColor className="text-red-400">
          {"'To Date' must be >= than 'From Date'"}
        </Info>
      )}
    </PageBlock>
  );
}

import { Tag } from "@atoms/badge/tag";
import { Dropdown } from "@atoms/dropdown";
import Link from "@atoms/link";
import { Loader } from "@atoms/loader";
import { useHasAccess } from "@features/auth/state/use-access";
import { InboxApiClient } from "@features/inbox/api-client/api-client";
import { InboxMessageAttachment } from "@features/inbox/types";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  ClipboardDocumentCheckIcon,
  ShieldExclamationIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import { getFileType } from "@views/client/customers/customer-details/document-manager";
import { useSetRecoilState } from "recoil";
import { UploadInboxAttachmentToCustomersModalAtom } from "../../components/modals/upload-attachment-to-customers-modal";

export const InboxAttachment = (props: {
  file: InboxMessageAttachment;
  threadId: string;
}) => {
  const hasAccess = useHasAccess();
  const { icon, color } = getFileType(props.file);
  const url = InboxApiClient.getAttachmentDownloadRoute(props.file);
  const setAttachmentTransferModal = useSetRecoilState(
    UploadInboxAttachmentToCustomersModalAtom
  );

  return (
    <div
      className="inline-flex flex-row items-center mr-1 mb-1"
      style={{ lineHeight: 0 }}
    >
      <Link
        onClick={
          !props.file.file_not_dangerous
            ? () => {
                if (
                  window.confirm(
                    "This file is potentially dangerous. Are you sure you want to open/download it?"
                  )
                )
                  window.open(url, "_blank");
              }
            : undefined
        }
        target="_blank"
        href={props.file.file_not_dangerous ? url : undefined}
      >
        <Tag
          noColor
          ellipsis
          className={
            "h-6 rounded-r-none inline-flex flex-row items-center cursor-pointer hover:opacity-75 bg-" +
            color +
            " " +
            (props.file.file_not_dangerous === true
              ? "text-white"
              : "text-red-300")
          }
          key={props.file.id}
        >
          {props.file.file_not_dangerous === null && (
            <>
              <Loader />{" "}
            </>
          )}
          {icon({
            className: "w-4 h-4 inline relative mr-px -ml-px",
          })}
          {props.file.file_not_dangerous === false && (
            <>
              <ShieldExclamationIcon className="w-4 h-4 inline relative -top-px mx-px mr-1" />
              [dangerous file detected]{" "}
            </>
          )}
          {props.file.file_name}
        </Tag>
      </Link>

      {props.file.attachment_movements?.movements?.length && (
        <div
          data-tooltip="Added to customer(s) documents"
          className={
            "flex items-center text-white h-6 px-1 inline ml-px bg-" + color
          }
        >
          <ClipboardDocumentCheckIcon className="w-4 h-4 inline" />
        </div>
      )}

      <Dropdown
        className={
          "cursor-pointer flex items-center text-white h-6 px-1 inline ml-px rounded-r hover:opacity-75 bg-" +
          color
        }
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        options={[
          ...(hasAccess("CUSTOMER_UPDATE")
            ? [
                {
                  onClick: () => {
                    setAttachmentTransferModal({
                      open: true,
                      attachment: props.file,
                      threadId: props.threadId,
                    });
                  },
                  icon: (p: any) => <UserPlusIcon {...p} />,
                  label: "Add to customer",
                },
              ]
            : []),
        ]}
      >
        <ChevronDownIcon className="w-4 h-4" />
      </Dropdown>
    </div>
  );
};

import { queryClient } from "@features/query";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import { ScenariosApiClient } from "../api-client/api-client";
import { ScenarioListType } from "../types";
import { useHasAccess } from "@features/auth/state/use-access";

export const useScenarios = () => {
  const hasAccess = useHasAccess();
  const scenarios = useQuery<{ data: ScenarioListType[]; total: number }>(
    ["scenarios"],
    () =>
      hasAccess("SIDENAV_SESSION")
        ? ScenariosApiClient.getScenarios()
        : { data: [], total: 0 }
  );

  const create = useMutation({
    mutationFn: async (input: Partial<ScenarioListType>) => {
      await ScenariosApiClient.create({
        client_id: 0,
        language: input.language!,
        label: input.label!,
        code: input.code!,
        enabled: false,
        randomized: false,
        questions: [],
        score_threshold: 0,
        status: [],
      });
    },
    onMutate: async () => {
      queryClient.invalidateQueries("scenarios");
    },
  });

  const deleteScenario = useMutation({
    mutationFn: async (id: number) => {
      await ScenariosApiClient.delete(id);
      toast.success("Scenario deleted successfully");
    },
    onError: (error) => {
      toast.error("Failed to delete scenario");
      throw error;
    },
    onMutate: async () => {
      queryClient.invalidateQueries("scenarios");
    },
  });

  return {
    scenarios: scenarios.data,
    loading:
      scenarios.isLoading || deleteScenario.isLoading || create.isLoading,
    refresh: scenarios.refetch,
    create: create.mutateAsync,
    deleteScenario: deleteScenario.mutateAsync,
  };
};

import { useDashboardTimeseries } from "@features/dashboard/state/hooks";
import { formatTime } from "@features/utils/dates";
import _ from "lodash";
import { Line } from "react-chartjs-2";
import { commonProductsOptions, getMetric } from "./utils";
import { CUSTOMERS_EDD_STATES_COLORS } from "@features/customers/utils";

export const PastRisk = () => {
  const { data } = useDashboardTimeseries();

  const labels = data.map((a) =>
    formatTime(a.date, "", {
      keepDate: false,
      keepSeconds: false,
      keepTime: false,
    })
  );

  const categories = [
    "error",
    "required",
    "not_required",
    "pending",
    "failed",
    "passed_1_month",
    "passed_3_months",
    "passed_6_months",
    "passed_12_months",
    "passed_24_months",
    "passed_36_months",
    "undefined",
  ];

  const colors = [
    CUSTOMERS_EDD_STATES_COLORS[2],
    CUSTOMERS_EDD_STATES_COLORS[3],
    CUSTOMERS_EDD_STATES_COLORS[4],
    CUSTOMERS_EDD_STATES_COLORS[5],
    CUSTOMERS_EDD_STATES_COLORS[6],
    CUSTOMERS_EDD_STATES_COLORS[7],
    CUSTOMERS_EDD_STATES_COLORS[8],
    CUSTOMERS_EDD_STATES_COLORS[9],
    CUSTOMERS_EDD_STATES_COLORS[10],
    CUSTOMERS_EDD_STATES_COLORS[11],
    CUSTOMERS_EDD_STATES_COLORS[12],
    "#AAAAAA",
  ];

  return (
    <div className="mt-2 flex sm:flex-row flex-col justify-between w-full h-72 space-y-4 sm:space-y-0 sm:space-x-4">
      <Line
        data={{
          labels,
          datasets: [
            ...categories.map((c, i) => ({
              label: _.upperFirst(c.replace(/_/g, " ")),
              data: data.map((a) =>
                getMetric(
                  a,
                  ("monitoringmetric_gorisk_customersbyeddstate_" + c) as any
                )
              ),
              borderColor: colors[i],
              backgroundColor: colors[i] + "EE",
              fill: true,
            })),
          ],
        }}
        options={commonProductsOptions as any}
      />
    </div>
  );
};

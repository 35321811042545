import { Button } from "@atoms/button/button";
import { PageBlock, PageBlockHr } from "@atoms/layout/page-block";
import Select from "@atoms/select";
import { Info, SectionSmall } from "@atoms/text";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { ReactNode, useState } from "react";

export const SessionBlock = (props: {
  id?: string;
  title: string | ReactNode;
  subtitle: string | ReactNode;
  tries?: number;
  render: (tryIndex?: number) => ReactNode;
  suffix?: ReactNode;
}) => {
  const [tries, setTries] = useState<number>((props.tries || 0) - 1);
  const [visible, setVisible] = useState<boolean>(true);

  return (
    <PageBlock>
      <div
        id={props.id}
        className="flex flex-row justify-between space-x-2 items-center"
      >
        <div className="grow">
          <SectionSmall>{props.title}</SectionSmall>
        </div>
        <div className="shrink-0">{props.suffix}</div>
        {(props.tries || 0) > 1 && (
          <div className="shrink-0">
            <Select
              size="sm"
              value={tries}
              onChange={(e) => setTries(Number(e.target.value))}
            >
              {Array.from(Array(props.tries).keys(), (_, i) => (
                <option key={i} value={i}>
                  Try {i + 1} of {props.tries}
                </option>
              ))}
            </Select>
          </div>
        )}
        <Button
          data-tooltip="Show/Hide details"
          theme="default"
          size="sm"
          className="shrink-0 px-1 py-1 w-6 h-6 -mr-2"
          onClick={() => setVisible(!visible)}
        >
          {visible && <ChevronUpIcon className="h-4 w-4" />}
          {!visible && <ChevronDownIcon className="h-4 w-4" />}
        </Button>
      </div>
      <Info>{props.subtitle}</Info>
      <div className={visible ? "block" : "hidden"}>
        <PageBlockHr />
        <div className="flex flex-col gap-2 py-2">{props.render(tries)}</div>
      </div>
    </PageBlock>
  );
};

import { ButtonConfirm } from "@atoms/button/confirm";
import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Modal, ModalContent } from "@atoms/modal/modal";
import {
  InboxThread,
  InboxThreadCreateInput,
  InboxThreadStatus,
} from "@features/inbox/types";
import { useMessagesLabels } from "@features/messages-labels/state/use-messages-labels";
import { useControlledEffect } from "@features/utils";
import { uniqBy } from "lodash";
import { useState } from "react";
import toast from "react-hot-toast";
import CustomerSearchInput from "../customer-search-input";

export default function ThreadModal(props: {
  open: boolean;
  onSave: (input: InboxThreadCreateInput) => Promise<void>;
  onClose: () => void;

  thread?: InboxThread;
}) {
  const { labels: tags, loading } = useMessagesLabels();

  const [thread, setThread] = useState<Partial<InboxThreadCreateInput>>();
  const [threadCustomers, setThreadCustomers] = useState<string[]>([]);

  useControlledEffect(() => {
    initThread();
  }, [props.open]);

  const initThread = () => {
    if (props.thread) {
      setThread({
        customer_ids: (props.thread.customers || []).map((c) => c.id),
        reminder: props.thread.reminder,
        status: props.thread.status,
        tag_codes: props.thread.tags,
      });
      setThreadCustomers((props.thread.customers || []).map((a) => a.id));
    } else {
      setThread({
        customer_ids: [],
        reminder: null,
        status: InboxThreadStatus.OPEN,
        tag_codes: [],
      });
      setThreadCustomers([]);
    }
  };

  if (loading) return <></>;

  return (
    <Modal className="min-w-[40vw]" open={props.open} onClose={props.onClose}>
      <ModalContent title={props.thread ? "Edit Thread" : "New Thread"}>
        <div id="thread-bloc" className="flex flex-col space-y-3 mb-4">
          <InputLabel
            label="Customers"
            className="z-50"
            input={
              <CustomerSearchInput
                placeholder="Thread Customers"
                value={threadCustomers}
                onCustomersChange={(customers) => {
                  setThreadCustomers(customers);
                }}
              />
            }
          />
          <InputLabel
            label="Tags"
            input={
              <SelectMultiple
                className="z-10"
                options={uniqBy(
                  (tags ?? []).map((a) => ({
                    label: a.name,
                    value: a.code,
                  })),
                  "value"
                )}
                value={thread?.tag_codes ?? []}
                onChange={(tags) => {
                  setThread({
                    ...thread,
                    tag_codes: tags,
                  });
                }}
              />
            }
          />
        </div>

        <div id="actions" className="flex flex-row justify-end space-x-2">
          <ButtonConfirm
            confirm={thread?.status === InboxThreadStatus.CLOSED}
            confirmTitle={"Save thread"}
            className="mt-6 float-right"
            theme="primary"
            // loading={loading}
            disabled={threadCustomers.length === 0}
            onClick={async () => {
              thread!.customer_ids = threadCustomers;
              try {
                await props.onSave(thread as InboxThreadCreateInput);
                props.onClose();
              } catch (e) {
                toast.error("Error while saving thread");
              }
            }}
          >
            Save
          </ButtonConfirm>
        </div>
      </ModalContent>
    </Modal>
  );
}

import { Alert } from "@atoms/alert";
import Avatar from "@atoms/avatar/avatar";
import { Button } from "@atoms/button/button";
import { Checkbox } from "@atoms/input/input-checkbox";
import { Loader } from "@atoms/loader";
import { Info } from "@atoms/text";
import { ModalSecurity } from "@components/security/modal-security";
import { Table } from "@components/table";
import { useAgents } from "@features/agents/state/use-agents";
import { useClientSettings } from "@features/clients/state/use-client-settings";
import { useWebAuthn } from "@features/webauthn/state/useWebAuthn";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import _ from "lodash";
import { useEffect, useState } from "react";

export function TwoFAConfiguration() {
  const { settings, isLoading, updateClientSettings } = useClientSettings();
  const { members, refresh } = useAgents();
  const { getDevicesQuery } = useWebAuthn();
  const { data: credentials } = getDevicesQuery();
  const [twoFAModal, setTwoFAModal] = useState<boolean>();
  const hasCredentials = credentials && credentials.length > 0;

  useEffect(() => {
    if (twoFAModal === false) {
      refresh();
    }
  }, [twoFAModal, refresh]);
  return (
    <div className="w-full p-4 border border-gray-200 rounded-sm bg-white">
      <Info>Enable this to force your user to connect with 2FA.</Info>
      <div className="flex flex-col gap-2 mt-4">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Checkbox
              label={"Enabled"}
              disabled={!hasCredentials}
              value={settings?.twofa_required}
              onChange={async (status: boolean) => {
                updateClientSettings({ twofa_required: status });
              }}
            />
            {!hasCredentials && (
              <div>
                <Alert
                  className="rounded-lg opacity-80"
                  icon={ExclamationCircleIcon}
                  theme="warning"
                  title={""}
                >
                  <div>
                    You need to setup your own 2FA before activating this option
                    for your agents.{" "}
                  </div>
                  <Button
                    theme="outlined"
                    size="sm"
                    onClick={() => setTwoFAModal(true)}
                  >
                    Setup 2FA
                  </Button>
                </Alert>
              </div>
            )}

            {settings?.twofa_required && (
              <div className="flex flex-col mt-4 sm:p-4 p-0 items-center">
                <Table
                  className="w-full"
                  showPagination={false}
                  data={_.sortBy(members, "name")}
                  columns={[
                    {
                      title: "",
                      render: (item) => (
                        <div className="flex items-center">
                          <Avatar
                            size={5}
                            avatar={item.avatar}
                            fallback={item.name}
                            className="h-5 w-5 mr-2"
                          />{" "}
                          {item.name}
                        </div>
                      ),
                    },
                    {
                      title: "",
                      render: (item) => (
                        <>
                          {item.has_two_fa ? (
                            <CheckIcon className="w-6 h-auto text-green-600" />
                          ) : (
                            <XMarkIcon className="w-6 h-auto text-red-600" />
                          )}
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </>
        )}
      </div>
      <ModalSecurity onClose={() => setTwoFAModal(false)} open={!!twoFAModal} />
    </div>
  );
}

import Link from "@atoms/link";
import { BaseSmall } from "@atoms/text";
import { InboxMessageCreateInput } from "@features/inbox/types";
import { fileToBase64 } from "@features/utils/compress";
import { PaperClipIcon } from "@heroicons/react/24/outline";
import { useRef } from "react";

export const MessageAttachments = ({
  message,
  onChange,
}: {
  message: Partial<InboxMessageCreateInput>;
  onChange: (message: Partial<InboxMessageCreateInput>) => void;
}) => {
  const fileRef = useRef<HTMLInputElement>(null);

  return (
    <div>
      <input
        type="file"
        onChange={async (e) => {
          const file = e.target.files?.[0];
          if (file) {
            const file_base64 = (await fileToBase64(file)).split(",")[1];
            onChange({
              ...message,
              attachments: [
                ...(message?.attachments ?? []),
                {
                  name: file.name,
                  file: file_base64,
                },
              ],
            });
          }
        }}
        className="hidden"
        ref={fileRef}
      />

      {message?.attachments?.map((a, i) => (
        <div key={i} className="flex flex-row space-x-2 items-center mb-1">
          <BaseSmall>
            {a.name + " "}
            <Link
              onClick={() => {
                onChange({
                  ...message,
                  attachments: message?.attachments?.filter(
                    (_, index) => index !== i
                  ),
                });
              }}
            >
              Remove
            </Link>
          </BaseSmall>
        </div>
      ))}

      <BaseSmall>
        <Link
          className="inline-flex flex-row items-center"
          onClick={() => {
            fileRef.current?.click();
          }}
        >
          <PaperClipIcon className="h-4 w-4 mr-1 -ml-1" /> Add attachment
        </Link>
      </BaseSmall>
    </div>
  );
};

import { atom } from "recoil";
import { ReportingSettingsType } from "../types";

export const ReportingSettingsAtom = atom<ReportingSettingsType>({
  key: "ReportingSettingsAtom",
  default: {
    outstanding_delay: {
      goscan: 0,
      gorisk: 0,
      govid: 0,
      gochat: 0,
    },
    reporting: [],
  },
});

import { ButtonConfirm } from "@atoms/button/confirm";

export default function RisksChangePanel({
  loading,
  onDiscard,
  onSave,
}: {
  loading?: boolean;
  onDiscard: () => void;
  onSave: () => void;
}) {
  return (
    <div className="flex flex-row space-x-2">
      <div className="m-auto">
        Modifications detected. Do you want to save them?
      </div>
      <ButtonConfirm
        loading={loading}
        confirmTitle="Discard modifications"
        confirmMessage="All changes will be lost"
        theme="outlined"
        onClick={() => {
          onDiscard();
        }}
      >
        Discard
      </ButtonConfirm>
      <ButtonConfirm
        loading={loading}
        confirmTitle="Save modifications"
        confirmMessage="This will be applied now"
        theme="primary"
        onClick={() => {
          onSave();
        }}
      >
        Save
      </ButtonConfirm>
    </div>
  );
}

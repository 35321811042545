import Card from "@atoms/card";
import { BaseSmall } from "@atoms/text";
import { NameSearchRequestOptions } from "@features/alerts-namesearch/types";
import { formatTime } from "@features/utils/dates";

export type NameSearchSummaryType = {
  total: number;
  showed_total: number;
  duration: number;
  started: number;
  ended: number;
  next: string | null;
  filters: Partial<NameSearchRequestOptions>;
};

export const NameSearchSummary = ({
  summary: resultMetadata,
}: {
  summary: NameSearchSummaryType;
}) => {
  return (
    <Card
      title={"Search summary"}
      text={
        <BaseSmall>
          {resultMetadata.showed_total} of {resultMetadata.total} results
          {!!resultMetadata.duration && (
            <> found in {resultMetadata.duration}ms</>
          )}{" "}
          <div className="flex flex-row mt-2">
            <div className="w-1/4 shrink-0">
              <b>Date: </b>{" "}
              {formatTime(resultMetadata.ended, undefined, {
                keepDate: true,
                keepTime: true,
              })}
            </div>
          </div>
          <div className="flex flex-row">
            <div className="w-1/4 shrink-0">
              <b>Type: </b>{" "}
              {resultMetadata.filters?.entity_type === 1 ? "Person" : "Company"}
            </div>
            <div className="ml-4 w-1/4">
              <b>Name: </b>{" "}
              {[
                resultMetadata.filters?.entity_type === 2
                  ? resultMetadata.filters?.company_input_data?.company_name
                  : "",
                resultMetadata.filters?.entity_type === 1
                  ? resultMetadata.filters?.person_input_data?.first_name
                  : "",
                resultMetadata.filters?.entity_type === 1
                  ? resultMetadata.filters?.person_input_data?.last_name
                  : "",
              ]
                .filter((a) => a)
                .join(" ")}
            </div>
          </div>
          <div className="flex flex-row">
            {resultMetadata.filters?.person_input_data?.date_of_birth && (
              <div className="mr-4 w-1/4 shrink-0">
                <b>DOB: </b>{" "}
                {resultMetadata.filters?.person_input_data?.date_of_birth}
              </div>
            )}
            {resultMetadata.filters?.entity_type === 2 &&
              !!resultMetadata.filters?.company_input_data?.countries
                ?.length && (
                <div className="mr-4 w-1/4 shrink-0">
                  <b>Countries: </b>{" "}
                  {resultMetadata.filters?.company_input_data?.countries.join(
                    ", "
                  )}
                </div>
              )}
            {resultMetadata.filters?.entity_type === 1 &&
              !!resultMetadata.filters?.person_input_data?.countries
                ?.length && (
                <div className="mr-4 w-1/4 shrink-0">
                  <b>Countries: </b>{" "}
                  {resultMetadata.filters?.person_input_data?.countries.join(
                    ", "
                  )}
                </div>
              )}
          </div>
          <div className="flex flex-row">
            {resultMetadata.filters?.post_filtering_alias !== null && (
              <div className="mr-4 w-1/4 shrink-0">
                <b>Filtering: </b>{" "}
                {resultMetadata.filters?.post_filtering_alias || "(Default)"}
              </div>
            )}
            {resultMetadata.filters?.post_filtering_alias === null && (
              <>
                <div className="mr-4 w-1/4 shrink-0">
                  <b>Fuzzy names: </b>{" "}
                  {resultMetadata.filters?.exact_match ? "No" : "Yes"}
                </div>
                <div className="mr-4 w-1/4 shrink-0">
                  <b>Fuzzy dates: </b>{" "}
                  {resultMetadata.filters?.person_input_data
                    ?.exact_date_of_birth
                    ? "No"
                    : "Yes"}
                </div>
              </>
            )}
          </div>
        </BaseSmall>
      }
    />
  );
};

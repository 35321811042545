import { is2XX, useFetch } from "@features/utils";
import {
  SaveScenarioInput,
  ScenarioListType,
  ScenarioType,
  ScenarioUpdateType,
} from "../types";
import { getAvailableLanguages } from "../utils";

export class ScenariosApiClient {
  static getScenarios = async () => {
    const response = await useFetch(`/web/scenario`, {
      method: "GET",
    });
    const data = await response.json();
    if (!is2XX(response.status)) {
      throw new Error("Failed to get scenarios: " + data?.errors[0]?.message);
    }
    return {
      data: (data?.scenarios || []) as ScenarioListType[],
      total: data?.total_number_of_results,
    };
  };

  static getScenario = async (id: number) => {
    const response = await useFetch(`/web/scenario/detail/${id}`, {
      method: "GET",
    });
    const data = await response.json();
    if (!is2XX(response.status)) {
      throw new Error("Failed to get scenario: " + data.errors[0]?.message);
    }
    return {
      ...(data as ScenarioType),
      languages: getAvailableLanguages(data as ScenarioType),
    };
  };

  static saveScenario = async (input: ScenarioUpdateType) => {
    const response = await useFetch(`/web/scenario`, {
      method: "PUT",
      body: JSON.stringify({
        ...input,
      }),
    });
    if (!is2XX(response.status)) {
      throw new Error("Failed to save scenario");
    }
  };

  static create = async (input: SaveScenarioInput) => {
    const response = await useFetch("/web/scenario", {
      method: "POST",
      body: JSON.stringify({
        ...input,
      }),
    });
    if (!is2XX(response.status)) {
      throw new Error("Failed to create scenario");
    }
  };

  static delete = async (id: number) => {
    const response = await useFetch(`/web/scenario/${id}`, {
      method: "DELETE",
    });
    if (!is2XX(response.status)) {
      throw new Error("Failed to delete scenario");
    }
  };
}

import { atom } from "recoil";
import { WebhookSettingsType } from "../types";

export const WebhookSettingsStateAtom = atom<WebhookSettingsType>({
    key: "WebhookSettingsStateAtom",
    default: {
        url: "",
        events: "",
        authorization: "",
    }
});
import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { SideModal } from "@atoms/modal/side-modal";
import { Table } from "@components/table";
import { Column } from "@components/table/table";
import { useHasAccess } from "@features/auth/state/use-access";
import { useCustomMatrix } from "@features/custom-matrix/state/use-custom-matrix";
import { CustomMatrixType } from "@features/custom-matrix/types";
import _ from "lodash";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { atom, useRecoilState } from "recoil";
import CustomMatrixEntries from "./custom-matrix-entries";

export const CustomMatricesModalAtom = atom<
  Partial<CustomMatrixType> & { open: boolean }
>({
  key: "CustomMatricesModalAtom",
  default: { open: false },
});

export const CustomMatricesTable = () => {
  const { matrices, loading, refresh, createMatrix, deleteMatrix, editMatrix } =
    useCustomMatrix();
  const [modal, setModal] = useRecoilState(CustomMatricesModalAtom);
  const [viewEntries, setViewEntries] = useState<CustomMatrixType>();
  const hasAccess = useHasAccess();

  useEffect(() => {
    refresh();
  }, [refresh]);

  const columns: Column<CustomMatrixType>[] = [
    {
      title: "Display name",
      render: (row) => <>{row.matrix_type}</>,
    },
    {
      title: "Actions",
      headClassName: "justify-end",
      className: "justify-end h-12",
      thClassName: "w-24",
      render: (row) => (
        <div className="flex flex-row space-x-2">
          {hasAccess("MATRIX_DELETE") && (
            <Button
              theme="default"
              size="sm"
              onClick={() => setModal({ open: true, ...row })}
            >
              Edit
            </Button>
          )}
          <Button
            theme="outlined"
            size="sm"
            onClick={() => setViewEntries(row)}
          >
            View values
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <SideModal
        className="bg-slate-100 dark:bg-slate-900"
        open={viewEntries !== undefined}
        onClose={() => {
          setViewEntries(undefined);
        }}
      >
        <CustomMatrixEntries
          matrix={viewEntries}
          onClose={() => setViewEntries(undefined)}
        />
      </SideModal>
      <Modal open={modal.open} onClose={() => setModal({ open: false })}>
        <ModalContent title="Add/Edit custom matrix">
          <InputLabel
            label="Display name"
            input={
              <Input
                placeholder="My Custom Matrix"
                value={modal.matrix_type || ""}
                onChange={(e) =>
                  setModal({ ...modal, matrix_type: e.target.value })
                }
              />
            }
          />

          <ButtonConfirm
            confirmTitle="Save custom matrix"
            confirmMessage="This will be applied now and have an effect on all ongoing risk scoring tasks or scanning tasks."
            className="mt-6 float-right"
            theme="primary"
            loading={loading}
            disabled={!modal.matrix_type}
            onClick={async () => {
              if (modal.id) {
                editMatrix(modal.id, modal.matrix_type!).then(() => {
                  setModal({ open: false });
                });
              } else {
                createMatrix(modal.matrix_type!).then(() => {
                  setModal({ open: false });
                });
              }
            }}
          >
            Save
          </ButtonConfirm>

          {matrices.find((m) => m.id === modal.id) && (
            <ButtonConfirm
              confirmTitle="Delete custom matrix"
              confirmMessage="This will be applied now and have an effect on all ongoing risk scoring tasks or scanning tasks."
              className="mt-6"
              theme="danger"
              loading={loading}
              onClick={async () => {
                deleteMatrix(modal.id!)
                  .then(() => {
                    setModal({ open: false });
                  })
                  .catch(() => {
                    toast.error("Unable to remove matrice");
                  });
              }}
            >
              Remove
            </ButtonConfirm>
          )}
        </ModalContent>
      </Modal>

      <Table
        data={_.sortBy(matrices, "matrix_type")}
        columns={columns}
        showPagination={false}
        loading={loading}
      />
    </>
  );
};

import { twMerge } from "tailwind-merge";
import { Shortcut, useShortcuts } from "@features/shortcuts";
import _ from "lodash";
import React from "react";

interface InputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement> &
      React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    "size"
  > {
  placeholder?: string;
  highlight?: boolean;
  theme?: "plain";
  label?: string;
  size?: "sm" | "md" | "lg";
  feedback?: string;
  hasError?: boolean;
  multiline?: boolean;
  inputComponent?: React.ReactNode;
  inputClassName?: string;
  className?: string;
  inputRef?: React.Ref<HTMLInputElement | HTMLTextAreaElement>;
  shortcut?: Shortcut[];
}

export const defaultInputClassName = () => {
  return "shadow-sm focus:ring-blue-600 focus:border-blue-600 block w-full text-sm border-gray-200 dark:bg-slate-900 dark:border-slate-700 dark:text-white rounded-lg";
};

export const errorInputClassName = () => {
  return (
    defaultInputClassName() +
    " bg-red-50 border-red-300 dark:bg-red-900 dark:border-red-800"
  );
};

export const Input = (props: InputProps) => {
  let inputClassName = props.hasError
    ? errorInputClassName()
    : defaultInputClassName();
  inputClassName = inputClassName + (props.disabled ? " opacity-75" : "");

  const internalRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>();
  const inputRef = props.inputRef || internalRef;

  if (props.highlight && props.value)
    inputClassName = inputClassName + " ring-2 ring-yellow-500";

  if (!props.multiline) {
    if (props.size === "lg") inputClassName = inputClassName + " h-11";
    else if (props.size === "sm") inputClassName = inputClassName + " h-7";
    else inputClassName = inputClassName + " h-9";
  }

  useShortcuts(
    !props.disabled && props.shortcut?.length ? [...props.shortcut] : [],
    () => {
      (inputRef as any)?.current?.focus();
    }
  );

  return (
    <>
      {props.inputComponent ||
        (props.multiline ? (
          <textarea
            ref={inputRef as React.Ref<HTMLTextAreaElement>}
            className={twMerge(
              inputClassName,
              props.inputClassName,
              props.className
            )}
            {..._.omit(
              props as any,
              "label",
              "inputClassName",
              "className",
              "value",
              "size",
              "multiline",
              "highlight"
            )}
            aria-placeholder={props["aria-placeholder"] || props.placeholder}
            value={props.value}
          />
        ) : (
          <input
            aria-placeholder={props["aria-placeholder"] || props.placeholder}
            ref={inputRef as React.Ref<HTMLInputElement>}
            type="text"
            className={twMerge(
              inputClassName,
              props.inputClassName,
              props.className
            )}
            {..._.omit(props, "label", "inputClassName", "className", "size")}
          />
        ))}
    </>
  );
};

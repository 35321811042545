import { RiskResourceType, RiskTreeNode } from "@features/risk-decisions/types";
import { NODE_INVISIBLE_UNDEFINED } from "@features/risk-decisions/utils";
import {
  ArrowDownOnSquareStackIcon,
  CheckBadgeIcon,
  CalculatorIcon,
  LightBulbIcon,
  PlusIcon,
  TableCellsIcon,
} from "@heroicons/react/24/outline";

import { Node } from "reactflow";

const POSITION_NEW_LEAF = { x: 80, y: 400 };
const POSITION_NEW_LOGIC = { x: 80, y: 500 };

export default function RiskFlowComponentPanel({
  type,
  enableOr,
  addNode,
}: {
  type: RiskResourceType;
  enableOr: boolean;
  addNode: (node: Node<RiskTreeNode>) => void;
}) {
  const addOrNode = () => {
    const date = new Date();
    const id = date.getTime();
    const node: Node<RiskTreeNode> = {
      id: "node_" + id,
      type: "or",
      data: {
        id: "node_" + id,
        name: "Or Component",
        or: {},
        output_node_ids: {
          or_output1: NODE_INVISIBLE_UNDEFINED.id,
          or_output2: NODE_INVISIBLE_UNDEFINED.id,
        },
      },
      position: POSITION_NEW_LOGIC,
    };

    addNode(node);
  };
  const addLeafNode = () => {
    const date = new Date();
    const id = date.getTime();
    const node: Node<RiskTreeNode> = {
      id: "node_" + id,
      type: "leaf",
      data: {
        name: "Leaf component",
        id: "node_" + id,
        leaf: {
          outputs: {
            score: type === "kyt" ? "100" : "L",
            action: type === "press" ? "false" : "not_required",
            alias: "default",
          },
        },
        output_node_ids: {},
      },
      position: POSITION_NEW_LEAF,
    };

    addNode(node);
  };

  const addComparisonNode = () => {
    const date = new Date();
    const id = date.getTime();
    const node: Node<RiskTreeNode> = {
      id: "node_" + id,
      type: "comparison",
      data: {
        name: "Comparison component",
        id: "node_" + id,
        comparison: {
          field: {
            id: 0,
            entity: type === "kyt" ? "transaction" : "customer",
          },
          operator: "",
          value: "",
        },
        output_node_ids: {
          on_default_node_id: NODE_INVISIBLE_UNDEFINED.id,
          on_success_node_id: NODE_INVISIBLE_UNDEFINED.id,
          on_undefined_node_id: NODE_INVISIBLE_UNDEFINED.id,
        },
      },
      position: POSITION_NEW_LOGIC,
    };

    addNode(node);
  };

  const addMatrixNode = () => {
    const date = new Date();
    const id = date.getTime();
    const node: Node<RiskTreeNode> = {
      id: "node_" + id,
      type: "matrix",
      data: {
        id: "node_" + id,
        name: "Matrix component",
        matrix: {
          matrix_id: "",
          field: {
            id: 0,
            entity: type === "kyt" ? "transaction" : "customer",
          },
          use_regex: false,
        },
        output_node_ids: {
          on_low_risk_node_id: NODE_INVISIBLE_UNDEFINED.id,
          on_medium_risk_node_id: NODE_INVISIBLE_UNDEFINED.id,
          on_high_risk_node_id: NODE_INVISIBLE_UNDEFINED.id,
          on_undefined_node_id: NODE_INVISIBLE_UNDEFINED.id,
        },
      },
      position: POSITION_NEW_LOGIC,
    };

    addNode(node);
  };

  const addFormulaNode = () => {
    const date = new Date();
    const id = date.getTime();
    const node: Node<RiskTreeNode> = {
      id: "node_" + id,
      type: "formula",
      data: {
        id: "node_" + id,
        name: "Formula component",
        formula: {
          comparison_operator: "",
          formula: {
            aggregation_operator: "",
            expressions: [
              {
                constant: 0,
              },
            ],
          },
        },
        output_node_ids: {
          on_default_node_id: NODE_INVISIBLE_UNDEFINED.id,
          on_success_node_id: NODE_INVISIBLE_UNDEFINED.id,
          on_undefined_node_id: NODE_INVISIBLE_UNDEFINED.id,
        },
      },
      position: POSITION_NEW_LOGIC,
    };

    addNode(node);
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row -m-2">
        <AddComponentButton
          onClick={addComparisonNode}
          icon={(p) => <LightBulbIcon {...p} />}
          text="Add a comparison node"
        />
        <AddComponentButton
          onClick={addMatrixNode}
          icon={(p) => <TableCellsIcon {...p} />}
          text="Add a matrix node"
        />
        <AddComponentButton
          onClick={addFormulaNode}
          icon={(p) => <CalculatorIcon {...p} />}
          text="Add a formula node"
        />
        <AddComponentButton
          onClick={addLeafNode}
          icon={(p) => <CheckBadgeIcon {...p} />}
          text="Add a leaf node"
        />
        {enableOr && (
          <AddComponentButton
            onClick={addOrNode}
            icon={(p) => (
              <div className="-rotate-90">
                <ArrowDownOnSquareStackIcon {...p} />
              </div>
            )}
            text="Add an Or node"
          />
        )}
      </div>
    </div>
  );
}

const AddComponentButton = (props: {
  onClick: () => void;
  icon: (...args: any[]) => any;
  text: string;
}) => {
  return (
    <span data-tooltip={props.text}>
      <button
        onClick={props.onClick}
        className="relative rounded bg-blue-400 p-3 m-2 hover:bg-blue-500"
      >
        <div className="flex items-center justify-center">
          <props.icon className="h-full w-6 h-6 text-white" />
        </div>

        <div className="absolute bottom-1 right-1 border border-blue-400 w-4 h-4 flex items-center justify-center text-blue-500 text-xs rounded-full bg-white">
          <PlusIcon className="h-3 w-3" />
        </div>
      </button>
    </span>
  );
};

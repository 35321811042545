import { Button } from "@atoms/button/button";
import { Section, Subtitle } from "@atoms/text";
import { Security } from "@components/security/security";
import SingleCenterCard from "@components/single-center-card/single-center-card";
import { useAuth } from "@features/auth/state/hooks";
import { ROUTES } from "@features/routes";
import { useWebAuthn } from "@features/webauthn/state/useWebAuthn";
import { useNavigate } from "react-router-dom";

export function Setup2FA() {
  const { getDevicesQuery } = useWebAuthn();
  const { data: devices } = getDevicesQuery();
  const navigate = useNavigate();
  const { logout, refreshUser } = useAuth();

  return (
    <div className="h-full w-full absolute sm:bg-transparent">
      <SingleCenterCard insetLogo>
        <div className="bg-blue-600 h-full w-full absolute left-0 top-0 -z-10" />

        <div className="text-center">
          <Section>Before to continue...</Section>
          <Subtitle>You need to setup a 2FA method</Subtitle>

          <div className="text-left">
            <Security />
          </div>
          <div className="flex justify-between mt-6">
            <Button
              size="lg"
              theme={"danger"}
              onClick={async () => {
                logout();
              }}
            >
              Log out
            </Button>
            <Button
              size="lg"
              onClick={async () => {
                await refreshUser();
                navigate(
                  decodeURIComponent(
                    new URL((window as any).document.location).searchParams.get(
                      "r"
                    ) || ""
                  ) || ROUTES.Dashboard
                );
              }}
              disabled={!devices || devices.length < 1}
            >
              Continue
            </Button>
          </div>
        </div>
      </SingleCenterCard>
    </div>
  );
}

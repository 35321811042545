import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Base, Info, SectionSmall } from "@atoms/text";
import { useHasAccess } from "@features/auth/state/use-access";
import { usePressReport } from "@features/press/state/use-press-report";
import { formatTime } from "@features/utils/dates";
import { useState } from "react";
import ReactMarkdown from "react-markdown";

export const Notes = ({ customerID }: { customerID: string }) => {
  const { report, addNote } = usePressReport(customerID);
  const hasAccess = useHasAccess();

  const [noteModal, setNoteModal] = useState(false);
  const [note, setNote] = useState("");

  return (
    <div>
      <Modal
        className="min-w-xl"
        open={noteModal}
        onClose={() => setNoteModal(false)}
      >
        <ModalContent title="Add note">
          <Input
            multiline
            rows={5}
            placeholder="Note with **markdown** support"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
          <ButtonConfirm
            className="float-right mt-4"
            confirmTitle="Confirm note"
            confirmMessage="Are you sure this note is ready to be uploaded ? It won't be modifiable afterward"
            onClick={() => {
              addNote(note);
              setNoteModal(false);
            }}
          >
            Add note
          </ButtonConfirm>
        </ModalContent>
      </Modal>

      {hasAccess("PRESS_AGENT") && (
        <Button
          className="float-right"
          size="sm"
          onClick={() => setNoteModal(true)}
        >
          Add note
        </Button>
      )}

      <SectionSmall className="mt-1 mb-2">Notes</SectionSmall>

      {(report?.latest_revision.notes || []).map((note, i) => (
        <div key={i} className="mb-2">
          <Base className="report-notes">
            <ReactMarkdown>{note.body}</ReactMarkdown>
          </Base>
          <Info>
            {note.created_by_agent_name} • {formatTime(note.created_at)}
          </Info>
        </div>
      ))}
      {(report?.previous_revisions || []).map((revision) =>
        (revision.notes || []).map((note, i) => (
          <div key={i} className="mb-2">
            <Base className="report-notes">
              <ReactMarkdown>{note.body}</ReactMarkdown>
            </Base>
            <Info>
              {note.created_by_agent_name} • {formatTime(note.created_at)}
            </Info>
          </div>
        ))
      )}
    </div>
  );
};

import { useCallback } from "react";
import toast from "react-hot-toast";
import { RiskApiClient } from "./api-client/api-client";
import { useRiskFactors } from "./use-risks-decisions";
import { RiskFactorElement, RiskResourceType } from "./types";

export const useRiskElement = (type: RiskResourceType, id: string) => {
  const { loading, refresh, riskFactors } = useRiskFactors(type);

  const element = riskFactors.find(
    (el) => el.id === parseInt(id) || el.id === id
  );

  const remove = useCallback(async () => {
    if (element) {
      try {
        await RiskApiClient.deleteRiskFactor(type, element);
        await refresh();
        toast.success("Risk Element deleted");
      } catch (e) {
        toast.error("Unable to deleted risk element");
        console.error(e);
      }
    }
  }, [element, type, refresh]);

  const update = useCallback(
    async (update: RiskFactorElement) => {
      try {
        await RiskApiClient.updateRiskFactor(type, update);
        toast.success("Risk element updated");
        refresh();
        return (await RiskApiClient.getRiskFactors(type)).find(
          (r) => r.label === update.label
        );
      } catch (e) {
        toast.error("Unable to update risk element");
        console.error(e);
      }
    },
    [refresh, type]
  );

  return {
    loading,
    refresh,
    element,
    remove,
    update,
  };
};

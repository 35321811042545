import { useRecoilState } from "recoil";
import { SessionDecisionsAtom } from "./store";
import { LoadingAtom } from "@features/utils/loading";
import { useCallback } from "react";
import { SessionsApiClient } from "../api-client/api-client";

export const useSessionDecisions = (id: string) => {
    const [decisions, setDecisions] = useRecoilState(SessionDecisionsAtom(id));
    const [loading, setLoading] = useRecoilState(LoadingAtom("useSessionDecisions-" + id));
    
    const refresh = useCallback(async () => {
        setLoading(true);
        const decisions = await SessionsApiClient.getSessionDecisions(id);
        if (decisions) {
        setDecisions(decisions.decisions);
        }
        setLoading(false);
    }, [setDecisions, id, setLoading]);
    
    return { decisions, loading, refresh };
    };
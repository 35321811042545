import { useGlobalEffect } from "@features/utils";
import { useRecoilState } from "recoil";
import { CUSTOMERS_RISKS_BACKEND_NAME } from "../utils";
import { CustomersRisksAtom } from "./store";

export const useCustomerRisks = () => {
  const [risks, setRisks] = useRecoilState(CustomersRisksAtom);

  const refresh = async () => {
    setRisks(CUSTOMERS_RISKS_BACKEND_NAME);
  };

  useGlobalEffect(
    "useCustomerRisks",
    () => {
      refresh();
    },
    []
  );

  return { risks, refresh };
};

import { atom } from "recoil";
import { InboxSettingsMailboxesOnlyType, InboxSettingsType } from "../types";
import { AuthJWT } from "@features/auth/jwt";

export const InboxSettingsAtom = atom<InboxSettingsType>({
  key: "InboxSettingsAtom",
  default: {
    client_id: AuthJWT.clientId?.toString() ?? "",
    mailboxes: [],
    max_thread_customers: 10,
    style_override: "",
    translations_override: "",
  },
});

export const InboxMailboxesAtom = atom<
  InboxSettingsMailboxesOnlyType["mailboxes"]
>({
  key: "InboxMailboxesAtom",
  default: [],
});

import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { PageBlock, PageBlockHr } from "@atoms/layout/page-block";
import Link from "@atoms/link";
import { BaseSmall, Info, SectionSmall } from "@atoms/text";
import { ModalImage } from "@components/modal-image";
import Env from "@config/environment";
import { SessionDocument } from "@features/sessions/types";
import { formatNumber } from "@features/utils";
import { DocumentIcon } from "@heroicons/react/24/outline";

type SessionDocumentsProps = {
  documents: SessionDocument[];
};

export function SessionDocuments({ documents }: SessionDocumentsProps) {
  return (
    <PageBlock>
      <div id="documents" className="flex flex-row justify-between">
        <SectionSmall>Session documents</SectionSmall>
      </div>
      <Info>
        Document that has been uploaded or generated during the session.
      </Info>
      <div className="flex flex-col gap-2 py-2">
        {documents
          .filter((a) => a.is_last_try)
          .map((doc, index) => (
            <Doc key={index} doc={doc} />
          ))}
      </div>
      {!!documents.filter((a) => !a.is_last_try)?.length && (
        <>
          <PageBlockHr />
          <Info>Document from previous tries</Info>
          <div className="flex flex-col gap-2 py-2 opacity-80">
            {documents
              .filter((a) => !a.is_last_try)
              .map((doc, index) => (
                <Doc key={index} doc={doc} />
              ))}
          </div>
        </>
      )}
    </PageBlock>
  );
}

const Doc = ({ doc }: { doc: SessionDocument }) => {
  return (
    <div className="border flex rounded-sm items-center">
      <div className="bg-slate-500 h-14 w-14 p-4 flex items-center justify-center">
        <DocumentIcon className="h-4 w-4 text-white" />
      </div>
      <div className="grow py-1 pl-3">
        <div>
          {doc.file_name && (
            <>
              <BaseSmall>{doc.file_name}</BaseSmall>
              {" • "}
            </>
          )}
          <BaseSmall>{doc.name}</BaseSmall>
          {!doc.is_last_try && doc.try_index !== undefined && (
            <>
              {" • "}
              <Tag>Answer {doc.try_index + 1}</Tag>
            </>
          )}
        </div>
        <div className="-mt-1">
          <Info>
            {doc.file_size && (
              <>{formatNumber(Math.round(doc.file_size / 1024)) + "kb"}, </>
            )}
            {doc.file_type}
          </Info>
        </div>
      </div>
      <div
        className="space-x-2 p-2"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {doc.previews.map((preview, index) => (
          <ModalImage
            key={index}
            className="h-10 w-10 inline-block rounded-sm"
            large={Env.server + preview}
            small={Env.server + preview}
            alt=""
          />
        ))}
      </div>

      <Link
        href={Env.server + doc.url}
        noColor
        target="_blank"
        className="p-2 pr-3"
      >
        <Button size="sm" theme="outlined">
          Download
        </Button>
      </Link>
    </div>
  );
};

import { Button } from "@atoms/button/button";
import { InputLabel } from "@atoms/input/input-decoration-label";
import Select from "@atoms/input/input-select";
import { Input } from "@atoms/input/input-text";
import { Section, Subtitle } from "@atoms/text";
import { PageLoader } from "@components/page-loader";
import { useAuth } from "@features/auth/state/hooks";
import { LoginStep } from "@features/auth/state/store";
import { TwoFAType, TwoFATypes } from "@features/auth/types";
import { FormEvent, useState } from "react";
import toast from "react-hot-toast";

export const Login = () => {
  const { loading: authLoading, login, twoFALogin, loginStep, get2FAChallenge } = useAuth();
  const [server, setServer] = useState(localStorage.getItem("redirect_server"));
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [twoFaMethod, setTwoFaMethod] = useState<TwoFAType>(TwoFAType.WebAuthn);
  const [loading, setLoading] = useState(false);

  if (document.location.search.toLocaleLowerCase().includes("selectserver")) {
    return (
      <div>
        <div className="bg-blue-600 h-full w-full absolute left-0 top-0 -z-10" />

        <div className="text-center">
          <Section>Before to continue...</Section>
          <Subtitle>Please enter your server domain</Subtitle>

          <div className="text-left">
            <InputLabel
              label="Server (ex. sandbox.algoreg.com)"
              className="my-8"
              input={
                <div className="flex">
                  <Input
                    className="grow rounded-r-none"
                    value={server || ""}
                    onChange={(e) => setServer(e.target.value)}
                  />
                  <Button
                    className="shrink-0 rounded-l-none"
                    size="md"
                    loading={loading}
                    onClick={async () => {
                      const fullServer = `https://backoffice.${(server || "")
                        .replace(/^https?:\/\//, "")
                        .replace(/^(backoffice|api|vid|chat)/, "")
                        .replace(/[^a-z0-9.-_/]/gm, "")}/`;
                      localStorage.setItem("redirect_server", fullServer);
                      document.location.href = fullServer;
                    }}
                  >
                    Open server
                  </Button>
                </div>
              }
            />
          </div>
        </div>
      </div>
    );
  }

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);
    try {
      let result: boolean | null = null;
      if (loginStep === LoginStep.NotLoggedIn) {
        result = await login(email, password);
      } else if (loginStep === LoginStep.TwoFA) {
        const data = await get2FAChallenge(twoFaMethod, email);
        result = await twoFALogin(email, password, twoFaMethod, data);
      }
      if (result) {
        toast.success("Logged in successfully");
      } else if (result === false) {
        toast.error("Failed to log in");
      }
    } catch (e) {
      console.error(e);
      toast.error("An error occured");
    }
    setLoading(false);
  };

  return (
    <form action="" onSubmit={onSubmit}>
      <div className="bg-blue-600 h-full w-full absolute left-0 top-0 -z-10" />

      {authLoading && <PageLoader />}
      {!authLoading && loginStep === LoginStep.NotLoggedIn && (
        <>
          <div className="text-center">
            <Section>Welcome back to Algoreg</Section>
            <Subtitle>Sign in to open back-office</Subtitle>
          </div>
          <InputLabel
            label="Email"
            className="mt-4"
            input={
              <Input
                value={email}
                name="username"
                onChange={(e) => setEmail(e.target.value)}
              />
            }
          />
          <InputLabel
            label="Password"
            className="mt-4"
            input={
              <Input
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            }
          />

          <div className="text-center mt-6">
            <Button type="submit" size="lg" loading={loading}>
              Sign in
            </Button>
          </div>

          {/*<div className="text-center mt-4">
            <Link to="/forgot-password">Troubles signing in?</Link>
            </div>*/}
        </>
      )}
      {!authLoading && loginStep === LoginStep.TwoFA && (
        <>
          <div className="text-center">
            <Section>Welcome back to Algoreg</Section>
            <Subtitle>Please select your 2FA method to log in</Subtitle>

            <Select
              value={twoFaMethod}
              className="mt-2"
              onChange={(e) => {
                const method = TwoFATypes[parseInt(e.target.value) as TwoFAType];
                setTwoFaMethod(method.value);
              }}
            >
              {Object.values(TwoFATypes).map((type) => (
                <option value={type.value} key={type.value}>
                  {type.label}
                </option>
              ))}
            </Select>

            <div className="text-center mt-6">
              <Button type="submit" size="lg" loading={loading}>
                Verify using this method
              </Button>
            </div>
          </div>
        </>
      )}
    </form>
  );
};

import { UpdateMultipleAlertsModal } from "@components/bulk-alert-status";
import { RiskElementEditModal } from "@components/risks/modals/metadata";
import { useListenForShortcuts } from "@features/shortcuts";
import { isEmbed } from "@features/utils/use-embed-utils";
import ReportingSettingsSideModal from "./client/account/audit-and-reporting/reporting-settings-modal";
import { AgentEditionModal } from "./client/account/users-management/edition-modal";
import CustomerSideModal from "./client/customers/customer-details/edition-form";
import { EventsTimelineModal } from "./client/customers/customer-details/events-timeline-modal";
import { CustomerStatusUpdateModal } from "./client/customers/customer-details/status-update-modal";
import { AttachmentsModal } from "./client/inbox/components/attachments-modal";
import { CreateMessageModal } from "./client/inbox/components/modals/create-message-modal";
import { UploadAttachmentToCustomersModal } from "./client/inbox/components/modals/upload-attachment-to-customers-modal";
import { InboxModal } from "./client/inbox/modal";
import { ExploreTransactionsFilterModal } from "./client/kyt/explore/query-patterns/query-modal";
import { PostHocPatternModal } from "./client/kyt/saved-searches/component/modal-post-hoc-pattern";
import { TransactionEditionModal } from "./client/kyt/transactions/edition-modal";
import { TransactionStatusUpdateModal } from "./client/kyt/transactions/transaction-details/status-update-modal";
import { AlertStatusUpdateModal } from "./client/scan/alert-details/status-update-modal";
import { ProfileModal } from "./client/scan/shared/profile-modal";
import { DeveloperEditionModal } from "./client/settings/developers/edition-modal";
import CreateSessionModal from "./client/vid/scenario-details/components/create-session-modal";
import { ReportStatusAndRiskUpdateModal } from "./client/press/report-details/status-risk-update-modal";
import CustomerSessionsModal from "./client/vid/session-details/components/customer-sessions-modal";
import SessionDecisionAddModal from "./client/vid/session-details/components/decision-add-modal";

export const Modals = () => {
  useListenForShortcuts();

  if (isEmbed()) {
    return <></>;
  }

  return (
    <>
      <TransactionEditionModal />
      <InboxModal />
      <DeveloperEditionModal />
      <AgentEditionModal />
      <AlertStatusUpdateModal />
      <CustomerStatusUpdateModal />
      <TransactionStatusUpdateModal />
      <ExploreTransactionsFilterModal />
      <PostHocPatternModal />
      <ProfileModal />
      <CustomerSideModal />
      <UpdateMultipleAlertsModal />
      <EventsTimelineModal />
      <CreateMessageModal />
      <AttachmentsModal />
      <UploadAttachmentToCustomersModal />
      <ReportingSettingsSideModal />
      <RiskElementEditModal resource="customer" />
      <RiskElementEditModal resource="kyt" />
      <CreateSessionModal />
      <CustomerSessionsModal />
      <SessionDecisionAddModal />
      <ReportStatusAndRiskUpdateModal />
    </>
  );
};

import { useDashboard } from "@features/dashboard/state/hooks";
import { RISKS_COLORS_HEX, RISK_LABEL } from "@features/risk-decisions/utils";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const RiskLevelsChart = () => {
  const { risk } = useDashboard();

  const riskInfoLevels = Object.values(
    risk?.global_risk_info.risk_levels || {}
  ).filter((a) => a.value > 0);

  return (
    <>
      <div className="h-16 w-40 mb-4">
        <Bar
          options={{
            animations: false as any,
            indexAxis: "y" as const,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  title: () => "",
                },
              },
            },
            interaction: {
              mode: "nearest",
              axis: "y",
              intersect: false,
            },
            scales: {
              x: {
                grid: {
                  display: false,
                },
                border: {
                  display: false,
                },
                ticks: {
                  display: false,
                },
              },
              y: {
                position: "right",
                grid: {
                  display: false,
                },
                border: {
                  display: false,
                },
              },
            },
            maintainAspectRatio: false,
          }}
          data={{
            labels: riskInfoLevels.map(
              (a) => `${(RISK_LABEL as any)[a.id] || "Unknown"}`
            ),
            datasets: [
              {
                borderRadius: Number.MAX_VALUE,
                borderSkipped: false,
                minBarLength: 8,
                data: riskInfoLevels.map((a) => a.value),
                backgroundColor: riskInfoLevels.map(
                  (a) => (RISKS_COLORS_HEX as any)[a.id] || "#000000"
                ),
              },
            ],
          }}
        />
      </div>
    </>
  );
};

import { Tag } from "@atoms/badge/tag";
import { Base } from "@atoms/text";
import { SessionScenarioMonitoringType } from "@features/sessions/types";
import { formatTime } from "@features/utils/dates";
import { twMerge } from "tailwind-merge";

export default function ScenarioMonitoring(props: { monitorings: string[] }) {
  const monitorings: SessionScenarioMonitoringType[] = props.monitorings.map(
    (monitoring) => {
      return JSON.parse(monitoring);
    }
  );
  return (
    <div className="flex flex-col" id="monitoring">
      {monitorings.map((monitoring, index) => {
        return (
          <div key={index} className="flex flex-row space-x-2">
            <div>
              <Tag
                noColor
                className={twMerge(
                  "w-12",
                  monitoring.type === "error"
                    ? "bg-red-500 text-white"
                    : "bg-slate-500 text-white"
                )}
              >
                {monitoring.type}
              </Tag>
            </div>
            <div className="grow">
              <Base>
                {monitoring.service}:<b>{monitoring.message}</b>
              </Base>
            </div>
            <div>{formatTime(monitoring.time)}</div>
          </div>
        );
      })}
    </div>
  );
}

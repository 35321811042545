import { Button } from "@atoms/button/button";
import {
  CustomMatrixType,
  MatrixEntryType,
} from "@features/custom-matrix/types";
import { useRef, useState } from "react";
import PapaParse from "papaparse";
import toast from "react-hot-toast";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { useMatrixEntries } from "@features/custom-matrix/state/use-matrix-entries";

export default function CustomMatrixCsv({
  matrix,
  matrixEntries,
}: {
  matrix: CustomMatrixType;
  matrixEntries: MatrixEntryType[];
}) {
  const { refresh, createMatrixEntry, deleteMatrixEntry, editMatrixEntry } =
    useMatrixEntries(matrix?.id);

  const fileRef = useRef<HTMLInputElement>(null);
  const [confirmModal, setConfirmModal] = useState<{
    added: { label: string; key: string; value: string }[];
    updated: { label: string; key: string; value: string }[];
    deleted: { label: string; key: string; value: string }[];
  } | null>(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const uploadedFile = async (file: File) => {
    fileRef.current!.value = "";
    PapaParse.parse(file, {
      header: true,
      worker: true,
      complete: (results) => {
        if (
          results.errors.length > 0 ||
          !results.data.some((e: any) => e.key) ||
          !results.data.some((e: any) => e.value) ||
          !results.data.some((e: any) => e.label) ||
          results.data.some(
            (e: any) =>
              !["L", "M", "H"].includes(
                (e.value || "").toLocaleUpperCase().trim()[0] || "L"
              )
          )
        ) {
          toast.error(
            "There was an error while parsing the file, check your file format and content."
          );
        } else {
          const added = [];
          const updated = [];
          const deleted = [];

          for (const entry of results.data.map((a: any) => ({
            label: (a.label || "").trim(),
            key: (a.key || "").trim(),
            value: (a.value || "").toLocaleUpperCase().trim()[0] || "L",
          })) as {
            label: string;
            key: string;
            value: string;
          }[]) {
            const existing = matrixEntries.find((e) => e.key === entry.key);
            if (existing) {
              if (existing.value !== entry.value) {
                updated.push({
                  label: entry.label || existing.label,
                  key: entry.key,
                  value: entry.value,
                });
              }
            } else {
              added.push({
                label: entry.label,
                key: entry.key,
                value: entry.value,
              });
            }
          }

          for (const entry of matrixEntries) {
            if (!results.data.find((e: any) => e.key === entry.key)) {
              deleted.push({
                label: entry.label,
                key: entry.key,
                value: entry.value,
              });
            }
          }

          setConfirmModal({
            added,
            updated,
            deleted,
          });
        }
      },
    });
  };

  return (
    <>
      <Modal open={!!confirmModal} onClose={() => setConfirmModal(null)}>
        <ModalContent title="Confirm changes">
          By importing this file you will:
          <ul>
            {confirmModal &&
              confirmModal?.added.length +
                confirmModal?.updated.length +
                confirmModal?.deleted.length ===
                0 && <li>- Do nothing (no changes detected)</li>}
            {confirmModal?.added && confirmModal?.added.length > 0 && (
              <li>- Add {confirmModal.added.length} new entries</li>
            )}
            {confirmModal?.updated && confirmModal?.updated.length > 0 && (
              <li>- Update {confirmModal.updated.length} entries</li>
            )}
            {confirmModal?.deleted && confirmModal?.deleted.length > 0 && (
              <li>- Delete {confirmModal.deleted.length} entries</li>
            )}
          </ul>
          <Button
            theme="primary"
            loading={loading}
            className="mt-4"
            onClick={async () => {
              setLoading(true);
              setProgress(0);
              const divisor =
                (confirmModal?.added?.length || 0) +
                  (confirmModal?.deleted?.length || 0) +
                  (confirmModal?.updated?.length || 0) || 1;
              let count = 0;

              for (const entry of confirmModal?.added || []) {
                await createMatrixEntry(
                  {
                    matrix_id: matrix?.id,
                    label: entry.label,
                    key: entry.key,
                    value: entry.value as "L" | "M" | "H",
                  },
                  false
                );
                count++;
                setProgress(count / divisor);
              }

              for (const entry of confirmModal?.updated || []) {
                const existing = matrixEntries.find((e) => e.key === entry.key);
                if (existing) {
                  await editMatrixEntry(
                    {
                      id: existing.id,
                      matrix_id: matrix?.id,
                      label: entry.label,
                      key: entry.key,
                      value: entry.value as "L" | "M" | "H",
                    },
                    false
                  );
                }
                count++;
                setProgress(count / divisor);
              }

              for (const entry of confirmModal?.deleted || []) {
                const existing = matrixEntries.find((e) => e.key === entry.key);
                if (existing) {
                  await deleteMatrixEntry(
                    {
                      id: existing.id,
                      matrix_id: matrix?.id,
                      label: existing.label,
                      key: existing.key,
                      value: existing.value as "L" | "M" | "H",
                    },
                    false
                  );
                }
                count++;
                setProgress(count / divisor);
              }

              await refresh();

              setLoading(false);
              setConfirmModal(null);
            }}
          >
            {progress > 0 && loading
              ? `Importing (${Math.floor(progress * 100)}%...)`
              : "Confirm import"}
          </Button>
        </ModalContent>
      </Modal>

      <input
        type="file"
        onChange={async (e) => {
          const file = e.target.files?.[0];
          if (file) {
            uploadedFile(file);
          }
        }}
        className="hidden"
        ref={fileRef}
      />
      <Button
        className="ml-2"
        theme="outlined"
        size="sm"
        onClick={async () => {
          let content = "label;key;value\n";
          content += matrixEntries
            .map((entry) => entry.label + ";" + entry.key + ";" + entry.value)
            .join("\n");

          const link = document.createElement("a");
          const file = new Blob([content], { type: "text/plain" });
          link.href = URL.createObjectURL(file);
          link.download =
            "algoreg-matrix-" +
            matrix?.matrix_type
              .toLocaleLowerCase()
              .replace(/[^a-z0-9]/gm, "-") +
            ".csv";
          link.click();
          URL.revokeObjectURL(link.href);
        }}
      >
        Download as CSV
      </Button>
      <Button
        className="ml-2"
        theme="outlined"
        size="sm"
        onClick={async () => {
          fileRef.current?.click();
        }}
      >
        Update from CSV
      </Button>
    </>
  );
}
